/* Copyright (C) 2020 Accenture. All rights reserved. */
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
/*
    HORIZONTAL NAVIGATION STYLE
*/
/* Copyright (C) 2020 Accenture. All rights reserved. */ /* Copyright (C) 2020 Accenture. All rights reserved. */ /* Copyright (C) 2020 Accenture. All rights reserved. */
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
.uil-button {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: none;
  padding: 0.5rem 1rem;
  text-align: center;
  outline: none !important;
  line-height: 1rem;
}
.uil-button [class^=uil-icon-],
.uil-button [class*=uil-icon-],
.uil-button [class^=uil-dl-icon-],
.uil-button [class*=uil-dl-icon-] {
  padding-right: 8px;
}
.uil-button [class^=uil-icon-]:only-child,
.uil-button [class*=uil-icon-]:only-child,
.uil-button [class^=uil-dl-icon-]:only-child,
.uil-button [class*=uil-dl-icon-]:only-child {
  padding-right: 0;
}
.uil-button [class^=uil-dl-icon-],
.uil-button [class*=uil-dl-icon-] {
  font-weight: 600;
  font-size: 1rem;
}
.uil-button [class^=uil-dl-icon-]:only-child,
.uil-button [class*=uil-dl-icon-]:only-child {
  font-weight: 600;
  font-size: 1rem;
}
.disabled.uil-button, [disabled].uil-button, fieldset[disabled] .uil-button {
  cursor: not-allowed;
}

.uil-button {
  border: none !important;
  -webkit-transition: visibility 0s;
  transition: visibility 0s;
  background: var(--color-bg-primaryDark);
  color: var(--color-font-light);
}
.uil-button [class^=uil-dl-icon-],
.uil-button [class*=uil-dl-icon-] {
  color: var(--color-font-light);
}
.uil-button a {
  color: var(--color-font-light);
}
.uil-button a [class^=uil-dl-icon-],
.uil-button a [class*=uil-dl-icon-] {
  color: var(--color-font-light);
}
.uil-button.disabled, .uil-button[disabled], fieldset[disabled] .uil-button {
  color: #abb0b6 !important;
  background: #ccd1d7 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.uil-button.disabled [class^=uil-icon-],
.uil-button.disabled [class*=uil-icon-],
.uil-button.disabled [class^=uil-dl-icon-],
.uil-button.disabled [class*=uil-dl-icon-], .uil-button[disabled] [class^=uil-icon-],
.uil-button[disabled] [class*=uil-icon-],
.uil-button[disabled] [class^=uil-dl-icon-],
.uil-button[disabled] [class*=uil-dl-icon-], fieldset[disabled] .uil-button [class^=uil-icon-],
fieldset[disabled] .uil-button [class*=uil-icon-],
fieldset[disabled] .uil-button [class^=uil-dl-icon-],
fieldset[disabled] .uil-button [class*=uil-dl-icon-] {
  color: #abb0b6 !important;
}
.uil-button.pressed {
  color: var(--color-font-light);
  background-color: var(--primary-02);
}
.uil-button.pressed [class^=uil-dl-icon-],
.uil-button.pressed [class*=uil-dl-icon-] {
  color: var(--color-font-light);
}
.uil-button.active, .uil-button:active, .uil-button:focus:active, .uil-button:focus:hover:active, .uil-button:hover:active {
  color: var(--color-font-light);
  background-color: var(--color-bg-primaryLight);
  border-style: none;
}
.uil-button.active [class^=uil-dl-icon-],
.uil-button.active [class*=uil-dl-icon-], .uil-button:active [class^=uil-dl-icon-],
.uil-button:active [class*=uil-dl-icon-], .uil-button:focus:active [class^=uil-dl-icon-],
.uil-button:focus:active [class*=uil-dl-icon-], .uil-button:focus:hover:active [class^=uil-dl-icon-],
.uil-button:focus:hover:active [class*=uil-dl-icon-], .uil-button:hover:active [class^=uil-dl-icon-],
.uil-button:hover:active [class*=uil-dl-icon-] {
  color: var(--color-font-light);
}
.uil-button:focus, .uil-button:hover {
  color: var(--color-font-light);
  background: var(--color-bg-primaryLight);
}
.uil-button:focus [class^=uil-dl-icon-],
.uil-button:focus [class*=uil-dl-icon-], .uil-button:hover [class^=uil-dl-icon-],
.uil-button:hover [class*=uil-dl-icon-] {
  color: var(--color-font-light);
}
.uil-button a:focus,
.uil-button a:hover {
  color: var(--color-font-light);
  background: var(--color-bg-primaryLight);
}
.uil-button a:focus [class^=uil-dl-icon-],
.uil-button a:focus [class*=uil-dl-icon-],
.uil-button a:hover [class^=uil-dl-icon-],
.uil-button a:hover [class*=uil-dl-icon-] {
  color: var(--color-font-light);
}
.uil-button:focus {
  background: var(--color-bg-primaryDark);
  -webkit-box-shadow: var(--primary-03) 0px 0px 0px 2px;
          box-shadow: var(--primary-03) 0px 0px 0px 2px;
  outline: none;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-button:focus:hover {
  background: var(--color-bg-primaryDark);
}

button[class*=uil-btn] span[class*=uil-icon]:not(:only-child) {
  padding-right: 8px;
}

.uil_button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  gap: 8px;
}

.uil_button span {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  line-height: 140%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: inherit;
  position: relative;
}

.disabled.uil_button label {
  cursor: not-allowed;
}

.uil_btn_square {
  border-radius: 0px;
}

.uil_btn_round {
  border-radius: 24px;
}

.uil_btn_primary {
  background: var(--color-bg-dark);
  border: none;
  color: var(--color-font-light);
}

.uil_btn_primary:hover,
.uil_btn_primary:focus {
  background: var(--color-bg-primaryDark);
  border: none;
  color: var(--color-font-light);
}

.disabled.uil_btn_primary,
.disabled.uil_btn_primary {
  color: var(--color-font-light);
  background: var(--color-bg-disabledDark);
  cursor: not-allowed;
}

.uil_btn_default {
  background: #004dff;
  border: none;
  color: var(--color-font-light);
}

.uil_btn_default:hover,
.uil_btn_default:focus {
  background: var(--primary-02);
  border: none;
  color: var(--color-font-light);
}

.disabled.uil_btn_default,
.disabled.uil_btn_default {
  color: var(--color-font-light);
  background: var(--color-bg-disabledDark);
  cursor: not-allowed;
}

.uil_btn_secondary {
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-dark);
  color: var(--color-font-dark);
}

.uil_btn_secondary:hover,
.uil_btn_secondary:focus {
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-primaryDark);
  color: var(--color-font-primaryDark);
}

.disabled.uil_btn_secondary,
.disabled.uil_btn_secondary {
  color: var(--color-bg-disabledDark);
  background: var(--color-bg-light);
  cursor: not-allowed;
  border: 1px solid var(--color-border-default);
}

.uil_btn_link {
  background: none;
  border: none;
  color: var(--color-font-primaryLight);
}

.uil_btn_link:hover,
.uil_btn_link:focus {
  background: none;
  border: none;
  color: var(--color-font-primaryDark);
}

.disabled.uil_btn_link,
.disabled.uil_btn_link {
  color: var(--color-font-disabled);
  background: none;
  cursor: not-allowed;
}

.uil_btn_fullwidth {
  width: 100%;
  height: 48px;
}

.uil_btn_large {
  width: 132px;
  height: 48px;
}

.uil_btn_medium {
  width: 93px;
  height: 32px;
}

.uil_btn_icononly {
  width: 32px;
  height: 32px;
}

.uil_btn_fitcontent {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.uil_btn_withicon_large {
  width: 160px;
  height: 48px;
}

.uil_btn_withicon_medium {
  width: 117px;
  height: 32px;
}

.uil_btn_withicon_fullwidth {
  width: 100%;
  height: 48px;
}

.uil_btn_icononly_large {
  width: 48px;
  height: 48px;
}

.uil_btn_icononly_medium {
  width: 32px;
  height: 32px;
}

.uil_btn_icononly_fullwidth {
  width: 100%;
  height: 48px;
}

.uil-dl-icon-downloadarrow:before {
  content: "\e953";
  color: inherit;
}

.disabled.uil_button,
.disabled.uil_button.active,
.disabled.uil_button:active,
.disabled.uil_button:focus:active,
.disabled.uil_button:focus:hover:active,
.disabled.uil_button:hover:active,
.disabled.uil_button:focus:hover,
.disabled.uil_button:focus,
.disabled.uil_button:hover {
  -webkit-box-shadow: none;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.uil_button_title {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  line-height: 140%;
  padding: 8px;
}

.uil_btn_fitcontent_large {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  padding-left: 40px;
  padding-right: 40px;
}

.uil_btn_fitcontent_medium {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 29px;
  padding-left: 40px;
  padding-right: 40px;
}
/*# sourceMappingURL=uil_button.module.css.map */