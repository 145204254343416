.uil-icon-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: 800px;
  align-content: flex-start;
}
.uil-icon-seach-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}
.uil-icon-preview-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 116px;
  width: 116px;
  padding: 8px 8px;
  margin: 0 12px 12px 0;
  background-color: #f4f4f4;
}
.uil-btn-icons,
.uil-btn-icons:hover {
  background-color: inherit;
  color: black;
  border: none;
}
