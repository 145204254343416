.uil-content {
  height: 1024px;
  width: 100rem;
  top:50px;
  position: relative;
}
.uil-page-header {
  width: 816px;
  height: 52px;
  top: 50px;
  left: 30px;
  position: relative;
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-page-desc {
  font-family: Graphik;
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 19.2px;
  letter-spacing: 0em;
  text-align: left;
  width: 657px;
  height: 151px;
  top: 100px;
  left: 600px;
  position: relative;
}
.uil-page-image {
  width: 465px;
  height: 262px;
  top: 120px;
  left: 30px;
  position: absolute;
  color: var(--color-font-disabled);
}

.uil-video {
  height: 262px;
  width: 465px;
}
