.fillRectangle {
  box-sizing: border-box;
  width: 50px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #ccd1d7;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.hexCodeContent {
  margin-left: 60px;
  top: 3px;
  position: relative;
}
.divFooter {
  border-top: 1px solid rgb(204, 209, 215);
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: calc((100vh - 42px) + 1700px);
  padding-bottom: 8px;
  justify-content: flex-start;
  left: 80px;
  position: absolute;
}

.div-tabbar {
  background: #f0f0f0;
}

#divTabBar{
  text-align: initial;
}

.tab-bar{
  right:0 ;
}

.componentContentSection {
  position: relative;
  left: 160px;
  text-align: initial;
  margin-right: 250px;
}

.developerContentSection {
  position: relative;
  left: 160px;
  text-align: initial;
  margin-right: 250px;
}

.uil_exapandablecard {
  width: 800px !important;
}

.cardSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  top: 40px;
  position: relative;
}

.developerSections {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
}

.cardTitle{
  font-family: 'Graphik';
  font-size: 20px;
  font-weight: 500;
  font-style: "normal";
}

.cardTitleBadge{
  position:relative;
  /* top:2px; */
  white-space: nowrap;
}

.uil-bglight-button{
  background-color: var(--color-bg-primaryLight) !important;
  border-color: var(--color-bg-primaryLight) !important;
  color: var(--color-font-light) !important;
}

.uil-bgDark-button{
  background-color: var(--color-bg-secondaryDark) !important;
  border-color: var(--color-bg-secondaryDark) !important;
  color: var(--color-font-light) !important;
}

.uil-fontred-button{
  color: var(--color-font-red) !important;
}
.uil-fontgreen-button{
  color: var(--color-font-green) !important;
}

.uil-bordergrey-button{
  border-color: var(--color-border-default) !important;
}
.uil-bordererror-button{
  border-color: var(--color-border-error) !important;
}
.uil-custom-banner{
  background-position-y: center;
  width: 100%;
  position: relative;
  left: 80px;
}

.uil_tabbar_uil-tabbar-default-without-border__DlFaB:hover{
  box-shadow:none !important;
}

.uil-dl-icon-Chevron-up::before{
  content:'\e92d';
  color: #000;
  top: 2px;
  position: relative;
  font-size: var(--font-size-title-lg);
}

.uil-dl-icon-Chevron-down::before{
  content:'\e92c';
  color: #000;
  top: 2px;
  position: relative;
  font-size: var(--font-size-title-lg);
}
.uil-bg-button{
  background-color: var(--color-bg-primaryLight);
}
.uil_exapandablecard_content {
  line-height: 14.4px;
}