.uil-card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.uil-card-wrapper-selected {
  border: 4px solid;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    #6f00ef 0%,
    rgba(181, 0, 239, 0.84) 41.15%,
    #ff6c00 100%
  );
}
.uil-card-bottom {
  border: 1px solid var(--color-border-default);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.uil-card-bottom-right-button {
  border-left: 1px solid var(--color-border-default);
}
.uil-card-bottom-right-button:hover {
  border-left: 1px solid var(--color-border-default);
}
.uil-card-bottom-right-button:focus {
  border-left: 1px solid var(--color-border-default);
}
.uil-card-bottom button {
  color: var(--color-font-dark);
}
.uil-basic-card {
  padding: 24px;
  /* padding: 24px 24px 0 24px; */
  /* top: 761px; */
  left: 176px;
  height: 172px;
  width: 523px;
}
.uil-basic-card2 {
  padding: 24px;
  left: 737px;
  height: 172px;
  width: 523px;
}
.uil-card .uil-basic-card-titlecheckbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-card .uil-basic-card-eyebrow {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  color: var(--color-font-primaryLight);
  margin: 0;
}
.uil-card .uil-basic-card-logo {
  height: 64px;
  width: 64px;
  margin-top: 16px;
  border: 1px solid var(--grey-medium);
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}
.uil-card .uil-basic-card-title {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: "Graphik";
  gap: 8px;
  display: flex;
}
.uil-card .uil-basic-card-captions {
  color: var(--grey-medium);
  margin-bottom: 8px;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
}
.uil-card .uil-basic-card-description {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  padding: 16px 24px;
  margin: 0;
  color: var(--grey-medium);
  letter-spacing: normal;
}

.uil-card .uil-image-card {
  width: 256px;
  height: 198px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.uil-card .uil-image-card-upper {
  padding: 24px 24px 0 24px;
  height: 112px;
}
.uil-card .uil-image-card-titlecheckbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-card .uil-image-card-eyebrow {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  color: var(--color-font-primaryLight);
  margin: 0;
}
.uil-card .uil-image-card-logo {
  height: 64px;
  width: 64px;
  margin-top: 16px;
  border: 1px solid var(--grey-medium);
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}
.uil-card .uil-image-card-title {
  color: #fff;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: "Graphik";
  top: 120px;
  position: relative;
}
.uil-card .uil-image-card-captions {
  color: var(--grey-medium);
  margin-bottom: 8px;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
}
.uil-card .uil-color-card {
  background: var(--color-bg-secondaryDark);
  padding: 24px 24px 0 24px;
}
.uil-card .uil-color-card-titlecheckbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-card .uil-color-card-title {
  color: white;
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: "Graphik";
}
.uil-card .uil-color-card-eyebrow {
  color: white;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.uil-card .uil-color-card-captions {
  color: white;
  margin-bottom: 8px;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
}
.uil-card .uil-color-card-logo {
  height: 64px;
  width: 64px;
  margin-top: 16px;
  border: 1px solid var(--grey-medium);
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}
.uil-card .uil-logo-card {
  padding: 16px 16px 0 16px;
  height: 220px;
}
.uil-card .uil-logo-card-threedot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.uil-card .uil-logo-card-without-border {
  border: 0;
  padding-left: 0;
}
.uil-card .uil-logo-card-body {
  padding: 16px 16px 0 16px;
}
.uil-card .uil-logo-card-captions {
  color: var(--grey-medium);
  font-size: 12px;
  margin: 0;
  margin-bottom: 8px;
}

.uil-color-checkbox {
  background-color: inherit !important;
}

.uil-image-checkbox {
  background-color: inherit !important;
}
/*# sourceMappingURL=uil_card_new.module.css.map */
