.uil-footer {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  left: 0px;
  bottom: 0;
  width: auto;
  background: var(--color-bg-light);
  border-top: 1px solid var(--color-border-default);
  
  position: relative;
}

.uil-footer-basic {
  padding: 24px;
  position: absolute;
  height: auto;
  width: 100%;
  overflow: hidden;
}

.uil-footer-text {
  font-style: normal;
  font-family: var(--font-family);
  font-size: 13.5px;
  /* //var(--font-size-body-sm); */
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-greyDark);
  text-align: initial;
}

.uil-footer-text-right {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  /* padding: 6px 8px; */
  color: var(--color-font-greyDark);
  float: right;
}

.uil-left {
  padding-left: 16px;
  border-left: 1px solid var(--color-border-default);
}

.uil-right {
  padding-right: 16px;
}

.uil-footer-basic.uil-footer-padding-small,
.uil-footer-detailed.uil-footer-padding-small {
  padding-left: 32px;
  padding-right: 32px;
}

.uil-footer-basic.uil-footer-padding-medium,
.uil-footer-detailed.uil-footer-padding-medium {
  padding-left: 80px;
  padding-right: 80px;
}

.uil-footer-basic.uil-footer-padding-large,
.uil-footer-detailed.uil-footer-padding-large {
  padding-left: 96px;
  padding-right: 80px;
}
.div-footer {
  left: 84px;
  position: relative;
}

.uil-footer-detailed {
  padding: 56px 24px;
  /* position: absolute; */
  height: 261px;
  width: 1440px;
}

.uil-footer-brand-copyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 305px;
  height: auto;
}

.uil-footer-logos {
  margin-bottom: 21px;
}

.uil-footer-logo-image {
  width: 101px;
  height: 28px;
  border: 0;
  margin-right: 16px;
}

.uil-footer-appcoe {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-title-lg);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-dark);
  padding-left: 16px;
  border-left: 2px solid var(--color-border-default);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
}

.uil-footer-hyperlinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0px;
  gap: 16px;
  position: relative;
  right: 20px;
  width: 126px;
  height: 100%;
  list-style-type: none;
  top:15px;
}

.uil-footer-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 8px;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  -ms-flex-positive: 0;
      flex-grow: 0;
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-bg-dark) !important;
}

.uil-footer-helpfeedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 437px;
  height: 100%;
  top: 20px;
  position: relative
}

.uil-footer-feedback {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-md);
  line-height: 120%;
  color: var(--color-font-dark);
}

.uil-footer-button {
  width: 200px;
  padding: 14px 40px;
  margin-bottom: 10px;
}

.uil-footer-small-links {
  list-style-type: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.uil-footer-small-links > span + span:before {
  content: "|";
  color: var(--color-font-disabled);
  font-weight: 400;
  padding: 0 16px;
}

.uil-footer a {
  color: var(--color-font-greyDark);
}
/*# sourceMappingURL=uil_footer.module.css.map */