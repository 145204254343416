.uil-design-content {
  height: 1524px;
  width: 100rem;
  top:50px;
  position: relative;
}

.uil-design-header {
  width: 816px;
  height: 52px;
  top: 50px;
  left: 30px;
  position: relative;
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-design-desc {
  width: 800px;
  height: 108px;
  top: 40px;
  position: relative;
  font-family: Graphik;
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 19.2px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-token-tiers {
  height: 52px;
  top: 230px;
  left: 30px;
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.uil-section-title {
  width: 816px;
  height: 52px;
  top: 350px;
  left: 30px;
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.uil-accordion-style {
  padding-top: 40px;
  line-height: 19.6px;
}

.uil-card{
  height:fit-content;
}

.uil-token-cards {
  width: 296px;
  top: 260px;
  left: 30px;
  display: flex;
  gap: 32px;
  position: relative;
}
.uil-token-card-header {
  width: 300px;
  height: 40px;
  padding: 16px 48px 0px 24px;
  font-family: Graphik;
  font-size: var(--font-size-title-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-token-card-caption {
  width: 346px;
  height: 88px;
  padding: 16px 24px 16px 24px;
  font-family: Graphik;
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-greyMedium);
  line-height: 14.4px;
  letter-spacing: 0em;
  text-align: left;
}
.uil-token-tag {
  display: grid;
  text-align: center;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.uil-tag.uil-size-medium {
  height: 42px;
  min-width: fit-content;
}
.uil-tag-round-styles{
  border-color:var(--brand-03);
}

.hexCode {
  position: absolute;
  margin-left: 55px;
  display: flex;
  color:var(--color-font-dark);
}
.filledRectangle {
  box-sizing: border-box;
  width: 50px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--color-border-default);
}
.uil-tag1 {
  width: 100px;
}
.uil-dl-icon-Arrow-down::before {
  width: 34px;
  font-size: var(--font-size-heading-md);
  top: 1034px;
  left: 333px;
  border: 1px;
  display: contents;
  color: var(--primary-dark);
}
.uil-tag-icon{
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
