.uil_exapandablecard {
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 472px;
  height: auto;
  
}
.uil_exapandablecard_wrapper {
  padding: 1rem 1.5rem;
}
.uil_exapandablecard_title {
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
}
.uil_exapandablecard_subtitle {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  color: var(--color-font-greyDark);
  margin: 0;
}
.uil_exapandablecard_content {
  padding: 0.8px 1.5rem 16px;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  color: var(--color-font-greyMedium);
}
.uil_exapandablecard_title_arrow_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
}
.uil_exapandablecard_button_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  background: var(--color-bg-disabledLight);
  padding: 16px;
  justify-content: flex-end;
}
.uil_exapandablecard_button_wrapper-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.uil_exapandablecard_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 10px;
  padding: 16px 24px 16px 24px;
}

.uil-dl-icon-Chevron-expandable-up::before{
  content:'\e92d';
}

.uil-dl-icon-Chevron-expandable-down::before{
  content:'\e92c';
}
/*# sourceMappingURL=uil_expandablecard.css.map */