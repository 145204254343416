/* Copyright (C) 2021 Accenture. All rights reserved. */
.uil-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 8px;
  background-color: var(--color-bg-light);
  color: var(--color-font-dark);
  height: 32px;
  border: 1px solid var(--color-border-default);
  border-radius: 0;
}
.uil-tag .uil-tag-text,
.uil-tag .uil-dl-icon-Close {
  color: var(--color-font-dark);
}

.uil-tag.disabled,
.uil-tag[disabled],
fieldset[disabled] .uil-tag {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
  cursor: not-allowed;
}
.uil-tag.disabled .uil-tag-text,
.uil-tag.disabled .uil-dl-icon-Close,
.uil-tag[disabled] .uil-tag-text,
.uil-tag[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag .uil-tag-text,
fieldset[disabled] .uil-tag .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.disabled:hover,
.uil-tag[disabled]:hover,
fieldset[disabled]:hover .uil-tag {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-success {
  background-color: #008731;
}
.uil-tag.uil-type-success .uil-dl-icon-Close,
.uil-tag.uil-type-success .uil-tag-text {
  color: var(--color-font-light);
}

.uil-tag.uil-type-success.disabled,
.uil-tag.uil-type-success[disabled],
fieldset[disabled] .uil-tag.uil-type-success {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-success.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-success.disabled .uil-tag-text,
.uil-tag.uil-type-success[disabled] .uil-dl-icon-Close,
.uil-tag.uil-type-success[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-success .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-success .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-primary {
  background-color: var(--color-bg-primaryLight);
}
.uil-tag.uil-type-primary .uil-tag-text,
.uil-tag.uil-type-primary .uil-dl-icon-Close {
  color: #000;
}

.uil-tag.uil-type-primary.disabled,
.uil-tag.uil-type-primary[disabled],
fieldset[disabled] .uil-tag.uil-type-primary {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-primary.disabled .uil-tag-text,
.uil-tag.uil-type-primary.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-primary[disabled] .uil-tag-text,
.uil-tag.uil-type-primary[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-primary .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-primary .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-secondary {
  background-color: var(--color-bg-light);
  border: 1px solid var(--color-border-dark);
}
.uil-tag.uil-type-secondary .uil-tag-text,
.uil-tag.uil-type-secondary .uil-dl-icon-Close {
  color: var(--color-font-dark);
}

.uil-tag.uil-type-secondary.disabled {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-secondary.disabled .uil-tag-text,
.uil-tag.uil-type-secondary.disabled .uil-dl-icon-Close {
  color: var(--color-font-disabled);
  background: none;
  cursor: not-allowed;
}
.uil-tag.uil-type-selected {
  border: 1px solid var(--color-border-primaryLight);
}
.uil-tag.uil-type-selected:is(.disabled) {
  border: 1px solid var(--color-border-default);
}
.uil-tag.uil-type-warning {
  background-color: #ff9f00;
}
.uil-tag.uil-type-warning .uil-tag-text,
.uil-tag.uil-type-warning .uil-dl-icon-Close {
  color: var(--color-font-light);
}

.uil-tag.uil-type-warning.disabled,
.uil-tag.uil-type-warning[disabled],
fieldset[disabled] .uil-tag.uil-type-warning {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-warning.disabled .uil-tag-text,
.uil-tag.uil-type-warning.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-warning[disabled] .uil-tag-text,
.uil-tag.uil-type-warning[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-warning .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-warning .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-error {
  background-color: #ff2825;
}
.uil-tag.uil-type-error .uil-tag-text,
.uil-tag.uil-type-error .uil-dl-icon-Close {
  color: var(--color-font-light);
}

.uil-tag.uil-type-error.disabled,
.uil-tag.uil-type-error[disabled],
fieldset[disabled] .uil-tag.uil-type-error {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-error.disabled .uil-tag-text,
.uil-tag.uil-type-error.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-error[disabled] .uil-tag-text,
.uil-tag.uil-type-error[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-error .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-error .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-info {
  color: #004dff;
  background-color: rgba(0, 77, 255, 0.1490196078);
}
.uil-tag.uil-type-info .uil-tag-text,
.uil-tag.uil-type-info .uil-dl-icon-Close {
  color: #004dff;
}
.uil-tag.uil-type-info:focus .uil-tag-text,
.uil-tag.uil-type-info:focus .uil-dl-icon-Close,
.uil-tag.uil-type-info:hover .uil-tag-text,
.uil-tag.uil-type-info:hover .uil-dl-icon-Close {
  color: #004dff;
}
.uil-tag.uil-type-info.disabled,
.uil-tag.uil-type-info[disabled],
fieldset[disabled] .uil-tag.uil-type-info {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-info.disabled .uil-tag-text,
.uil-tag.uil-type-info.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-info[disabled] .uil-tag-text,
.uil-tag.uil-type-info[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-info .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-info .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-tag-link {
  background-color: transparent;
  border: none;
}
.uil-tag.uil-tag-link .uil-tag-text,
.uil-tag.uil-tag-link .uil-dl-icon-Close {
  color: var(--color-font-primaryLight);
}

.uil-tag.uil-tag-link.disabled,
.uil-tag.uil-tag-link[disabled],
fieldset[disabled] .uil-tag.uil-tag-link {
  border: none;
  background: none;
}
.uil-tag.uil-tag-link.disabled .uil-tag-text,
.uil-tag.uil-tag-link.disabled .uil-dl-icon-Close,
.uil-tag.uil-tag-link[disabled] .uil-tag-text,
.uil-tag.uil-tag-link[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-tag-link .uil-tag-text,
fieldset[disabled] .uil-tag.uil-tag-link .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-tag-link.disabled:hover,
.uil-tag.uil-tag-link[disabled]:hover,
fieldset[disabled]:hover .uil-tag.uil-tag-link {
  border: none;
  background: none;
}
.uil-tag.fullwidth {
  width: 100%;
}
.uil-tag.uil-size-small {
  height: 24px;
  width: 50px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  display: inline-flex;
}
.uil-tag.uil-size-small > .uil-dl-icon-Close:before {
  font-size: 10px;
}
.uil-tag.uil-size-small .uil-tag-text {
  font-size: 10px;
}
.uil-tag.uil-size-medium {
  height: 39px;
  width: 65px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #fff;
  color: #000;
  gap: 24px;
  display: inline-flex;
  flex-direction: row;
}
.uil-tag.uil-size-medium > .uil-dl-icon-Close:before {
  font-size: var(--font-size-caption-xs);
}
.uil-tag.uil-size-medium .uil-tag-text {
  font-size: var(--font-size-caption-xs);
}
.uil-tag.uil-size-large {
  height: 48px;
  width: 75px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}
.uil-tag.uil-size-large > .uil-dl-icon-Close:before {
  font-size: var(--font-size-body-sm);
}
.uil-tag.uil-size-large .uil-tag-text {
  font-size: var(--font-size-body-sm);
}

.uil-tag-link.uil-type-secondary:focus,
.uil-tag-link.uil-type-secondary:hover {
  border: none;
}

.uil-tag-text {
  width: auto;
  height: 20px;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  /* color: var(--color-font-dark); */
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uil-tag-text:hover {
  cursor: pointer;
}

.uil-dl-icon-Close:before {
  padding-left: 8px;
  width: 18px;
  content: "\e98e";
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: var(--font-size-title-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uil-dl-icon-Close:before.disabled,
.uil-dl-icon-Close:before[disabled],
fieldset[disabled] .uil-dl-icon-Close:before {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}

.uil-tag-square {
  border-radius: 0;
  color: var(--color-font-dark);
  border-color: var(--color-border-default);
}
.uil-tag-square.active,
.uil-tag-square:active,
.uil-tag-square:focus:active,
.uil-tag-square:focus:hover:active,
.uil-tag-square:hover:active {
  border: 1px solid var(--color-font-primaryLight);
}

.uil-tag-square.disabled,
.uil-tag-square[disabled],
fieldset[disabled] .uil-tag-square {
  border: 1px solid var(--color-border-default);
}
.uil-tag-square.disabled .uil-tag-text,
.uil-tag-square.disabled .uil-dl-icon-Close,
.uil-tag-square[disabled] .uil-tag-text,
.uil-tag-square[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag-square .uil-tag-text,
fieldset[disabled] .uil-tag-square .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag-square.disabled:hover,
.uil-tag-square[disabled]:hover,
fieldset[disabled]:hover .uil-tag-square {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}

.uil-tag-round {
  border-radius: 35px;
  border-color: var(--color-border-default);
  padding: 8px 16px;
}
.uil-tag-round .uil-tag-text,
.uil-tag-round .uil-dl-icon-Close {
  color: var(--color-font-dark);
}

.uil-tag-round.disabled,
.uil-tag-round[disabled],
fieldset[disabled] .uil-tag-round {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag-round.disabled .uil-tag-text,
.uil-tag-round.disabled .uil-dl-icon-Close,
.uil-tag-round[disabled] .uil-tag-text,
.uil-tag-round[disabled] .uil-dl-icon-Close,
fieldset[disabled] .uil-tag-round .uil-tag-text,
fieldset[disabled] .uil-tag-round .uil-dl-icon-Close {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag-round.disabled:hover,
.uil-tag-round[disabled]:hover,
fieldset[disabled]:hover .uil-tag-round {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
/*# sourceMappingURL=uil_tag.module.css.map */
