.uil-basic-cards {
  padding: 24px;
  left: 176px;
  height: 172px;
  width: 523px;
}

.uil-card .uil-card-titles {
  font-size: 20px;
  font-weight: 500;
  font-family: "Graphik";
  gap: 16px;
  display: flex;
  align-items: center;
  color: var(--black);
}

.uil-card .uil-card-caption {
  font-family: Graphik;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey-medium);
  margin-bottom: 8px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
}

.uil-tag-section {
  width: 100%;
  height: 353px;
  position: relative;
  left: 80px;
  border: 1px;
  background-color: var(--color-bg-disabledLight);
}

.uil-tag-section-subheader {
  font-family: Graphik;
  font-size: var(--font-size-title-sm);
  font-weight: var(--font-weight-sm);
  color: var(--black);
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 888px;
  height: 27px;
  top: 80px;
  left: 100px;
  padding-top: 32px;
  position: relative;
}

.uil-tag-styles {
  top: 160px;
  display: inline-flex;
  gap: 8px;
  left: 240px;
  position: absolute;
}

.uil-tag-header {
  width: 135px;
  height: 30px;
  top: 130px;
  padding-top: 40px;
  left: 100px;
  position: absolute;
  font-family: Graphik;
  font-size: var(--font-size-title-md);
  color: var(--black);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-tag-section-header {
  width: 445px;
  height: 52px;
  top: 50px;
  left: 100px;
  position: absolute;
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color: var(--black);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-connect-section {
  position: relative;
  width: 1, 360px;
  height: 431px;
  left: 78px;
  padding: 56px 0px 80px 0px;
  background-image: url(../../../assets/img/Connect_&_collaborate.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 20% 15%;
}

.uil-connect-header {
  font-family: Graphik;
  font-size: var(--font-size-heading-xl);
  font-weight: var(--font-weight-sm);
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  left: 100px;
  position: absolute;
  color: var(--color-font-light);
}

.uil-connect-subheader {
  font-family: Graphik;
  font-size: var(--font-size-title-sm);
  font-weight: var(--font-weight-sm);
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 870px;
  height: 44px;
  left: 100px;
  position: absolute;
  color: var(--color-font-light);
  top: 150px;
}

.uil-banner5-button {
  display: inline-flex;
  height: 50px;
  justify-content: center;
  text-align: center;
  align-self: center;
  gap: 16px;
  left: 0px;
  position: absolute;
  width: 205px;
  height: 48px;
  top: 230px;
  left: 100px;
  border: 1px solid var(--color-border-light);
}

.uil-connect-btn {
  width: 150px;
  height: 19px;
  font-family: Graphik;
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.uil-card-section-home {
  display: flex;
  flex-direction: row;
  gap: 70px;
  left: 100px;
  top: 200px;
  position: absolute;
}

.uil-img-section {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-left: 100px;
  height: 310px;
  width: 256px;
  top: 90px;
  position: relative;
  width: 256px;
  height: 310px;
  left: 80px;
  border-radius: 4px;
  margin-bottom: 150px;
}

.uil-basic-card-title {
  height: 24px;
  width: 24px;
}

.uil-dl-pen-icon:before {
  padding-top: 2px;
  content: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 3.13977L17.4018 10.7023C17.5029 10.839 17.5317 11.0239 17.4615 11.1923L15.25 16.5H16C16.8284 16.5 17.5 17.1716 17.5 18V20H16.5V18C16.5 17.7239 16.2761 17.5 16 17.5H14.5112L14.5019 17.5001L14.4884 17.5H9.51159C9.50398 17.5002 9.49639 17.5002 9.48882 17.5H8C7.72386 17.5 7.5 17.7239 7.5 18V20H6.5V18C6.5 17.1716 7.17157 16.5 8 16.5H8.75L6.53846 11.1923C6.4683 11.0239 6.49709 10.839 6.59822 10.7023L12 3.13977ZM9.83333 16.5H14.1667L16.4315 11.0644L12.5 5.56023V9.56301C13.3626 9.78503 14 10.5681 14 11.5C14 12.6046 13.1046 13.5 12 13.5C10.8954 13.5 10 12.6046 10 11.5C10 10.5681 10.6374 9.78503 11.5 9.56301V5.56024L7.56848 11.0644L9.83333 16.5ZM11 11.5C11 10.9477 11.4477 10.5 12 10.5C12.5523 10.5 13 10.9477 13 11.5C13 12.0523 12.5523 12.5 12 12.5C11.4477 12.5 11 12.0523 11 11.5Z' fill='%23505050'/%3E%3C/svg%3E");
}

.uil-dl-code-icon:before {
  padding-top: px;
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.67806 17L3 12L7.67806 7L8.51187 7.89234L4.66853 12L8.51187 16.1077L7.67806 17ZM16.3228 17L15.489 16.1077L19.3315 12L15.489 7.89234L16.3228 7L21 12L16.3228 17ZM10.4372 16.8361L9.38288 16.2716L13.5646 7.35306L14.6189 7.91756L10.4372 16.8361Z' fill='%23505050'/%3E%3C/svg%3E%0A");
}

.uil-dl-icon-Coding:before {
  display: flex;
  height: 32px;
  width: 32px;
  font-size: 32px;
  align-items: center;
}

.uil-dl-icon-Arrow-rightUp {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.uil-dl-icon-Arrow-rightUp:before {
  content: "\e9b3";
  font-size: 24px;
}

.body-class {
  width: 100%;
  height: 444px;
  left: 80px;
  gap: 50px;
  position: relative;
  background-color: var(--color-bg-disabledLight);
}
.body-header {
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  width: 445px;
  height: 42px;
  left: 100px;
  top: 50px;
  position: absolute;
  color: var(--black);
}

.image-card-section {
  top: 1020px;
  position: absolute;
}
.image-card-section-header {
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color: var(--black);
  line-height: 38px;
  letter-spacing: 0em;
  left: 176px;
  position: absolute;
  text-align: left;
}

.image-card-section-sub-header {
  font-family: Graphik;
  font-size: var(--font-size-title-sm);
  font-weight: var(--font-weight-sm);
  color: var(--black);
  margin-top: 32px;
  letter-spacing: 0em;
  left: 176px;
  padding-top: 32px;
  position: absolute;
  text-align: left;
}

.uil-image-styles {
  width: 256px;
  height: 310px;
  border-radius: 4px;
}

.body-sub-header {
  width: 1102px;
  height: 44px;
  font-family: Graphik;
  font-size: var(--font-size-title-sm);
  font-weight: var(--font-weight-sm);
  line-height: 22px;
  letter-spacing: 0em;
  left: 100px;
  position: absolute;
  top: 105px;
  width: 100%;
  text-align: left;
  color: var(--black);
}

.content-footer-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: overlay;
  margin-top: -56px;
  padding-top: 56px;
  scroll-padding-top: 56px;
  z-index: 998;
  height: 100vh;
  padding-left: 248px;
  margin-left: -248px;
  width: calc(100% + 248px);
}

.uil-card .uil-image-card-captions {
  color: var(--color-font-greyMedium);
  font-family: Graphik;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-card .uil-image-cards {
  width: 256px;
  height: 198px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.uil-card .uil-image-card-desc {
  padding: 16px 24px 16px 24px;
  height: 112px;
}

.uil-card .uil-image-card-header {
  color: var(--white);
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: "Graphik";
  top: 110px;
  position: relative;
  text-align: left;
}
