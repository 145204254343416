@charset "UTF-8"; /*!
 * UIL v6.5.3
 * Copyright 2021 Accenture.
 * All rights reserved.
 */ /*! */ /*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.uil-advanced-banner,
.uil-background-banner,
.uil-banner,
.uil-card-banner,
.uil-collapsed-card-banner,
.wrapper {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xs,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}
.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.3333333333%;
}
.offset-2 {
  margin-left: 16.6666666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.3333333333%;
}
.offset-5 {
  margin-left: 41.6666666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.3333333333%;
}
.offset-8 {
  margin-left: 66.6666666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.3333333333%;
}
.offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu,
.uil-app-menu,
.uil-megamenu-dropdown,
.uil-megamenu-horizontal-expansion-area,
.uil-toolbar-dropdown,
.uil-toolbar-guide-menu,
.uil-toolbar-option-menu,
.uil-toolbar-search-menu,
.uil-toolbar-user-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu,
.dropup .uil-app-menu,
.dropup .uil-megamenu-dropdown,
.dropup .uil-megamenu-horizontal-expansion-area,
.dropup .uil-toolbar-dropdown,
.dropup .uil-toolbar-guide-menu,
.dropup .uil-toolbar-option-menu,
.dropup .uil-toolbar-search-menu,
.dropup .uil-toolbar-user-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu,
.dropright .uil-app-menu,
.dropright .uil-megamenu-dropdown,
.dropright .uil-megamenu-horizontal-expansion-area,
.dropright .uil-toolbar-dropdown,
.dropright .uil-toolbar-guide-menu,
.dropright .uil-toolbar-option-menu,
.dropright .uil-toolbar-search-menu,
.dropright .uil-toolbar-user-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu,
.dropleft .uil-app-menu,
.dropleft .uil-megamenu-dropdown,
.dropleft .uil-megamenu-horizontal-expansion-area,
.dropleft .uil-toolbar-dropdown,
.dropleft .uil-toolbar-guide-menu,
.dropleft .uil-toolbar-option-menu,
.dropleft .uil-toolbar-search-menu,
.dropleft .uil-toolbar-user-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"],
[x-placement^="bottom"].uil-app-menu,
[x-placement^="bottom"].uil-megamenu-dropdown,
[x-placement^="bottom"].uil-megamenu-horizontal-expansion-area,
[x-placement^="bottom"].uil-toolbar-dropdown,
[x-placement^="bottom"].uil-toolbar-guide-menu,
[x-placement^="bottom"].uil-toolbar-option-menu,
[x-placement^="bottom"].uil-toolbar-search-menu,
[x-placement^="bottom"].uil-toolbar-user-menu,
[x-placement^="left"].uil-app-menu,
[x-placement^="left"].uil-megamenu-dropdown,
[x-placement^="left"].uil-megamenu-horizontal-expansion-area,
[x-placement^="left"].uil-toolbar-dropdown,
[x-placement^="left"].uil-toolbar-guide-menu,
[x-placement^="left"].uil-toolbar-option-menu,
[x-placement^="left"].uil-toolbar-search-menu,
[x-placement^="left"].uil-toolbar-user-menu,
[x-placement^="right"].uil-app-menu,
[x-placement^="right"].uil-megamenu-dropdown,
[x-placement^="right"].uil-megamenu-horizontal-expansion-area,
[x-placement^="right"].uil-toolbar-dropdown,
[x-placement^="right"].uil-toolbar-guide-menu,
[x-placement^="right"].uil-toolbar-option-menu,
[x-placement^="right"].uil-toolbar-search-menu,
[x-placement^="right"].uil-toolbar-user-menu,
[x-placement^="top"].uil-app-menu,
[x-placement^="top"].uil-megamenu-dropdown,
[x-placement^="top"].uil-megamenu-horizontal-expansion-area,
[x-placement^="top"].uil-toolbar-dropdown,
[x-placement^="top"].uil-toolbar-guide-menu,
[x-placement^="top"].uil-toolbar-option-menu,
[x-placement^="top"].uil-toolbar-search-menu,
[x-placement^="top"].uil-toolbar-user-menu {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show,
.show.uil-app-menu,
.show.uil-megamenu-dropdown,
.show.uil-megamenu-horizontal-expansion-area,
.show.uil-toolbar-dropdown,
.show.uil-toolbar-guide-menu,
.show.uil-toolbar-option-menu,
.show.uil-toolbar-search-menu,
.show.uil-toolbar-user-menu {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs,
.uil-nav-tabs,
.uil-navbar-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item,
.uil-nav-tabs .nav-item,
.uil-navbar-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link,
.uil-nav-tabs .nav-link,
.uil-navbar-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.uil-nav-tabs .nav-link:focus,
.uil-nav-tabs .nav-link:hover,
.uil-navbar-tabs .nav-link:focus,
.uil-navbar-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled,
.uil-nav-tabs .nav-link.disabled,
.uil-navbar-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.uil-nav-tabs .nav-item.show .nav-link,
.uil-nav-tabs .nav-link.active,
.uil-navbar-tabs .nav-item.show .nav-link,
.uil-navbar-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu,
.nav-tabs .uil-app-menu,
.nav-tabs .uil-megamenu-dropdown,
.nav-tabs .uil-megamenu-horizontal-expansion-area,
.nav-tabs .uil-toolbar-dropdown,
.nav-tabs .uil-toolbar-guide-menu,
.nav-tabs .uil-toolbar-option-menu,
.nav-tabs .uil-toolbar-search-menu,
.nav-tabs .uil-toolbar-user-menu,
.uil-nav-tabs .dropdown-menu,
.uil-nav-tabs .uil-app-menu,
.uil-nav-tabs .uil-megamenu-dropdown,
.uil-nav-tabs .uil-megamenu-horizontal-expansion-area,
.uil-nav-tabs .uil-toolbar-dropdown,
.uil-nav-tabs .uil-toolbar-guide-menu,
.uil-nav-tabs .uil-toolbar-option-menu,
.uil-nav-tabs .uil-toolbar-search-menu,
.uil-nav-tabs .uil-toolbar-user-menu,
.uil-navbar-tabs .dropdown-menu,
.uil-navbar-tabs .uil-app-menu,
.uil-navbar-tabs .uil-megamenu-dropdown,
.uil-navbar-tabs .uil-megamenu-horizontal-expansion-area,
.uil-navbar-tabs .uil-toolbar-dropdown,
.uil-navbar-tabs .uil-toolbar-guide-menu,
.uil-navbar-tabs .uil-toolbar-option-menu,
.uil-navbar-tabs .uil-toolbar-search-menu,
.uil-navbar-tabs .uil-toolbar-user-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl,
.navbar .uil-advanced-banner,
.navbar .uil-background-banner,
.navbar .uil-banner,
.navbar .uil-card-banner,
.navbar .uil-collapsed-card-banner,
.navbar .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu,
.navbar-nav .uil-app-menu,
.navbar-nav .uil-megamenu-dropdown,
.navbar-nav .uil-megamenu-horizontal-expansion-area,
.navbar-nav .uil-toolbar-dropdown,
.navbar-nav .uil-toolbar-guide-menu,
.navbar-nav .uil-toolbar-option-menu,
.navbar-nav .uil-toolbar-search-menu,
.navbar-nav .uil-toolbar-user-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .uil-advanced-banner,
  .navbar-expand-sm > .uil-background-banner,
  .navbar-expand-sm > .uil-banner,
  .navbar-expand-sm > .uil-card-banner,
  .navbar-expand-sm > .uil-collapsed-card-banner,
  .navbar-expand-sm > .wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu,
  .navbar-expand-sm .navbar-nav .uil-app-menu,
  .navbar-expand-sm .navbar-nav .uil-megamenu-dropdown,
  .navbar-expand-sm .navbar-nav .uil-megamenu-horizontal-expansion-area,
  .navbar-expand-sm .navbar-nav .uil-toolbar-dropdown,
  .navbar-expand-sm .navbar-nav .uil-toolbar-guide-menu,
  .navbar-expand-sm .navbar-nav .uil-toolbar-option-menu,
  .navbar-expand-sm .navbar-nav .uil-toolbar-search-menu,
  .navbar-expand-sm .navbar-nav .uil-toolbar-user-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .uil-advanced-banner,
  .navbar-expand-sm > .uil-background-banner,
  .navbar-expand-sm > .uil-banner,
  .navbar-expand-sm > .uil-card-banner,
  .navbar-expand-sm > .uil-collapsed-card-banner,
  .navbar-expand-sm > .wrapper {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .uil-advanced-banner,
  .navbar-expand-md > .uil-background-banner,
  .navbar-expand-md > .uil-banner,
  .navbar-expand-md > .uil-card-banner,
  .navbar-expand-md > .uil-collapsed-card-banner,
  .navbar-expand-md > .wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu,
  .navbar-expand-md .navbar-nav .uil-app-menu,
  .navbar-expand-md .navbar-nav .uil-megamenu-dropdown,
  .navbar-expand-md .navbar-nav .uil-megamenu-horizontal-expansion-area,
  .navbar-expand-md .navbar-nav .uil-toolbar-dropdown,
  .navbar-expand-md .navbar-nav .uil-toolbar-guide-menu,
  .navbar-expand-md .navbar-nav .uil-toolbar-option-menu,
  .navbar-expand-md .navbar-nav .uil-toolbar-search-menu,
  .navbar-expand-md .navbar-nav .uil-toolbar-user-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .uil-advanced-banner,
  .navbar-expand-md > .uil-background-banner,
  .navbar-expand-md > .uil-banner,
  .navbar-expand-md > .uil-card-banner,
  .navbar-expand-md > .uil-collapsed-card-banner,
  .navbar-expand-md > .wrapper {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .uil-advanced-banner,
  .navbar-expand-lg > .uil-background-banner,
  .navbar-expand-lg > .uil-banner,
  .navbar-expand-lg > .uil-card-banner,
  .navbar-expand-lg > .uil-collapsed-card-banner,
  .navbar-expand-lg > .wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu,
  .navbar-expand-lg .navbar-nav .uil-app-menu,
  .navbar-expand-lg .navbar-nav .uil-megamenu-dropdown,
  .navbar-expand-lg .navbar-nav .uil-megamenu-horizontal-expansion-area,
  .navbar-expand-lg .navbar-nav .uil-toolbar-dropdown,
  .navbar-expand-lg .navbar-nav .uil-toolbar-guide-menu,
  .navbar-expand-lg .navbar-nav .uil-toolbar-option-menu,
  .navbar-expand-lg .navbar-nav .uil-toolbar-search-menu,
  .navbar-expand-lg .navbar-nav .uil-toolbar-user-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .uil-advanced-banner,
  .navbar-expand-lg > .uil-background-banner,
  .navbar-expand-lg > .uil-banner,
  .navbar-expand-lg > .uil-card-banner,
  .navbar-expand-lg > .uil-collapsed-card-banner,
  .navbar-expand-lg > .wrapper {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .uil-advanced-banner,
  .navbar-expand-xl > .uil-background-banner,
  .navbar-expand-xl > .uil-banner,
  .navbar-expand-xl > .uil-card-banner,
  .navbar-expand-xl > .uil-collapsed-card-banner,
  .navbar-expand-xl > .wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu,
  .navbar-expand-xl .navbar-nav .uil-app-menu,
  .navbar-expand-xl .navbar-nav .uil-megamenu-dropdown,
  .navbar-expand-xl .navbar-nav .uil-megamenu-horizontal-expansion-area,
  .navbar-expand-xl .navbar-nav .uil-toolbar-dropdown,
  .navbar-expand-xl .navbar-nav .uil-toolbar-guide-menu,
  .navbar-expand-xl .navbar-nav .uil-toolbar-option-menu,
  .navbar-expand-xl .navbar-nav .uil-toolbar-search-menu,
  .navbar-expand-xl .navbar-nav .uil-toolbar-user-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .uil-advanced-banner,
  .navbar-expand-xl > .uil-background-banner,
  .navbar-expand-xl > .uil-banner,
  .navbar-expand-xl > .uil-card-banner,
  .navbar-expand-xl > .uil-collapsed-card-banner,
  .navbar-expand-xl > .wrapper {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl,
.navbar-expand > .uil-advanced-banner,
.navbar-expand > .uil-background-banner,
.navbar-expand > .uil-banner,
.navbar-expand > .uil-card-banner,
.navbar-expand > .uil-collapsed-card-banner,
.navbar-expand > .wrapper {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu,
.navbar-expand .navbar-nav .uil-app-menu,
.navbar-expand .navbar-nav .uil-megamenu-dropdown,
.navbar-expand .navbar-nav .uil-megamenu-horizontal-expansion-area,
.navbar-expand .navbar-nav .uil-toolbar-dropdown,
.navbar-expand .navbar-nav .uil-toolbar-guide-menu,
.navbar-expand .navbar-nav .uil-toolbar-option-menu,
.navbar-expand .navbar-nav .uil-toolbar-search-menu,
.navbar-expand .navbar-nav .uil-toolbar-user-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl,
.navbar-expand > .uil-advanced-banner,
.navbar-expand > .uil-background-banner,
.navbar-expand > .uil-banner,
.navbar-expand > .uil-card-banner,
.navbar-expand > .uil-collapsed-card-banner,
.navbar-expand > .wrapper {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card,
.uil-horizontal-nav-submenu,
.uil-side-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr,
.uil-horizontal-nav-submenu > hr,
.uil-side-nav > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group,
.uil-horizontal-nav-submenu > .list-group,
.uil-side-nav > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child,
.uil-horizontal-nav-submenu > .list-group:first-child,
.uil-side-nav > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child,
.uil-horizontal-nav-submenu > .list-group:last-child,
.uil-side-nav > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer,
.uil-horizontal-nav-submenu > .card-header + .list-group,
.uil-horizontal-nav-submenu > .list-group + .card-footer,
.uil-side-nav > .card-header + .list-group,
.uil-side-nav > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck .card,
.card-deck .uil-horizontal-nav-submenu,
.card-deck .uil-side-nav {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card,
  .card-deck .uil-horizontal-nav-submenu,
  .card-deck .uil-side-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card,
.card-group > .uil-horizontal-nav-submenu,
.card-group > .uil-side-nav {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card,
  .card-group > .uil-horizontal-nav-submenu,
  .card-group > .uil-side-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card,
  .card-group > .card + .uil-horizontal-nav-submenu,
  .card-group > .card + .uil-side-nav,
  .card-group > .uil-horizontal-nav-submenu + .card,
  .card-group > .uil-horizontal-nav-submenu + .uil-horizontal-nav-submenu,
  .card-group > .uil-horizontal-nav-submenu + .uil-side-nav,
  .card-group > .uil-side-nav + .card,
  .card-group > .uil-side-nav + .uil-horizontal-nav-submenu,
  .card-group > .uil-side-nav + .uil-side-nav {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child),
  .card-group > .uil-horizontal-nav-submenu:not(:last-child),
  .card-group > .uil-side-nav:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .uil-horizontal-nav-submenu:not(:last-child) .card-header,
  .card-group > .uil-horizontal-nav-submenu:not(:last-child) .card-img-top,
  .card-group > .uil-side-nav:not(:last-child) .card-header,
  .card-group > .uil-side-nav:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .uil-horizontal-nav-submenu:not(:last-child) .card-footer,
  .card-group > .uil-horizontal-nav-submenu:not(:last-child) .card-img-bottom,
  .card-group > .uil-side-nav:not(:last-child) .card-footer,
  .card-group > .uil-side-nav:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child),
  .card-group > .uil-horizontal-nav-submenu:not(:first-child),
  .card-group > .uil-side-nav:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .uil-horizontal-nav-submenu:not(:first-child) .card-header,
  .card-group > .uil-horizontal-nav-submenu:not(:first-child) .card-img-top,
  .card-group > .uil-side-nav:not(:first-child) .card-header,
  .card-group > .uil-side-nav:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .uil-horizontal-nav-submenu:not(:first-child) .card-footer,
  .card-group > .uil-horizontal-nav-submenu:not(:first-child) .card-img-bottom,
  .card-group > .uil-side-nav:not(:first-child) .card-footer,
  .card-group > .uil-side-nav:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card,
.card-columns .uil-horizontal-nav-submenu,
.card-columns .uil-side-nav {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card,
  .card-columns .uil-horizontal-nav-submenu,
  .card-columns .uil-side-nav {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card,
.accordion > .uil-horizontal-nav-submenu,
.accordion > .uil-side-nav {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type),
.accordion > .uil-horizontal-nav-submenu:not(:last-of-type),
.accordion > .uil-side-nav:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type),
.accordion > .uil-horizontal-nav-submenu:not(:first-of-type),
.accordion > .uil-side-nav:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header,
.accordion > .uil-horizontal-nav-submenu > .card-header,
.accordion > .uil-side-nav > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb,
.uil-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
[class*="uil-icon-"],
[class^="uil-icon-"] {
  font-family: uilicon, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-icon-BLUETHOOT-ANCHOR_blue .path1:before {
  content: "\e931";
}
.uil-icon-BLUETHOOT-ANCHOR_blue .path2:before {
  content: "\e932";
}
.uil-icon-BLUETHOOT-ANCHOR_blue .path3:before {
  content: "\e933";
}
.uil-icon-BLUETHOOT-ANCHOR_orange .path1:before {
  content: "\e934";
}
.uil-icon-BLUETHOOT-ANCHOR_orange .path2:before {
  content: "\e935";
}
.uil-icon-BLUETHOOT-ANCHOR_orange .path3:before {
  content: "\e936";
}
.uil-icon-BLUETHOOT-ANCHOR_red .path1:before {
  content: "\e937";
}
.uil-icon-BLUETHOOT-ANCHOR_red .path2:before {
  content: "\e938";
}
.uil-icon-BLUETHOOT-ANCHOR_red .path3:before {
  content: "\e939";
}
.uil-icon-BLUETHOOT-ANCHOR_yellow .path1:before {
  content: "\e93a";
}
.uil-icon-BLUETHOOT-ANCHOR_yellow .path2:before {
  content: "\e9e1";
}
.uil-icon-BLUETHOOT-ANCHOR_yellow .path3:before {
  content: "\e9e2";
}
.uil-icon-BRACELET_blue .path1:before {
  content: "\e900";
}
.uil-icon-BRACELET_blue .path2:before {
  content: "\e901";
}
.uil-icon-BRACELET_blue .path3:before {
  content: "\e902";
}
.uil-icon-BRACELET_blue .path4:before {
  content: "\e903";
}
.uil-icon-BRACELET_orange .path1:before {
  content: "\e9ea";
}
.uil-icon-BRACELET_orange .path2:before {
  content: "\e9eb";
}
.uil-icon-BRACELET_orange .path3:before {
  content: "\e9ec";
}
.uil-icon-BRACELET_orange .path4:before {
  content: "\e9ed";
}
.uil-icon-BRACELET_orange .path5:before {
  content: "\e9ee";
}
.uil-icon-BRACELET_orange .path6:before {
  content: "\e9ef";
}
.uil-icon-BRACELET_orange .path7:before {
  content: "\e9f0";
}
.uil-icon-BRACELET_red .path1:before {
  content: "\e9f1";
}
.uil-icon-BRACELET_red .path2:before {
  content: "\e9f2";
}
.uil-icon-BRACELET_red .path3:before {
  content: "\e9f3";
}
.uil-icon-BRACELET_red .path4:before {
  content: "\e9f4";
}
.uil-icon-BRACELET_red .path5:before {
  content: "\e9f5";
}
.uil-icon-BRACELET_red .path6:before {
  content: "\e9f6";
}
.uil-icon-BRACELET_red .path7:before {
  content: "\e9f7";
}
.uil-icon-BRACELET_yellow .path1:before {
  content: "\e9f8";
}
.uil-icon-BRACELET_yellow .path2:before {
  content: "\e9f9";
}
.uil-icon-BRACELET_yellow .path3:before {
  content: "\e9fa";
}
.uil-icon-BRACELET_yellow .path4:before {
  content: "\e9fb";
}
.uil-icon-BRACELET_yellow .path5:before {
  content: "\e9fc";
}
.uil-icon-BRACELET_yellow .path6:before {
  content: "\e9fd";
}
.uil-icon-BRACELET_yellow .path7:before {
  content: "\e9fe";
}
.uil-icon-CAMERA_blue .path1:before {
  content: "\e9ff";
}
.uil-icon-CAMERA_blue .path2:before {
  content: "\ea00";
}
.uil-icon-CAMERA_blue .path3:before {
  content: "\ea01";
}
.uil-icon-CAMERA_orange .path1:before {
  content: "\ea02";
}
.uil-icon-CAMERA_orange .path2:before {
  content: "\ea03";
}
.uil-icon-CAMERA_orange .path3:before {
  content: "\ea04";
}
.uil-icon-CAMERA_red .path1:before {
  content: "\ea05";
}
.uil-icon-CAMERA_red .path2:before {
  content: "\ea06";
}
.uil-icon-CAMERA_red .path3:before {
  content: "\ea07";
}
.uil-icon-CAMERA_yellow .path1:before {
  content: "\ea08";
}
.uil-icon-CAMERA_yellow .path2:before {
  content: "\ea09";
}
.uil-icon-CAMERA_yellow .path3:before {
  content: "\ea0a";
}
.uil-icon-ENERGYMETER_blue .path1:before {
  content: "\ea0b";
}
.uil-icon-ENERGYMETER_blue .path2:before {
  content: "\ea0c";
}
.uil-icon-ENERGYMETER_blue .path3:before {
  content: "\ea0d";
}
.uil-icon-ENERGYMETER_blue .path4:before {
  content: "\ea0e";
}
.uil-icon-ENERGYMETER_orange .path1:before {
  content: "\ea0f";
}
.uil-icon-ENERGYMETER_orange .path2:before {
  content: "\ea10";
}
.uil-icon-ENERGYMETER_orange .path3:before {
  content: "\ea11";
}
.uil-icon-ENERGYMETER_orange .path4:before {
  content: "\ea12";
}
.uil-icon-ENERGYMETER_red .path1:before {
  content: "\ea13";
}
.uil-icon-ENERGYMETER_red .path2:before {
  content: "\ea14";
}
.uil-icon-ENERGYMETER_red .path3:before {
  content: "\ea15";
}
.uil-icon-ENERGYMETER_red .path4:before {
  content: "\ea16";
}
.uil-icon-ENERGYMETER_yellow .path1:before {
  content: "\ea17";
}
.uil-icon-ENERGYMETER_yellow .path2:before {
  content: "\ea18";
}
.uil-icon-ENERGYMETER_yellow .path3:before {
  content: "\ea19";
}
.uil-icon-ENERGYMETER_yellow .path4:before {
  content: "\ea1a";
}
.uil-icon-harsh-turn-left_green .path1:before {
  content: "\ea1b";
}
.uil-icon-harsh-turn-left_green .path2:before {
  content: "\ea1c";
}
.uil-icon-harsh-turn-left_green .path3:before {
  content: "\ea1d";
}
.uil-icon-harsh-turn-left_green .path4:before {
  content: "\ea1e";
}
.uil-icon-harsh-turn-left_green .path5:before {
  content: "\ea1f";
}
.uil-icon-harsh-turn-left_green .path6:before {
  content: "\ea20";
}
.uil-icon-harsh-turn-left_yellow .path1:before {
  content: "\ea21";
}
.uil-icon-harsh-turn-left_yellow .path2:before {
  content: "\ea22";
}
.uil-icon-harsh-turn-left_yellow .path3:before {
  content: "\ea23";
}
.uil-icon-harsh-turn-left_yellow .path4:before {
  content: "\ea24";
}
.uil-icon-harsh-turn-left_yellow .path5:before {
  content: "\ea25";
}
.uil-icon-harsh-turn-left_yellow .path6:before {
  content: "\ea26";
}
.uil-icon-harsh-turn-right_green .path1:before {
  content: "\ea27";
}
.uil-icon-harsh-turn-right_green .path2:before {
  content: "\ea28";
}
.uil-icon-harsh-turn-right_green .path3:before {
  content: "\eae6";
}
.uil-icon-harsh-turn-right_green .path4:before {
  content: "\eae7";
}
.uil-icon-harsh-turn-right_green .path5:before {
  content: "\eae8";
}
.uil-icon-harsh-turn-right_green .path6:before {
  content: "\eae9";
}
.uil-icon-harsh-turn-right_yellow .path1:before {
  content: "\eaea";
}
.uil-icon-harsh-turn-right_yellow .path2:before {
  content: "\eaeb";
}
.uil-icon-harsh-turn-right_yellow .path3:before {
  content: "\eaec";
}
.uil-icon-harsh-turn-right_yellow .path4:before {
  content: "\eaed";
}
.uil-icon-harsh-turn-right_yellow .path5:before {
  content: "\eaee";
}
.uil-icon-harsh-turn-right_yellow .path6:before {
  content: "\eaef";
}
.uil-icon-HELMET-DETECTOR_blue .path1:before {
  content: "\eaf0";
}
.uil-icon-HELMET-DETECTOR_blue .path2:before {
  content: "\eaf1";
}
.uil-icon-HELMET-DETECTOR_blue .path3:before {
  content: "\eaf2";
}
.uil-icon-HELMET-DETECTOR_blue .path4:before {
  content: "\eaf3";
}
.uil-icon-HELMET-DETECTOR_blue .path5:before {
  content: "\eaf4";
}
.uil-icon-HELMET-DETECTOR_blue .path6:before {
  content: "\eaf5";
}
.uil-icon-HELMET-DETECTOR_blue .path7:before {
  content: "\eaf6";
}
.uil-icon-HELMET-DETECTOR_blue .path8:before {
  content: "\eaf7";
}
.uil-icon-HELMET-DETECTOR_orange .path1:before {
  content: "\eaf8";
}
.uil-icon-HELMET-DETECTOR_orange .path2:before {
  content: "\eaf9";
}
.uil-icon-HELMET-DETECTOR_orange .path3:before {
  content: "\eafa";
}
.uil-icon-HELMET-DETECTOR_orange .path4:before {
  content: "\eafb";
}
.uil-icon-HELMET-DETECTOR_orange .path5:before {
  content: "\eafc";
}
.uil-icon-HELMET-DETECTOR_orange .path6:before {
  content: "\eafd";
}
.uil-icon-HELMET-DETECTOR_orange .path7:before {
  content: "\eafe";
}
.uil-icon-HELMET-DETECTOR_orange .path8:before {
  content: "\eaff";
}
.uil-icon-HELMET-DETECTOR_red .path1:before {
  content: "\eb00";
}
.uil-icon-HELMET-DETECTOR_red .path2:before {
  content: "\eb01";
}
.uil-icon-HELMET-DETECTOR_red .path3:before {
  content: "\eb02";
}
.uil-icon-HELMET-DETECTOR_red .path4:before {
  content: "\eb03";
}
.uil-icon-HELMET-DETECTOR_red .path5:before {
  content: "\eb04";
}
.uil-icon-HELMET-DETECTOR_red .path6:before {
  content: "\eb05";
}
.uil-icon-HELMET-DETECTOR_red .path7:before {
  content: "\eb06";
}
.uil-icon-HELMET-DETECTOR_red .path8:before {
  content: "\eb07";
}
.uil-icon-HELMET-DETECTOR_yellow .path1:before {
  content: "\eb08";
}
.uil-icon-HELMET-DETECTOR_yellow .path2:before {
  content: "\eb09";
}
.uil-icon-HELMET-DETECTOR_yellow .path3:before {
  content: "\eb0a";
}
.uil-icon-HELMET-DETECTOR_yellow .path4:before {
  content: "\eb0b";
}
.uil-icon-HELMET-DETECTOR_yellow .path5:before {
  content: "\eb0c";
}
.uil-icon-HELMET-DETECTOR_yellow .path6:before {
  content: "\eb0d";
}
.uil-icon-HELMET-DETECTOR_yellow .path7:before {
  content: "\eb0e";
}
.uil-icon-HELMET-DETECTOR_yellow .path8:before {
  content: "\eb0f";
}
.uil-icon-INDUSTRIAL-MACHINE_blue .path1:before {
  content: "\eb10";
}
.uil-icon-INDUSTRIAL-MACHINE_blue .path2:before {
  content: "\eb11";
}
.uil-icon-INDUSTRIAL-MACHINE_blue .path3:before {
  content: "\eb12";
}
.uil-icon-INDUSTRIAL-MACHINE_blue .path4:before {
  content: "\eb13";
}
.uil-icon-INDUSTRIAL-MACHINE_orange .path1:before {
  content: "\eb14";
}
.uil-icon-INDUSTRIAL-MACHINE_orange .path2:before {
  content: "\eb15";
}
.uil-icon-INDUSTRIAL-MACHINE_orange .path3:before {
  content: "\eb16";
}
.uil-icon-INDUSTRIAL-MACHINE_orange .path4:before {
  content: "\eb17";
}
.uil-icon-INDUSTRIAL-MACHINE_red .path1:before {
  content: "\eb18";
}
.uil-icon-INDUSTRIAL-MACHINE_red .path2:before {
  content: "\eb19";
}
.uil-icon-INDUSTRIAL-MACHINE_red .path3:before {
  content: "\eb1a";
}
.uil-icon-INDUSTRIAL-MACHINE_red .path4:before {
  content: "\eb1b";
}
.uil-icon-INDUSTRIAL-MACHINE_yellow .path1:before {
  content: "\eb1c";
}
.uil-icon-INDUSTRIAL-MACHINE_yellow .path2:before {
  content: "\eb1d";
}
.uil-icon-INDUSTRIAL-MACHINE_yellow .path3:before {
  content: "\eb1e";
}
.uil-icon-INDUSTRIAL-MACHINE_yellow .path4:before {
  content: "\eb1f";
}
.uil-icon-MASK-DETECTOR_blue .path1:before {
  content: "\e904";
}
.uil-icon-MASK-DETECTOR_blue .path2:before {
  content: "\e905";
}
.uil-icon-MASK-DETECTOR_blue .path3:before {
  content: "\e906";
}
.uil-icon-MASK-DETECTOR_blue .path4:before {
  content: "\e907";
}
.uil-icon-MASK-DETECTOR_blue .path5:before {
  content: "\e908";
}
.uil-icon-MASK-DETECTOR_blue .path6:before {
  content: "\e909";
}
.uil-icon-MASK-DETECTOR_blue .path7:before {
  content: "\e90a";
}
.uil-icon-MASK-DETECTOR_orange .path1:before {
  content: "\eb2a";
}
.uil-icon-MASK-DETECTOR_orange .path2:before {
  content: "\eb2b";
}
.uil-icon-MASK-DETECTOR_orange .path3:before {
  content: "\eb2c";
}
.uil-icon-MASK-DETECTOR_orange .path4:before {
  content: "\eb2d";
}
.uil-icon-MASK-DETECTOR_orange .path5:before {
  content: "\eb2e";
}
.uil-icon-MASK-DETECTOR_orange .path6:before {
  content: "\eb2f";
}
.uil-icon-MASK-DETECTOR_orange .path7:before {
  content: "\eb30";
}
.uil-icon-MASK-DETECTOR_orange .path8:before {
  content: "\eb31";
}
.uil-icon-MASK-DETECTOR_orange .path9:before {
  content: "\eb32";
}
.uil-icon-MASK-DETECTOR_orange .path10:before {
  content: "\eb33";
}
.uil-icon-MASK-DETECTOR_red .path1:before {
  content: "\eb34";
}
.uil-icon-MASK-DETECTOR_red .path2:before {
  content: "\eb35";
}
.uil-icon-MASK-DETECTOR_red .path3:before {
  content: "\eb36";
}
.uil-icon-MASK-DETECTOR_red .path4:before {
  content: "\eb37";
}
.uil-icon-MASK-DETECTOR_red .path5:before {
  content: "\eb38";
}
.uil-icon-MASK-DETECTOR_red .path6:before {
  content: "\eb39";
}
.uil-icon-MASK-DETECTOR_red .path7:before {
  content: "\eb3a";
}
.uil-icon-MASK-DETECTOR_red .path8:before {
  content: "\eb3b";
}
.uil-icon-MASK-DETECTOR_red .path9:before {
  content: "\eb3c";
}
.uil-icon-MASK-DETECTOR_red .path10:before {
  content: "\eb3d";
}
.uil-icon-MASK-DETECTOR_yellow .path1:before {
  content: "\eb3e";
}
.uil-icon-MASK-DETECTOR_yellow .path2:before {
  content: "\eb3f";
}
.uil-icon-MASK-DETECTOR_yellow .path3:before {
  content: "\eb40";
}
.uil-icon-MASK-DETECTOR_yellow .path4:before {
  content: "\eb41";
}
.uil-icon-MASK-DETECTOR_yellow .path5:before {
  content: "\eb42";
}
.uil-icon-MASK-DETECTOR_yellow .path6:before {
  content: "\eb43";
}
.uil-icon-MASK-DETECTOR_yellow .path7:before {
  content: "\eb44";
}
.uil-icon-MASK-DETECTOR_yellow .path8:before {
  content: "\eb45";
}
.uil-icon-MASK-DETECTOR_yellow .path9:before {
  content: "\eb46";
}
.uil-icon-MASK-DETECTOR_yellow .path10:before {
  content: "\eb47";
}
.uil-icon-OBJECT-DETECTOR_blue .path1:before {
  content: "\eb48";
}
.uil-icon-OBJECT-DETECTOR_blue .path2:before {
  content: "\eb49";
}
.uil-icon-OBJECT-DETECTOR_blue .path3:before {
  content: "\eb4a";
}
.uil-icon-OBJECT-DETECTOR_blue .path4:before {
  content: "\eb4b";
}
.uil-icon-OBJECT-DETECTOR_blue .path5:before {
  content: "\eb4c";
}
.uil-icon-OBJECT-DETECTOR_blue .path6:before {
  content: "\eb4d";
}
.uil-icon-OBJECT-DETECTOR_blue .path7:before {
  content: "\eb4e";
}
.uil-icon-OBJECT-DETECTOR_orange .path1:before {
  content: "\eb4f";
}
.uil-icon-OBJECT-DETECTOR_orange .path2:before {
  content: "\eb50";
}
.uil-icon-OBJECT-DETECTOR_orange .path3:before {
  content: "\eb51";
}
.uil-icon-OBJECT-DETECTOR_orange .path4:before {
  content: "\eb52";
}
.uil-icon-OBJECT-DETECTOR_orange .path5:before {
  content: "\eb53";
}
.uil-icon-OBJECT-DETECTOR_orange .path6:before {
  content: "\eb54";
}
.uil-icon-OBJECT-DETECTOR_orange .path7:before {
  content: "\eb55";
}
.uil-icon-OBJECT-DETECTOR_red .path1:before {
  content: "\eb56";
}
.uil-icon-OBJECT-DETECTOR_red .path2:before {
  content: "\eb57";
}
.uil-icon-OBJECT-DETECTOR_red .path3:before {
  content: "\eb58";
}
.uil-icon-OBJECT-DETECTOR_red .path4:before {
  content: "\eb59";
}
.uil-icon-OBJECT-DETECTOR_red .path5:before {
  content: "\eb5a";
}
.uil-icon-OBJECT-DETECTOR_red .path6:before {
  content: "\eb5b";
}
.uil-icon-OBJECT-DETECTOR_red .path7:before {
  content: "\eb5c";
}
.uil-icon-OBJECT-DETECTOR_yellow .path1:before {
  content: "\eb5d";
}
.uil-icon-OBJECT-DETECTOR_yellow .path2:before {
  content: "\eb5e";
}
.uil-icon-OBJECT-DETECTOR_yellow .path3:before {
  content: "\eb5f";
}
.uil-icon-OBJECT-DETECTOR_yellow .path4:before {
  content: "\eb60";
}
.uil-icon-OBJECT-DETECTOR_yellow .path5:before {
  content: "\eb61";
}
.uil-icon-OBJECT-DETECTOR_yellow .path6:before {
  content: "\eb62";
}
.uil-icon-OBJECT-DETECTOR_yellow .path7:before {
  content: "\eb63";
}
.uil-icon-OCCUPANCY-DETECTOR_blue .path1:before {
  content: "\eb64";
}
.uil-icon-OCCUPANCY-DETECTOR_blue .path2:before {
  content: "\eb65";
}
.uil-icon-OCCUPANCY-DETECTOR_blue .path3:before {
  content: "\eb66";
}
.uil-icon-OCCUPANCY-DETECTOR_orange .path1:before {
  content: "\eb67";
}
.uil-icon-OCCUPANCY-DETECTOR_orange .path2:before {
  content: "\eb68";
}
.uil-icon-OCCUPANCY-DETECTOR_orange .path3:before {
  content: "\eb69";
}
.uil-icon-OCCUPANCY-DETECTOR_red .path1:before {
  content: "\eb6a";
}
.uil-icon-OCCUPANCY-DETECTOR_red .path2:before {
  content: "\eb6b";
}
.uil-icon-OCCUPANCY-DETECTOR_red .path3:before {
  content: "\eb6c";
}
.uil-icon-OCCUPANCY-DETECTOR_yellow .path1:before {
  content: "\eb6d";
}
.uil-icon-OCCUPANCY-DETECTOR_yellow .path2:before {
  content: "\eb6e";
}
.uil-icon-OCCUPANCY-DETECTOR_yellow .path3:before {
  content: "\eb6f";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path1:before {
  content: "\eb70";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path2:before {
  content: "\eb71";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path3:before {
  content: "\eb72";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path4:before {
  content: "\eb73";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path5:before {
  content: "\eb74";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path6:before {
  content: "\eb75";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path7:before {
  content: "\eb76";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path8:before {
  content: "\eb77";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path9:before {
  content: "\eb78";
}
.uil-icon-PATH-CHECKING-DETECTOR_blue .path10:before {
  content: "\eb79";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path1:before {
  content: "\eb7a";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path2:before {
  content: "\eb7b";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path3:before {
  content: "\eb7c";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path4:before {
  content: "\eb7d";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path5:before {
  content: "\eb7e";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path6:before {
  content: "\eb7f";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path7:before {
  content: "\eb80";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path8:before {
  content: "\eb81";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path9:before {
  content: "\eb82";
}
.uil-icon-PATH-CHECKING-DETECTOR_orange .path10:before {
  content: "\eb83";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path1:before {
  content: "\eb84";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path2:before {
  content: "\eb85";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path3:before {
  content: "\eb86";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path4:before {
  content: "\eb87";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path5:before {
  content: "\eb88";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path6:before {
  content: "\eb89";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path7:before {
  content: "\eb8a";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path8:before {
  content: "\eb8b";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path9:before {
  content: "\eb8c";
}
.uil-icon-PATH-CHECKING-DETECTOR_red .path10:before {
  content: "\eb8d";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path1:before {
  content: "\eb8e";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path2:before {
  content: "\eb8f";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path3:before {
  content: "\eb90";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path4:before {
  content: "\eb91";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path5:before {
  content: "\eb92";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path6:before {
  content: "\eb93";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path7:before {
  content: "\eb94";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path8:before {
  content: "\eb95";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path9:before {
  content: "\eb96";
}
.uil-icon-PATH-CHECKING-DETECTOR_yellow .path10:before {
  content: "\eb97";
}
.uil-icon-PEOPLE-COUNTER_blue .path1:before {
  content: "\eb98";
}
.uil-icon-PEOPLE-COUNTER_blue .path2:before {
  content: "\eb99";
}
.uil-icon-PEOPLE-COUNTER_blue .path3:before {
  content: "\eb9a";
}
.uil-icon-PEOPLE-COUNTER_blue .path4:before {
  content: "\eb9b";
}
.uil-icon-PEOPLE-COUNTER_blue .path5:before {
  content: "\eb9c";
}
.uil-icon-PEOPLE-COUNTER_blue .path6:before {
  content: "\eb9d";
}
.uil-icon-PEOPLE-COUNTER_blue .path7:before {
  content: "\eb9e";
}
.uil-icon-PEOPLE-COUNTER_orange .path1:before {
  content: "\eb9f";
}
.uil-icon-PEOPLE-COUNTER_orange .path2:before {
  content: "\eba0";
}
.uil-icon-PEOPLE-COUNTER_orange .path3:before {
  content: "\eba1";
}
.uil-icon-PEOPLE-COUNTER_orange .path4:before {
  content: "\eba2";
}
.uil-icon-PEOPLE-COUNTER_orange .path5:before {
  content: "\eba3";
}
.uil-icon-PEOPLE-COUNTER_orange .path6:before {
  content: "\eba4";
}
.uil-icon-PEOPLE-COUNTER_orange .path7:before {
  content: "\eba5";
}
.uil-icon-PEOPLE-COUNTER_red .path1:before {
  content: "\eba6";
}
.uil-icon-PEOPLE-COUNTER_red .path2:before {
  content: "\eba7";
}
.uil-icon-PEOPLE-COUNTER_red .path3:before {
  content: "\eba8";
}
.uil-icon-PEOPLE-COUNTER_red .path4:before {
  content: "\eba9";
}
.uil-icon-PEOPLE-COUNTER_red .path5:before {
  content: "\ebaa";
}
.uil-icon-PEOPLE-COUNTER_red .path6:before {
  content: "\ebab";
}
.uil-icon-PEOPLE-COUNTER_red .path7:before {
  content: "\ebac";
}
.uil-icon-PEOPLE-COUNTER_yellow .path1:before {
  content: "\ebad";
}
.uil-icon-PEOPLE-COUNTER_yellow .path2:before {
  content: "\ebae";
}
.uil-icon-PEOPLE-COUNTER_yellow .path3:before {
  content: "\ebaf";
}
.uil-icon-PEOPLE-COUNTER_yellow .path4:before {
  content: "\ebb0";
}
.uil-icon-PEOPLE-COUNTER_yellow .path5:before {
  content: "\ebb1";
}
.uil-icon-PEOPLE-COUNTER_yellow .path6:before {
  content: "\ebb2";
}
.uil-icon-PEOPLE-COUNTER_yellow .path7:before {
  content: "\ebb3";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_blue .path1:before {
  content: "\ebb4";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_blue .path2:before {
  content: "\ebb5";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_blue .path3:before {
  content: "\ebb6";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_orange .path1:before {
  content: "\ebb7";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_orange .path2:before {
  content: "\ebb8";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_orange .path3:before {
  content: "\ebb9";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_red .path1:before {
  content: "\ebba";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_red .path2:before {
  content: "\ebbb";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_red .path3:before {
  content: "\ebbc";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_yellow .path1:before {
  content: "\ebbd";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_yellow .path2:before {
  content: "\ebbe";
}
.uil-icon-SOCIAL-DISTANCE-DETECTOR_yellow .path3:before {
  content: "\ebbf";
}
.uil-icon-TANK_blue .path1:before {
  content: "\ebc0";
}
.uil-icon-TANK_blue .path2:before {
  content: "\ebc1";
}
.uil-icon-TANK_blue .path3:before {
  content: "\ebc2";
}
.uil-icon-TANK_blue .path4:before {
  content: "\ebc3";
}
.uil-icon-TANK_blue .path5:before {
  content: "\ebc4";
}
.uil-icon-TANK_blue .path6:before {
  content: "\ebc5";
}
.uil-icon-TANK_orange .path1:before {
  content: "\ebc6";
}
.uil-icon-TANK_orange .path2:before {
  content: "\ebc7";
}
.uil-icon-TANK_orange .path3:before {
  content: "\ebc8";
}
.uil-icon-TANK_orange .path4:before {
  content: "\ebc9";
}
.uil-icon-TANK_orange .path5:before {
  content: "\ebca";
}
.uil-icon-TANK_orange .path6:before {
  content: "\ebcb";
}
.uil-icon-TANK_yellow .path1:before {
  content: "\ebcc";
}
.uil-icon-TANK_yellow .path2:before {
  content: "\ebcd";
}
.uil-icon-TANK_yellow .path3:before {
  content: "\ebce";
}
.uil-icon-TANK_yellow .path4:before {
  content: "\ebcf";
}
.uil-icon-TANK_yellow .path5:before {
  content: "\ebd0";
}
.uil-icon-TANK_yellow .path6:before {
  content: "\ebd1";
}
.uil-icon-THERMOMETER_blue .path1:before {
  content: "\e90b";
}
.uil-icon-THERMOMETER_blue .path2:before {
  content: "\e90c";
}
.uil-icon-THERMOMETER_blue .path3:before {
  content: "\e90d";
}
.uil-icon-THERMOMETER_blue .path4:before {
  content: "\e90e";
}
.uil-icon-THERMOMETER_blue .path5:before {
  content: "\e90f";
}
.uil-icon-THERMOMETER_blue .path6:before {
  content: "\e910";
}
.uil-icon-THERMOMETER_blue .path7:before {
  content: "\e911";
}
.uil-icon-THERMOMETER_blue .path8:before {
  content: "\e912";
}
.uil-icon-THERMOMETER_orange .path1:before {
  content: "\ebdb";
}
.uil-icon-THERMOMETER_orange .path2:before {
  content: "\ebdc";
}
.uil-icon-THERMOMETER_orange .path3:before {
  content: "\ebdd";
}
.uil-icon-THERMOMETER_orange .path4:before {
  content: "\ebde";
}
.uil-icon-THERMOMETER_orange .path5:before {
  content: "\ebdf";
}
.uil-icon-THERMOMETER_orange .path6:before {
  content: "\ebe0";
}
.uil-icon-THERMOMETER_orange .path7:before {
  content: "\ebe1";
}
.uil-icon-THERMOMETER_orange .path8:before {
  content: "\ebe2";
}
.uil-icon-THERMOMETER_orange .path9:before {
  content: "\ebe3";
}
.uil-icon-THERMOMETER_red .path1:before {
  content: "\ebe4";
}
.uil-icon-THERMOMETER_red .path2:before {
  content: "\ebe5";
}
.uil-icon-THERMOMETER_red .path3:before {
  content: "\ebe6";
}
.uil-icon-THERMOMETER_red .path4:before {
  content: "\ebe7";
}
.uil-icon-THERMOMETER_red .path5:before {
  content: "\ebe8";
}
.uil-icon-THERMOMETER_red .path6:before {
  content: "\ebe9";
}
.uil-icon-THERMOMETER_red .path7:before {
  content: "\ebea";
}
.uil-icon-THERMOMETER_red .path8:before {
  content: "\ebeb";
}
.uil-icon-THERMOMETER_red .path9:before {
  content: "\ebec";
}
.uil-icon-THERMOMETER_yellow .path1:before {
  content: "\ebed";
}
.uil-icon-THERMOMETER_yellow .path2:before {
  content: "\ebee";
}
.uil-icon-THERMOMETER_yellow .path3:before {
  content: "\ebef";
}
.uil-icon-THERMOMETER_yellow .path4:before {
  content: "\ebf0";
}
.uil-icon-THERMOMETER_yellow .path5:before {
  content: "\ebf1";
}
.uil-icon-THERMOMETER_yellow .path6:before {
  content: "\ebf2";
}
.uil-icon-THERMOMETER_yellow .path7:before {
  content: "\ebf3";
}
.uil-icon-THERMOMETER_yellow .path8:before {
  content: "\ebf4";
}
.uil-icon-THERMOMETER_yellow .path9:before {
  content: "\ebf5";
}
.uil-icon-breaking-pin_black .path1:before {
  content: "\e91d";
}
.uil-icon-breaking-pin_black .path2:before {
  content: "\e926";
}
.uil-icon-breaking-pin_black .path3:before {
  content: "\e9e3";
}
.uil-icon-breaking-pin_black .path4:before {
  content: "\e9e4";
}
.uil-icon-breaking-pin_black .path5:before {
  content: "\e9e5";
}
.uil-icon-breaking-pin_black .path6:before {
  content: "\e9e6";
}
.uil-icon-breaking-pin_black .path7:before {
  content: "\e9e7";
}
.uil-icon-breaking-pin_black .path8:before {
  content: "\e9e8";
}
.uil-icon-breaking-pin_black .path9:before {
  content: "\e9e9";
}
.uil-icon-condition-based-pin_green .path1:before {
  content: "\e91e";
}
.uil-icon-condition-based-pin_green .path2:before {
  content: "\eb20";
}
.uil-icon-condition-based-pin_green .path3:before {
  content: "\eb21";
}
.uil-icon-condition-based-pin_green .path4:before {
  content: "\eb22";
}
.uil-icon-condition-based-pin_green .path5:before {
  content: "\eb23";
}
.uil-icon-condition-based-pin_orange .path1:before {
  content: "\e91f";
}
.uil-icon-condition-based-pin_orange .path2:before {
  content: "\eb24";
}
.uil-icon-condition-based-pin_orange .path3:before {
  content: "\eb25";
}
.uil-icon-condition-based-pin_orange .path4:before {
  content: "\eb26";
}
.uil-icon-condition-based-pin_orange .path5:before {
  content: "\eb27";
}
.uil-icon-condition-based-pin_red .path1:before {
  content: "\e920";
}
.uil-icon-condition-based-pin_red .path2:before {
  content: "\eb28";
}
.uil-icon-condition-based-pin_red .path3:before {
  content: "\eb29";
}
.uil-icon-condition-based-pin_red .path4:before {
  content: "\ebd2";
}
.uil-icon-condition-based-pin_red .path5:before {
  content: "\ebd3";
}
.uil-icon-damage-pin_green .path1:before {
  content: "\e921";
}
.uil-icon-damage-pin_green .path2:before {
  content: "\ebd4";
}
.uil-icon-damage-pin_green .path3:before {
  content: "\ebd5";
}
.uil-icon-damage-pin_green .path4:before {
  content: "\ebd6";
}
.uil-icon-damage-pin_green .path5:before {
  content: "\ebd7";
}
.uil-icon-damage-pin_green .path6:before {
  content: "\ebd8";
}
.uil-icon-damage-pin_orange .path1:before {
  content: "\e922";
}
.uil-icon-damage-pin_orange .path2:before {
  content: "\ebd9";
}
.uil-icon-damage-pin_orange .path3:before {
  content: "\ebda";
}
.uil-icon-damage-pin_orange .path4:before {
  content: "\ebf6";
}
.uil-icon-damage-pin_orange .path5:before {
  content: "\ebf7";
}
.uil-icon-damage-pin_orange .path6:before {
  content: "\ebf8";
}
.uil-icon-damage-pin_red .path1:before {
  content: "\e927";
}
.uil-icon-damage-pin_red .path2:before {
  content: "\e928";
}
.uil-icon-damage-pin_red .path3:before {
  content: "\e929";
}
.uil-icon-damage-pin_red .path4:before {
  content: "\e92a";
}
.uil-icon-damage-pin_red .path5:before {
  content: "\e92b";
}
.uil-icon-damage-pin_red .path6:before {
  content: "\e92c";
}
.uil-icon-harsh-acceleration_orange .path1:before {
  content: "\e92d";
}
.uil-icon-harsh-acceleration_orange .path2:before {
  content: "\e92e";
}
.uil-icon-harsh-acceleration_orange .path3:before {
  content: "\e92f";
}
.uil-icon-harsh-acceleration_orange .path4:before {
  content: "\e930";
}
.uil-icon-key-off-pin_orange .path1:before {
  content: "\e93b";
}
.uil-icon-key-off-pin_orange .path2:before {
  content: "\e93c";
}
.uil-icon-key-off-pin_orange .path3:before {
  content: "\e93d";
}
.uil-icon-key-off-pin_orange .path4:before {
  content: "\e93e";
}
.uil-icon-key-on-pin_orange .path1:before {
  content: "\e93f";
}
.uil-icon-key-on-pin_orange .path2:before {
  content: "\e940";
}
.uil-icon-key-on-pin_orange .path3:before {
  content: "\e941";
}
.uil-icon-map-marker-stroke_red:before {
  content: "\e942";
}
.uil-icon-outside-geo-pin_green .path1:before {
  content: "\e943";
}
.uil-icon-outside-geo-pin_green .path2:before {
  content: "\e944";
}
.uil-icon-outside-geo-pin_green .path3:before {
  content: "\e945";
}
.uil-icon-outside-geo-pin_green .path4:before {
  content: "\e946";
}
.uil-icon-outside-geo-pin_green .path5:before {
  content: "\e947";
}
.uil-icon-outside-geo-pin_green .path6:before {
  content: "\e948";
}
.uil-icon-outside-geo-pin_orange .path1:before {
  content: "\e949";
}
.uil-icon-outside-geo-pin_orange .path2:before {
  content: "\e94a";
}
.uil-icon-outside-geo-pin_orange .path3:before {
  content: "\e94b";
}
.uil-icon-outside-geo-pin_orange .path4:before {
  content: "\e94c";
}
.uil-icon-outside-geo-pin_orange .path5:before {
  content: "\e94d";
}
.uil-icon-outside-geo-pin_orange .path6:before {
  content: "\e94e";
}
.uil-icon-outside-geo-pin_red .path1:before {
  content: "\e94f";
}
.uil-icon-outside-geo-pin_red .path2:before {
  content: "\e950";
}
.uil-icon-outside-geo-pin_red .path3:before {
  content: "\e951";
}
.uil-icon-outside-geo-pin_red .path4:before {
  content: "\e952";
}
.uil-icon-outside-geo-pin_red .path5:before {
  content: "\e953";
}
.uil-icon-outside-geo-pin_red .path6:before {
  content: "\e954";
}
.uil-icon-predictive-failure-pin_green .path1:before {
  content: "\e955";
}
.uil-icon-predictive-failure-pin_green .path2:before {
  content: "\e956";
}
.uil-icon-predictive-failure-pin_green .path3:before {
  content: "\e957";
}
.uil-icon-predictive-failure-pin_green .path4:before {
  content: "\e958";
}
.uil-icon-predictive-failure-pin_green .path5:before {
  content: "\e959";
}
.uil-icon-predictive-failure-pin_green .path6:before {
  content: "\e95a";
}
.uil-icon-predictive-failure-pin_green .path7:before {
  content: "\e95b";
}
.uil-icon-predictive-failure-pin_orange .path1:before {
  content: "\e95c";
}
.uil-icon-predictive-failure-pin_orange .path2:before {
  content: "\e95d";
}
.uil-icon-predictive-failure-pin_orange .path3:before {
  content: "\e95e";
}
.uil-icon-predictive-failure-pin_orange .path4:before {
  content: "\e95f";
}
.uil-icon-predictive-failure-pin_orange .path5:before {
  content: "\e960";
}
.uil-icon-predictive-failure-pin_orange .path6:before {
  content: "\e961";
}
.uil-icon-predictive-failure-pin_orange .path7:before {
  content: "\e962";
}
.uil-icon-predictive-failure-pin_red .path1:before {
  content: "\e963";
}
.uil-icon-predictive-failure-pin_red .path2:before {
  content: "\e964";
}
.uil-icon-predictive-failure-pin_red .path3:before {
  content: "\e965";
}
.uil-icon-predictive-failure-pin_red .path4:before {
  content: "\e966";
}
.uil-icon-predictive-failure-pin_red .path5:before {
  content: "\e967";
}
.uil-icon-predictive-failure-pin_red .path6:before {
  content: "\e968";
}
.uil-icon-predictive-failure-pin_red .path7:before {
  content: "\e969";
}
.uil-icon-sampling-filled_orange .path1:before {
  content: "\e96a";
}
.uil-icon-sampling-filled_orange .path2:before {
  content: "\e96b";
}
.uil-icon-sampling-filled_orange .path3:before {
  content: "\e96c";
}
.uil-icon-sampling-filled_orange .path4:before {
  content: "\e96d";
}
.uil-icon-sampling-filled_red .path1:before {
  content: "\e96e";
}
.uil-icon-sampling-filled_red .path2:before {
  content: "\e96f";
}
.uil-icon-sampling-filled_red .path3:before {
  content: "\e970";
}
.uil-icon-sampling-filled_red .path4:before {
  content: "\e971";
}
.uil-icon-speeding-pin_green .path1:before {
  content: "\e972";
}
.uil-icon-speeding-pin_green .path2:before {
  content: "\e973";
}
.uil-icon-speeding-pin_green .path3:before {
  content: "\e974";
}
.uil-icon-speeding-pin_green .path4:before {
  content: "\e975";
}
.uil-icon-speeding-pin_green .path5:before {
  content: "\e976";
}
.uil-icon-speeding-pin_green .path6:before {
  content: "\e977";
}
.uil-icon-speeding-pin_green .path7:before {
  content: "\e978";
}
.uil-icon-speeding-pin_green .path8:before {
  content: "\e979";
}
.uil-icon-speeding-pin_green .path9:before {
  content: "\e97a";
}
.uil-icon-speeding-pin_green .path10:before {
  content: "\e97b";
}
.uil-icon-speeding-pin_green .path11:before {
  content: "\e97c";
}
.uil-icon-speeding-pin_orange .path1:before {
  content: "\e97d";
}
.uil-icon-speeding-pin_orange .path2:before {
  content: "\e97e";
}
.uil-icon-speeding-pin_orange .path3:before {
  content: "\e97f";
}
.uil-icon-speeding-pin_orange .path4:before {
  content: "\e980";
}
.uil-icon-speeding-pin_orange .path5:before {
  content: "\e981";
}
.uil-icon-speeding-pin_orange .path6:before {
  content: "\e982";
}
.uil-icon-speeding-pin_orange .path7:before {
  content: "\e983";
}
.uil-icon-speeding-pin_orange .path8:before {
  content: "\e984";
}
.uil-icon-speeding-pin_orange .path9:before {
  content: "\e985";
}
.uil-icon-speeding-pin_orange .path10:before {
  content: "\e986";
}
.uil-icon-speeding-pin_orange .path11:before {
  content: "\e987";
}
.uil-icon-speeding-pin_red .path1:before {
  content: "\e988";
}
.uil-icon-speeding-pin_red .path2:before {
  content: "\e989";
}
.uil-icon-speeding-pin_red .path3:before {
  content: "\e98a";
}
.uil-icon-speeding-pin_red .path4:before {
  content: "\e98b";
}
.uil-icon-speeding-pin_red .path5:before {
  content: "\e98c";
}
.uil-icon-speeding-pin_red .path6:before {
  content: "\e98d";
}
.uil-icon-speeding-pin_red .path7:before {
  content: "\e98e";
}
.uil-icon-speeding-pin_red .path8:before {
  content: "\e98f";
}
.uil-icon-speeding-pin_red .path9:before {
  content: "\e990";
}
.uil-icon-speeding-pin_red .path10:before {
  content: "\e991";
}
.uil-icon-speeding-pin_red .path11:before {
  content: "\e992";
}
.uil-icon-theft-pin_green .path1:before {
  content: "\e993";
}
.uil-icon-theft-pin_green .path2:before {
  content: "\e994";
}
.uil-icon-theft-pin_green .path3:before {
  content: "\e995";
}
.uil-icon-theft-pin_green .path4:before {
  content: "\e996";
}
.uil-icon-theft-pin_orange .path1:before {
  content: "\e997";
}
.uil-icon-theft-pin_orange .path2:before {
  content: "\e998";
}
.uil-icon-theft-pin_orange .path3:before {
  content: "\e999";
}
.uil-icon-theft-pin_orange .path4:before {
  content: "\e99a";
}
.uil-icon-theft-pin_red .path1:before {
  content: "\e99b";
}
.uil-icon-theft-pin_red .path2:before {
  content: "\e99c";
}
.uil-icon-theft-pin_red .path3:before {
  content: "\e99d";
}
.uil-icon-theft-pin_red .path4:before {
  content: "\e99e";
}
.uil-icon-tow-truck-pin .path1:before {
  content: "\e99f";
}
.uil-icon-tow-truck-pin .path2:before {
  content: "\e9a0";
}
.uil-icon-tow-truck-pin .path3:before {
  content: "\e9a1";
}
.uil-icon-tow-truck-pin .path4:before {
  content: "\e9a2";
}
.uil-icon-tow-truck-pin .path5:before {
  content: "\e9a3";
}
.uil-icon-uil-icon-BG-filled-pin_green:before {
  content: "\e9a4";
}
.uil-icon-uil-icon-BG-filled-pin_orange:before {
  content: "\e9a5";
}
.uil-icon-uil-icon-mobile-assets-pin_blue .path1:before {
  content: "\e9a6";
}
.uil-icon-uil-icon-mobile-assets-pin_blue .path2:before {
  content: "\e9a7";
}
.uil-icon-uil-icon-mobile-assets-pin_blue .path3:before {
  content: "\e9a8";
}
.uil-icon-uil-icon-mobile-assets-pin_blue .path4:before {
  content: "\e9a9";
}
.uil-icon-uil-icon-mobile-assets-pin_blue .path5:before {
  content: "\e9aa";
}
.uil-icon-uil-icon-mobile-assets-pin_blue .path6:before {
  content: "\e9ab";
}
.uil-icon-uil-icon-mobile-assets-pin_blue .path7:before {
  content: "\e9ac";
}
.uil-icon-uil-icon-mobile-assets-pin_green .path1:before {
  content: "\e9ad";
}
.uil-icon-uil-icon-mobile-assets-pin_green .path2:before {
  content: "\e9ae";
}
.uil-icon-uil-icon-mobile-assets-pin_green .path3:before {
  content: "\e9af";
}
.uil-icon-uil-icon-mobile-assets-pin_green .path4:before {
  content: "\e9b0";
}
.uil-icon-uil-icon-mobile-assets-pin_green .path5:before {
  content: "\e9b1";
}
.uil-icon-uil-icon-mobile-assets-pin_green .path6:before {
  content: "\e9b2";
}
.uil-icon-uil-icon-mobile-assets-pin_green .path7:before {
  content: "\e9b3";
}
.uil-icon-uil-icon-mobile-assets-pin_orange .path1:before {
  content: "\e9b4";
}
.uil-icon-uil-icon-mobile-assets-pin_orange .path2:before {
  content: "\e9b5";
}
.uil-icon-uil-icon-mobile-assets-pin_orange .path3:before {
  content: "\e9b6";
}
.uil-icon-uil-icon-mobile-assets-pin_orange .path4:before {
  content: "\e9b7";
}
.uil-icon-uil-icon-mobile-assets-pin_orange .path5:before {
  content: "\e9b8";
}
.uil-icon-uil-icon-mobile-assets-pin_orange .path6:before {
  content: "\e9b9";
}
.uil-icon-uil-icon-mobile-assets-pin_orange .path7:before {
  content: "\e9ba";
}
.uil-icon-uil-icon-moving-pin_gray .path1:before {
  content: "\e9bb";
}
.uil-icon-uil-icon-moving-pin_gray .path2:before {
  content: "\e9bc";
}
.uil-icon-uil-icon-moving-pin_gray .path3:before {
  content: "\e9bd";
}
.uil-icon-uil-icon-moving-pin_gray .path4:before {
  content: "\e9be";
}
.uil-icon-uil-icon-moving-pin_gray .path5:before {
  content: "\e9bf";
}
.uil-icon-uil-icon-moving-pin_gray .path6:before {
  content: "\e9c0";
}
.uil-icon-uil-icon-moving-pin_gray .path7:before {
  content: "\e9c1";
}
.uil-icon-uil-icon-moving-pin_gray .path8:before {
  content: "\e9c2";
}
.uil-icon-uil-icon-moving-pin_green .path1:before {
  content: "\e9c3";
}
.uil-icon-uil-icon-moving-pin_green .path2:before {
  content: "\e9c4";
}
.uil-icon-uil-icon-moving-pin_green .path3:before {
  content: "\e9c5";
}
.uil-icon-uil-icon-moving-pin_green .path4:before {
  content: "\e9c6";
}
.uil-icon-uil-icon-moving-pin_green .path5:before {
  content: "\e9c7";
}
.uil-icon-uil-icon-moving-pin_green .path6:before {
  content: "\e9c8";
}
.uil-icon-uil-icon-moving-pin_green .path7:before {
  content: "\e9c9";
}
.uil-icon-uil-icon-moving-pin_green .path8:before {
  content: "\e9ca";
}
.uil-icon-uil-icon-moving-pin_orange .path1:before {
  content: "\e9cb";
}
.uil-icon-uil-icon-moving-pin_orange .path2:before {
  content: "\e9cc";
}
.uil-icon-uil-icon-moving-pin_orange .path3:before {
  content: "\e9cd";
}
.uil-icon-uil-icon-moving-pin_orange .path4:before {
  content: "\e9ce";
}
.uil-icon-uil-icon-moving-pin_orange .path5:before {
  content: "\e9cf";
}
.uil-icon-uil-icon-moving-pin_orange .path6:before {
  content: "\e9d0";
}
.uil-icon-uil-icon-moving-pin_orange .path7:before {
  content: "\e9d1";
}
.uil-icon-uil-icon-moving-pin_orange .path8:before {
  content: "\e9d2";
}
.uil-icon-uil-icon-parked-pin_green .path1:before {
  content: "\e9d3";
}
.uil-icon-uil-icon-parked-pin_green .path2:before {
  content: "\e9d4";
}
.uil-icon-uil-icon-parked-pin_green .path3:before {
  content: "\e9d5";
}
.uil-icon-uil-icon-parked-pin_green .path4:before {
  content: "\e9d6";
}
.uil-icon-uil-icon-parked-pin_green .path5:before {
  content: "\e9d7";
}
.uil-icon-uil-icon-parked-pin_green .path6:before {
  content: "\e9d8";
}
.uil-icon-uil-icon-parked-pin_green .path7:before {
  content: "\e9d9";
}
.uil-icon-uil-icon-parked-pin_orange .path1:before {
  content: "\e9da";
}
.uil-icon-uil-icon-parked-pin_orange .path2:before {
  content: "\e9db";
}
.uil-icon-uil-icon-parked-pin_orange .path3:before {
  content: "\e9dc";
}
.uil-icon-uil-icon-parked-pin_orange .path4:before {
  content: "\e9dd";
}
.uil-icon-uil-icon-parked-pin_orange .path5:before {
  content: "\e9de";
}
.uil-icon-uil-icon-parked-pin_orange .path6:before {
  content: "\e9df";
}
.uil-icon-uil-icon-parked-pin_orange .path7:before {
  content: "\e9e0";
}
.uil-icon-predictive-failure-pin:before {
  content: "\e923";
}
.uil-icon-condition-based-pin:before {
  content: "\e924";
}
.uil-icon-nav-arrow-back:before {
  content: "\ea29";
}
.uil-icon-nav-arrow-up:before {
  content: "\ea2a";
}
.uil-icon-nav-arrow-down:before {
  content: "\ea2b";
}
.uil-icon-arrow-right-filled:before {
  content: "\ea2c";
}
.uil-icon-Disk-filled:before {
  content: "\ea2d";
}
.uil-icon-date-range-filled:before {
  content: "\ea2e";
}
.uil-icon-fullscreen-exit-filled:before {
  content: "\ea2f";
}
.uil-icon-fullscreen-filled:before {
  content: "\ea30";
}
.uil-icon-move-filled:before {
  content: "\ea31";
}
.uil-icon-zoom-out-filled:before {
  content: "\ea32";
}
.uil-icon-delet-single-filled:before {
  content: "\ea33";
}
.uil-icon-delete-all-filled:before {
  content: "\ea34";
}
.uil-icon-arrow-down-filled:before {
  content: "\eab6";
}
.uil-icon-zoom-in-map-filled:before {
  content: "\eab7";
}
.uil-icon-theft-box:before {
  content: "\eab8";
}
.uil-icon-eye-crossed:before {
  content: "\eab9";
}
.uil-icon-profile-stroke:before {
  content: "\eaba";
}
.uil-icon-mobile-assets:before {
  content: "\eabb";
}
.uil-icon-arrow-pin:before {
  content: "\eabc";
}
.uil-icon-condition-based:before {
  content: "\eabd";
}
.uil-icon-in-transit:before {
  content: "\eabe";
}
.uil-icon-predictive-failure:before {
  content: "\eabf";
}
.uil-icon-speed:before {
  content: "\eac0";
}
.uil-icon-mobile-assets-pin:before {
  content: "\eac4";
}
.uil-icon-Cart:before {
  content: "\eac5";
}
.uil-icon-Route-start:before {
  content: "\eac6";
}
.uil-icon-Route-end:before {
  content: "\eac7";
}
.uil-icon-Accenture-greater-than:before {
  content: "\eac8";
}
.uil-icon-Spam:before {
  content: "\eac9";
}
.uil-icon-Success:before {
  content: "\eaca";
}
.uil-icon-User:before {
  content: "\eacb";
}
.uil-icon-Cancel:before {
  content: "\eacc";
}
.uil-icon-Heart:before {
  content: "\eacd";
}
.uil-icon-Filtrate:before {
  content: "\eace";
}
.uil-icon-Edit:before {
  content: "\eacf";
}
.uil-icon-Chat-alt:before {
  content: "\ead0";
}
.uil-icon-Arrow-down:before {
  content: "\ead1";
}
.uil-icon-Arrow-up:before {
  content: "\ead2";
}
.uil-icon-Arrow-left-down:before {
  content: "\ead3";
}
.uil-icon-Arrow-right-up:before {
  content: "\ead4";
}
.uil-icon-Arrow-left-up:before {
  content: "\ead5";
}
.uil-icon-Arrow-left:before {
  content: "\ead6";
}
.uil-icon-Arrow-right:before {
  content: "\ead7";
}
.uil-icon-Download:before {
  content: "\ead8";
}
.uil-icon-Heart-filled:before {
  content: "\ead9";
}
.uil-icon-Star-empty:before {
  content: "\eada";
}
.uil-icon-Star-filled:before {
  content: "\eadb";
}
.uil-icon-datasource:before {
  content: "\e913";
}
.uil-icon-datasets:before {
  content: "\e914";
}
.uil-icon-Table:before {
  content: "\e915";
}
.uil-icon-Bar-chart:before {
  content: "\e916";
}
.uil-icon-edit_widget:before {
  content: "\ea35";
}
.uil-icon-bin-x-filled:before {
  content: "\ea36";
}
.uil-icon-bin-lines-filled:before {
  content: "\ea37";
}
.uil-icon-unlocked-filled:before {
  content: "\ea38";
}
.uil-icon-page-gear-filled:before {
  content: "\ea39";
}
.uil-icon-circle-arrow-right-stroke:before {
  content: "\ea3a";
}
.uil-icon-add-multiple-users-filled:before {
  content: "\ea3b";
}
.uil-icon-add-user-filled:before {
  content: "\ea3c";
}
.uil-icon-crown-filled:before {
  content: "\ea3d";
}
.uil-icon-APS-Toggle-Default-Play:before {
  content: "\ea3e";
}
.uil-icon-fab-filled:before {
  content: "\ea3f";
}
.uil-icon-tickmark-filled:before {
  content: "\ea40";
}
.uil-icon-tickmark-filled-green:before {
  content: "\ea41";
}
.uil-icon-notifications_filled:before {
  content: "\ea42";
}
.uil-icon-bell-active-filled .path1:before {
  content: "\ea43";
}
.uil-icon-bell-active-filled .path2:before {
  content: "\eadc";
}
.uil-icon-bell-active-filled .path3:before {
  content: "\eadd";
}
.uil-icon-bell-active-filled .path4:before {
  content: "\eade";
}
.uil-icon-star-filled:before {
  content: "\ea44";
}
.uil-icon-minus-filled:before {
  content: "\ea45";
}
.uil-icon-circle-x-stroke:before {
  content: "\ea46";
}
.uil-icon-x-filled:before {
  content: "\ea47";
}
.uil-icon-plus_grey_icon:before {
  content: "\ea48";
}
.uil-icon-unsubscribe:before {
  content: "\ea49";
}
.uil-icon-9-Dots-Grey:before {
  content: "\ea4a";
}
.uil-icon-ic_alert:before {
  content: "\ea4b";
}
.uil-icon-alert:before {
  content: "\ea4c";
}
.uil-icon-Asset .path1:before {
  content: "\ea4d";
}
.uil-icon-Asset .path2:before {
  content: "\eadf";
}
.uil-icon-Asset_Hover .path1:before {
  content: "\ea4e";
}
.uil-icon-Asset_Hover .path2:before {
  content: "\eae0";
}
.uil-icon-attachment:before {
  content: "\ea4f";
}
.uil-icon-calendar:before {
  content: "\ea50";
}
.uil-icon-copy:before {
  content: "\ea51";
}
.uil-icon-copy_widget:before {
  content: "\ea52";
}
.uil-icon-cross_icon:before {
  content: "\ea53";
}
.uil-icon-dropdown_arrow:before {
  content: "\ea54";
}
.uil-icon-dropup_arrow:before {
  content: "\ea55";
}
.uil-icon-extra_menu:before {
  content: "\ea56";
}
.uil-icon-eye_widget:before {
  content: "\ea57";
}
.uil-icon-FAB-hover .path1:before {
  content: "\ea58";
  opacity: 0.9541;
}
.uil-icon-FAB-hover .path2:before {
  content: "\eae1";
}
.uil-icon-FAB-normal .path1:before {
  content: "\ea59";
  opacity: 0.9541;
}
.uil-icon-FAB-normal .path2:before {
  content: "\eae2";
}
.uil-icon-FAB-pressed .path1:before {
  content: "\ea5a";
  opacity: 0.9541;
}
.uil-icon-FAB-pressed .path2:before {
  content: "\eae3";
}
.uil-icon-filter:before {
  content: "\ea5b";
}
.uil-icon-forbidden:before {
  content: "\ea5c";
}
.uil-icon-Gateway .path1:before {
  content: "\ea5d";
}
.uil-icon-Gateway .path2:before {
  content: "\eae4";
}
.uil-icon-Gateway_Hover .path1:before {
  content: "\ea5e";
}
.uil-icon-Gateway_Hover .path2:before {
  content: "\eae5";
}
.uil-icon-gear:before {
  content: "\ea5f";
}
.uil-icon-gear_gray:before {
  content: "\ea60";
}
.uil-icon-gear-stroke:before {
  content: "\ea61";
}
.uil-icon-Hamburger:before {
  content: "\ea62";
}
.uil-icon-help:before {
  content: "\ea63";
}
.uil-icon-ic_account_circle:before {
  content: "\ea64";
}
.uil-icon-ic_arrow_left:before {
  content: "\ea65";
}
.uil-icon-ic_arrow_right:before {
  content: "\ea66";
}
.uil-icon-ic_dnd_on:before {
  content: "\ea67";
}
.uil-icon-ic_information:before {
  content: "\ea68";
}
.uil-icon-ic_menu:before {
  content: "\ea69";
}
.uil-icon-ic_more_vert:before {
  content: "\ea6a";
}
.uil-icon-ic_search_white:before {
  content: "\ea6b";
}
.uil-icon-ic_visibility:before {
  content: "\ea6c";
}
.uil-icon-left_arrow:before {
  content: "\ea6d";
}
.uil-icon-location_big:before {
  content: "\ea6e";
}
.uil-icon-location_purple:before {
  content: "\ea6f";
}
.uil-icon-location_small:before {
  content: "\ea70";
}
.uil-icon-lock_filled:before {
  content: "\ea71";
}
.uil-icon-mobilephone:before {
  content: "\ea72";
}
.uil-icon-play_widget:before {
  content: "\ea73";
}
.uil-icon-profile:before {
  content: "\ea74";
}
.uil-icon-profile_iconGrey:before {
  content: "\ea75";
}
.uil-icon-profile_iconWhite:before {
  content: "\ea76";
}
.uil-icon-right_arrow:before {
  content: "\ea77";
}
.uil-icon-search:before {
  content: "\ea78";
}
.uil-icon-Search_iconGrey:before {
  content: "\ea79";
}
.uil-icon-Search_iconWhite:before {
  content: "\ea7a";
}
.uil-icon-sort:before {
  content: "\ea7b";
}
.uil-icon-spinner:before {
  content: "\ea7c";
}
.uil-icon-supervisor:before {
  content: "\ea7d";
}
.uil-icon-timepicker_icon:before {
  content: "\ea7e";
}
.uil-icon-trash:before {
  content: "\ea7f";
}
.uil-icon-divide-line:before {
  content: "\ea80";
}
.uil-icon-place-holder-filled:before {
  content: "\ea81";
}
.uil-icon-damage-box:before {
  content: "\ea82";
}
.uil-icon-accident-filled:before {
  content: "\ea83";
}
.uil-icon-outside-geo-box:before {
  content: "\ea84";
}
.uil-icon-damage-pin:before {
  content: "\ea85";
}
.uil-icon-braking-pin:before {
  content: "\ea86";
}
.uil-icon-theft-pin:before {
  content: "\ea87";
}
.uil-icon-ket-off-pin:before {
  content: "\ea88";
}
.uil-icon-moving-pin:before {
  content: "\ea89";
}
.uil-icon-outside-geo-pin:before {
  content: "\ea8a";
}
.uil-icon-sampling-filled:before {
  content: "\ea8b";
}
.uil-icon-parked-pin:before {
  content: "\ea8c";
}
.uil-icon-speeding-pin:before {
  content: "\ea8d";
}
.uil-icon-key-on-pin:before {
  content: "\ea8e";
}
.uil-icon-idle-pin:before {
  content: "\ea8f";
}
.uil-icon-BG-filled-pin:before {
  content: "\ea90";
}
.uil-icon-car-filled:before {
  content: "\ea91";
}
.uil-icon-vehicle-idle-filled:before {
  content: "\ea92";
}
.uil-icon-vehicle-moving-filled:before {
  content: "\ea93";
}
.uil-icon-vehicle-parked-filled:before {
  content: "\ea94";
}
.uil-icon-theft-filled:before {
  content: "\ea95";
}
.uil-icon-outside-geo-filled:before {
  content: "\ea96";
}
.uil-icon-time-sroke:before {
  content: "\ea97";
}
.uil-icon-swap-stroke:before {
  content: "\ea98";
}
.uil-icon-avg-speed-filled:before {
  content: "\ea99";
}
.uil-icon-info-stroke:before {
  content: "\ea9a";
}
.uil-icon-braking-filled:before {
  content: "\ea9b";
}
.uil-icon-speeding-filled:before {
  content: "\ea9c";
}
.uil-icon-max-speed-filled:before {
  content: "\ea9d";
}
.uil-icon-start-location-filled:before {
  content: "\ea9e";
}
.uil-icon-end-location-filled:before {
  content: "\ea9f";
}
.uil-icon-map-marker-stroke:before {
  content: "\eaa0";
}
.uil-icon-nav-stroke:before {
  content: "\eaa1";
}
.uil-icon-date-stroke:before {
  content: "\eaa2";
}
.uil-icon-distance-filled:before {
  content: "\eaa3";
}
.uil-icon-driver-filled:before {
  content: "\eaa4";
}
.uil-icon-engine-ok-filled:before {
  content: "\eaa5";
}
.uil-icon-engine-damage-filled:before {
  content: "\eaa6";
}
.uil-icon-key-off-filled:before {
  content: "\eaa7";
}
.uil-icon-key-on-filled:before {
  content: "\eaa8";
}
.uil-icon-fleet-filled:before {
  content: "\eaa9";
}
.uil-icon-FleetM-My-location:before {
  content: "\eaaa";
}
.uil-icon-FleetM-Satellite:before {
  content: "\eaab";
}
.uil-icon-FleetM-zoom:before {
  content: "\eaac";
}
.uil-icon-FleetM-Near-me:before {
  content: "\eaad";
}
.uil-icon-zoom-in:before {
  content: "\eaae";
}
.uil-icon-zoom-out:before {
  content: "\eaaf";
}
.uil-icon-upload-filled:before {
  content: "\eab0";
}
.uil-icon-download-filled:before {
  content: "\eab1";
}
.uil-icon-bookmark-filled:before {
  content: "\eab2";
}
.uil-icon-bookmark-stroke:before {
  content: "\eab3";
}
.uil-icon-bubbles-filled:before {
  content: "\eab4";
}
.uil-icon-table-stroke:before {
  content: "\eab5";
}
.uil-icon-minus:before {
  content: "\eac1";
}
.uil-icon-Plus:before {
  content: "\eac2";
}
.uil-icon-minusIcon:before {
  content: "−";
}
.uil-icon-plusIcon:before {
  content: "+";
}
.uil-icon-Open:before {
  content: "\eac3";
}
.uil-icon-extra_menu_horizontal:before {
  content: "\e925";
}
[class*="uil-dl-icon-"],
[class^="uil-dl-icon-"] {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:24px;
  height:24px;
  display: block;
}
.uil-dl-icon-AIP_Logo:before {
  content: "\ea29";
}
.uil-dl-icon-Acc_Digital_Logo_All_Black-mod_17px:before {
  content: "\e900";
}
.uil-dl-icon-accenture-greater-than:before {
  content: "\e901";
}
.uil-dl-icon-Arrow-left:before {
  content: "\e9ee";
}
.uil-dl-icon-Arrow-right:before {
  content: "\e93c";
}
.uil-dl-icon-Arrow-up:before {
  content: "\e9da";
}
.uil-dl-icon-Arrow-down:before {
  content: "\e902";
}
.uil-dl-icon-Arrow-left-up:before {
  content: "\e93b";
}
.uil-dl-icon-Arrow-right-up:before {
  content: "\e9b3";
}
.uil-dl-icon-Arrow-right-down:before {
  content: "\e9d9";
}
.uil-dl-icon-Arrow-left-down:before {
  content: "\e961";
}
.uil-dl-icon-Direction-left:before {
  content: "\e915";
}
.uil-dl-icon-Direction-right:before {
  content: "\e9a1";
}
.uil-dl-icon-Turn-left:before {
  content: "\e9d7";
}
.uil-dl-icon-Turn-right:before {
  content: "\e94b";
}
.uil-dl-icon-Back-left:before {
  content: "\e9c7";
}
.uil-dl-icon-Back-right:before {
  content: "\e98a";
}
.uil-dl-icon-Undo:before {
  content: "\e9fd";
}
.uil-dl-icon-Redo:before {
  content: "\e9bd";
}
.uil-dl-icon-Refresh:before {
  content: "\e998";
}
.uil-dl-icon-Trending-up:before {
  content: "\ea26";
}
.uil-dl-icon-Trending-down:before {
  content: "\ea13";
}
.uil-dl-icon-Crossing:before {
  content: "\e9cf";
}
.uil-dl-icon-Chevron-left:before {
  content: "\ea19";
}
.uil-dl-icon-Chevron-right:before {
  content: "\e93e";
}
.uil-dl-icon-Chevron-up:before {
  content: "\e92d";
}
.uil-dl-icon-Chevron-down:before,
.uil-expandable-card-collapser.expanded:before,
.uil-expandable-card-collapser:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="false"]:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"]:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="false"]:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"]:before,
.uil-megamenu-expandable.uil-megamenu-expandable-current:before,
.uil-megamenu-expandable.uil-megamenu-vertical-expand:before,
.uil-megamenu-titlearea:before,
.uil-side-nav-item a[aria-expanded="false"]:before,
.uil-side-nav-item a[aria-expanded="true"]:before,
.uil-side-nav-item[aria-expanded="false"]:before,
.uil-side-nav-item[aria-expanded="true"]:before {
  content: "\e92c";
}
.uil-dl-icon-Chevrons-right:before {
  content: "\e906";
}
.uil-dl-icon-Chevrons-left:before {
  content: "\e9f0";
}
.uil-dl-icon-Chevrons-up:before {
  content: "\e9ca";
}
.uil-dl-icon-Chevrons-down:before {
  content: "\e905";
}
.uil-dl-icon-Sorting:before {
  content: "\e937";
}
.uil-dl-icon-Return:before {
  content: "\e95b";
}
.uil-dl-icon-Shuffle:before {
  content: "\e9fa";
}
.uil-dl-icon-Repeat:before {
  content: "\e90f";
}
.uil-dl-icon-Rotate:before {
  content: "\e936";
}
.uil-dl-icon-Plus:before {
  content: "\e981";
}
.uil-dl-icon-Minus:before {
  content: "\ea1e";
}
.uil-chip-selected-icon:before,
.uil-dl-icon-Check:before {
  content: "\e979";
}
.uil-chip-close-icon:before,
.uil-dl-icon-Close:before,
.uil-icon-close_icon:before,
.uil-status-card-close-icon:before {
  content: "\e98e";
}
.uil-dl-icon-Back:before {
  content: "\e99e";
}
.uil-dl-icon-Forward:before {
  content: "\ea08";
}
.uil-dl-icon-Pause:before {
  content: "\ea0e";
}
.uil-dl-icon-Previous:before {
  content: "\e9ab";
}
.uil-dl-icon-Next:before {
  content: "\e9d2";
}
.uil-dl-icon-Video:before {
  content: "\e9fe";
}
.uil-dl-icon-Send:before {
  content: "\e9be";
}
.uil-dl-icon-Back-alt:before {
  content: "\e9c6";
}
.uil-dl-icon-Play:before {
  content: "\e9bc";
}
.uil-dl-icon-Power:before {
  content: "\e935";
}
.uil-dl-icon-Time:before {
  content: "\e972";
}
.uil-dl-icon-History:before {
  content: "\ea1d";
}
.uil-dl-icon-Timer:before {
  content: "\ea25";
}
.uil-dl-icon-Stopwatch:before {
  content: "\e960";
}
.uil-dl-icon-Alarm:before {
  content: "\ea28";
}
.uil-dl-icon-Upload:before {
  content: "\ea14";
}
.uil-dl-icon-Download:before {
  content: "\e953";
}
.uil-dl-icon-Shift:before {
  content: "\e91e";
}
.uil-dl-icon-Cloud-upload:before {
  content: "\e940";
}
.uil-dl-icon-Loading:before {
  content: "\e9a8";
}
.uil-dl-icon-Sound-low:before {
  content: "\e9d5";
}
.uil-dl-icon-Sound-medium:before {
  content: "\e95e";
}
.uil-dl-icon-Sound-high:before {
  content: "\e999";
}
.uil-dl-icon-Mute:before {
  content: "\e9bb";
}
.uil-dl-icon-Voicemail:before {
  content: "\e94d";
}
.uil-dl-icon-Music:before {
  content: "\e934";
}
.uil-dl-icon-Microphone:before {
  content: "\e933";
}
.uil-dl-icon-Cellular:before {
  content: "\e904";
}
.uil-dl-icon-Battery-orizontal:before {
  content: "\e903";
}
.uil-dl-icon-Battery-vertical:before {
  content: "\e98c";
}
.uil-dl-icon-Battery-full:before {
  content: "\e977";
}
.uil-dl-icon-Battery-medium:before {
  content: "\e963";
}
.uil-dl-icon-Battery-low:before {
  content: "\e9b4";
}
.uil-dl-icon-Battery-charging:before {
  content: "\e962";
}
.uil-dl-icon-Danger:before {
  content: "\e9b9";
}
.uil-dl-icon-Error:before {
  content: "\e9f4";
}
.uil-dl-icon-Info:before,
.uil-icon-pb:before {
  content: "\e994";
}
.uil-dl-icon-Help:before {
  content: "\ea0a";
}
.uil-dl-icon-Spam:before {
  content: "\e95f";
}
.uil-dl-icon-No-entry:before {
  content: "\e944";
}
.uil-dl-icon-Add:before,
.uil-icon-zoomin-icon:before {
  content: "\e975";
}
.uil-dl-icon-Remove:before,
.uil-icon-zoomout-icon:before {
  content: "\e9ea";
}
.uil-dl-icon-Success:before {
  content: "\e9ec";
}
.uil-dl-icon-Cancel:before {
  content: "\e92b";
}
.uil-dl-icon-Not-allowed:before {
  content: "\e96e";
}
.uil-dl-icon-Circle-arrow-up:before {
  content: "\e9f1";
}
.uil-dl-icon-Circle-arrow-down:before {
  content: "\e9cb";
}
.uil-dl-icon-Circle-arrow-left:before {
  content: "\e967";
}
.uil-dl-icon-Circle-arrow-right:before {
  content: "\e9b8";
}
.uil-dl-icon-Circle:before {
  content: "\e914";
}
.uil-dl-icon-Octagon:before {
  content: "\e9e8";
}
.uil-dl-icon-Esagon:before {
  content: "\e918";
}
.uil-dl-icon-Pentagon:before {
  content: "\ea22";
}
.uil-dl-icon-Star:before {
  content: "\e9c0";
}
.uil-dl-icon-Triangle:before {
  content: "\e912";
}
.uil-dl-icon-Heart:before {
  content: "\ea1c";
}
.uil-dl-icon-Square-small:before {
  content: "\e9bf";
}
.uil-dl-icon-Square:before {
  content: "\e9ad";
}
.uil-dl-icon-Placeholder:before,
.uil-icon-user-menu-placeholder-icon:before {
  content: "\ea23";
}
.uil-dl-icon-Home:before {
  content: "\e919";
}
.uil-dl-icon-Home-alt:before {
  content: "\e942";
}
.uil-dl-icon-Hand_1:before {
  content: "\e9a3";
}
.uil-dl-icon-Hand:before {
  content: "\e90a";
}
.uil-dl-icon-Thumb-up:before {
  content: "\e922";
}
.uil-dl-icon-Thumb-down:before {
  content: "\e9ae";
}
.uil-dl-icon-People:before {
  content: "\e996";
}
.uil-dl-icon-Person:before {
  content: "\e91c";
}
.uil-dl-icon-User:before,
.uil-icon-user-menu:before {
  content: "\e926";
}
.uil-dl-icon-Face-happy:before {
  content: "\e96c";
}
.uil-dl-icon-Face-neutral:before {
  content: "\e97c";
}
.uil-dl-icon-Face-sad:before {
  content: "\e930";
}
.uil-dl-icon-Chat:before {
  content: "\e9c9";
}
.uil-dl-icon-Chat-alt:before {
  content: "\e966";
}
.uil-dl-icon-Hide-alt:before {
  content: "\e9e3";
}
.uil-dl-icon-Eye:before {
  content: "\e9e1";
}
.uil-dl-icon-Hide:before {
  content: "\e9a4";
}
.uil-dl-icon-Wheelchair:before {
  content: "\e9d8";
}
.uil-dl-icon-Compass:before {
  content: "\e9cc";
}
.uil-dl-icon-Search:before {
  content: "\e910";
}
.uil-dl-icon-Lightbulb:before {
  content: "\e9a7";
}
.uil-dl-icon-Zoom-in:before {
  content: "\e99c";
}
.uil-dl-icon-Zoom-out:before {
  content: "\e9b0";
}
.uil-chip-unlocked-icon:before,
.uil-dl-icon-Lock-alt-open:before {
  content: "\e958";
}
.uil-dl-icon-Lock-alt-closed:before {
  content: "\e90d";
}
.uil-dl-icon-Lock-open:before {
  content: "\ea0d";
}
.uil-chip-locked-icon:before,
.uil-dl-icon-Lock-closed:before {
  content: "\e9f9";
}
.uil-dl-icon-Key:before {
  content: "\e956";
}
.uil-dl-icon-Theft:before {
  content: "\e99a";
}
.uil-dl-icon-Table-horizontal:before {
  content: "\e949";
}
.uil-dl-icon-Table-vertical:before {
  content: "\e985";
}
.uil-dl-icon-Grid-9-circles:before {
  content: "\e993";
}
.uil-dl-icon-Grid-9-squares:before {
  content: "\e908";
}
.uil-dl-icon-Grid-small:before {
  content: "\e9d1";
}
.uil-dl-icon-Grid:before {
  content: "\e909";
}
.uil-dl-icon-Component:before {
  content: "\ea03";
}
.uil-dl-icon-List-alt:before {
  content: "\e90b";
}
.uil-dl-icon-List:before {
  content: "\e90c";
}
.uil-dl-icon-Dashboard:before {
  content: "\e96b";
}
.uil-dl-icon-Align-type-justify:before {
  content: "\e928";
}
.uil-dl-icon-Vertical:before {
  content: "\e93a";
}
.uil-dl-icon-Stack:before {
  content: "\e9fb";
}
.uil-dl-icon-Filter-alt:before {
  content: "\e9f5";
}
.uil-dl-icon-Filter:before {
  content: "\e9f6";
}
.uil-dl-icon-More-horizontal:before {
  content: "\e91b";
}
.uil-dl-icon-More-vertical:before,
.uil-icon-more-vertical:before {
  content: "\e9e6";
}
.uil-dl-icon-Burger:before,
.uil-toolicon-hamburger:before {
  content: "\e98d";
}
.uil-dl-icon-Bell:before {
  content: "\e964";
}
.uil-dl-icon-Feed:before {
  content: "\e97d";
}
.uil-dl-icon-Pie-chart:before {
  content: "\e980";
}
.uil-dl-icon-Bar-chart:before {
  content: "\e93d";
}
.uil-dl-icon-Line-chart:before {
  content: "\e9e4";
}
.uil-dl-icon-Activity:before {
  content: "\e9ed";
}
.uil-dl-icon-Keyboard:before {
  content: "\e943";
}
.uil-dl-icon-Mouse:before {
  content: "\ea20";
}
.uil-dl-icon-Print:before {
  content: "\ea24";
}
.uil-dl-icon-Laptop:before {
  content: "\e9f8";
}
.uil-dl-icon-Tv:before {
  content: "\e974";
}
.uil-dl-icon-Tablet:before {
  content: "\ea12";
}
.uil-dl-icon-Smartphone:before {
  content: "\e91f";
}
.uil-dl-icon-iPhone:before {
  content: "\ea0b";
}
.uil-dl-icon-Contact-book:before {
  content: "\e96a";
}
.uil-dl-icon-Phone:before {
  content: "\e9e9";
}
.uil-dl-icon-Suitcase-alt:before {
  content: "\e911";
}
.uil-dl-icon-Suitcase:before {
  content: "\e9fc";
}
.uil-dl-icon-Credit-card:before {
  content: "\e9ce";
}
.uil-dl-icon-Coffee:before {
  content: "\e968";
}
.uil-dl-icon-Restaurant:before {
  content: "\e91d";
}
.uil-dl-icon-Wine-glass:before {
  content: "\e9c4";
}
.uil-dl-icon-Glasses:before {
  content: "\e992";
}
.uil-dl-icon-Watch:before {
  content: "\e99b";
}
.uil-dl-icon-News:before {
  content: "\e995";
}
.uil-dl-icon-Hourglass:before {
  content: "\e91a";
}
.uil-dl-icon-Cup:before {
  content: "\ea04";
}
.uil-dl-icon-Anchor:before {
  content: "\e9c5";
}
.uil-dl-icon-Support:before {
  content: "\e984";
}
.uil-dl-icon-Headphones:before {
  content: "\e97f";
}
.uil-dl-icon-Megaphone:before {
  content: "\e90e";
}
.uil-dl-icon-Typography:before {
  content: "\e94c";
}
.uil-dl-icon-Typography-alt:before {
  content: "\e924";
}
.uil-dl-icon-Stats:before {
  content: "\ea11";
}
.uil-dl-icon-Align-type-left:before {
  content: "\e9b1";
}
.uil-dl-icon-Align-type-center:before {
  content: "\e989";
}
.uil-dl-icon-Align-type-right:before {
  content: "\e9b2";
}
.uil-dl-icon-Envelope-alt:before {
  content: "\e92f";
}
.uil-dl-icon-Envelope:before {
  content: "\e954";
}
.uil-dl-icon-At:before {
  content: "\e94f";
}
.uil-dl-icon-Paperclip:before {
  content: "\e9aa";
}
.uil-dl-icon-Inbox:before {
  content: "\e931";
}
.uil-dl-icon-Box-alt:before {
  content: "\e92a";
}
.uil-dl-icon-Box:before {
  content: "\e9b5";
}
.uil-dl-icon-Save:before {
  content: "\e9ac";
}
.uil-dl-icon-Folder-add:before {
  content: "\e9e2";
}
.uil-dl-icon-Folder:before {
  content: "\ea1b";
}
.uil-dl-icon-Book-open:before {
  content: "\e951";
}
.uil-dl-icon-Book:before {
  content: "\e965";
}
.uil-dl-icon-Notebook:before {
  content: "\ea21";
}
.uil-dl-icon-Edit:before {
  content: "\e9d0";
}
.uil-dl-icon-New:before {
  content: "\e9e7";
}
.uil-dl-icon-Clipboard-alt:before {
  content: "\e99f";
}
.uil-dl-icon-Calendar:before {
  content: "\e9ef";
}
.uil-dl-icon-Clipboard:before {
  content: "\e93f";
}
.uil-dl-icon-Copy:before {
  content: "\e990";
}
.uil-dl-icon-Cards:before {
  content: "\ea18";
}
.uil-dl-icon-Sticker:before {
  content: "\e9c1";
}
.uil-dl-icon-Page:before {
  content: "\e9d3";
}
.uil-dl-icon-File-success:before {
  content: "\ea07";
}
.uil-dl-icon-File-error:before {
  content: "\e907";
}
.uil-dl-icon-Bin:before {
  content: "\e9db";
}
.uil-dl-icon-Delete:before {
  content: "\e9de";
}
.uil-dl-icon-Cut:before {
  content: "\e9f3";
}
.uil-dl-icon-Crop:before {
  content: "\e92e";
}
.uil-dl-icon-Minimize:before {
  content: "\e959";
}
.uil-dl-icon-Maximise:before {
  content: "\e9a9";
}
.uil-dl-icon-Map:before {
  content: "\e932";
}
.uil-dl-icon-Location:before {
  content: "\e9e5";
}
.uil-dl-icon-Geofence-violation:before {
  content: "\e9a2";
}
.uil-dl-icon-Cursor:before {
  content: "\e97a";
}
.uil-dl-icon-Bike:before {
  content: "\e929";
}
.uil-dl-icon-Plane:before {
  content: "\e945";
}
.uil-dl-icon-Train:before {
  content: "\e973";
}
.uil-dl-icon-Car:before {
  content: "\e9dc";
}
.uil-dl-icon-Fleet:before {
  content: "\e991";
}
.uil-dl-icon-Car-alt:before {
  content: "\ea02";
}
.uil-dl-icon-Crash:before {
  content: "\e9dd";
}
.uil-dl-icon-Truck:before {
  content: "\e9c3";
}
.uil-dl-icon-Speed:before {
  content: "\e938";
}
.uil-dl-icon-Cart:before {
  content: "\e952";
}
.uil-dl-icon-Basket:before {
  content: "\ea17";
}
.uil-dl-icon-Bag:before {
  content: "\e98b";
}
.uil-dl-icon-Tag:before {
  content: "\e939";
}
.uil-dl-icon-Tag-alt:before {
  content: "\e986";
}
.uil-dl-icon-Signal-alt:before {
  content: "\e946";
}
.uil-dl-icon-Signal:before {
  content: "\e947";
}
.uil-dl-icon-Wifi:before {
  content: "\e988";
}
.uil-dl-icon-Rss:before {
  content: "\e982";
}
.uil-dl-icon-Radio:before {
  content: "\e96f";
}
.uil-dl-icon-Bluetooth:before {
  content: "\e978";
}
.uil-dl-icon-Euro:before {
  content: "\e955";
}
.uil-dl-icon-Dollar:before {
  content: "\e917";
}
.uil-dl-icon-Yen:before {
  content: "\ea00";
}
.uil-dl-icon-Film:before {
  content: "\ea1a";
}
.uil-dl-icon-Camera:before {
  content: "\e9b7";
}
.uil-dl-icon-Camera-rear:before {
  content: "\e9b6";
}
.uil-dl-icon-Aperture:before {
  content: "\e94e";
}
.uil-dl-icon-Bolt:before {
  content: "\e950";
}
.uil-dl-icon-Settings:before {
  content: "\e95c";
}
.uil-dl-icon-Tool:before {
  content: "\e987";
}
.uil-dl-icon-Controls:before {
  content: "\e9cd";
}
.uil-dl-icon-Link:before {
  content: "\e957";
}
.uil-dl-icon-QRcode:before {
  content: "\e95a";
}
.uil-dl-icon-Image:before {
  content: "\e9a5";
}
.uil-dl-icon-Colors:before {
  content: "\e969";
}
.uil-dl-icon-Drop:before {
  content: "\ea05";
}
.uil-dl-icon-Layers:before {
  content: "\e96d";
}
.uil-dl-icon-Window:before {
  content: "\e9ff";
}
.uil-dl-icon-Layout-left:before {
  content: "\ea0c";
}
.uil-dl-icon-Layout-right:before {
  content: "\e9a6";
}
.uil-dl-icon-Align-left:before {
  content: "\ea15";
}
.uil-dl-icon-Align-top:before {
  content: "\ea16";
}
.uil-dl-icon-Align-center-horizontally:before {
  content: "\e976";
}
.uil-dl-icon-Align-center-vertically:before {
  content: "\e913";
}
.uil-dl-icon-Align-right:before {
  content: "\e99d";
}
.uil-dl-icon-Align-bottom:before {
  content: "\e927";
}
.uil-dl-icon-Share-ios:before {
  content: "\e983";
}
.uil-dl-icon-Entrace:before {
  content: "\ea06";
}
.uil-dl-icon-Exit:before {
  content: "\e9e0";
}
.uil-dl-icon-Share-android:before {
  content: "\e9eb";
}
.uil-chip-placeholder-icon:before,
.uil-dl-icon-Flag:before {
  content: "\e97e";
}
.uil-dl-icon-Shield:before {
  content: "\e95d";
}
.uil-dl-icon-Education:before {
  content: "\ea2a";
}
.uil-dl-icon-Diploma:before {
  content: "\ea2b";
}
.uil-dl-icon-Award:before {
  content: "\ea2c";
}
.uil-dl-icon-HDR:before {
  content: "\ea09";
}
.uil-dl-icon-PDF:before {
  content: "\e9d4";
}
.uil-dl-icon-XLSX:before {
  content: "\e9af";
}
.uil-dl-icon-XLS:before {
  content: "\ea27";
}
.uil-dl-icon-CSV:before {
  content: "\e9a0";
}
.uil-dl-icon-TXT:before {
  content: "\e923";
}
.uil-dl-icon-DOCX:before {
  content: "\e916";
}
.uil-dl-icon-DOC:before {
  content: "\e941";
}
.uil-dl-icon-Globe:before {
  content: "\e9f7";
}
.uil-dl-icon-Thermometer:before {
  content: "\e971";
}
.uil-dl-icon-Sun-cloud:before {
  content: "\e9d6";
}
.uil-dl-icon-Cloud:before {
  content: "\e9f2";
}
.uil-dl-icon-Snow:before {
  content: "\e920";
}
.uil-dl-icon-Thunder:before {
  content: "\e94a";
}
.uil-dl-icon-Rain:before {
  content: "\e997";
}
.uil-dl-icon-Umbrella:before {
  content: "\e925";
}
.uil-dl-icon-Sun:before {
  content: "\e9c2";
}
.uil-dl-icon-Sunrise:before {
  content: "\e921";
}
.uil-dl-icon-Moon:before {
  content: "\ea1f";
}
.uil-dl-icon-Sea:before {
  content: "\ea10";
}
.uil-dl-icon-Coding:before {
  content: "\e98f";
}
.uil-dl-icon-Bug:before {
  content: "\e9c8";
}
.uil-dl-icon-Hashtag:before {
  content: "\e9ba";
}
.uil-dl-icon-Diamond:before {
  content: "\e9df";
}
.uil-dl-icon-Rocket:before {
  content: "\ea0f";
}
.uil-dl-icon-Stark:before {
  content: "\e948";
}
.uil-dl-icon-Shield-alt:before {
  content: "\e970";
}
.uil-dl-icon-Disc:before {
  content: "\e97b";
}
.uil-dl-icon-Circle-filled:before {
  content: "\ea2d";
}
.uil-dl-icon-information-point:before {
  content: "\ea2e";
}
.uil-dl-icon-question-point:before {
  content: "\ea2f";
}
.uil-dl-icon-Disc-filled:before {
  content: "\ea30";
}
.uil-dl-icon-Import:before {
  content: "\ea31";
}
.uil-dl-icon-Distance:before {
  content: "\ea34";
}
.uil-dl-icon-Crowd:before {
  content: "\ea35";
}
.uil-dl-icon-Facemask:before {
  content: "\ea32";
}
.uil-dl-icon-Flag-filled:before {
  content: "\ea33";
}
.uil-dl-icon-Bookmark:before {
  content: "\ea01";
}
.uil-dl-icon-Bookmark-filled:before {
  content: "\ea36";
}
.uil-dl-icon-Heart-filled:before {
  content: "\ea37";
}
.uil-dl-icon-Star-filled:before {
  content: "\ea38";
}
.uil-dl-icon-Dynamic-Devices-Default:before {
  content: "\ea39";
}
.uil-dl-icon-Object-Detector:before {
  content: "\ea3a";
}
.uil-dl-icon-Helmet-Detector:before {
  content: "\ea3b";
}
.uil-dl-icon-Bracelet:before {
  content: "\ea3c";
}
.uil-dl-icon-Industrial-Machine:before {
  content: "\ea3d";
}
.uil-dl-icon-Path-Checking-Detector:before {
  content: "\ea3e";
}
.uil-dl-icon-People-Counter:before {
  content: "\ea3f";
}
.uil-dl-icon-Tank:before {
  content: "\ea40";
}
.uil-dl-icon-Occupancy:before {
  content: "\ea41";
}
.uil-dl-icon-Circle-chevron-down:before {
  content: "\ea42";
}
.uil-dl-icon-Circle-chevron-up:before {
  content: "\ea43";
}
.uil-dl-icon-Circle-chevron-left:before {
  content: "\ea44";
}
.uil-dl-icon-Circle-chevron-right:before {
  content: "\ea45";
}
.uil-dl-icon-JPG:before {
  content: "\ea46";
}
.uil-dl-icon-GIF:before {
  content: "\ea47";
}
.uil-dl-icon-MP3:before {
  content: "\ea48";
}
.uil-dl-icon-MP4:before {
  content: "\ea49";
}
.uil-dl-icon-PNG:before {
  content: "\ea4a";
}
.uil-dl-icon-Fog:before {
  content: "\ea4b";
}
.uil-dl-icon-Haze:before {
  content: "\ea4c";
}
.uil-dl-icon-Sandstorm:before {
  content: "\ea4e";
}
.uil-dl-icon-Storm:before {
  content: "\ea50";
}
.uil-dl-icon-External-link:before {
  content: "\ea4d";
}
.uil-dl-icon-Factory:before {
  content: "\ea4f";
}
.uil-dl-icon-Manufacturing:before {
  content: "\ea51";
}
.uil-dl-icon-Manufacture:before {
  content: "\ea52";
}
.uil-dl-icon-Folder-Open:before {
  content: "\ea53";
}
.fade-scale {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fade-scale.in,
.fade-scale.show {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fade-slide {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fade-slide.left {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.fade-slide.right {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.fade-slide.in,
.fade-slide.show {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.uil-advanced-banner,
.uil-background-banner,
.uil-banner,
.uil-card-banner,
.uil-collapsed-card-banner,
.wrapper {
  padding-left: 0;
  padding-right: 0;
}
.uil-focused-scroll-x {
  overflow-x: hidden !important;
}
.uil-focused-scroll-x:focus,
.uil-focused-scroll-x:hover {
  overflow-x: auto !important;
}
.uil-focused-scroll-y {
  overflow-y: hidden !important;
}
.uil-focused-scroll-y:focus,
.uil-focused-scroll-y:hover {
  overflow-y: auto !important;
}
.uil-focused-scroll {
  overflow: hidden !important;
}
.uil-focused-scroll:focus,
.uil-focused-scroll:hover {
  overflow: auto !important;
}
.uil-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
}
.uil-inline-edit-mode .uil-show-on-hover {
  display: none !important;
}
.uil-inline-edit-mode:hover .uil-show-on-hover {
  display: initial !important;
}
.without-arrow a[aria-expanded="false"]::before,
.without-arrow a[aria-expanded="true"]::before,
.without-arrow[aria-expanded="false"]::before,
.without-arrow[aria-expanded="true"]::before {
  display: none !important;
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/graphik/Graphik-Bold-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Bold-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Bold-Web.woff2) format("woff2");
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/graphik/Graphik-Regular-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Regular-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Regular-Web.woff2) format("woff2");
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/graphik/Graphik-Light-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Light-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Light-Web.woff2) format("woff2");
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/graphik/Graphik-Medium-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Medium-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Medium-Web.woff2) format("woff2");
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/graphik/Graphik-Thin-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Thin-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Thin-Web.woff2) format("woff2");
}
@font-face {
  font-family: uilicon;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/fonticons/uil.eot?) format("eot"),
    url(../fonts/fonticons/uil.woff) format("woff"),
    url(../fonts/fonticons/uil.woff2) format("woff2");
}
body,
html {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.1px;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-size: 16px;
  background-color: #fff;
  color: #393a3e;
  line-height: 1.3;
}
@media (max-width: 767px) {
  body,
  html {
    font-size: 14px;
  }
}
p {
  margin: 0 0 1rem;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 1.67;
}
h1,
h2,
h3 {
  margin: 1.25rem 0 0.625rem 0;
}
label {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 12px;
  color: #393a3e;
  margin-bottom: 4px;
}
pre {
  display: block;
  padding: 8px;
  margin: 0 0 10px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
h4,
h5,
h6 {
  margin: 0.625rem 0 0.3rem 0;
}
h1 {
  font-size: 2.125rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 1.2;
}
h2 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
}
h3 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.2;
}
h4 {
  font-size: 0.9375rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 1.4;
}
h5 {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
}
h6 {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
}
hr {
  border: none;
  border-bottom: 1px solid #ddd;
  margin: 4px 0;
}
button,
input {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.1px;
}
button:focus,
input:focus {
  outline: 0;
}
button {
  cursor: pointer;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
a {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: var(--primary-01);
  text-decoration: none;
  cursor: pointer;
}
@media (prefers-reduced-motion: reduce) {
  a {
    -webkit-transition: none;
    transition: none;
  }
}
a:focus,
a:hover {
  color: var(--primary-01);
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
b,
strong {
  font-weight: 500;
}
.uil-clickable-active-table.active {
  background-color: #f8f9fa !important;
  border-bottom: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-clickable-active-table.selected {
  background-color: #f8f9fa !important;
  border-bottom: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-clickable {
  cursor: pointer;
}
.uil-clickable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f8f9fa !important;
}
@media (hover: hover) {
  .uil-clickable:hover
    .uil-clickable:not(.disabled)
    .uil-clickable:not([disabled]) {
    background-color: #f8f9fa !important;
  }
}
@media (hover: none) {
  .uil-clickable {
    background-color: transparent !important;
  }
}
.uil-clickable.active {
  background-color: #f8f9fa !important;
}
.uil-link-colored {
  color: var(--primary-01) !important;
}
.uil-search-result-title {
  font-size: 1.25rem;
  font-weight: 300;
  color: var(--primary-01) !important;
  line-height: 1.5;
  padding-top: 16px;
}
.uil-search-result-subtitle {
  font-size: 0.875rem;
  font-weight: 300;
  color: var(--secondary-01) !important;
  line-height: 1.5;
  padding-bottom: 16px;
}
.uil-search-result-description {
  font-size: 0.875rem;
  font-weight: 300;
  color: #393a3e !important;
  padding-bottom: 16px;
}
.contextual-region {
  position: static;
}
.modal-header {
  display: none;
}
.modal-content {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-body {
  padding: 0;
}
.form-group {
  margin-bottom: 0 !important;
}
.uil-overlay {
  z-index: 1050;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.uil-overlay .uil-dialog-card {
  background-color: transparent;
  color: #f4f4f4;
  border: none;
}
.uil-overlay .uil-btn,
.uil-overlay .uil-btn-bordered,
.uil-overlay .uil-btn-flat,
.uil-overlay .uil-btn-secondary,
.uil-overlay .uil-login-card-btn {
  margin-right: 4px;
}
.uil-fullwidth {
  width: 100%;
}
.alignRight {
  text-align: right;
}
.uil-font-banner-title {
  font-size: 4rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-banner-text {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-header {
  font-size: 2.125rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-header2 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
}
.uil-font-title {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1px !important;
  line-height: 1.2;
}
.uil-font-button {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.75px !important;
  line-height: 1.4;
  text-transform: uppercase;
}
.uil-font-subheader {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-subheader2 {
  font-size: 0.9375rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.15px !important;
  line-height: 1.4;
}
.uil-font-subheader3 {
  font-size: 0.9375rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.15px !important;
  line-height: 1.4;
}
.uil-font-body1 {
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1px !important;
  line-height: 1.4;
}
.uil-font-body2 {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.4;
}
.uil-font-body3 {
  font-size: 0.625rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.4;
}
.uil-font-paragraph {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.4;
  line-height: 1.67;
}
.uil-font-size-header2 {
  font-size: 1.5rem !important;
}
.uil-font-large {
  font-size: 1rem !important;
}
.uil-font-medium {
  font-size: 0.875rem !important;
}
.uil-font-low {
  font-size: 0.75rem !important;
}
.uil-font-light,
.uil-font-light-weight {
  font-weight: 300 !important;
}
.uil-font-medium-weight {
  font-weight: 500 !important;
}
.uil-font-regular,
.uil-font-regular-weight {
  font-weight: 400 !important;
}
.uil-font-bold,
.uil-font-bold-weight {
  font-weight: 600 !important;
}
.uil-font-oblique {
  font-style: oblique !important;
}
.uil-font-italic {
  font-style: italic !important;
}
.uil-font-normal {
  font-style: normal !important;
}
.uil-font-underline {
  text-decoration: underline !important;
  text-underline-position: under;
  -ms-text-underline-position: under;
  -webkit-text-underline-position: under;
}
.uil-font-overline {
  text-decoration: overline !important;
}
.uil-font-linethrough {
  text-decoration: line-through !important;
}
.uil-form-item-label-font {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 12px;
}
.uil-color-primary,
.uil-color-primary-01 {
  color: var(--primary-01) !important;
}
.uil-backgroundcolor-transparent {
  background-color: transparent !important;
}
.uil-backgroundcolor-primary,
.uil-backgroundcolor-primary-01 {
  background-color: var(--primary-01) !important;
}
.uil-color-primary-focused,
.uil-color-secondary-01 {
  color: var(--secondary-01) !important;
}
.uil-backgroundcolor-primary-focused,
.uil-backgroundcolor-secondary-01 {
  background-color: var(--secondary-01) !important;
}
.uil-color-primary-02,
.uil-color-primary-pressed {
  color: var(--primary-02) !important;
}
.uil-backgroundcolor-primary-02,
.uil-backgroundcolor-primary-pressed {
  background-color: var(--primary-02) !important;
}
.uil-color-primary-03 {
  color: var(--primary-03) !important;
}
.uil-backgroundcolor-primary-03 {
  background-color: var(--primary-03) !important;
}
.uil-color-secondary-02 {
  color: var(--secondary-02) !important;
}
.uil-backgroundcolor-secondary-02 {
  background-color: var(--secondary-02) !important;
}
.uil-color-branding1 {
  color: var(--brand-01) !important;
}
.uil-backgroundcolor-branding1 {
  background-color: var(--brand-01) !important;
}
.uil-color-branding2 {
  color: var(--brand-02) !important;
}
.uil-backgroundcolor-branding2 {
  background-color: var(--brand-02) !important;
}
.uil-color-branding3 {
  color: var(--brand-03) !important;
}
.uil-backgroundcolor-branding3 {
  background-color: var(--brand-03) !important;
}
.uil-color-darkgrey {
  color: #767676 !important;
}
.uil-backgroundcolor-darkgrey {
  background-color: #767676 !important;
}
.uil-color-middlegrey {
  color: #c1c1c1 !important;
}
.uil-backgroundcolor-middlegrey {
  background-color: #c1c1c1 !important;
}
.uil-color-lightgrey {
  color: #f4f4f4 !important;
}
.uil-backgroundcolor-lightgrey {
  background-color: #f4f4f4 !important;
}
.uil-backgroundcolor-lightgrey-op {
  background-color: #f8f9fa !important;
}
.uil-color-headerline-grey {
  color: #e8ecee !important;
}
.uil-backgroundcolor-headerline-grey {
  background-color: #e8ecee !important;
}
.uil-color-rowline-grey {
  color: #ddd !important;
}
.uil-backgroundcolor-rowline-grey {
  background-color: #ddd !important;
}
.uil-color-black {
  color: #000 !important;
}
.uil-backgroundcolor-black {
  background-color: #000 !important;
}
.uil-backgroundcolor-overlay-op {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.uil-color-default-text {
  color: #393a3e !important;
}
.uil-color-disabled-text {
  color: #c2c7cb !important;
}
.uil-color-default-label {
  color: #393a3e !important;
}
.uil-color-highlight {
  color: var(--primary-01) !important;
}
.uil-advanced-banner .uil-banner-content,
.uil-advanced-banner .uil-banner-header,
.uil-advanced-banner .uil-banner-strapline,
.uil-advanced-banner .uil-banner-subtitle,
.uil-advanced-banner .uil-banner-title,
.uil-color-white {
  color: #fff !important;
}
.uil-backgroundcolor-white {
  background-color: #fff !important;
}
.uil-color-red {
  color: #ff3246 !important;
}
.uil-backgroundcolor-red {
  background-color: #ff3246 !important;
}
.uil-color-green {
  color: #4ab43e !important;
}
.uil-backgroundcolor-green {
  background-color: #4ab43e !important;
}
.uil-color-yellow {
  color: #f2d80a !important;
}
.uil-backgroundcolor-yellow {
  background-color: #f2d80a !important;
}
.uil-color-orange {
  color: #ff9f00 !important;
}
.uil-backgroundcolor-orange {
  background-color: #ff9f00 !important;
}
.uil-gradient-background {
  background-image: linear-gradient(
    -135deg,
    var(--primary-02) 0,
    var(--primary-01) 100%
  );
}
.uil-horizontal-line {
  border-bottom: 1px solid #ddd !important;
}
.uil-horizontal-header-line {
  border-bottom: 2px solid #e8ecee !important;
}
.uil-search-font {
  font-size: 1.5rem !important;
}
.ngb-dp-navigation-chevron {
  border-color: transparent;
  -webkit-transform: rotate(0) !important;
  transform: rotate(0) !important;
  color: #767676;
  border: none !important;
  background: 0 0;
  padding: 0;
}
.ngb-dp-navigation-chevron.disabled,
.ngb-dp-navigation-chevron[disabled],
fieldset[disabled] .ngb-dp-navigation-chevron {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.ngb-dp-navigation-chevron:hover {
  color: var(--primary-01) !important;
}
.ngb-dp-navigation-chevron:hover.disabled,
.ngb-dp-navigation-chevron:hover[disabled],
fieldset[disabled] .ngb-dp-navigation-chevron:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.ngb-dp-navigation-chevron:before {
  font-family: uilicon, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea6d";
}
.ngb-dp-navigation-chevron:before.right {
  content: "\ea77";
}
.right .ngb-dp-navigation-chevron:before {
  font-family: uilicon, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea77";
}
.ngb-dp-arrow-btn:active,
.ngb-dp-arrow-btn:focus,
.ngb-dp-arrow-btn:focus:active {
  outline: 0 !important;
}
.hidden {
  display: none !important;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.pull-none {
  float: none !important;
}
@font-face {
  font-family: uilicon_dl;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/fonticons/uil-dl.eot?) format("eot"),
    url(../fonts/fonticons/uil-dl.woff) format("woff"),
    url(../fonts/fonticons/uil-dl.woff2) format("woff2");
}
.uil-form-item-label-font {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-dls-font-heading-04 {
  font-size: 4.25rem !important;
  font-weight: 400 !important;
  letter-spacing: -1px !important;
  line-height: 1.2;
  line-height: 5rem;
}
.uil-dls-font-heading-03 {
  font-size: 3rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 4rem;
}
.uil-dls-font-heading-01 {
  font-size: 1.75rem !important;
  font-weight: 300 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 2rem;
}
.uil-dls-font-heading-02 {
  font-size: 2.125rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 2.5rem;
}
.uil-dls-font-title-00,
.uil-textbox.uil-search-font {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
  line-height: 1.5rem;
}
.uil-dls-font-title-01 {
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-dls-font-title-02 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 1.75rem;
}
.uil-dls-font-caption-00 {
  font-size: 0.625rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 0.75rem;
}
.uil-dls-font-caption-01 {
  font-size: 0.625rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 0.75rem;
}
.uil-dls-font-caption-02 {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-dls-font-caption-03 {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-dls-font-body-01 {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-dls-font-body-02 {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-banner-title {
  font-size: 4.25rem !important;
  font-weight: 400 !important;
  letter-spacing: -1px !important;
  line-height: 1.2;
  line-height: 5rem;
}
.uil-font-header {
  font-size: 3rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 4rem;
}
.uil-font-header2 {
  font-size: 2.125rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 2.5rem;
}
.uil-font-button {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-subheader {
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-body1 {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-body2 {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-body3 {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-font-paragraph {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-size-header2 {
  font-size: 2.125rem !important;
}
.uil-font-light,
.uil-font-light-weight {
  font-weight: 300 !important;
}
.uil-font-medium-weight {
  font-weight: 500 !important;
}
.uil-font-regular,
.uil-font-regular-weight {
  font-weight: 400 !important;
}
.uil-font-bold,
.uil-font-bold-weight {
  font-weight: 600 !important;
}
h1 {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 4rem;
}
h2 {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 2.5rem;
}
h3 {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 1.75rem;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
h5 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
h6 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
p {
  margin: 0 0 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-color-branding1 {
  color: var(--brand-01);
}
.uil-backgroundcolor-branding1 {
  background-color: var(--brand-01);
}
.uil-color-branding2 {
  color: var(--brand-02);
}
.uil-backgroundcolor-branding2 {
  background-color: var(--brand-02);
}
.uil-color-branding3 {
  color: var(--brand-03);
}
.uil-backgroundcolor-branding3 {
  background-color: var(--brand-03);
}
.uil-color-branding4 {
  color: var(--brand-04);
}
.uil-backgroundcolor-branding4 {
  background-color: var(--brand-04);
}
.uil-overlay {
  z-index: 1050;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.uil-overlay .uil-btn,
.uil-overlay .uil-btn-bordered,
.uil-overlay .uil-btn-flat,
.uil-overlay .uil-btn-secondary,
.uil-overlay .uil-login-card-btn {
  height: 32px;
  margin-right: 4px;
}
label {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  color: #393a3e;
  margin-bottom: 4px;
}
label.disabled,
label[disabled] {
  color: #c2c7cb;
  cursor: not-allowed;
}
.uil-dl-specific {
  display: block !important;
}
.uil-color-red-01 {
  color: #e6242f !important;
}
.uil-backgroundcolor-red-01 {
  background-color: #e6242f !important;
}
.uil-color-red-02 {
  color: #b41a22 !important;
}
.uil-backgroundcolor-red-02 {
  background-color: #b41a22 !important;
}
.uil-color-red-03 {
  color: #ff7b7b !important;
}
.uil-backgroundcolor-red-03 {
  background-color: #ff7b7b !important;
}
.uil-color-red-04 {
  color: #d94747 !important;
}
.uil-backgroundcolor-red-04 {
  background-color: #d94747 !important;
}
.uil-color-green-01 {
  color: #008731 !important;
}
.uil-backgroundcolor-green-01 {
  background-color: #008731 !important;
}
.uil-color-green-02 {
  color: #3edd7b !important;
}
.uil-backgroundcolor-green-02 {
  background-color: #3edd7b !important;
}
.uil-color-yellow-01 {
  color: #ffbe00 !important;
}
.uil-backgroundcolor-yellow-01 {
  background-color: #ffbe00 !important;
}
.uil-color-yellow-02 {
  color: #ffdf39 !important;
}
.uil-backgroundcolor-yellow-02 {
  background-color: #ffdf39 !important;
}
.uil-color-yellow-03 {
  color: #ff7400 !important;
}
.uil-backgroundcolor-yellow-03 {
  background-color: #ff7400 !important;
}
.uil-color-gray-01 {
  color: #f8f9fa !important;
}
.uil-backgroundcolor-gray-01 {
  background-color: #f8f9fa !important;
}
.uil-color-gray-02 {
  color: #f1f2f4 !important;
}
.uil-backgroundcolor-gray-02 {
  background-color: #f1f2f4 !important;
}
.uil-color-gray-03 {
  color: #e8ecee !important;
}
.uil-backgroundcolor-gray-03 {
  background-color: #e8ecee !important;
}
.uil-color-gray-04 {
  color: #ccd1d7 !important;
}
.uil-backgroundcolor-gray-04 {
  background-color: #ccd1d7 !important;
}
.uil-color-gray-05 {
  color: #c2c7cb !important;
}
.uil-backgroundcolor-gray-05 {
  background-color: #c2c7cb !important;
}
.uil-color-gray-06 {
  color: #abb0b6 !important;
}
.uil-backgroundcolor-gray-06 {
  background-color: #abb0b6 !important;
}
.uil-color-gray-07 {
  color: #8b9298 !important;
}
.uil-backgroundcolor-gray-07 {
  background-color: #8b9298 !important;
}
.uil-color-gray-08 {
  color: #575961 !important;
}
.uil-backgroundcolor-gray-08 {
  background-color: #575961 !important;
}
.uil-color-gray-09 {
  color: #393a3e !important;
}
.uil-backgroundcolor-gray-09 {
  background-color: #393a3e !important;
}
.uil-color-gray-10 {
  color: #222 !important;
}
.uil-backgroundcolor-gray-10 {
  background-color: #222 !important;
}
.uil-content-span-10-col,
.uil-content-span-11-col,
.uil-content-span-12-col,
.uil-content-span-2-col,
.uil-content-span-3-col,
.uil-content-span-4-col,
.uil-content-span-5-col,
.uil-content-span-6-col,
.uil-content-span-7-col,
.uil-content-span-8-col,
.uil-content-span-9-col,
.uil-no-padding {
  padding: 0 !important;
}
.uil-left-padding {
  padding-left: 12px !important;
  padding-right: 0;
}
.uil-right-padding {
  padding-right: 12px !important;
  padding-left: 0;
}
.uil-no-margin {
  margin: 0 !important;
}
.uil-no-margin.uil-card-span-12 {
  width: -o-calc(12 / 12 * 100% - 0.5px);
  width: calc(12 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-11 {
  width: -o-calc(11 / 12 * 100% - 0.5px);
  width: calc(11 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-10 {
  width: -o-calc(10 / 12 * 100% - 0.5px);
  width: calc(10 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-9 {
  width: -o-calc(9 / 12 * 100% - 0.5px);
  width: calc(9 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-8 {
  width: -o-calc(8 / 12 * 100% - 0.5px);
  width: calc(8 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-7 {
  width: -o-calc(7 / 12 * 100% - 0.5px);
  width: calc(7 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-6 {
  width: -o-calc(6 / 12 * 100% - 0.5px);
  width: calc(6 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-5 {
  width: -o-calc(5 / 12 * 100% - 0.5px);
  width: calc(5 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-4 {
  width: -o-calc(4 / 12 * 100% - 0.5px);
  width: calc(4 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-3 {
  width: -o-calc(3 / 12 * 100% - 0.5px);
  width: calc(3 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-2 {
  width: -o-calc(2 / 12 * 100% - 0.5px);
  width: calc(2 / 12 * 100% - 0.5px);
}
.uil-no-margin.uil-card-span-1 {
  width: -o-calc(1 / 12 * 100% - 0.5px);
  width: calc(1 / 12 * 100% - 0.5px);
}
.uil-content-span-10-col,
.uil-content-span-11-col,
.uil-content-span-12-col,
.uil-content-span-2-col,
.uil-content-span-3-col,
.uil-content-span-4-col,
.uil-content-span-5-col,
.uil-content-span-6-col,
.uil-content-span-7-col,
.uil-content-span-8-col,
.uil-content-span-9-col,
.uil-noborder {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}
.uil-no-background {
  background-color: transparent !important;
}
.uil-not-visible {
  opacity: 0;
}
.wrapper {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  max-width: 1920px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.wrapper > .uil-curtain:first-child {
  border: none;
  height: auto;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel {
  z-index: 2;
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  top: 56px !important;
  height: calc(100% - 56px);
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel.uil-sidemenu-closed.active {
  z-index: 2;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel.uil-sidemenu-closed.active
  .uil-curtain-open
  .side-wrapper {
  height: 100%;
  top: 0;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-curtain-open::before {
  display: none;
  content: "sidemenu_collapsed";
}
@media screen and (min-width: 1200px) {
  .wrapper
    > .uil-curtain:first-child
    > .uil-curtain-wrapper
    > .uil-curtain-panel {
    -webkit-transform: translateX(-100%) translateX(248px);
    transform: translateX(-100%) translateX(248px);
  }
  .wrapper
    > .uil-curtain:first-child
    > .uil-curtain-wrapper
    > .uil-curtain-panel
    .uil-curtain-open::before {
    display: none;
    content: "sidemenu_not_collapsed";
  }
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel.active {
  -webkit-transform: translateX(-100%) translateX(248px);
  transform: translateX(-100%) translateX(248px);
  background: #fff;
  -webkit-box-shadow: 0 7px 7px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 7px 7px 0 rgba(34, 34, 34, 0.1);
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel.active
  .uil-horizontal-nav-titlearea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  padding-bottom: 4px;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel.active
  .uil-horizontal-nav-titlearea
  .uil-horizontal-nav-logo {
  height: 48px;
  width: 140px;
  margin-left: 48px;
}
@media screen and (min-width: 1200px) {
  .wrapper
    > .uil-curtain:first-child
    > .uil-curtain-wrapper
    > .uil-curtain-panel.active:not(.uil-sidemenu-closed) {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: 0 0;
  }
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-curtain-open {
  display: inherit;
  height: 100%;
  float: right;
  direction: rtl;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-horizontal-nav-titlearea {
  padding-left: 0;
  padding-bottom: 4px;
}
@media screen and (max-width: calc(1200px - 1px)) {
  .wrapper
    > .uil-curtain:first-child
    > .uil-curtain-wrapper
    > .uil-curtain-panel
    .uil-horizontal-nav-titlearea {
    display: none;
  }
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-horizontal-nav-titlearea
  .uil-horizontal-nav-logo {
  height: 48px;
  width: 140px;
  margin-left: 8px;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel:not(.uil-sidemenu-closed)
  .uil-curtain-toggle {
  background-color: #fff;
}
@media screen and (min-width: 1200px) {
  .wrapper
    > .uil-curtain:first-child
    > .uil-curtain-wrapper
    > .uil-curtain-panel:not(.uil-sidemenu-closed)
    .uil-curtain-toggle {
    display: none;
  }
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-side-nav {
  background: 0 0 !important;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-side-nav
  .nav-tabs,
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-side-nav
  .uil-nav-tabs,
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-panel
  .uil-side-nav
  .uil-navbar-tabs {
  border: none;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-prize {
  position: relative;
}
@media screen and (max-width: calc(1200px - 1px)) {
  .wrapper
    > .uil-curtain:first-child
    > .uil-curtain-wrapper
    > .uil-curtain-prize {
    padding-left: 0;
  }
}
@media screen and (min-width: 1200px) {
  .wrapper
    > .uil-curtain:first-child
    > .uil-curtain-wrapper
    > .uil-curtain-prize
    > .uil-horizontal-nav
    > .uil-horizontal-navarea
    .uil-curtain-toggle {
    display: none;
  }
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-prize
  .uil-horizontal-nav-titlearea {
  padding-left: 0;
}
.wrapper
  > .uil-curtain:first-child
  > .uil-curtain-wrapper
  > .uil-curtain-prize
  .uil-horizontal-nav-titlearea
  .uil-horizontal-nav-logo {
  margin-left: 16px;
}

.content-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin: 0;
  background-color: #fff;
}
.content-wrapper::after {
  display: block;
  clear: both;
  content: "";
}
.content-footer-wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: overlay;
  margin-top: -56px;
  padding-top: 56px;
  scroll-padding-top: 56px;
  z-index: 998;
  height: 100vh;
  padding-left: 248px;
  margin-left: -248px;
  width: calc(100% + 248px);
}
@media screen and (max-width: 767px) {
  .content-footer-wrapper {
    margin-left: calc(-80px + 24px);
    padding-left: 0;
    width: calc(100% + 80px - 24px);
  }
}
.content-footer-wrapper .content-footer-wrapper,
.content-footer-wrapper.uil-static-navbar {
  margin-top: 0;
  padding-top: 0;
}
.uil-horizontal-nav + .content-wrapper .uil-static-navbar {
  min-height: calc(100vh - 56px) !important;
  height: calc(100vh - 56px) !important;
}
.uil-horizontal-nav
  + .content-wrapper
  .uil-horizontal-nav
  + .content-wrapper
  .content-footer-wrapper.uil-content-wrapper-in-content {
  min-height: calc(100vh - (2 * 56px)) !important;
  height: calc(100vh - (2 * 56px)) !important;
}
.content {
  padding: 12px 12px;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 80px;
  padding-left: 80px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.content .uil-horizontal-nav .uil-main-nav-footer {
  position: absolute !important;
}
.content .uil-horizontal-nav .uil-main-nav-footer.top {
  position: sticky !important;
}
.uil-content-containers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}
.uil-content-wrapper-in-content {
  min-height: calc(100vh - 56px) !important;
  height: calc(100vh - 56px) !important;
}
.uil-content-in-content {
  min-height: calc(100vh - (2 * 56px)) !important;
}
.uil-curtain-panel-content .side-wrapper {
  height: 100%;
}
.side-wrapper {
  position: relative;
  z-index: 999;
  height: calc(100vh - 56px);
  padding-top: 0;
  min-width: -webkit-fit-content;
}
.uil-sidemenu-closed {
  -webkit-transform: translateX(-100%) !important;
  transform: translateX(-100%) !important;
}
.uil-sidemenu-closed.active {
  -webkit-transform: translateX(-100%) translateX(248px) !important;
  transform: translateX(-100%) translateX(248px) !important;
}
.uil-sidemenu-opened {
  -webkit-transform: translateX(-100%) translateX(248px);
  transform: translateX(-100%) translateX(248px);
}
.uil-titlearea-shown {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.uil-container-content {
  max-width: calc(1032px - -12px - -12px);
  margin-left: -12px;
  margin-right: -12px;
  width: 100%;
}
.row {
  width: 100%;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xs,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 1px) {
  .col-xs {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xs-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xs-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xs-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xs-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xs-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xs-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xs-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xs-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xs-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xs-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xs-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xs-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xs-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xs-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xs-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xs-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xs-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xs-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xs-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xs-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xs-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xs-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xs-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xs-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xs-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xs-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xs-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xs-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xs-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xs-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xs-11 {
    margin-left: 91.6666666667%;
  }
}
.uil-container-title {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 2.5rem;
  padding-top: 2px;
  padding-bottom: 8px;
}
.uil-container-section-title {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 1.75rem;
  padding-top: calc(0.875rem / 2);
  margin-bottom: 24px;
}
.uil-icon-size-sm {
  font-size: 1rem;
}
.uil-icon-size-md {
  font-size: 1.5rem;
}
.uil-icon-size-lg {
  font-size: 2rem;
}
.uil-icon-notification {
  background-color: #4ab43e;
  border: 0.1em solid #fff;
  border-radius: 2rem;
  height: 0.5em;
  width: 0.5em;
  top: -3px;
  right: 16px;
  position: absolute;
  font-size: 1em;
}
.uil-font-icon {
  position: relative;
}
.uil-text-grid-icon {
  color: #767676;
}
.uil-text-grid-icon:hover {
  background: #f1f2f4 !important;
}
.uil-text-grid-icon .uil-font-icon {
  margin: 0 32px;
  display: block;
  vertical-align: top;
  text-align: center;
}
.uil-text-grid-icon .uil-font-icon img {
  width: 1em;
  height: 1em;
}
.uil-text-grid-icon .uil-text-icon {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 1;
  color: #393a3e;
  padding: 8px;
}
.uil-link-icon,
.uil-plain-icon,
.uil-popup-button,
.uil-popup-button-hover,
.uil-toggle-icon {
  display: inline-block;
  text-align: center;
  margin: 0 2px;
  outline: 0;
  max-width: calc(1em + 48px + 16px);
  max-height: calc(1em + 48px + 8px);
  cursor: pointer;
}
.uil-link-icon,
.uil-popup-button,
.uil-popup-button-hover {
  cursor: pointer;
  color: #767676;
  border: none;
  background: 0 0;
  padding: 0;
}
.disabled.uil-popup-button,
.disabled.uil-popup-button-hover,
.uil-link-icon.disabled,
.uil-link-icon[disabled],
[disabled].uil-popup-button,
[disabled].uil-popup-button-hover,
fieldset[disabled] .uil-link-icon,
fieldset[disabled] .uil-popup-button,
fieldset[disabled] .uil-popup-button-hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-link-icon:focus,
.uil-link-icon:hover,
.uil-popup-button-hover:focus,
.uil-popup-button-hover:hover,
.uil-popup-button:focus,
.uil-popup-button:hover {
  color: var(--primary-01) !important;
}
.uil-link-icon:focus.disabled,
.uil-link-icon:focus[disabled],
.uil-link-icon:hover.disabled,
.uil-link-icon:hover[disabled],
.uil-popup-button-hover:focus.disabled,
.uil-popup-button-hover:focus[disabled],
.uil-popup-button-hover:hover.disabled,
.uil-popup-button-hover:hover[disabled],
.uil-popup-button:focus.disabled,
.uil-popup-button:focus[disabled],
.uil-popup-button:hover.disabled,
.uil-popup-button:hover[disabled],
fieldset[disabled] .uil-link-icon:focus,
fieldset[disabled] .uil-link-icon:hover,
fieldset[disabled] .uil-popup-button-hover:focus,
fieldset[disabled] .uil-popup-button-hover:hover,
fieldset[disabled] .uil-popup-button:focus,
fieldset[disabled] .uil-popup-button:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-link-icon .uil-font-icon,
.uil-popup-button .uil-font-icon,
.uil-popup-button-hover .uil-font-icon {
  display: block;
  vertical-align: top;
  text-align: center;
}
.uil-link-icon .uil-font-icon img,
.uil-link-icon .uil-font-icon span,
.uil-popup-button .uil-font-icon img,
.uil-popup-button .uil-font-icon span,
.uil-popup-button-hover .uil-font-icon img,
.uil-popup-button-hover .uil-font-icon span {
  width: 1em;
  height: 1em;
}
.uil-link-icon-wrapper,
.uil-link-icon-wrapper-animated {
  position: absolute;
  top: 30px;
  right: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-link-icon-wrapper .uil-link-icon,
.uil-link-icon-wrapper .uil-popup-button,
.uil-link-icon-wrapper .uil-popup-button-hover,
.uil-link-icon-wrapper-animated .uil-link-icon,
.uil-link-icon-wrapper-animated .uil-popup-button,
.uil-link-icon-wrapper-animated .uil-popup-button-hover {
  padding-left: 8px;
  font-size: 18px;
}
.uil-link-icon-wrapper-animated {
  top: 30px;
}
.uil-text-grid-icon,
.uil-text-link-icon,
.uil-text-toggle-icon,
.uil-with-text-icon {
  display: inline-block;
  text-align: center;
  outline: 0;
  max-width: calc(1em + 48px + 16px);
  font-size: 32px;
}
.uil-text-link-icon {
  color: #767676;
  border: none;
  background: 0 0;
  padding: 0;
  vertical-align: bottom;
}
.uil-text-link-icon.disabled,
.uil-text-link-icon[disabled],
fieldset[disabled] .uil-text-link-icon {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-text-link-icon:hover {
  background-color: transparent !important;
  color: var(--primary-01) !important;
}
.uil-text-link-icon:hover.disabled,
.uil-text-link-icon:hover[disabled],
fieldset[disabled] .uil-text-link-icon:hover {
  color: #c2c7cb !important;
  background-color: transparent !important;
  cursor: not-allowed;
}
.uil-text-link-icon:focus {
  color: #767676;
}
.uil-text-link-icon .uil-font-icon {
  margin: 8px 32px 0;
  display: block;
  vertical-align: top;
  text-align: center;
}
.uil-text-link-icon .uil-font-icon img {
  width: 1em;
  height: 1em;
}
.uil-text-link-icon .uil-text-icon {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 14px;
  color: #393a3e;
  padding: 8px;
}
.uil-toggle-icon {
  cursor: pointer;
  color: #767676;
  border: none;
  background: 0 0;
  padding: 0;
}
.uil-toggle-icon.disabled,
.uil-toggle-icon[disabled],
fieldset[disabled] .uil-toggle-icon {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-toggle-icon:hover {
  color: var(--primary-01) !important;
}
.uil-toggle-icon:hover.disabled,
.uil-toggle-icon:hover[disabled],
fieldset[disabled] .uil-toggle-icon:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-toggle-icon:focus {
  color: #767676;
}
.uil-toggle-icon.toggle-icon-active {
  color: var(--primary-01) !important;
}
.uil-text-toggle-icon {
  cursor: pointer;
  color: #767676 !important;
  border: none;
  background: 0 0;
  padding: 0;
  vertical-align: bottom;
}
.uil-text-toggle-icon.disabled,
.uil-text-toggle-icon[disabled],
fieldset[disabled] .uil-text-toggle-icon {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-text-toggle-icon:hover {
  background-color: transparent !important;
  color: var(--primary-01) !important;
}
.uil-text-toggle-icon:hover.disabled,
.uil-text-toggle-icon:hover[disabled],
fieldset[disabled] .uil-text-toggle-icon:hover {
  color: #c2c7cb !important;
  background-color: transparent !important;
  cursor: not-allowed;
}
.uil-text-toggle-icon:focus {
  color: #767676;
  background: 0 0;
}
.uil-text-toggle-icon.toggle-icon-active {
  color: var(--primary-01) !important;
  background-color: transparent;
}
.uil-text-toggle-icon .uil-font-icon {
  margin: 8px 32px 0;
  display: block;
  vertical-align: top;
  text-align: center;
}
.uil-text-toggle-icon .uil-font-icon img {
  width: 1em;
  height: 1em;
}
.uil-text-toggle-icon .uil-text-icon {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 14px;
  color: #393a3e;
  padding: 8px;
}
#icon_modal_white {
  background-color: #767676;
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 12px 0;
}
.uil-icon-stacked {
  position: absolute;
}
.card,
.uil-card-span-1,
.uil-card-span-10,
.uil-card-span-11,
.uil-card-span-12,
.uil-card-span-2,
.uil-card-span-3,
.uil-card-span-4,
.uil-card-span-5,
.uil-card-span-6,
.uil-card-span-7,
.uil-card-span-8,
.uil-card-span-9,
.uil-content-span-10-col,
.uil-content-span-11-col,
.uil-content-span-12-col,
.uil-content-span-2-col,
.uil-content-span-3-col,
.uil-content-span-4-col,
.uil-content-span-5-col,
.uil-content-span-6-col,
.uil-content-span-7-col,
.uil-content-span-8-col,
.uil-content-span-9-col,
.uil-dialog-card,
.uil-horizontal-nav-submenu,
.uil-login-card,
.uil-login-card-info,
.uil-side-nav,
.uil-widget-container-card {
  color: #393a3e;
  background-color: #fff;
  border-color: #f1f2f4;
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  padding: 16px 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 1.67;
  overflow: visible;
  min-width: 232px;
}
.uil-card-selectable.active.card,
.uil-card-selectable.active.uil-card-span-1,
.uil-card-selectable.active.uil-card-span-10,
.uil-card-selectable.active.uil-card-span-11,
.uil-card-selectable.active.uil-card-span-12,
.uil-card-selectable.active.uil-card-span-2,
.uil-card-selectable.active.uil-card-span-3,
.uil-card-selectable.active.uil-card-span-4,
.uil-card-selectable.active.uil-card-span-5,
.uil-card-selectable.active.uil-card-span-6,
.uil-card-selectable.active.uil-card-span-7,
.uil-card-selectable.active.uil-card-span-8,
.uil-card-selectable.active.uil-card-span-9,
.uil-card-selectable.active.uil-content-span-10-col,
.uil-card-selectable.active.uil-content-span-11-col,
.uil-card-selectable.active.uil-content-span-12-col,
.uil-card-selectable.active.uil-content-span-2-col,
.uil-card-selectable.active.uil-content-span-3-col,
.uil-card-selectable.active.uil-content-span-4-col,
.uil-card-selectable.active.uil-content-span-5-col,
.uil-card-selectable.active.uil-content-span-6-col,
.uil-card-selectable.active.uil-content-span-7-col,
.uil-card-selectable.active.uil-content-span-8-col,
.uil-card-selectable.active.uil-content-span-9-col,
.uil-card-selectable.active.uil-dialog-card,
.uil-card-selectable.active.uil-horizontal-nav-submenu,
.uil-card-selectable.active.uil-login-card,
.uil-card-selectable.active.uil-login-card-info,
.uil-card-selectable.active.uil-side-nav,
.uil-card-selectable.active.uil-widget-container-card {
  border-color: var(--primary-01);
  background-color: initial !important;
}
.uil-card-selectable.active.card .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-1 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-10 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-11 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-12 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-2 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-3 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-4 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-5 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-6 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-7 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-8 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-9 .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-10-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-11-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-12-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-2-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-3-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-4-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-5-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-6-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-7-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-8-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-9-col
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-dialog-card .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-horizontal-nav-submenu
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-login-card .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-login-card-info
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-side-nav .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-widget-container-card
  .uil-card-icon-selectable::after {
  opacity: 1;
  color: var(--primary-01);
}
.uil-card-selectable.active.card:hover .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-10:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-11:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-12:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-1:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-2:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-3:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-4:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-5:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-6:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-7:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-8:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-card-span-9:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-10-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-11-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-12-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-2-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-3-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-4-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-5-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-6-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-7-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-8-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-content-span-9-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-dialog-card:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-horizontal-nav-submenu:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-login-card-info:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-login-card:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-side-nav:hover .uil-card-icon-selectable::after,
.uil-card-selectable.active.uil-widget-container-card:hover
  .uil-card-icon-selectable::after {
  opacity: 1;
  color: var(--primary-01);
}
.uil-card-selectable.card:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-10:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-11:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-12:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-1:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-2:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-3:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-4:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-5:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-6:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-7:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-8:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-card-span-9:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-10-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-11-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-12-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-2-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-3-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-4-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-5-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-6-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-7-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-8-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-content-span-9-col:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-dialog-card:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-horizontal-nav-submenu:hover
  .uil-card-icon-selectable::after,
.uil-card-selectable.uil-login-card-info:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-login-card:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-side-nav:hover .uil-card-icon-selectable::after,
.uil-card-selectable.uil-widget-container-card:hover
  .uil-card-icon-selectable::after {
  opacity: 1;
  color: #ccd1d7;
}
.card,
.uil-horizontal-nav-submenu,
.uil-side-nav {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .card,
  .uil-horizontal-nav-submenu,
  .uil-side-nav {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .card,
  .uil-horizontal-nav-submenu,
  .uil-side-nav {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .card,
  .uil-horizontal-nav-submenu,
  .uil-side-nav {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.card.uil-card-shadow,
.uil-card-shadow.uil-horizontal-nav-submenu,
.uil-card-shadow.uil-side-nav {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.card .uil-advanced-banner,
.card .uil-background-banner,
.card .uil-banner,
.card .uil-card-banner,
.card .uil-collapsed-card-banner,
.uil-horizontal-nav-submenu .uil-advanced-banner,
.uil-horizontal-nav-submenu .uil-background-banner,
.uil-horizontal-nav-submenu .uil-banner,
.uil-horizontal-nav-submenu .uil-card-banner,
.uil-horizontal-nav-submenu .uil-collapsed-card-banner,
.uil-side-nav .uil-advanced-banner,
.uil-side-nav .uil-background-banner,
.uil-side-nav .uil-banner,
.uil-side-nav .uil-card-banner,
.uil-side-nav .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
[class^="uil-card-span-"] [class^="uil-card-span-"] {
  min-width: 16px;
}
.uil-card-span-12 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 1872px;
}
@media (min-width: 767px) {
  .uil-card-span-12 {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-12 {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-12 {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-12.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-12 .uil-advanced-banner,
.uil-card-span-12 .uil-background-banner,
.uil-card-span-12 .uil-banner,
.uil-card-span-12 .uil-card-banner,
.uil-card-span-12 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-11 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 1695.4128440367px;
}
@media (min-width: 767px) {
  .uil-card-span-11 {
    float: left;
    width: -o-calc(11 / 12 * 100% - 24px - 0.5px);
    width: calc(11 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-11 {
    float: left;
    width: -o-calc(11 / 12 * 100% - 24px - 0.5px);
    width: calc(11 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-11 {
    float: left;
    width: -o-calc(11 / 12 * 100% - 24px - 0.5px);
    width: calc(11 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-11.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-11 .uil-advanced-banner,
.uil-card-span-11 .uil-background-banner,
.uil-card-span-11 .uil-banner,
.uil-card-span-11 .uil-card-banner,
.uil-card-span-11 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-10 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 1540px;
}
@media (min-width: 767px) {
  .uil-card-span-10 {
    float: left;
    width: -o-calc(10 / 12 * 100% - 24px - 0.5px);
    width: calc(10 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-10 {
    float: left;
    width: -o-calc(10 / 12 * 100% - 24px - 0.5px);
    width: calc(10 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-10 {
    float: left;
    width: -o-calc(10 / 12 * 100% - 24px - 0.5px);
    width: calc(10 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-10.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-10 .uil-advanced-banner,
.uil-card-span-10 .uil-background-banner,
.uil-card-span-10 .uil-banner,
.uil-card-span-10 .uil-card-banner,
.uil-card-span-10 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-9 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 1389.4736842105px;
}
@media (min-width: 767px) {
  .uil-card-span-9 {
    float: left;
    width: -o-calc(9 / 12 * 100% - 24px - 0.5px);
    width: calc(9 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-9 {
    float: left;
    width: -o-calc(9 / 12 * 100% - 24px - 0.5px);
    width: calc(9 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-9 {
    float: left;
    width: -o-calc(9 / 12 * 100% - 24px - 0.5px);
    width: calc(9 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-9.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-9 .uil-advanced-banner,
.uil-card-span-9 .uil-background-banner,
.uil-card-span-9 .uil-banner,
.uil-card-span-9 .uil-card-banner,
.uil-card-span-9 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-8 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 1232px;
}
@media (min-width: 767px) {
  .uil-card-span-8 {
    float: left;
    width: -o-calc(8 / 12 * 100% - 24px - 0.5px);
    width: calc(8 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-8 {
    float: left;
    width: -o-calc(8 / 12 * 100% - 24px - 0.5px);
    width: calc(8 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-8 {
    float: left;
    width: -o-calc(8 / 12 * 100% - 24px - 0.5px);
    width: calc(8 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-8.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-8 .uil-advanced-banner,
.uil-card-span-8 .uil-background-banner,
.uil-card-span-8 .uil-banner,
.uil-card-span-8 .uil-card-banner,
.uil-card-span-8 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-7 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 1080.701754386px;
}
@media (min-width: 767px) {
  .uil-card-span-7 {
    float: left;
    width: -o-calc(7 / 12 * 100% - 24px - 0.5px);
    width: calc(7 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-7 {
    float: left;
    width: -o-calc(7 / 12 * 100% - 24px - 0.5px);
    width: calc(7 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-7 {
    float: left;
    width: -o-calc(7 / 12 * 100% - 24px - 0.5px);
    width: calc(7 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-7.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-7 .uil-advanced-banner,
.uil-card-span-7 .uil-background-banner,
.uil-card-span-7 .uil-banner,
.uil-card-span-7 .uil-card-banner,
.uil-card-span-7 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-6 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 924px;
}
@media (min-width: 767px) {
  .uil-card-span-6 {
    float: left;
    width: -o-calc(6 / 12 * 100% - 24px - 0.5px);
    width: calc(6 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-6 {
    float: left;
    width: -o-calc(6 / 12 * 100% - 24px - 0.5px);
    width: calc(6 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-6 {
    float: left;
    width: -o-calc(6 / 12 * 100% - 24px - 0.5px);
    width: calc(6 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-6.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-6 .uil-advanced-banner,
.uil-card-span-6 .uil-background-banner,
.uil-card-span-6 .uil-banner,
.uil-card-span-6 .uil-card-banner,
.uil-card-span-6 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-5 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 760px;
}
@media (min-width: 767px) {
  .uil-card-span-5 {
    float: left;
    width: -o-calc(5 / 12 * 100% - 24px - 0.5px);
    width: calc(5 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-5 {
    float: left;
    width: -o-calc(5 / 12 * 100% - 24px - 0.5px);
    width: calc(5 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-5 {
    float: left;
    width: -o-calc(5 / 12 * 100% - 24px - 0.5px);
    width: calc(5 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-5.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-5 .uil-advanced-banner,
.uil-card-span-5 .uil-background-banner,
.uil-card-span-5 .uil-banner,
.uil-card-span-5 .uil-card-banner,
.uil-card-span-5 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-4 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 608px;
}
@media (min-width: 767px) {
  .uil-card-span-4 {
    float: left;
    width: -o-calc(4 / 12 * 100% - 24px - 0.5px);
    width: calc(4 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-4 {
    float: left;
    width: -o-calc(4 / 12 * 100% - 24px - 0.5px);
    width: calc(4 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-4 {
    float: left;
    width: -o-calc(4 / 12 * 100% - 24px - 0.5px);
    width: calc(4 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-4.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-4 .uil-advanced-banner,
.uil-card-span-4 .uil-background-banner,
.uil-card-span-4 .uil-banner,
.uil-card-span-4 .uil-card-banner,
.uil-card-span-4 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-3 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 450px;
}
@media (min-width: 767px) {
  .uil-card-span-3 {
    float: left;
    width: -o-calc(3 / 12 * 100% - 24px - 0.5px);
    width: calc(3 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-3 {
    float: left;
    width: -o-calc(3 / 12 * 100% - 24px - 0.5px);
    width: calc(3 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-3 {
    float: left;
    width: -o-calc(3 / 12 * 100% - 24px - 0.5px);
    width: calc(3 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-3.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-3 .uil-advanced-banner,
.uil-card-span-3 .uil-background-banner,
.uil-card-span-3 .uil-banner,
.uil-card-span-3 .uil-card-banner,
.uil-card-span-3 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-2 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 292px;
}
@media (min-width: 767px) {
  .uil-card-span-2 {
    float: left;
    width: -o-calc(2 / 12 * 100% - 24px - 0.5px);
    width: calc(2 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-2 {
    float: left;
    width: -o-calc(2 / 12 * 100% - 24px - 0.5px);
    width: calc(2 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-2 {
    float: left;
    width: -o-calc(2 / 12 * 100% - 24px - 0.5px);
    width: calc(2 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-2.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-2 .uil-advanced-banner,
.uil-card-span-2 .uil-background-banner,
.uil-card-span-2 .uil-banner,
.uil-card-span-2 .uil-card-banner,
.uil-card-span-2 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-card-span-1 {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  max-width: 134px;
}
@media (min-width: 767px) {
  .uil-card-span-1 {
    float: left;
    width: -o-calc(1 / 12 * 100% - 24px - 0.5px);
    width: calc(1 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-card-span-1 {
    float: left;
    width: -o-calc(1 / 12 * 100% - 24px - 0.5px);
    width: calc(1 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-card-span-1 {
    float: left;
    width: -o-calc(1 / 12 * 100% - 24px - 0.5px);
    width: calc(1 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-card-span-1.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-card-span-1 .uil-advanced-banner,
.uil-card-span-1 .uil-background-banner,
.uil-card-span-1 .uil-banner,
.uil-card-span-1 .uil-card-banner,
.uil-card-span-1 .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-advanced-banner .uil-banner-title,
.uil-card-title {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.2;
}
.uil-advanced-banner .uil-banner-subtitle,
.uil-card-subtitle {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
  color: #767676;
  display: block;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin-bottom: 24px;
}
.uil-advanced-banner .uil-banner-strapline,
.uil-card-strapline {
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.4;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.uil-card-text {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 1.67;
  line-height: 1.67;
}
.uil-card-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px 0 0;
}
.uil-card-footer-action-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.uil-card-footer-icon-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}
.uil-card-text-limited {
  max-width: 45em;
  text-align: justify;
}
.uil-card-toolbar {
  line-height: 24px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-card-toolbar .svg {
  height: 1rem !important;
  width: 1rem !important;
}
.uil-card-toolbar .uil-toolbar-search {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0;
  height: auto;
}
.uil-card-toolbar .uil-toolbar-search .uil-toolbar-search-icon {
  -ms-flex-item-align: center;
  align-self: center;
}
.uil-card-toolbar > .uil-toolbar-search.active .uil-toolbar-search-field {
  width: 100%;
}
.uil-card-toolbar > .uil-toolbar-search.active .uil-toolbar-search-field input {
  height: 24px;
}
.uil-card-toolbar > .uil-dropdown > .uil-btn-flat {
  padding: 4px;
  background-color: transparent !important;
}
.uil-card-toolbar > .uil-dropdown > .uil-btn-flat:hover {
  background-color: transparent !important;
}
.uil-card-toolbar > .uil-dropdown > .uil-dropdown-menu {
  left: initial;
  right: 0;
}
.uil-card-toolicon {
  color: #838b95;
  float: right;
  margin: 0 4px;
  height: 24px;
  width: 24px;
  text-align: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.uil-card-template .uil-card-title-area-with-icon,
.uil-card-title-area,
.uil-card-title-area-underline {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-banner-subtitle,
.uil-advanced-banner .uil-card-title-area .uil-banner-subtitle,
.uil-advanced-banner .uil-card-title-area-underline .uil-banner-subtitle,
.uil-card-template
  .uil-advanced-banner
  .uil-card-title-area-with-icon
  .uil-banner-subtitle,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template .uil-card-title-area-with-icon .uil-card-subtitle,
.uil-card-title-area .uil-advanced-banner .uil-banner-subtitle,
.uil-card-title-area .uil-card-subtitle,
.uil-card-title-area-underline .uil-advanced-banner .uil-banner-subtitle,
.uil-card-title-area-underline .uil-card-subtitle {
  margin-bottom: 0;
  margin-top: 8px;
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-title-area
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-title-area-underline
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-card-template
  .uil-advanced-banner
  .uil-card-title-area-with-icon
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-expandable-card-collapser
  + .uil-card-subtitle,
.uil-card-title-area
  .uil-advanced-banner
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-card-title-area .uil-expandable-card-collapser + .uil-card-subtitle,
.uil-card-title-area-underline
  .uil-advanced-banner
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-card-title-area-underline
  .uil-expandable-card-collapser
  + .uil-card-subtitle {
  margin-top: 4px;
}
.uil-card-template .uil-card-title-area-with-icon .nowrap,
.uil-card-title-area .nowrap,
.uil-card-title-area-underline .nowrap {
  white-space: nowrap;
  overflow: hidden;
}
.uil-card-title-area-underline {
  border-bottom: 1px solid #ddd;
  padding-bottom: 4px;
}
.uil-card-image-area {
  -ms-flex-item-align: center;
  align-self: center;
  width: 100%;
  margin: -16px -16px 16px;
}
.uil-card-image-area > img {
  width: calc(100% + 32px);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.uil-card-action-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 34px;
  background-color: #c1c1c1;
  margin: -16px -16px 16px;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 15px;
}
.uil-card-action-area-title {
  font-size: 0.75rem;
  font-weight: 400;
  color: #393a3e;
  opacity: 0.54;
}
.uil-card-toolicon-image {
  height: 0.875em;
  width: 0.875em;
  margin-left: 2px;
}
.uil-card-action-area-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 0.75rem;
  font-weight: 400;
  color: #393a3e;
}
.uil-card-action-area-toolbar .svg {
  height: 1em;
  width: 1em;
}
.uil-table-widget {
  overflow: auto;
  max-height: 70%;
  margin-right: -8px;
  padding-right: 8px;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}
.uil-table-widget .uil-table > thead > tr > th a > .svg {
  height: 0.875em;
  width: 0.875em;
}
.uil-table-widget::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
  border-radius: 19px;
}
.uil-table-widget::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  display: none;
  opacity: 0;
}
.uil-table-widget::-webkit-scrollbar-track-piece {
  display: none;
  opacity: 0;
}
.uil-table-widget::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.6;
}
.uil-dialog-card {
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  border-radius: 0;
  overflow: visible;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 376px;
  width: auto;
}
@media screen and (max-width: 376px) {
  .uil-dialog-card {
    min-width: 100%;
  }
}
.uil-dialog-card button.close {
  outline: 0 !important;
  height: 1.5rem;
  width: 1.5rem;
}
.uil-dialog-card button.close > .svg {
  height: 1.5rem;
  width: 1.5rem;
}
.uil-dialog-card a.close {
  opacity: 1;
  height: 1.5rem;
  width: 1.5rem;
}
.uil-card-template .uil-dialog-card .uil-card-title-area-with-icon,
.uil-dialog-card .uil-card-template .uil-card-title-area-with-icon,
.uil-dialog-card .uil-card-title-area,
.uil-dialog-card .uil-card-title-area-underline {
  margin-bottom: 8px;
}
.uil-advanced-banner .uil-dialog-card .uil-banner-title,
.uil-dialog-card .uil-advanced-banner .uil-banner-title,
.uil-dialog-card .uil-card-title {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  color: #393a3e;
}
.uil-dialog-card .uil-card-text {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #393a3e;
  margin-top: 0;
}
.uil-dialog-card .uil-checkbox {
  padding-top: 24px;
}
.uil-dialog-card .uil-checkbox :first-child {
  margin-top: 0;
}
.uil-dialog-card .uil-checkbox label {
  top: 24px;
}
.uil-register-dialog {
  width: 344px;
  min-width: 344px;
  max-width: 344px;
}
.uil-register-dialog + div {
  margin-top: 24px;
}
@media screen and (max-width: 376px) {
  .uil-register-dialog {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
.uil-icon-close_icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
}
.uil-icon-zoomin-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-icon-zoomout-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-dialog-card-full {
  width: 100%;
  height: 100%;
  margin: 0;
}
.uil-dialog-card-curtain {
  width: 50%;
  height: 100%;
  margin: 0;
  margin-left: 50%;
}
.uil-dialog-button-area {
  float: right;
  padding-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.uil-dialog-button-area button {
  margin-left: 8px;
}
.uil-dialog-close-button-area {
  position: absolute;
  right: 1px;
  top: 2px;
  width: 60px;
  height: 60px;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.uil-dialog-close-button-area .close {
  opacity: 1;
  color: #fff;
}
.uil-dialog-confirmation-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-status-card {
  border-radius: 0 !important;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 16px !important;
  padding: 8px !important;
  min-height: calc(24px + 2 * 8px);
}
.uil-status-card div {
  width: 100%;
}
.uil-status-card a.close {
  color: #767676 !important;
  font-size: 24px;
  line-height: 24px !important;
  margin-left: 8px;
  cursor: pointer;
}
.uil-status-card.uil-status-error {
  background-color: #e6242f1a;
  border: 1px solid #e6242f;
}
.uil-status-card.uil-status-warning {
  background-color: #ffbe001a;
  border: 1px solid #ffbe00;
}
.uil-status-card.uil-status-success {
  background-color: #0087311a;
  border: 1px solid #008731;
}
.uil-status-card.uil-status-info {
  background-color: var(--primary-01) 1a;
  border: 1px solid var(--primary-01);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.uil-status-card.uil-status-info a {
  display: none;
}
.uil-status-card .uil-status-card-value {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  color: #393a3e;
}
.uil-status-card .uil-status-card-text {
  color: #393a3e;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-status-card-close-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-modal-dialog-scrollable {
  max-height: calc(100vh - 30px - 30px);
  overflow-y: auto;
  overflow-x: hidden;
}
.uil-login-card {
  width: 344px;
  height: auto;
  background-color: #fff;
  padding: 16px;
  margin: 24px;
  border-radius: 0;
  border: 1px solid #abb0b6;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: auto;
  left: 8%;
  position: relative;
}
@media screen and (max-width: 376px) {
  .uil-login-card {
    width: calc(100% - 1rem);
  }
}
.modal-dialog .uil-login-card {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
.uil-login-card-info {
  width: 392px;
  height: 581px;
  margin: 24px;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: auto;
  left: 8%;
  position: relative;
}
.uil-login-card-title {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 2.5rem;
  font-weight: 400;
  line-height: 40px;
  line-height: 40px;
  color: #393a3e;
}
.uil-login-card-info-title {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 2.5rem;
  font-weight: 400;
  line-height: 40px;
  line-height: 40px;
  margin-bottom: 40px;
  margin-top: 0;
  color: #393a3e;
}
.uil-login-card-info-subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.2;
  line-height: 1.5rem;
  color: #393a3e;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 24px;
}
.uil-login-card-info-content p {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #393a3e;
  text-align: left;
  line-height: 20px;
}
.uil-login-card-link {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
}
.uil-login-card-link a {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-login-card-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.uil-login-card-close a > .svg {
  height: 1.5rem;
  width: 1.5rem;
}
.uil-login-card-btn {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
}
.uil-login-card-footer {
  text-align: center;
  padding-top: 24px;
}
.uil-login-card-footer .uil-footer-logo-image {
  height: 24px;
  width: 64px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.uil-login-card-content-item {
  margin-bottom: 1.5rem;
  line-height: 12px;
  margin-bottom: 0 !important;
  margin-top: 24px;
}
.uil-login-card-content-item label {
  line-height: 12px;
  margin-bottom: 4px;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-login-card-content-item label.uil-form-item-help-text {
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 0.75rem;
}
.uil-login-card-content-item.disabled,
.uil-login-card-content-item[disabled] {
  cursor: not-allowed;
}
.uil-login-card-content-item.disabled label,
.uil-login-card-content-item[disabled] label {
  color: #c2c7cb;
}
.uil-login-card-content-item label {
  margin-bottom: 8px;
}
.uil-login-card-content-item label.uil-form-item-error-text,
.uil-login-card-content-item label.uil-form-item-help-text,
.uil-login-card-content-item label.uil-text-danger {
  margin-bottom: 0 !important;
}
.uil-advanced-banner,
.uil-background-banner,
.uil-banner,
.uil-card-banner,
.uil-collapsed-card-banner {
  margin-right: -12px;
  margin-left: -12px;
  margin-top: -12px;
  height: 100%;
  width: auto;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  vertical-align: middle;
  -ms-flex-align: center;
  background-position: center;
  background-size: cover;
}
.uil-advanced-banner img,
.uil-background-banner img,
.uil-banner img,
.uil-card-banner img,
.uil-collapsed-card-banner img {
  width: 100%;
}
.uil-background-banner {
  position: absolute;
}
.uil-widget-container-card {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
  min-width: 120px;
  max-width: 1872px;
  padding: 0;
  height: calc(100% - 24px);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: #fff;
}
@media (min-width: 767px) {
  .uil-widget-container-card {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-widget-container-card {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-widget-container-card {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-widget-container-card.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-widget-container-card .uil-advanced-banner,
.uil-widget-container-card .uil-background-banner,
.uil-widget-container-card .uil-banner,
.uil-widget-container-card .uil-card-banner,
.uil-widget-container-card .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
.uil-widget-container-card .uil-card-action-area {
  margin: 0;
}
.uil-advanced-banner {
  height: auto;
  position: relative;
  width: initial;
  padding: 24px;
  background-color: var(--primary-01);
}
.uil-advanced-banner .uil-banner-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 16px;
}
.uil-advanced-banner .uil-banner-header .uil-banner-header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.uil-advanced-banner .uil-banner-header .uil-banner-icon-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 25%;
}
.uil-advanced-banner
  .uil-banner-header
  .uil-banner-icon-area
  .uil-banner-header-collapser {
  position: relative;
  font-size: 24px;
  cursor: pointer;
}
.uil-advanced-banner
  .uil-banner-header
  .uil-banner-icon-area
  .uil-banner-header-collapser
  ::before {
  float: right;
  margin-left: 8px;
}
.uil-advanced-banner .uil-banner-subtitle {
  margin-bottom: 0;
}
.uil-advanced-banner .uil-banner-content {
  padding-top: 16px;
}
.uil-collapse-card {
  text-align: justify;
  margin-bottom: 3rem;
}
button.uil-btn-collapse-card {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.uil-show-remaining-content {
  display: none;
}
.uil-card-banner,
.uil-collapsed-card-banner {
  margin: -16px -16px 16px -16px;
  overflow: inherit;
  height: auto;
  width: auto;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.uil-card-banner .uil-card-template .uil-card-title-area-with-icon,
.uil-card-banner .uil-card-title-area,
.uil-card-banner .uil-card-title-area-underline,
.uil-card-template .uil-card-banner .uil-card-title-area-with-icon,
.uil-card-template .uil-collapsed-card-banner .uil-card-title-area-with-icon,
.uil-collapsed-card-banner .uil-card-template .uil-card-title-area-with-icon,
.uil-collapsed-card-banner .uil-card-title-area,
.uil-collapsed-card-banner .uil-card-title-area-underline {
  margin: 16px 16px;
}
.uil-card-banner .uil-multi-line-item-icon,
.uil-collapsed-card-banner .uil-multi-line-item-icon {
  margin: 0 16px;
}
.uil-expandable-card-collapser::before {
  cursor: pointer;
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 1.5rem 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin: 0;
}
.uil-expandable-card-collapser.expanded::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 1.5rem 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin: 0;
}
[data-toggle="collapse"] {
  cursor: pointer;
}
.uil-expandable-card {
  padding-top: 8px;
  cursor: default;
}
.uil-expandable-card.in {
  display: block;
  background-color: transparent !important;
}
.uil-expandable-card-btn {
  float: right;
}
.uil-card-icon,
.uil-card-icon-link,
.uil-card-icon-selectable {
  position: absolute;
  top: 0;
  right: 0;
}
.uil-card-icon-link:before,
.uil-card-icon-selectable:before,
.uil-card-icon:before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin: 0;
}
.uil-card-icon-link:before {
  font-family: uilicon, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ead4";
  background-size: 1rem 1rem;
  height: 1rem;
  width: 1rem;
  color: var(--primary-01);
  font-size: 1.5rem;
}
.uil-card-icon-selectable:after {
  opacity: 0;
  font-family: uilicon, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\eaca";
  background-size: 1rem 1rem;
  height: 1rem;
  width: 1rem;
  color: var(--primary-01);
  font-size: 1.5rem;
}
.uil-expandable-element {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 64px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-right: 8px;
  padding-bottom: 0;
  padding-top: 0;
  max-width: 100%;
  color: #767676;
}
.uil-expandable-element .uil-expandable-element-open-icon {
  margin-bottom: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-expandable-element.active .uil-expandable-element-open-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-expandable-element.active .uil-expandable-element-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  margin-bottom: 0 !important;
}
@media (max-width: 480px) {
  .uil-expandable-element.active .uil-expandable-element-field {
    width: 96px;
  }
}
.uil-expandable-element.active .uil-expandable-element-close-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: flex-end;
  -ms-flex-item-align: end;
  margin-bottom: 0;
}
.uil-expandable-element.active .uil-expandable-element-close-icon .svg {
  height: 20px;
  width: 20px;
}
.uil-expandable-element-field {
  display: none;
  background: 0 0;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px !important;
}
.uil-expandable-element-close-icon {
  display: none;
  background: 0 0;
  cursor: pointer;
}
.uil-example-icons {
  max-height: 500px;
  overflow-y: scroll;
}
.uil-cut-corner-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%, 0 80px);
  -webkit-clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%, 0 80px);
}
.uil-cut-corner-card > div:nth-of-type(1) {
  border-left: 0 solid rgba(0, 0, 0, 0.24);
  border-top: 0 solid rgba(0, 0, 0, 0.24);
  padding-left: 80px;
}
.uil-cut-corner-clip {
  position: absolute;
  top: auto;
  width: 80px;
  height: 80px;
  margin-top: -1px;
  z-index: 100;
  clip-path: polygon(100% 0, 0 0, 0 100%);
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
  background: var(--primary-01);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .uil-cut-corner-clip {
    visibility: hidden;
  }
}
@supports (-ms-ime-align: auto) {
  .uil-cut-corner-clip {
    visibility: hidden;
  }
}
.uil-cut-corner-clip + .uil-cut-corner-card-shadow {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.24));
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.24));
}
.uil-cut-corner-clip + .uil-cut-corner-card-shadow.uil-card-shadow {
  -webkit-filter: drop-shadow(-8px 16px 8px rgba(47, 47, 47, 0.13));
  filter: drop-shadow(-8px 16px 8px rgba(47, 47, 47, 0.13));
}
.uil-cut-corner-clip + .uil-cut-corner-card-shadow > .uil-cut-corner-card,
.uil-cut-corner-clip
  + .uil-cut-corner-card-shadow
  > .uil-small-cut-corner-card {
  -webkit-clip-path: none;
  clip-path: none;
}
.uil-small-cut-corner-card {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 24px;
  padding-top: 24px;
  clip-path: polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 24px);
  -webkit-clip-path: polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 24px);
}
.uil-card-template .uil-small-cut-corner-card .uil-card-title-area-with-icon,
.uil-small-cut-corner-card .uil-card-template .uil-card-title-area-with-icon,
.uil-small-cut-corner-card .uil-card-title-area,
.uil-small-cut-corner-card .uil-card-title-area-underline {
  margin-bottom: 8px;
}
.uil-advanced-banner
  .uil-small-cut-corner-card
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-banner-title,
.uil-advanced-banner
  .uil-small-cut-corner-card
  .uil-card-title-area
  .uil-banner-title,
.uil-advanced-banner
  .uil-small-cut-corner-card
  .uil-card-title-area-underline
  .uil-banner-title,
.uil-card-template
  .uil-advanced-banner
  .uil-small-cut-corner-card
  .uil-card-title-area-with-icon
  .uil-banner-title,
.uil-card-template
  .uil-small-cut-corner-card
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-title,
.uil-card-template
  .uil-small-cut-corner-card
  .uil-card-title-area-with-icon
  .uil-card-title,
.uil-small-cut-corner-card
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-title,
.uil-small-cut-corner-card
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-card-title,
.uil-small-cut-corner-card
  .uil-card-title-area
  .uil-advanced-banner
  .uil-banner-title,
.uil-small-cut-corner-card .uil-card-title-area .uil-card-title,
.uil-small-cut-corner-card
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-title,
.uil-small-cut-corner-card .uil-card-title-area-underline .uil-card-title {
  width: calc(100% - 2.5rem);
}
.uil-small-cut-corner-card.uil-clickable:hover:not(.disabled):not(
    .selected
  ):not([disabled]) {
  background-color: #f8f9fa !important;
}
@media (hover: hover) {
  .uil-small-cut-corner-card.uil-clickable:hover
    .uil-small-cut-corner-card.uil-clickable:not(.disabled)
    .uil-small-cut-corner-card.uil-clickable:not([disabled]) {
    background-color: #f8f9fa !important;
  }
}
@media (hover: none) {
  .uil-small-cut-corner-card.uil-clickable {
    background-color: #fff !important;
  }
}
.uil-small-cut-corner-card.uil-card-selectable.active {
  background-color: #fff !important;
}
.uil-cut-corner-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  border-radius: 0;
  background-size: 100%;
  clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%, 0 80px);
  -webkit-clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%, 0 80px);
  padding: 24px 80px;
}
.uil-cut-corner-banner .uil-cut-corner-card > div:nth-of-type(1),
.uil-cut-corner-banner .uil-small-cut-corner-card > div:nth-of-type(1),
.uil-cut-corner-banner ~ .uil-cut-corner-card > div:nth-of-type(1),
.uil-cut-corner-banner ~ .uil-small-cut-corner-card > div:nth-of-type(1) {
  padding-left: 80px;
}
.uil-cut-corner-banner > div:nth-of-type(1) {
  border-left: 0 solid rgba(0, 0, 0, 0.24);
  border-top: 0 solid rgba(0, 0, 0, 0.24);
}
.uil-cut-corner-image-banner .uil-cut-corner-banner {
  background-size: cover;
  padding: 80px 80px 160px 80px;
}
.uil-cut-corner-image-banner + .uil-cut-corner-clip {
  background: 0 0 !important;
}
.uil-cut-corner-image-banner
  + .uil-cut-corner-clip
  + .uil-cut-corner-card-shadow {
  margin-top: -80px;
}
.uil-cut-corner-image-banner
  + .uil-cut-corner-clip
  + .uil-cut-corner-card-shadow
  .uil-cut-corner-card {
  clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%, 0 80px);
  -webkit-clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%, 0 80px);
}
.uil-cut-corner-card-shadow {
  background-color: transparent;
  -webkit-filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.24));
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.24));
}
.uil-cut-corner-card-shadow.uil-card-shadow {
  -webkit-filter: drop-shadow(0 0 8px rgba(47, 47, 47, 0.24));
  filter: drop-shadow(0 0 8px rgba(47, 47, 47, 0.24));
}
.uil-cut-corner-card-shadow.uil-card-shadow
  .uil-cut-corner-card
  > div:nth-of-type(1),
.uil-cut-corner-card-shadow.uil-card-shadow
  .uil-small-cut-corner-card
  > div:nth-of-type(1) {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-cut-corner-banner-shadow {
  -webkit-filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.24));
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.24));
}
.uil-cut-corner-banner-shadow.uil-card-shadow {
  -webkit-filter: drop-shadow(0 0 15px rgba(57, 58, 62, 0.3));
  filter: drop-shadow(0 0 15px rgba(57, 58, 62, 0.3));
}
.uil-cut-corner-banner-shadow.uil-card-shadow
  .uil-cut-corner-banner
  > div:nth-of-type(1) {
  border: 0;
}
.uil-cut-corner-banner-shadow
  + .uil-cut-corner-clip
  + .uil-cut-corner-card-shadow {
  position: relative;
}
.uil-cut-corner-banner-shadow
  + .uil-cut-corner-clip
  + .uil-cut-corner-card-shadow
  .uil-cut-corner-card
  > div:nth-of-type(1) {
  padding-left: 80px;
}
.uil-combined-shape {
  padding-right: 60px;
  padding-left: 60px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.uil-combined-shape .uil-small-cut-corner-card {
  margin: 20px;
  width: 440px;
}
.uil-card-icon-oval {
  border: 1px solid #c1c1c1;
  color: #c1c1c1;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.3;
  line-height: 1.4;
  border-radius: 1.25rem;
  height: 2.5rem;
  min-width: 2.5rem;
  line-height: 2.5rem;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
}
.uil-card-text-lines-1,
.uil-card-text-lines-2,
.uil-card-text-lines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.uil-card-text-lines-1 {
  -webkit-line-clamp: 1;
}
.uil-card-text-lines-2 {
  -webkit-line-clamp: 2;
}
.uil-card-text-lines-3 {
  -webkit-line-clamp: 3;
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-banner-title,
.uil-advanced-banner .uil-card-title-area .uil-banner-title,
.uil-advanced-banner .uil-card-title-area-underline .uil-banner-title,
.uil-card-template
  .uil-advanced-banner
  .uil-card-title-area-with-icon
  .uil-banner-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-title,
.uil-card-template .uil-card-title-area-with-icon .uil-card-title,
.uil-card-title-area .uil-advanced-banner .uil-banner-title,
.uil-card-title-area .uil-card-title,
.uil-card-title-area-underline .uil-advanced-banner .uil-banner-title,
.uil-card-title-area-underline .uil-card-title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-card-text-lines-1.uil-banner-title,
.uil-advanced-banner
  .uil-card-title-area
  .uil-card-text-lines-1.uil-banner-title,
.uil-advanced-banner
  .uil-card-title-area-underline
  .uil-card-text-lines-1.uil-banner-title,
.uil-card-template
  .uil-advanced-banner
  .uil-card-title-area-with-icon
  .uil-card-text-lines-1.uil-banner-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-card-text-lines-1.uil-banner-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-card-title.uil-card-text-lines-1,
.uil-card-title-area
  .uil-advanced-banner
  .uil-card-text-lines-1.uil-banner-title,
.uil-card-title-area .uil-card-title.uil-card-text-lines-1,
.uil-card-title-area-underline
  .uil-advanced-banner
  .uil-card-text-lines-1.uil-banner-title,
.uil-card-title-area-underline .uil-card-title.uil-card-text-lines-1 {
  height: calc(1.25rem * 1.2 * 1);
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-card-text-lines-2.uil-banner-title,
.uil-advanced-banner
  .uil-card-title-area
  .uil-card-text-lines-2.uil-banner-title,
.uil-advanced-banner
  .uil-card-title-area-underline
  .uil-card-text-lines-2.uil-banner-title,
.uil-card-template
  .uil-advanced-banner
  .uil-card-title-area-with-icon
  .uil-card-text-lines-2.uil-banner-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-card-text-lines-2.uil-banner-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-card-title.uil-card-text-lines-2,
.uil-card-title-area
  .uil-advanced-banner
  .uil-card-text-lines-2.uil-banner-title,
.uil-card-title-area .uil-card-title.uil-card-text-lines-2,
.uil-card-title-area-underline
  .uil-advanced-banner
  .uil-card-text-lines-2.uil-banner-title,
.uil-card-title-area-underline .uil-card-title.uil-card-text-lines-2 {
  height: calc(1.25rem * 1.2 * 2);
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-card-text-lines-3.uil-banner-title,
.uil-advanced-banner
  .uil-card-title-area
  .uil-card-text-lines-3.uil-banner-title,
.uil-advanced-banner
  .uil-card-title-area-underline
  .uil-card-text-lines-3.uil-banner-title,
.uil-card-template
  .uil-advanced-banner
  .uil-card-title-area-with-icon
  .uil-card-text-lines-3.uil-banner-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-card-text-lines-3.uil-banner-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-card-title.uil-card-text-lines-3,
.uil-card-title-area
  .uil-advanced-banner
  .uil-card-text-lines-3.uil-banner-title,
.uil-card-title-area .uil-card-title.uil-card-text-lines-3,
.uil-card-title-area-underline
  .uil-advanced-banner
  .uil-card-text-lines-3.uil-banner-title,
.uil-card-title-area-underline .uil-card-title.uil-card-text-lines-3 {
  height: calc(1.25rem * 1.2 * 3);
}
.uil-card-text.uil-card-text-lines-1 {
  height: calc(0.75rem * 1.67 * 1);
}
.uil-card-text.uil-card-text-lines-1 p {
  height: calc(1.25rem * 1);
}
.uil-card-text.uil-card-text-lines-2 {
  height: calc(0.75rem * 1.67 * 2);
}
.uil-card-text.uil-card-text-lines-2 p {
  height: calc(1.25rem * 2);
}
.uil-card-text.uil-card-text-lines-3 {
  height: calc(0.75rem * 1.67 * 3);
}
.uil-card-text.uil-card-text-lines-3 p {
  height: calc(1.25rem * 3);
}
.uil-black-card {
  background-color: #393a3e;
  color: #fff;
}
.uil-black-card .uil-card-icon-link:before {
  color: var(--primary-03);
}
.uil-black-card .uil-card-footer-icon-area {
  color: var(--primary-03);
}
.uil-black-card .uil-card-footer-icon-area a {
  color: var(--primary-03);
}
.uil-black-card.uil-clickable:hover:not(.disabled):not(.selected):not(
    [disabled]
  ) {
  background-color: #222 !important;
}
@media (hover: hover) {
  .uil-black-card.uil-clickable:hover
    .uil-black-card.uil-clickable:not(.disabled)
    .uil-black-card.uil-clickable:not([disabled]) {
    background-color: #222 !important;
  }
}
@media (hover: none) {
  .uil-black-card.uil-clickable {
    background-color: #393a3e !important;
  }
}
.uil-black-card.uil-clickable.active {
  background-color: #222 !important;
}
.uil-black-card.uil-card-selectable.active .uil-card-icon-selectable::after {
  opacity: 1;
  color: var(--primary-03);
}
.uil-black-card.uil-card-selectable.active:hover
  .uil-card-icon-selectable::after {
  opacity: 1;
  color: var(--primary-03);
}
.uil-card-template {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-card-template .uil-card-title-multi-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-card-template .uil-list-icon-logo {
  font-size: 32px;
  height: 32px;
  width: 32px;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
}
.uil-card-template .uil-card-title-area,
.uil-card-template .uil-card-title-area-underline,
.uil-card-template .uil-card-title-area-with-icon {
  margin-bottom: 8px;
  padding-right: 24px;
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area
  .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-underline
  .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-banner-subtitle,
.uil-card-template
  .uil-card-title-area
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template .uil-card-title-area .uil-card-subtitle,
.uil-card-template
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template .uil-card-title-area-underline .uil-card-subtitle,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template .uil-card-title-area-with-icon .uil-card-subtitle {
  margin-top: 0;
}
.uil-card-template .uil-card-title-area-with-icon {
  max-width: 90%;
  padding-right: 8px;
}
.uil-card-template .uil-card-title-icon-area {
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  color: var(--primary-01);
}
.uil-card-template .uil-card-title-icon-area .uil-card-icon-link,
.uil-card-template .uil-card-title-icon-area .uil-card-icon-oval,
.uil-card-template .uil-card-title-icon-area .uil-card-icon-selectable {
  position: relative;
}
.uil-advanced-banner
  .uil-card-template
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-card-template
  .uil-advanced-banner
  .uil-expandable-card-collapser
  + .uil-banner-subtitle,
.uil-card-template .uil-expandable-card-collapser + .uil-card-subtitle {
  margin-top: 0;
}
.uil-card-template .uil-expandable-card-collapser.expanded::before {
  color: var(--primary-01);
}
.uil-card-template .uil-card-text {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.uil-card-template p {
  margin: 0;
}
.uil-card-template .uil-card-footer {
  margin-top: 16px;
  padding: 0 !important;
}
.uil-card-template .uil-card-footer .uil-card-footer-action-area {
  min-width: 50%;
}
.uil-card-template .uil-card-footer .uil-card-footer-action-area .uil-btn,
.uil-card-template
  .uil-card-footer
  .uil-card-footer-action-area
  .uil-login-card-btn {
  width: 100%;
}
.uil-card-template .uil-card-footer .uil-card-footer-icon-area {
  min-width: 50%;
}
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-banner-strapline,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-banner-title,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-banner-strapline,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-banner-title,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-banner-strapline,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-banner-title,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-advanced-banner
  .uil-banner-strapline,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-advanced-banner
  .uil-banner-title,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-card-strapline,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-card-subtitle,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area
  .uil-card-title,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-strapline,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-title,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-card-strapline,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-card-subtitle,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-underline
  .uil-card-title,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-strapline,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-title,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-card-strapline,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-card-subtitle,
.uil-card-template.uil-black-card
  .uil-card-title-multi-item-wrapper
  .uil-card-title-area-with-icon
  .uil-card-title {
  color: #fff;
}
.uil-card-template.uil-black-card .uil-card-text {
  color: #fff;
}
.uil-card-template.uil-status-default {
  border-left: 4px solid;
  border-left-color: var(--primary-01);
}
.uil-card-template.uil-status-error {
  border-left: 4px solid;
  border-left-color: #ff3246;
}
.uil-card-template.uil-status-warning {
  border-left: 4px solid;
  border-left-color: #f2d80a;
}
.uil-card-template.uil-status-info {
  border-left: 4px solid;
  border-left-color: #c1c1c1;
}
.uil-card-template.disabled {
  cursor: not-allowed;
  color: #c2c7cb;
}
.uil-card-template.disabled .uil-card-footer .uil-card-footer-icon-area {
  color: #c2c7cb;
}
.uil-card-template.disabled .uil-card-footer .uil-card-footer-icon-area a {
  color: #c2c7cb;
}
.uil-card-template.disabled .uil-card-footer .uil-card-footer-action-area {
  color: #c2c7cb;
}
.uil-card-template.disabled
  .uil-card-footer
  .uil-card-footer-action-area.uil-card-link {
  color: #c2c7cb;
}
.uil-card-template.disabled .uil-card-text {
  color: #c2c7cb;
}
.uil-card-template.disabled .uil-card-title-area *,
.uil-card-template.disabled .uil-card-title-area-underline *,
.uil-card-template.disabled .uil-card-title-area-with-icon * {
  color: #c2c7cb !important;
}
.uil-card-template.disabled .uil-card-title-icon-area {
  color: #c2c7cb;
}
.uil-card-template.disabled .uil-expandable-card-collapser::before {
  cursor: inherit;
}
.uil-card-template.disabled .uil-expandable-card-collapser.expanded::before {
  color: #c2c7cb;
}
.uil-card-template .uil-ribbon {
  position: absolute;
  right: 0;
  margin-right: 0;
  top: 0;
  margin-top: 0;
  background: var(--brand-01);
  font-size: 10px;
  padding: 4px;
  line-height: 12px;
}
.uil-card-template.uil-card-bgimage-container {
  overflow: hidden;
  border: none;
}
.uil-card-template.uil-card-bgimage-container.uil-card-shadow {
  -webkit-filter: drop-shadow(0 0 15px rgba(57, 58, 62, 0.3));
  filter: drop-shadow(0 0 15px rgba(57, 58, 62, 0.3));
}
.uil-card-template .uil-card-bgimage {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center 0;
  object-position: center 0;
}
.uil-card-template .uil-card-bgimage-title {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 1.75rem;
  position: absolute;
  left: 0;
  right: initial;
  margin: 0;
  top: 0;
  padding: 20px 16px;
  overflow: hidden;
}
.uil-card-template .uil-card-bgimage-title.bottom {
  top: initial;
  bottom: 0;
}
.uil-card-template .uil-card-bgimage-title.right {
  left: initial;
  right: 0;
}
.uil-card-template .uil-card-bgimage-content {
  position: absolute;
  left: 0;
  margin: 0;
  bottom: 0;
  padding: 16px;
  overflow: hidden;
  width: 100%;
  max-height: 50%;
}
.uil-card-template .uil-card-bgimage-content-text {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.2;
  line-height: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.uil-content-span-2-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-2-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-2-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-2-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-2-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-2-col .uil-advanced-banner,
.uil-content-span-2-col .uil-background-banner,
.uil-content-span-2-col .uil-banner,
.uil-content-span-2-col .uil-card-banner,
.uil-content-span-2-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-2-col {
    width: calc(2 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-2-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-2-col {
    width: calc(2 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-2-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-2-col {
    width: calc(2 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-2-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-2-col {
    width: calc(2 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-2-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-3-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-3-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-3-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-3-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-3-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-3-col .uil-advanced-banner,
.uil-content-span-3-col .uil-background-banner,
.uil-content-span-3-col .uil-banner,
.uil-content-span-3-col .uil-card-banner,
.uil-content-span-3-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-3-col {
    width: calc(3 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-3-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-3-col {
    width: calc(3 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-3-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-3-col {
    width: calc(3 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-3-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-3-col {
    width: calc(3 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-3-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-4-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-4-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-4-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-4-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-4-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-4-col .uil-advanced-banner,
.uil-content-span-4-col .uil-background-banner,
.uil-content-span-4-col .uil-banner,
.uil-content-span-4-col .uil-card-banner,
.uil-content-span-4-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-4-col {
    width: calc(4 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-4-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-4-col {
    width: calc(4 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-4-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-4-col {
    width: calc(4 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-4-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-4-col {
    width: calc(4 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-4-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-5-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-5-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-5-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-5-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-5-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-5-col .uil-advanced-banner,
.uil-content-span-5-col .uil-background-banner,
.uil-content-span-5-col .uil-banner,
.uil-content-span-5-col .uil-card-banner,
.uil-content-span-5-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-5-col {
    width: calc(5 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-5-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-5-col {
    width: calc(5 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-5-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-5-col {
    width: calc(5 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-5-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-5-col {
    width: calc(5 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-5-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-6-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-6-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-6-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-6-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-6-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-6-col .uil-advanced-banner,
.uil-content-span-6-col .uil-background-banner,
.uil-content-span-6-col .uil-banner,
.uil-content-span-6-col .uil-card-banner,
.uil-content-span-6-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-6-col {
    width: calc(6 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-6-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-6-col {
    width: calc(6 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-6-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-6-col {
    width: calc(6 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-6-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-6-col {
    width: calc(6 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-6-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-7-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-7-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-7-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-7-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-7-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-7-col .uil-advanced-banner,
.uil-content-span-7-col .uil-background-banner,
.uil-content-span-7-col .uil-banner,
.uil-content-span-7-col .uil-card-banner,
.uil-content-span-7-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-7-col {
    width: calc(7 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-7-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-7-col {
    width: calc(7 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-7-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-7-col {
    width: calc(7 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-7-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-7-col {
    width: calc(7 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-7-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-8-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-8-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-8-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-8-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-8-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-8-col .uil-advanced-banner,
.uil-content-span-8-col .uil-background-banner,
.uil-content-span-8-col .uil-banner,
.uil-content-span-8-col .uil-card-banner,
.uil-content-span-8-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-8-col {
    width: calc(8 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-8-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-8-col {
    width: calc(8 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-8-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-8-col {
    width: calc(8 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-8-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-8-col {
    width: calc(8 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-8-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-9-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-9-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-9-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-9-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-9-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-9-col .uil-advanced-banner,
.uil-content-span-9-col .uil-background-banner,
.uil-content-span-9-col .uil-banner,
.uil-content-span-9-col .uil-card-banner,
.uil-content-span-9-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-9-col {
    width: calc(9 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-9-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-9-col {
    width: calc(9 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-9-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-9-col {
    width: calc(9 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-9-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-9-col {
    width: calc(9 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-9-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-10-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-10-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-10-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-10-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-10-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-10-col .uil-advanced-banner,
.uil-content-span-10-col .uil-background-banner,
.uil-content-span-10-col .uil-banner,
.uil-content-span-10-col .uil-card-banner,
.uil-content-span-10-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-10-col {
    width: calc(10 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-10-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-10-col {
    width: calc(10 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-10-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-10-col {
    width: calc(10 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-10-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-10-col {
    width: calc(10 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-10-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-11-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-11-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-11-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-11-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-11-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-11-col .uil-advanced-banner,
.uil-content-span-11-col .uil-background-banner,
.uil-content-span-11-col .uil-banner,
.uil-content-span-11-col .uil-card-banner,
.uil-content-span-11-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-11-col {
    width: calc(11 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-11-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-11-col {
    width: calc(11 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-11-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-11-col {
    width: calc(11 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-11-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-11-col {
    width: calc(11 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-11-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-content-span-12-col {
  position: relative;
  min-height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
@media (min-width: 767px) {
  .uil-content-span-12-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1023px) {
  .uil-content-span-12-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
@media (min-width: 1280px) {
  .uil-content-span-12-col {
    float: left;
    width: -o-calc(12 / 12 * 100% - 24px - 0.5px);
    width: calc(12 / 12 * 100% - 24px - 0.5px);
  }
}
.uil-content-span-12-col.uil-card-shadow {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
}
.uil-content-span-12-col .uil-advanced-banner,
.uil-content-span-12-col .uil-background-banner,
.uil-content-span-12-col .uil-banner,
.uil-content-span-12-col .uil-card-banner,
.uil-content-span-12-col .uil-collapsed-card-banner {
  margin: -16px -16px 16px;
}
@media screen and (max-width: 768px) {
  .uil-content-span-12-col {
    width: calc(12 / 3 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-12-col [class*="uil-card-span-"] {
    min-width: calc(1 / 3 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 768px) {
  .uil-content-span-12-col {
    width: calc(12 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-12-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 992px) {
  .uil-content-span-12-col {
    width: calc(12 / 6 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-12-col [class*="uil-card-span-"] {
    min-width: calc(1 / 6 * 100% - (12 * 2 * 1px)) !important;
  }
}
@media screen and (min-width: 1200px) {
  .uil-content-span-12-col {
    width: calc(12 / 12 * 100% - (12 * 2 * 1px));
    max-width: calc(100% - (12 * 2 * 1px));
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
  .uil-content-span-12-col [class*="uil-card-span-"] {
    min-width: calc(1 / 12 * 100% - (12 * 2 * 1px)) !important;
  }
}
.uil-nbr-of-columns:after {
  content: "3";
}
@media screen and (min-width: 768px) {
  .uil-nbr-of-columns:after {
    content: "6";
  }
}
@media screen and (min-width: 992px) {
  .uil-nbr-of-columns:after {
    content: "6";
  }
}
@media screen and (min-width: 1200px) {
  .uil-nbr-of-columns:after {
    content: "12";
  }
}
.uil-grid-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.uil-grid-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}
.uil-zero-min-width {
  min-width: 0;
}
.uil-flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.uil-flex-direction-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.uil-flex-direction-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.uil-flex-wrap-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.uil-flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.uil-align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-align-items-flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.uil-align-items-flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.uil-align-items-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.uil-align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}
.uil-align-content-flex-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
.uil-align-content-flex-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}
.uil-align-content-xs-space-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}
.uil-align-content-space-around {
  -ms-flex-line-pack: space-around;
  align-content: space-around;
}
.uil-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.uil-justify-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.uil-justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-justify-space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.uil-justify-space-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}
.uil-grid-xs-col-0 {
  display: none;
}
.uil-grid-xs-col-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 8.3333333333%;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 16.6666666667%;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 33.3333333333%;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 41.6666666667%;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 58.3333333333%;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 66.6666666667%;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-10 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 83.3333333333%;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-11 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 91.6666666667%;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-grid-xs-col-12 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-0 {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-11 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 576px) {
  .uil-grid-sm-col-12 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-0 {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-11 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-12 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-0 {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-11 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-12 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-0 {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-11 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-12 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
.uil-grid-spacing-1 {
  margin: -4px;
  width: calc(100% + 8px);
}
.uil-grid-spacing-1 > .uil-grid-item {
  padding: 4px;
}
.uil-grid-spacing-2 {
  margin: -8px;
  width: calc(100% + 16px);
}
.uil-grid-spacing-2 > .uil-grid-item {
  padding: 8px;
}
.uil-grid-spacing-3 {
  margin: -12px;
  width: calc(100% + 24px);
}
.uil-grid-spacing-3 > .uil-grid-item {
  padding: 12px;
}
.uil-grid-spacing-4 {
  margin: -16px;
  width: calc(100% + 32px);
}
.uil-grid-spacing-4 > .uil-grid-item {
  padding: 16px;
}
.uil-grid-spacing-5 {
  margin: -20px;
  width: calc(100% + 40px);
}
.uil-grid-spacing-5 > .uil-grid-item {
  padding: 20px;
}
.uil-grid-spacing-6 {
  margin: -24px;
  width: calc(100% + 48px);
}
.uil-grid-spacing-6 > .uil-grid-item {
  padding: 24px;
}
.uil-grid-spacing-7 {
  margin: -28px;
  width: calc(100% + 56px);
}
.uil-grid-spacing-7 > .uil-grid-item {
  padding: 28px;
}
.uil-grid-spacing-8 {
  margin: -32px;
  width: calc(100% + 64px);
}
.uil-grid-spacing-8 > .uil-grid-item {
  padding: 32px;
}
.uil-grid-spacing-9 {
  margin: -36px;
  width: calc(100% + 72px);
}
.uil-grid-spacing-9 > .uil-grid-item {
  padding: 36px;
}
.uil-grid-spacing-10 {
  margin: -40px;
  width: calc(100% + 80px);
}
.uil-grid-spacing-10 > .uil-grid-item {
  padding: 40px;
}
.uil-grid-col-auto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: none;
}
.uil-grid-xs-col-auto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: none;
}
@media screen and (min-width: 576px) {
  .uil-grid-sm--auto {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    max-width: none;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .uil-grid-md-col-auto {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    max-width: none;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 992px) {
  .uil-grid-lg-col-auto {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    max-width: none;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) {
  .uil-grid-xl-col-auto {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    max-width: none;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
.uil-advanced-banner,
.uil-background-banner,
.uil-banner,
.uil-card-banner,
.uil-collapsed-card-banner {
  background: var(--color-bg-light);
  width: 1440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  background-size: cover;
  font-family: Graphik;
  font-style: normal;
}
.uil-banner-large {
  height: 720px;
}
.uil-banner-medium {
  height: 480px;
}
.uil-banner-small {
  height: 320px;
}
.uil-banner-title {
  width: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-heading-xl);
  line-height: 120%;
  cursor: text;
}
.uil-banner-content {
  width: 703px;
  font-size: var(--font-size-title-sm);
  font-weight: var(--font-weight-sm);
  line-height: 176%;
  margin-bottom: 24px;
  margin-top: 24px;
}
.uil-banner-subheading {
  width: inherit;
  height: inherit;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  caret-color: transparent;
  margin-bottom: 24px;
}
.uil-banner-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -ms-flex-item-align: center;
  align-self: center;
  gap: 16px;
}

.uil-banner-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: var(--color-font-dark);
}
.uil-banner-hero {
  color: var(--color-font-light);
}

.uil-banner-btn-border {
  border: 1px solid #fff;
}
.small.uil-advanced-banner,
.small.uil-background-banner,
.small.uil-card-banner,
.small.uil-collapsed-card-banner,
.uil-banner.small {
  padding: 0 0 0 24px;
}
.medium.uil-advanced-banner,
.medium.uil-background-banner,
.medium.uil-card-banner,
.medium.uil-collapsed-card-banner,
.uil-banner.medium {
  padding: 0 0 0 36px;
}
.large.uil-advanced-banner,
.large.uil-background-banner,
.large.uil-card-banner,
.large.uil-collapsed-card-banner,
.uil-banner.large {
  padding: 0 0 0 80px;
}
.uil-banner.xlarge,
.xlarge.uil-advanced-banner,
.xlarge.uil-background-banner,
.xlarge.uil-card-banner,
.xlarge.uil-collapsed-card-banner {
  padding: 0 0 0 96px;
}
.uil-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
}
.uil-steps.uil-steps-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.uil-steps.uil-steps-vertical .uil-step-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.uil-steps.uil-steps-vertical .uil-step-item::after {
  width: 2px;
  height: 100%;
  left: 2.1rem;
  right: auto;
}
.uil-steps.uil-steps-vertical .uil-step-item:last-child::after {
  content: none;
}
.uil-steps.uil-steps-vertical .uil-steps-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.uil-steps.uil-steps-vertical .uil-steps-item .uil-steps-point {
  border-color: var(--brand-02);
  color: var(--brand-02);
  font-weight: 600;
}
.uil-steps.uil-steps-vertical
  .uil-steps-item:last-child
  .uil-steps-point-connector {
  display: none;
}
.uil-steps.uil-steps-vertical .uil-steps-item .uil-steps-point-connector {
  display: inline-block;
  position: absolute;
  margin: 0 auto;
  text-shadow: none;
  background-color: var(--brand-02);
  width: 2px;
  z-index: -1;
  height: 100%;
  left: calc(0.75rem + 8px - 2px / 2);
  position: absolute;
}
.uil-steps.uil-steps-vertical .uil-steps-item.disabled,
.uil-steps.uil-steps-vertical .uil-steps-item[disabled] {
  color: #c2c7cb;
  border-color: #c2c7cb;
  cursor: not-allowed;
}
.uil-steps.uil-steps-vertical .uil-steps-item.disabled .uil-steps-point,
.uil-steps.uil-steps-vertical .uil-steps-item[disabled] .uil-steps-point {
  color: #c2c7cb;
  border-color: #c2c7cb;
}
.uil-steps.uil-steps-vertical
  .uil-steps-item.disabled
  .uil-steps-point-connector,
.uil-steps.uil-steps-vertical
  .uil-steps-item[disabled]
  .uil-steps-point-connector {
  color: #c2c7cb;
  background-color: #c2c7cb;
  border-color: #c2c7cb;
}
.uil-steps.uil-steps-vertical
  .uil-steps-item.disabled
  .uil-steps-item-connector,
.uil-steps.uil-steps-vertical
  .uil-steps-item[disabled]
  .uil-steps-item-connector {
  color: #c2c7cb;
  border-color: #c2c7cb;
}
.uil-steps.uil-steps-vertical.uil-steps-md
  .uil-steps-item
  .uil-steps-point-connector {
  left: calc(2rem / 2 + 8px - 2px / 2);
}
.uil-steps.uil-steps-vertical.uil-steps-lg
  .uil-steps-item
  .uil-steps-point-connector {
  left: calc(3rem / 2 + 8px - 2px / 2);
}
.uil-steps.uil-steps-vertical .uil-steps-item-content {
  margin: calc(1.5rem / 2 + 4px / 2) 8px;
}
.uil-steps.uil-steps-md .uil-steps-item {
  font-size: 1rem;
}
.uil-steps.uil-steps-md .uil-steps-item .uil-steps-point-connector {
  top: calc(2rem / 2 + 8px - 2px / 2);
}
.uil-steps.uil-steps-md .uil-steps-item .uil-steps-point {
  border-radius: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}
.uil-steps.uil-steps-lg .uil-steps-item {
  font-size: 1.5rem;
}
.uil-steps.uil-steps-lg .uil-steps-item .uil-steps-point-connector {
  top: calc(3rem / 2 + 8px - 2px / 2);
}
.uil-steps.uil-steps-lg .uil-steps-item .uil-steps-point {
  border-radius: 3rem;
  min-height: 3rem;
  min-width: 3rem;
}
.uil-step-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  font-size: 0.75rem;
  position: relative;
  z-index: 0;
}
.uil-step-item::after {
  display: inline-block;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: calc(1.5rem / 2 + 8px - 2px);
  height: 4px;
  content: " ";
  text-shadow: none;
  background-color: #f4f4f4;
  width: 100%;
  z-index: -1;
}
.uil-step-item:last-child::after {
  right: auto;
  width: 50%;
}
.uil-step-item:first-child::after {
  left: auto;
  width: 50%;
}
.uil-steps-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  font-size: 0.75rem;
  position: relative;
  z-index: 0;
}
.uil-steps-item .uil-steps-point {
  border: 3px solid var(--brand-02);
  color: var(--brand-02);
  font-weight: 600;
}
.uil-steps-item:last-child .uil-steps-point-connector {
  display: none;
}
.uil-steps-item .uil-steps-point-connector {
  display: inline-block;
  position: absolute;
  margin: 0 auto;
  left: calc(12px + 8px - 3px);
  text-shadow: none;
  background-color: var(--brand-02);
  width: 100%;
  height: 2px;
  z-index: -1;
  top: calc(1.5rem / 2 + 8px - 2px / 2);
}
.uil-steps-item.disabled,
.uil-steps-item[disabled] {
  color: #c2c7cb;
  border-color: #c2c7cb;
  cursor: not-allowed;
}
.uil-steps-item.disabled .uil-steps-point,
.uil-steps-item[disabled] .uil-steps-point {
  color: #c2c7cb;
  border-color: #c2c7cb;
}
.uil-steps-item.disabled .uil-steps-point-connector,
.uil-steps-item[disabled] .uil-steps-point-connector {
  color: #c2c7cb;
  background-color: #c2c7cb;
  border-color: #c2c7cb;
}
.uil-steps-item.disabled .uil-steps-item-connector,
.uil-steps-item[disabled] .uil-steps-item-connector {
  color: #c2c7cb;
  border-color: #c2c7cb;
}
.uil-steps-point-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  z-index: 1;
}
.uil-steps-point {
  border: 2px solid #c1c1c1;
  border-radius: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 8px;
}
.uil-steps-point.active,
.uil-steps-point[data-status="active"] {
  border-color: var(--secondary-01);
  background-color: var(--secondary-01);
}
.uil-steps-point.completed,
.uil-steps-point[data-status="completed"] {
  border-color: #c1c1c1;
}
.uil-step-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 12px 8px;
  text-align: center;
}
.uil-steps-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}
.uil-steps-item-content {
  margin: 0 8px;
}
.uil-steps-label {
  position: absolute;
  margin: 0 8px;
  top: 0;
}
.checkbox + .checkbox,
.checkbox + .uil-checkbox,
.radio + .radio,
.radio + .uil-radio,
.tooltip + .radio,
.uil-checkbox + .checkbox,
.uil-checkbox + .uil-checkbox,
.uil-checkbox:first-child,
.uil-radio + .radio,
.uil-radio + .uil-radio,
.uil-radio:first-child {
  margin-top: -8px;
}
.checkbox + .checkbox label.uil-form-item-help-text,
.checkbox + .uil-checkbox label.uil-form-item-help-text,
.radio + .radio label.uil-form-item-help-text,
.radio + .uil-radio label.uil-form-item-help-text,
.tooltip + .radio label.uil-form-item-help-text,
.uil-checkbox + .checkbox label.uil-form-item-help-text,
.uil-checkbox + .uil-checkbox label.uil-form-item-help-text,
.uil-checkbox:first-child label.uil-form-item-help-text,
.uil-radio + .radio label.uil-form-item-help-text,
.uil-radio + .uil-radio label.uil-form-item-help-text,
.uil-radio:first-child label.uil-form-item-help-text {
  margin-top: -16px;
}
.uil-checkbox:last-child,
.uil-radio:last-child {
  padding-bottom: 8px;
}
.uil-form-group-tight .checkbox + .checkbox,
.uil-form-group-tight .checkbox + .uil-checkbox,
.uil-form-group-tight .radio + .radio,
.uil-form-group-tight .radio + .uil-radio,
.uil-form-group-tight .uil-checkbox + .checkbox,
.uil-form-group-tight .uil-checkbox + .uil-checkbox,
.uil-form-group-tight .uil-checkbox:first-child,
.uil-form-group-tight .uil-radio + .radio,
.uil-form-group-tight .uil-radio + .uil-radio,
.uil-form-group-tight .uil-radio:first-child {
  margin-top: -12px;
}
.uil-form-group-tight .checkbox + .checkbox label.uil-form-item-help-text,
.uil-form-group-tight .checkbox + .uil-checkbox label.uil-form-item-help-text,
.uil-form-group-tight .radio + .radio label.uil-form-item-help-text,
.uil-form-group-tight .radio + .uil-radio label.uil-form-item-help-text,
.uil-form-group-tight .uil-checkbox + .checkbox label.uil-form-item-help-text,
.uil-form-group-tight
  .uil-checkbox
  + .uil-checkbox
  label.uil-form-item-help-text,
.uil-form-group-tight .uil-checkbox:first-child label.uil-form-item-help-text,
.uil-form-group-tight .uil-radio + .radio label.uil-form-item-help-text,
.uil-form-group-tight .uil-radio + .uil-radio label.uil-form-item-help-text,
.uil-form-group-tight .uil-radio:first-child label.uil-form-item-help-text {
  margin-top: -16px;
}
.uil-checkable-textboxes {
  display: block !important;
}
.uil-checkable-textbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.uil-checkable-textbox > .uil-checkbox {
  margin-bottom: 6px;
  margin-top: 4px;
}
.uil-checkable-textbox > .uil-checkbox label {
  top: calc(100% - 15px - 7px);
}
.uil-checkable-textbox.without-label {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.uil-checkable-textbox.without-label > .uil-checkbox {
  margin-bottom: 6px;
  margin-top: 4px;
}
.uil-checkbox label {
  margin-left: -24px;
  padding-left: 14px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  cursor: pointer;
  top: 20px;
  left: 0;
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-light1);
  border-radius: 0;
  opacity: 1;
  margin-top: 0;
}
.uil-modified-checkbox
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):not(
    .uil-form-item-error-text
  ):after {
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
  opacity: 0;
  content: "";
  position: absolute;
  width: 12px;
  height: 8px;
  background: 0 0;
  top: 3px;
  left: 3px;
  border: 1.3px solid var(--color-border-primaryLight);
  border-top: unset !important;
  border-right: unset !important;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  padding-left: 8px;
}
.uil-checkbox
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):hover
  :not(.uil-form-item-error-text)[disabled]:hover {
  border: 1px solid var(--color-border-primaryLight);
}
.uil-checkbox input[type="checkbox"]:checked ~ label:first-of-type,
.uil-checkbox input[type="checkbox"]:indeterminate ~ label:first-of-type {
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-primaryLight);
}
.uil-checkbox input[type="checkbox"]:focus ~ label:first-of-type,
.uil-checkbox
  label:not(.uil-form-item-help-text):not(.uil-text-danger):not(
    .uil-form-item-error-text
  ):not(.uil-form-item-error-text):focus {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  outline: 0;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-checkbox input[type="checkbox"]:checked[disabled] ~ label:first-of-type,
.uil-checkbox
  input[type="checkbox"]:indeterminate[disabled]
  ~ label:first-of-type {
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-light1);
}
.uil-checkbox input[type="checkbox"][disabled] label:after,
.uil-checkbox
  input[type="checkbox"][disabled]
  ~ label:first-of-type
  label:after,
.uil-checkbox.disabled label:after,
.uil-checkbox.disabled.active label:after,
.uil-checkbox.disabled.active ~ label:first-of-type label:after,
.uil-checkbox.disabled ~ label:first-of-type label:after {
  border-color: var(--color-border-light);
}
input[type="checkbox"],
input[type="radio"] {
  margin-left: -22px;
  position: absolute;
  opacity: 0;
}
input[type="checkbox"][disabled],
input[type="radio"][disabled] {
  cursor: not-allowed;
}
.uil-checkbox {
  position: relative;
  color: var(--black);
  line-height: 20px;
  padding-top: 20px;
  margin-bottom: 0;
  margin-left: 24px;
  margin-top: 0;
  padding-left: 8px;
  left: 3px;
}
.uil-checkbox.active {
  color: var(--black);
}
.uil-checkbox label {
  margin-left: -24px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 0;
  background: var(--color-bg-light);
  border: 1px solid var(--color-bg-disabledDark);
  border-radius: 0;
  opacity: 1;
  margin-top: 0;
}
.uil-checkbox label.uil-checkbox-check {
  position: absolute;
  top: 4px;
  left: 2px;
}
.uil-checkbox
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):hover {
  border-color: var(--color-border-primaryLight);
}
.uil-checkbox
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):after {
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
  opacity: 0;
  content: "";
  position: absolute;
  width: 10px;
  height: 5px;
  background: 0 0;
  top: 3px;
  left: 2px;
  border: 1px solid var(--primary-light);
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.uil-checkbox input[type="checkbox"][disabled],
.uil-checkbox input[type="checkbox"][disabled] ~ label:first-of-type,
.uil-checkbox.disabled,
.uil-checkbox.disabled.active,
.uil-checkbox.disabled.active ~ label:first-of-type,
.uil-checkbox.disabled ~ label:first-of-type {
  color: var(--grey-light1);
  cursor: not-allowed;
}
.uil-checkbox input[type="checkbox"][disabled] label,
.uil-checkbox input[type="checkbox"][disabled] ~ label:first-of-type label,
.uil-checkbox.disabled label,
.uil-checkbox.disabled.active label,
.uil-checkbox.disabled.active ~ label:first-of-type label,
.uil-checkbox.disabled ~ label:first-of-type label {
  color: var(--grey-light1);
  cursor: not-allowed;
}
.uil-checkbox input[type="checkbox"][disabled] label:after,
.uil-checkbox
  input[type="checkbox"][disabled]
  ~ label:first-of-type
  label:after,
.uil-checkbox.disabled label:after,
.uil-checkbox.disabled.active label:after,
.uil-checkbox.disabled.active ~ label:first-of-type label:after,
.uil-checkbox.disabled ~ label:first-of-type label:after {
  border-color: var(--color-border-light);
}
.uil-checkbox input[type="checkbox"][disabled] label:hover,
.uil-checkbox
  input[type="checkbox"][disabled]
  ~ label:first-of-type
  label:hover,
.uil-checkbox.disabled label:hover,
.uil-checkbox.disabled.active label:hover,
.uil-checkbox.disabled.active ~ label:first-of-type label:hover,
.uil-checkbox.disabled ~ label:first-of-type label:hover {
  border-color: var(--color-border-light1);
}
.uil-checkbox input[type="checkbox"]:checked[disabled] ~ label:first-of-type,
.uil-checkbox
  input[type="checkbox"]:indeterminate[disabled]
  ~ label:first-of-type {
  background: #ccd1d7;
}
.uil-checkbox input[type="checkbox"]:checked[disabled] ~ label:first-of-type,
input[type="checkbox"]:indeterminate[disabled]
  ~ label:first-of-type
  .uil-checkbox:after {
  background: var(--color-bg-light);
}
.uil-checkbox input[type="checkbox"]:checked + a + .tooltip + label::after,
.uil-checkbox input[type="checkbox"]:checked + a + label::after,
.uil-checkbox input[type="checkbox"]:checked + label::after,
.uil-checkbox input[type="checkbox"]:checked ~ label:first-of-type::after {
  opacity: 1 !important;
}
.uil-checkbox
  input[type="checkbox"]:checked
  + a
  + .tooltip
  + label::after:disabled,
.uil-checkbox input[type="checkbox"]:checked + a + label::after:disabled,
.uil-checkbox input[type="checkbox"]:checked + label::after:disabled,
.uil-checkbox
  input[type="checkbox"]:checked
  ~ label:first-of-type::after:disabled {
  opacity: 0.5 !important;
}
.uil-checkbox input[type="checkbox"]:focus ~ label:first-of-type,
.uil-checkbox
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):focus {
  -webkit-box-shadow: var(--primary-03) 0 0 0 2px;
  box-shadow: var(--primary-03) 0 0 0 2px;
  outline: 0;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-checkbox input[type="checkbox"]:indeterminate ~ label:first-of-type:after {
  opacity: 1 !important;
  border-left: none !important;
  -webkit-transform: rotate(0) !important;
  transform: rotate(0) !important;
}
.uil-checkbox
  input[type="checkbox"]:indeterminate
  ~ label:first-of-type:after:disabled {
  opacity: 0.5 !important;
}
.uil-checkbox-title[disabled],
.uil-form-item-help-text[disabled],
.uil-form-item-title-text[disabled] {
  color: var(--grey-light1);
  cursor: not-allowed;
}
.uil-form-item-title-text {
  top: 4px;
  position: relative;
  color: var(--black);
}
.uil-form-item-help-text {
  font-size: 0.625rem;
  margin-left: 0;
  margin-top: 8px;
  padding-left: 0;
  border: none;
  max-width: 100%;
  position: relative;
  display: block;
  max-height: 100%;
}
.uil-checkbox-main-span {
  display: inline;
}
.uil-checkbox-sub-main {
  padding-right: 8px;
}
.uil-form-item-title-radiotext {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  top: 28px;
  position: relative;
  left: 30px;
  color: var(--color-font-dark);
}
.uil-radio {
  position: relative;
  color: #393a3e;
  line-height: 20px;
  margin-left: 24px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-left: 5px;
}
.uil-radio > label:first-of-type {
  width: 20px;
  height: 20px;
}
.uil-radio > label {
  margin-left: -24px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 0;
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-light1);
  border-radius: 12px;
  opacity: 1;
}
.uil-radio > label.uil-form-item-help-text {
  margin-left: 0;
  margin-top: -16px;
  padding-left: 0;
  margin-bottom: 16px;
  border: none;
  line-height: 12px;
  position: relative;
  display: block;
}
.uil-radio > label.uil-form-item-error-text {
  margin-left: 0;
  margin-top: -16px;
  padding-left: 0;
  margin-bottom: 16px;
  border: none;
  line-height: 12px;
  position: relative;
  display: block;
}
.uil-radio
  > label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):hover {
  border-color: var(--color-border-primaryLight);
}
.uil-radio
  > label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):after {
  opacity: 0;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--color-border-primaryLight);
  -webkit-box-shadow: var(--color-border-primaryLight) 0 0 0 1px;
  box-shadow: var(--color-border-primaryLight) 0 0 0 1px;
  border: 5px solid var(--color-border-light);
  border-radius: 10px;
  -webkit-box-sizing: unset;
  box-sizing: unset;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
}
.uil-radio.uil-input-error-state
  > label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):hover {
  border-color: var(--color-border-primaryLight);
}
.uil-radio.disabled label,
.uil-radio.disabled.active label,
.uil-radio.disabled.active ~ label:first-of-type label,
.uil-radio.disabled ~ label:first-of-type label {
  color: #c2c7cb;
  background-color: var(--color-bg-disabled);
  cursor: not-allowed;
}
.uil-radio.disabled label:hover,
.uil-radio.disabled.active label:hover,
.uil-radio.disabled.active ~ label:first-of-type label:hover,
.uil-radio.disabled ~ label:first-of-type label:hover {
  border-color: #c2c7cb !important;
}
.uil-radio.active {
  color: #393a3e;
}
.uil-radio input[type="radio"]:checked + a + .tooltip + label:after,
.uil-radio input[type="radio"]:checked + a + label:after,
.uil-radio input[type="radio"]:checked + label:after,
.uil-radio input[type="radio"]:checked ~ label:first-of-type:after {
  opacity: 1 !important;
}
.uil-radio input[type="radio"]:checked + a + .tooltip + label:after:disabled,
.uil-radio input[type="radio"]:checked + a + label:after:disabled,
.uil-radio input[type="radio"]:checked + label:after:disabled,
.uil-radio input[type="radio"]:checked ~ label:first-of-type:after:disabled {
  opacity: 0.5 !important;
}
.uil-radio input[type="radio"][disabled]:checked ~ label::after {
  -webkit-box-shadow: 0 0 0 1px #ccc !important;
  box-shadow: 0 0 0 1px #ccc !important;
  background-color: var(--color-bg-disabledDark);
}
.uil-radio .uil-radio input[type="radio"][disabled] ~ label:first-of-type,
.uil-radio .uil-radio.disabled,
.uil-radio .uil-radio.disabled.active,
.uil-radio .uil-radio.disabled.active ~ label:first-of-type,
.uil-radio .uil-radio.disabled ~ label:first-of-type,
.uil-radio input[type="radio"][disabled] {
  color: var(--color-border-light1) !important;
  cursor: not-allowed;
}
.uil-radio .uil-form-item label + .uil-radio label.uil-form-item-help-text {
  margin-top: 15px;
  position: sticky;
}
.uil-radio input[type="radio"]:focus ~ label:first-of-type,
.uil-radio
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text):focus {
  -webkit-box-shadow: var(--color-boxshadow-primaryLight) 0 0 0 1px;
  box-shadow: var(--color-boxshadow-primaryLight) 0 0 0 1px;
  outline: 0;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-radio input[type="radio"]:checked ~ .uil-radio-content {
  display: block;
}
.uil-radio input[type="radio"]:checked ~ .uil-radio-content label {
  padding-left: 0;
}
.uil-radio-content {
  display: none;
}
.uil-default-disabled {
  border: 1px solid var(--color-border-light1);
}
label + .uil-checkbox,
label + .uil-radio {
  padding-top: 8px;
}
label + .uil-checkbox label,
label + .uil-radio label {
  top: 8px;
}
.uil-form-item label + .uil-radio {
  padding-left: 5px;
}
label.uil-form-item-title-radiotext[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.uil-checkbox input[type="checkbox"]:checked + label::after {
  content: "" !important;
  position: absolute !important;
  width: 1.95ex !important;
  height: 0.8ex !important;
  background: rgba(0, 0, 0, 0) !important;
  top: 0.8ex !important;
  left: 0.5ex !important;
  border: 1.3px solid var(--primary-light);
  border-top: none !important;
  border-right: none !important;
  -webkit-transform: rotate(-45deg) !important;
  transform: rotate(-45deg) !important;
}
.uil-modified-checkbox.uil-checkbox
  input[type="checkbox"]:checked[disabled]
  ~ label:first-of-type {
  background: var(--color-bg-disabled);
  border: 1px solid var(--color-border-light1);
}
.uil-modified-checkbox.uil-checkbox
  input[type="checkbox"][disabled]
  ~ label:first-of-type {
  background: var(--color-bg-disabled);
  border: 1px solid var(--color-border-light1);
}
input[type="checkbox"]:indeterminate[disabled]
  ~ label:first-of-type
  .uil-modified-checkbox.uil-checkbox:after {
  background: var(--color-bg-disabled);
  border: 1px solid var(--color-border-light1);
}
.uil-modified-checkbox input[type="checkbox"][disabled]:checked + label::after {
  border: 1.3px solid var(--color-border-light1);
}
.uil-checkbox-main-span.uil-checkbox-span {
  display: block;
}
.cursor-label-footnote {
  cursor: not-allowed;
}
.uil-singledropdown button {
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-singledropdown .uil-dropdown-searchbox {
  display: none;
}
.uil-singledropdown {
  position: relative;
}
.uil-singledropdown.open .caret,
.uil-singledropdown.show .caret {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  top: 1px;
  right: 8px;
  position: relative;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  font-size: var(--font-size-title-lg);
  font-family: uilicon_dl, Graphik, sans-serif;
  speak: none;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-singledropdown.open .uil-dropdown-searchbox,
.uil-singledropdown.show .uil-dropdown-searchbox {
  display: block;
  margin-top: 1px;
  height: 48px;
  padding: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  z-index: 1000;
  background-color: var(--color-bg-light);
}
.uil-singledropdown.open .uil-dropdown-searchbox .uil-search-toolbar,
.uil-singledropdown.show .uil-dropdown-searchbox .uil-search-toolbar {
  padding: 0;
  height: 48px;
  background-color: var(--color-bg-light);
  position: relative;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #e8ecee;
}
.uil-singledropdown.open
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field,
.uil-singledropdown.show
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field {
  width: 100%;
}
.uil-singledropdown.open
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"],
.uil-singledropdown.show
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"] {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-singledropdown.open
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"]:focus,
.uil-singledropdown.show
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-singledropdown.open
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field-close,
.uil-singledropdown.show
  .uil-dropdown-searchbox
  .uil-search-toolbar.active
  .uil-search-toolbar-field-close {
  -ms-flex-item-align: center;
  align-self: center;
}
.uil-singledropdown.open
  .uil-dropdown-searchbox
  .uil-search-toolbar
  .uil-search-toolbar-field,
.uil-singledropdown.show
  .uil-dropdown-searchbox
  .uil-search-toolbar
  .uil-search-toolbar-field {
  margin: 3px 0 0 0;
}
.uil-singledropdown.open
  .uil-dropdown-searchbox
  .uil-search-toolbar
  .uil-search-toolbar-field-close,
.uil-singledropdown.show
  .uil-dropdown-searchbox
  .uil-search-toolbar
  .uil-search-toolbar-field-close {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-singledropdown.open
  .uil-dropdown-searchbox
  .uil-search-toolbar
  .uil-search-toolbar-field-close
  .uil-search-cancel-icon,
.uil-singledropdown.show
  .uil-dropdown-searchbox
  .uil-search-toolbar
  .uil-search-toolbar-field-close
  .uil-search-cancel-icon {
  padding: 0;
}
.uil-singledropdown.open .uil-dropdown-searchbox + .uil-singledropdown-menu,
.uil-singledropdown.show .uil-dropdown-searchbox + .uil-singledropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
  position: absolute;
  width: 100%;
  -webkit-box-shadow: 0 -1px 0 -1px #fff, 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 -1px 0 -1px #fff, 0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-singledropdown.open .uil-search-toolbar,
.uil-singledropdown.show .uil-search-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  height: 48px;
}
.uil-singledropdown.open
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"],
.uil-singledropdown.show
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"] {
  padding-top: 0;
  margin-top: 0;
  -webkit-box-shadow: inset 0 -1px 0 #ddd;
  box-shadow: inset 0 -1px 0 #ddd;
}
.uil-singledropdown.open
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"]:focus,
.uil-singledropdown.show
  .uil-search-toolbar.active
  .uil-search-toolbar-field
  input[type="search"]:focus {
  -webkit-box-shadow: inset 0 -2px 0 var(--primary-01);
  box-shadow: inset 0 -2px 0 var(--primary-01);
}
.uil-singledropdown.open .uil-search-toolbar + .uil-singledropdown-menu,
.uil-singledropdown.show .uil-search-toolbar + .uil-singledropdown-menu {
  top: 80px;
}
.uil-singledropdown.in-modal.open,
.uil-singledropdown.in-modal.show {
  position: absolute;
}
.uil-singledropdown .uil-btn-bordered,
.uil-singledropdown .uil-btn-flat {
  text-transform: none;
  overflow: hidden;
  max-width: 100%;
  padding: 10px 0 10px 16px;
  height: 48px;
  min-height: 32px;
  max-height: 72px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.uil-singledropdown .uil-btn-bordered:focus,
.uil-singledropdown .uil-btn-bordered:hover,
.uil-singledropdown .uil-btn-flat:focus,
.uil-singledropdown .uil-btn-flat:hover {
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-singledropdown .uil-btn-flat.uil-no-underline,
.uil-singledropdown .uil-no-underline.uil-btn-bordered {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-singledropdown .uil-btn-flat.uil-no-underline:focus,
.uil-singledropdown .uil-btn-flat.uil-no-underline:hover,
.uil-singledropdown .uil-no-underline.uil-btn-bordered:focus,
.uil-singledropdown .uil-no-underline.uil-btn-bordered:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #abb0b6;
}
.uil-singledropdown .uil-btn-flat[aria-expanded="true"],
.uil-singledropdown [aria-expanded="true"].uil-btn-bordered {
  border-color: var(--color-border-dark);
}
.uil-singledropdown.disabled .uil-btn-bordered,
.uil-singledropdown.disabled .uil-btn-flat,
.uil-singledropdown.disabled .uil-btn-flat[disabled],
.uil-singledropdown[disabled] .uil-btn-bordered,
.uil-singledropdown[disabled] .uil-btn-flat,
.uil-singledropdown[disabled] .uil-btn-flat[disabled] {
  border-color: var(--color-border-default);
  background: var(--color-bg-light);
  padding: 10px 0 10px 16px;
}
.uil-singledropdown.disabled .uil-btn-bordered:hover,
.uil-singledropdown.disabled .uil-btn-flat:hover,
.uil-singledropdown.disabled .uil-btn-flat[disabled]:hover,
.uil-singledropdown[disabled] .uil-btn-bordered:hover,
.uil-singledropdown[disabled] .uil-btn-flat:hover,
.uil-singledropdown[disabled] .uil-btn-flat[disabled]:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-singledropdown.disabled,
.uil-singledropdown[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-singledropdown.disabled label,
.uil-singledropdown[disabled] label {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-singledropdown .uil-search-toolbar {
  padding: 8px 16px 0;
  height: inherit;
  width: inherit;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
  background-color: var(--color-bg-light);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}
.uil-singledropdown .uil-search-toolbar .uil-search-toolbar-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--color-font-greyDark);
}
.uil-singledropdown .uil-search-toolbar .uil-search-toolbar-field {
  width: 100%;
}
.uil-singledropdown .uil-search-toolbar .uil-search-toolbar-field-close,
.uil-singledropdown .uil-search-toolbar .uil-search-toolbar-icon {
  height: 32px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  font-size: var(--font-size-body-md);
  width: 15px;
}
.uil-singledropdown .uil-search-toolbar + .uil-singledropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.uil-singledropdown-menu-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: inherit;
  cursor: pointer;
  background: var(--color-bg-light);
  z-index: 1000;
  margin: 0;
  -webkit-box-shadow: 0 15px 6px rgba(0, 0, 0, 0.01),
    0 8px 5px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.07),
    0 1px 2px rgba(0, 0, 0, 0.08), 0 0 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 15px 6px rgba(0, 0, 0, 0.01), 0 8px 5px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.08),
    0 0 0 rgba(0, 0, 0, 0.08);
}
.uil-singledropdown-toggle {
  color: var(--color-font-greyDark);
  cursor: pointer;
  height: 40px;
}
.uil-singledropdown-toggle::after {
  display: none;
}
.uil-singledropdown-toggle > span {
  width: calc(100% - 1.5rem);
}
.uil-singledropdown-toggle > .caret {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  top: 1px;
  right: 8px;
  position: relative;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  font-family: uilicon_dl, Graphik, sans-serif;
  speak: none;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-title-lg);
}
.uil-singledropdown-toggle[aria-expanded="false"] > .caret {
  color: var(--color-font-dark);
}
.uil-singledropdown-toggle[aria-expanded="true"] > .caret {
  color: var(--color-font-primaryLight);
}
.uil-singledropdown-toggle.disabled,
.uil-singledropdown-toggle[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-singledropdown-toggle.disabled img,
.uil-singledropdown-toggle[disabled] img {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-singledropdown-toggle.disabled > .caret,
.uil-singledropdown-toggle[disabled] > .caret {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.dropdown-selected {
  font-family: Graphik;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-md);
  line-height: 20px;
  color: var(--color-font-dark);
  letter-spacing: 0.3px;
}
.dropdown-placeholder {
  font-family: Graphik;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-md);
  line-height: 20px;
}
.dropdown-placeholder .disabled,
.dropdown-placeholder [disabled],
.dropdown-selected .disabled,
.dropdown-selected [disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-singledropdown-toggle > .caret:before {
  content: "\e92c";
  color: inherit;
}
.uil-singledropdown-menu
  .uil-multi-select-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-singledropdown-menu
  .uil-multi-select-sub-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-singledropdown-menu
  .uil-single-select-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-singledropdown-menu
  .uil-single-unselect-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-singledropdown-menu
  li.uil-multi-select-item:hover:not(.disabled):not(.selected):not([disabled]),
.uil-singledropdown-menu
  li.uil-multi-select-sub-item:hover:not(.disabled):not(.selected):not(
    [disabled]
  ),
.uil-singledropdown-menu
  li.uil-single-select-item:hover:not(.disabled):not(.selected):not([disabled]),
.uil-singledropdown-menu
  li.uil-single-unselect-item:hover:not(.disabled):not(.selected):not(
    [disabled]
  ) {
  background-color: rgba(204, 204, 204, 0.4);
  color: var(--color-font-primaryLight);
}
.uil-multidropdown-menu
  .uil-multi-select-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-multidropdown-menu
  .uil-multi-select-sub-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-multidropdown-menu
  .uil-single-select-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-multidropdown-menu
  .uil-single-unselect-item.uil-footer-menu-item:hover:not(.disabled):not(
    .selected
  ):not([disabled]),
.uil-multidropdown-menu
  li.uil-multi-select-item:hover:not(.disabled):not(.selected):not([disabled]),
.uil-multidropdown-menu
  li.uil-multi-select-sub-item:hover:not(.disabled):not(.selected):not(
    [disabled]
  ),
.uil-multidropdown-menu
  li.uil-single-select-item:hover:not(.disabled):not(.selected):not([disabled]),
.uil-multidropdown-menu
  li.uil-single-unselect-item:hover:not(.disabled):not(.selected):not(
    [disabled]
  ) {
  background-color: rgba(204, 204, 204, 0.4);
  color: var(--color-font-primaryLight);
}
.uil-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
  font-family: sans-serif;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-sm);
  line-height: 20px;
}
.uil-dropdown-searchbox .uil-search-toolbar,
.uil-dropdown-searchbox .uil-toolbar-search {
  background-color: var(--color-bg-light);
  width: inherit;
  height: 44px;
  padding: 0;
  -webkit-box-align: normal;
  -ms-flex-align: normal;
  align-items: normal;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: inherit;
  height: 44px;
}
.uil-searchbar {
  border: none;
  height: 25px;
  width: inherit;
  font-family: Graphik;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-sm);
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.3px;
  color: var(--color-font-greyDark);
}
.uil-singledropdown .uil-btn-bordered:focus,
.uil-singledropdown .uil-btn-bordered:hover,
.uil-singledropdown .uil-btn-flat:focus,
.uil-singledropdown .uil-btn-flat:hover {
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-icon-info-stroke:before {
  content: "\ea9a";
  padding-left: 5px;
}
.uil-icon-Star-filled:before {
  content: "*";
  padding-left: 5px;
  color: var(--color-font-red);
}

.uil-optionsname {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
  padding-right: 5px;
  color: var(--color-font-dark);
}
.uil-options {
  border: 1px solid var(--color-border-default);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.uil-options[disabled] {
  border: 1px solid var(--color-border-disable);
}
.uil-optionsname[disabled] {
  color: var(--color-font-disabled);
}
.uil-selectedoptions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 8px;
}
.uil-multidropdown {
  position: relative;
}
.uil-multidropdown .uil-btn-bordered,
.uil-multidropdown .uil-btn-flat {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  text-transform: none;
  overflow: hidden;
  max-width: 100%;
  padding: 12px 12px 12px 8px;
  height: 50px;
  min-height: 32px;
  max-height: 72px;
  background: var(--color-bg-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid var(--color-border-default);
}
.uil-multidropdown .uil-btn-flat[aria-expanded="true"],
.uil-multidropdown [aria-expanded="true"].uil-btn-bordered {
  border-color: var(--color-border-dark);
}
.uil-multidropdown button {
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-multidropdown-toggle {
  color: var(--color-font-greyMedium);
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  padding: 12px 11px 12px 16px;
  gap: 8px;
  height: 48px;
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
  cursor: pointer;
  height: 40px;
}
.dropdown-toggle,
.uil-multidropdown-toggle {
  white-space: nowrap;
}
.uil-multidropdown-toggle::after {
  display: none;
}
.dropdown-toggle::after,
.uil-multidropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.uil-multidropdown.disabled .uil-btn-bordered,
.uil-multidropdown.disabled .uil-btn-flat,
.uil-multidropdown.disabled .uil-btn-flat[disabled],
.uil-multidropdown[disabled] .uil-btn-bordered,
.uil-multidropdown[disabled] .uil-btn-flat,
.uil-multidropdown[disabled] .uil-btn-flat[disabled] {
  border-color: var(--color-border-default) !important;
  background: var(--color-bg-light) !important;
  padding: 10px 0 10px 16px !important;
}
.uil-multidropdown-toggle {
  cursor: pointer;
  height: 44px;
  caret-color: transparent;
}
.dropdown-toggle,
.uil-multidropdown-toggle {
  white-space: nowrap;
}
.uil-multidropdown-toggle > .caret {
  font-family: var(--font-family);
  font-size: var(--font-size-title-lg);
  font-weight: var(--font-weight-sm);
  border: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  top: 8px;
  right: 16px;
  position: absolute;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-multidropdown-toggle > span {
  width: calc(100% - 1.5rem);
}
.dropdown-toggle,
.uil-multidropdown-toggle {
  white-space: nowrap;
}
.uil-dl-icon-Chevron-down:before,
.uil-expandable-card-collapser.expanded:before,
.uil-expandable-card-collapser:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="false"]:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"]:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="false"]:before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"]:before,
.uil-megamenu-expandable.uil-megamenu-expandable-current:before,
.uil-megamenu-expandable.uil-megamenu-vertical-expand:before,
.uil-megamenu-titlearea:before,
.uil-multidropdown-toggle > .caret:before,
.uil-multidropdown.open .caret:before,
.uil-multidropdown.show .caret:before,
.uil-side-nav-item a[aria-expanded="false"]:before,
.uil-side-nav-item a[aria-expanded="true"]:before,
.uil-side-nav-item[aria-expanded="false"]:before,
.uil-side-nav-item[aria-expanded="true"]:before {
  content: "\e92c";
}
.uil-multidropdown.open .caret,
.uil-multidropdown.show .caret {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-transition: 0s ease;
  transition: 0s ease;
}
.uil-multidropdown .uil-btn-flat[aria-expanded="true"],
.uil-multidropdown [aria-expanded="true"].uil-btn-bordered {
  border-color: var(--primary-01);
}
.uil-multidropdown-menu,
.uil-multidropdown-menu-list {
  scrollbar-width: thin;
  -ms-overflow-style: none;
}
.uil-multidropdown-menu-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.uil-multidropdown-menu {
  background-color: var(--color-bg-light);
  padding-top: 8px;
  padding-bottom: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-shadow: 0 15px 6px rgba(0, 0, 0, 0.01),
    0 8px 5px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.07),
    0 1px 2px rgba(0, 0, 0, 0.08), 0 0 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 15px 6px rgba(0, 0, 0, 0.01), 0 8px 5px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.08),
    0 0 0 rgba(0, 0, 0, 0.08);
}
.dropdown-menu,
.uil-app-menu,
.uil-megamenu-dropdown,
.uil-megamenu-horizontal-expansion-area,
.uil-multidropdown-menu,
.uil-toolbar-dropdown,
.uil-toolbar-guide-menu,
.uil-toolbar-option-menu,
.uil-toolbar-search-menu,
.uil-toolbar-user-menu {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: inherit;
  margin: 0.125rem 0 0;
  text-align: left;
  list-style: none;
  background-color: var(--color-bg-light);
  background-clip: padding-box;
}
.uil-checkboxspan[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.uil-multidropdown-title-item[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.uil-multidropdown-title-item {
  padding-left: 16px;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-multidropdown-toggle[disabled] {
  cursor: not-allowed !important;
  border-color: var(--color-border-default) !important;
  color: var(--color-font-disabled) !important;
  background-color: var(--color-bg-disabled) !important;
}
[disabled].uil-dl-icon-close:before {
  cursor: not-allowed !important;
  color: var(--color-font-disabled);
}
.uil-multidropdown-menu .uil-footer-menu-item,
.uil-multidropdown-menu li {
  line-height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-height: 36px;
  white-space: nowrap !important;
}
.uil-multidropdown-menu.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 0;
  margin-top: 0;
}
.uil-multidropdown-menu.open .uil-dropdown-searchbox {
  display: block;
  margin-top: 1px;
  height: 44px;
  padding: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  z-index: 1000;
  background-color: var(--color-bg-light);
  width: 100%;
  min-width: 10rem;
}
.uil-multidropdown-menu.open .uil-dropdown-searchbox .uil-toolbar-search {
  padding: 0;
  height: 44px;
  background-color: var(--color-bg-light);
  position: relative;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-multidropdown-menu.open
  .uil-dropdown-searchbox
  .uil-toolbar-search.active
  .uil-toolbar-search-field {
  width: 100%;
}
.uil-multidropdown-menu.open
  .uil-dropdown-searchbox
  .uil-toolbar-search.active
  .uil-toolbar-search-field
  input[type="search"] {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-multidropdown-menu.open
  .uil-dropdown-searchbox
  .uil-toolbar-search.active
  .uil-toolbar-search-field
  input[type="search"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 480px) {
  .uil-multidropdown-menu.open
    .uil-dropdown-searchbox
    .uil-toolbar-search.active
    .uil-toolbar-search-field {
    width: 100%;
  }
}
.uil-multidropdown-menu.open
  .uil-dropdown-searchbox
  .uil-toolbar-search.active
  .uil-toolbar-search-field-close {
  -ms-flex-item-align: center;
  align-self: center;
}
.uil-multidropdown-menu.open
  .uil-dropdown-searchbox
  .uil-toolbar-search
  .uil-toolbar-search-field {
  margin: 3px 0 0 0;
}
.uil-multidropdown-menu.open
  .uil-dropdown-searchbox
  .uil-toolbar-search
  .uil-toolbar-search-field-close {
  margin: 0;
  padding-left: 8px;
}
.uil-multidropdown-menu.open
  .uil-dropdown-searchbox
  .uil-toolbar-search
  .uil-toolbar-search-field-close
  .uil-search-cancel-icon {
  padding: 0;
}
.uil-multidropdown-menu.open .uil-dropdown-searchbox + .uil-multidropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  margin-top: 0;
  top: 90px !important;
  -webkit-box-shadow: 0 -1px 0 -1px var(--color-bg-light),
    0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 -1px 0 -1px var(--color-bg-light),
    0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-multidropdown-menu.open .uil-toolbar-search-icon {
  margin: auto 8px;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 1;
  color: var(--color-font-dark);
}
.uil-checkboxlabel label {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: absolute !important;
  width: 20px !important;
  height: 20px !important;
  top: 8px !important;
  border: 1px solid inherit !important;
  margin-top: 8px !important;
}
.uil-checkboxspan {
  position: relative !important;
  position: relative !important;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.3px;
  bottom: 2px;
  cursor: pointer;
  caret-color: transparent;
}
.uil-checkboxdropdownspan {
  top: 8px !important;
  position: relative !important;
  position: relative !important;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.3px;
}
.uil-multidropdown .uil-btn-bordered:focus,
.uil-multidropdown .uil-btn-bordered:hover,
.uil-multidropdown .uil-btn-flat:focus,
.uil-multidropdown .uil-btn-flat:hover {
  -webkit-transition: none !important;
  transition: none !important;
  -webkit-box-shadow: none;
  box-shadow: none !important;
}
.uil-dropdown-searchbox[disabled],
.uil-single-select-item .disabled {
  color: var(--color-font-disabled) !important;
  cursor: not-allowed !important;
}
.uil-single-select-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
}
.uil-multidropdown.open .uil-dropdown-searchbox .uil-toolbar-search,
.uil-multidropdown.show .uil-dropdown-searchbox .uil-toolbar-search {
  padding: 0;
  height: 44px;
  background-color: var(--color-bg-light);
  position: relative;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px !important;
  border: 1px solid var(--color-border-default);
}
.uil-single-select-item:hover .selectall {
  border: 1px solid var(--brand-01);
}
.uil-single-select-item[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed !important;
  pointer-events: none;
}
.uil-checkboxlabel[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
  pointer-events: none !important;
  width: 30px !important;
}
.uil-dropdown-search {
  height: 44px;
}
.uil-link-icon,
.uil-popup-button,
.uil-popup-button-hover {
  border: none;
  width: 30px !important;
  padding: 0;
  background: 0 0 !important;
}
.uil-link-icon[disabled],
[disabled].uil-popup-button,
[disabled].uil-popup-button-hover {
  border: none;
  width: 30px !important;
  padding: 0;
  background: 0 0 !important;
}
.uil-dropdownmenu {
  width: 100%;
  background-color: var(--color-bg-light);
  position: absolute;
  z-index: 1;
}
.uil-dropdownmenu [disabled] {
  cursor: not-allowed;
}
.uil-dropdown-searchbox .uil-toolbar-search {
  background-color: var(--color-bg-light);
  width: 100%;
  height: 44px;
  padding: 0;
  -webkit-box-align: normal;
  -ms-flex-align: normal;
  align-items: normal;
  border-radius: 0;
}
.uil-dropdown-searchbox .uil-toolbar-search .uil-toolbar-search-icon {
  margin: auto 8px;
  font-size: 1rem;
  line-height: 1;
  cursor: initial;
}
.uil-dropdown-searchbox .uil-toolbar-search .uil-toolbar-search-field-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 8px;
  visibility: hidden;
}
.uil-dropdown-searchbox
  .uil-toolbar-search.haschars
  .uil-toolbar-search-field-close {
  visibility: visible;
}
.uil-dropdown-searchbox .uil-toolbar-search .uil-toolbar-search-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  margin-bottom: 0 !important;
  width: 100%;
}
.uil-dropdown-searchbox
  .uil-toolbar-search
  .uil-toolbar-search-field[disabled] {
  cursor: not-allowed;
}
@media (max-width: 480px) {
  .uil-dropdown-searchbox .uil-toolbar-search .uil-toolbar-search-field {
    width: 100%;
  }
}
.uil-dropdown-searchbox
  .uil-toolbar-search
  .uil-toolbar-search-field
  input[type="search"] {
  line-height: 1.4;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  margin-top: 0;
  background: 0 0;
  min-height: 30px;
  min-width: 5rem;
  width: 100%;
}
.uil-dropdown-searchbox
  .uil-toolbar-search
  .uil-toolbar-search-field
  input[type="search"][disabled] {
  cursor: not-allowed;
}
.uil-icon-search[disabled] {
  cursor: not-allowed;
}
.uil-dropdown-selectall {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 15px;
  gap: 8px;
}
.uil-dropdown-searchbox .uil-toolbar-search.active .uil-toolbar-search-field {
  width: 100%;
}
.uil-dropdown-searchbox[disabled] {
  cursor: not-allowed;
}
@media (max-width: 480px) {
  .uil-dropdown-searchbox .uil-toolbar-search.active .uil-toolbar-search-field {
    width: 100%;
  }
}
.uil-custom-taglist {
  border: unset !important;
  padding: unset !important;
}
.uil-multidropdown.open .caret.uil-multi-caret {
  -webkit-transform: scaleY(-1) !important;
  transform: scaleY(-1) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: auto;
  right: 2px;
  padding-right: 8px !important;
  color: var(--color-font-dark);
}
.uil-multidropdown-toggle[aria-expanded="false"] > .caret {
  top: unset;
  right: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 8px !important;
  color: var(--color-font-dark);
}
.uil-multidropdown-toggle[aria-disabled="true"] > .caret {
  top: unset;
  right: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 8px !important;
  color: var(--color-font-disabled);
}
button.uil-singledropdown-toggle.uil-single-dropdown_toggle {
  background: var(--color-bg-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
button.uil-singledropdown-toggle.uil-single-dropdown_toggle[disabled] {
  cursor: not-allowed;
}
button.uil-btn-flat.uil-singledropdown-toggle.uil-single-dropdown_toggle[disabled] {
  background: var(--color-bg-disabled);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: not-allowed;
}
.uil-singledropdown-toggle.uil-single-dropdown_toggle [disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.uil-singledropdown-toggle[disabled] > .caret.uil-single-dropdown-caret {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  -webkit-transition: unset;
  transition: unset;
  -webkit-transform: none;
  transform: none;
}
span.caret.uil-single-dropdown-caret {
  top: auto;
}
.uil-ss-default .dropdown-placeholder {
  color: var(--color-font-greyMedium);
}
.uil-singledropdown.uil-ss-focused .uil-btn-flat {
  border-color: var(--color-border-dark);
}
.uil-singledropdown.uil-ss-focused .uil-btn-bordered {
  border-color: var(--color-border-dark);
}
.uil-ss-focused .dropdown-placeholder {
  color: var(--color-font-greyMedium);
}
.uil-ss-withContent .dropdown-placeholder {
  color: var(--color-font-dark);
}
.uil-singledropdown.uil-ss-error .uil-btn-flat {
  border-color: var(--color-border-error);
}
.uil-singledropdown.uil-ss-error .uil-btn-bordered {
  border-color: var(--color-border-error);
}
[disabled].uil-searchbar {
  cursor: not-allowed;
  background-color: transparent;
}
input.uil-searchbar[type="search"]::-webkit-search-cancel-button,
input.uil-searchbar[type="search"]::-webkit-search-decoration,
input.uil-searchbar[type="search"]::-webkit-search-results-button,
input.uil-searchbar[type="search"]::-webkit-search-results-decoration,
input.uil-textbox[type="search"]::-webkit-search-cancel-button,
input.uil-textbox[type="search"]::-webkit-search-decoration,
input.uil-textbox[type="search"]::-webkit-search-results-button,
input.uil-textbox[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.uil-chip-close-icon:before,
.uil-dl-icon-Close:before,
.uil-icon-close_icon:before,
.uil-status-card-close-icon:before {
  content: "\e98e";
}
.uil-popup-button-hover > .uil-popup-button-icon,
.uil-popup-button > .uil-popup-button-icon {
  color: #393a3e;
}
.uil-popup-button:hover {
  color: inherit;
}
.uil-popup-button:hover > .uil-popup-button-icon {
  color: inherit;
}
.uil-popup-button-hover {
  cursor: default;
}
.uil-popup-container {
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  position: fixed;
  padding: 12px;
  margin: 4px 0;
  z-index: 1000;
  color: #393a3e;
  background-color: var(--color-bg-light);
  visibility: hidden;
}
.uil-popup-container.uil-visibility-delay {
  -webkit-transition-property: visibility;
  transition-property: visibility;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.uil-popup-container.uil-visibility-delay:hover {
  visibility: visible;
}
.uil-popup-container.active {
  visibility: visible;
}
.uil-icon-pb {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-button {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: none;
  padding: 0.5rem 1rem;
  text-align: center;
  outline: 0 !important;
  line-height: 1rem;
}
.uil-button [class*="uil-dl-icon-"],
.uil-button [class*="uil-icon-"],
.uil-button [class^="uil-dl-icon-"],
.uil-button [class^="uil-icon-"] {
  padding-right: 8px;
}
.uil-button [class*="uil-dl-icon-"]:only-child,
.uil-button [class*="uil-icon-"]:only-child,
.uil-button [class^="uil-dl-icon-"]:only-child,
.uil-button [class^="uil-icon-"]:only-child {
  padding-right: 0;
}
.uil-button [class*="uil-dl-icon-"],
.uil-button [class^="uil-dl-icon-"] {
  font-weight: 600;
  font-size: 1rem;
}
.uil-button [class*="uil-dl-icon-"]:only-child,
.uil-button [class^="uil-dl-icon-"]:only-child {
  font-weight: 600;
  font-size: 1rem;
}
.disabled.uil-button,
[disabled].uil-button,
fieldset[disabled] .uil-button {
  cursor: not-allowed;
}
.uil-button {
  border: none !important;
  -webkit-transition: visibility 0s;
  transition: visibility 0s;
  background: var(--color-bg-primaryDark);
  color: var(--color-font-light);
}
.uil-button [class*="uil-dl-icon-"],
.uil-button [class^="uil-dl-icon-"] {
  color: var(--color-font-light);
}
.uil-button a {
  color: var(--color-font-light);
}
.uil-button a [class*="uil-dl-icon-"],
.uil-button a [class^="uil-dl-icon-"] {
  color: var(--color-font-light);
}
.uil-button.disabled,
.uil-button[disabled],
fieldset[disabled] .uil-button {
  color: #abb0b6 !important;
  background: #ccd1d7 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.uil-button.disabled [class*="uil-dl-icon-"],
.uil-button.disabled [class*="uil-icon-"],
.uil-button.disabled [class^="uil-dl-icon-"],
.uil-button.disabled [class^="uil-icon-"],
.uil-button[disabled] [class*="uil-dl-icon-"],
.uil-button[disabled] [class*="uil-icon-"],
.uil-button[disabled] [class^="uil-dl-icon-"],
.uil-button[disabled] [class^="uil-icon-"],
fieldset[disabled] .uil-button [class*="uil-dl-icon-"],
fieldset[disabled] .uil-button [class*="uil-icon-"],
fieldset[disabled] .uil-button [class^="uil-dl-icon-"],
fieldset[disabled] .uil-button [class^="uil-icon-"] {
  color: #abb0b6 !important;
}
.uil-button.pressed {
  color: var(--color-font-light);
  background-color: var(--primary-02);
}
.uil-button.pressed [class*="uil-dl-icon-"],
.uil-button.pressed [class^="uil-dl-icon-"] {
  color: var(--color-font-light);
}
.uil-button.active,
.uil-button:active,
.uil-button:focus:active,
.uil-button:focus:hover:active,
.uil-button:hover:active {
  color: var(--color-font-light);
  background-color: var(--color-bg-primaryLight);
  border-style: none;
}
.uil-button.active [class*="uil-dl-icon-"],
.uil-button.active [class^="uil-dl-icon-"],
.uil-button:active [class*="uil-dl-icon-"],
.uil-button:active [class^="uil-dl-icon-"],
.uil-button:focus:active [class*="uil-dl-icon-"],
.uil-button:focus:active [class^="uil-dl-icon-"],
.uil-button:focus:hover:active [class*="uil-dl-icon-"],
.uil-button:focus:hover:active [class^="uil-dl-icon-"],
.uil-button:hover:active [class*="uil-dl-icon-"],
.uil-button:hover:active [class^="uil-dl-icon-"] {
  color: var(--color-font-light);
}
.uil-button:focus,
.uil-button:hover {
  color: var(--color-font-light);
  background: var(--color-bg-primaryLight);
}
.uil-button:focus [class*="uil-dl-icon-"],
.uil-button:focus [class^="uil-dl-icon-"],
.uil-button:hover [class*="uil-dl-icon-"],
.uil-button:hover [class^="uil-dl-icon-"] {
  color: var(--color-font-light);
}
.uil-button a:focus,
.uil-button a:hover {
  color: var(--color-font-light);
  background: var(--color-bg-primaryLight);
}
.uil-button a:focus [class*="uil-dl-icon-"],
.uil-button a:focus [class^="uil-dl-icon-"],
.uil-button a:hover [class*="uil-dl-icon-"],
.uil-button a:hover [class^="uil-dl-icon-"] {
  color: var(--color-font-light);
}
.uil-button:focus {
  background: var(--color-bg-primaryDark);
  -webkit-box-shadow: var(--primary-03) 0 0 0 2px;
  box-shadow: var(--primary-03) 0 0 0 2px;
  outline: 0;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-button:focus:hover {
  background: var(--color-bg-primaryDark);
}
button[class*="uil-btn"] span[class*="uil-icon"]:not(:only-child) {
  padding-right: 8px;
}
.uil_button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  gap: 8px;
}
.uil_button span {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  line-height: 140%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: inherit;
  position: relative;
}
.disabled.uil_button label {
  cursor: not-allowed;
}
.uil_btn_square {
  border-radius: 0;
}
.uil_btn_round {
  border-radius: 24px;
}
.uil_btn_primary {
  background: var(--color-bg-dark);
  border: none;
  color: var(--color-font-light);
}
.uil_btn_primary:focus,
.uil_btn_primary:hover {
  background: var(--color-bg-primaryDark);
  border: none;
  color: var(--color-font-light);
}
.disabled.uil_btn_primary {
  color: var(--color-font-light);
  background: var(--color-bg-disabledDark);
  cursor: not-allowed;
}
.uil_btn_default {
  background: #004dff;
  border: none;
  color: var(--color-font-light);
}
.uil_btn_default:focus,
.uil_btn_default:hover {
  background: var(--primary-02);
  border: none;
  color: var(--color-font-light);
}
.disabled.uil_btn_default {
  color: var(--color-font-light);
  background: var(--color-bg-disabledDark);
  cursor: not-allowed;
}
.uil_btn_secondary {
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-dark);
  color: var(--color-font-dark);
}
.uil_btn_secondary:focus,
.uil_btn_secondary:hover {
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-primaryDark);
  color: var(--color-font-primaryDark);
}
.disabled.uil_btn_secondary {
  color: var(--color-bg-disabledDark);
  background: var(--color-bg-light);
  cursor: not-allowed;
  border: 1px solid var(--color-border-default);
}
.uil_btn_link {
  background: 0 0;
  border: none;
  color: var(--color-font-primaryLight);
}
.uil_btn_link:focus,
.uil_btn_link:hover {
  background: 0 0;
  border: none;
  color: var(--color-font-primaryDark);
}
.disabled.uil_btn_link {
  color: var(--color-font-disabled);
  background: 0 0;
  cursor: not-allowed;
}
.uil_btn_fullwidth {
  width: 100%;
  height: 48px;
}
.uil_btn_large {
  width: 132px;
  height: 48px;
}
.uil_btn_medium {
  width: 93px;
  height: 32px;
}
.uil_btn_icononly {
  width: 32px;
  height: 32px;
}
.uil_btn_fitcontent {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.uil_btn_withicon_large {
  width: 160px;
  height: 48px;
}
.uil_btn_withicon_medium {
  width: 117px;
  height: 32px;
}
.uil_btn_withicon_fullwidth {
  width: 100%;
  height: 48px;
}
.uil_btn_icononly_large {
  width: 48px;
  height: 48px;
}
.uil_btn_icononly_medium {
  width: 32px;
  height: 32px;
}
.uil_btn_icononly_fullwidth {
  width: 100%;
  height: 48px;
}
.uil-dl-icon-downloadarrow:before {
  content: "\e953";
  color: inherit;
}
.disabled.uil_button,
.disabled.uil_button.active,
.disabled.uil_button:active,
.disabled.uil_button:focus,
.disabled.uil_button:focus:active,
.disabled.uil_button:focus:hover,
.disabled.uil_button:focus:hover:active,
.disabled.uil_button:hover,
.disabled.uil_button:hover:active {
  -webkit-box-shadow: none;
  box-shadow: none !important;
  cursor: not-allowed !important;
}
.uil_button_title {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  line-height: 140%;
  padding: 8px;
}
.uil_btn_fitcontent_large {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  padding-left: 40px;
  padding-right: 40px;
}
.uil_btn_fitcontent_medium {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 29px;
  padding-left: 40px;
  padding-right: 40px;
}
.modal-backdrop.in,
.modal-backdrop.show {
  opacity: 0;
}
.uil-alert,
.uil-status-card {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid transparent;
  border-radius: 3px;
  background-color: #ff2825;
  margin: auto;
  padding: 16px 24px;
  line-height: 24px;
}
.uil-alert a.close,
.uil-status-card a.close {
  float: right;
  line-height: 24px;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  opacity: 1;
  text-shadow: none;
  cursor: pointer;
}
.uil-alert a.close > .svg,
.uil-status-card a.close > .svg {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}
.modal,
.modal.popup,
.uil-modal {
  height: 100%;
  width: 100%;
  margin: auto;
  text-align: center;
  margin: auto;
  pointer-events: auto;
}
.modal {
  background-color: rgba(0, 0, 0, 0.6);
}
.modal.popup {
  background-color: transparent !important;
}
.modal.uil-modal-curtain {
  background-color: transparent;
  opacity: 1;
}
.modal.uil-modal-curtain.show {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal.uil-modal-curtain.in {
  background-color: rgba(0, 0, 0, 0.6);
}
.modal:before {
  display: inline-block;
  vertical-align: middle !important;
  content: " ";
  height: 100%;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  pointer-events: auto;
}
@media screen and (max-width: 576px) {
  .modal-dialog {
    max-width: calc(100% - 2rem);
  }
}
@media screen and (max-width: 376px) {
  .modal-dialog {
    width: calc(100% - 1rem);
  }
}
.uil-image-modal-dialog {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  max-width: inherit;
}
.uil-image-modal-dialog .uil-card-title-area-underline {
  margin-bottom: 4px;
}
.uil-modal-image-view {
  height: 100%;
  overflow: auto;
}
.uil-modal-image-preview {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.uil-modal-image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.uil-modal-image {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.uil-zoom-in,
.uil-zoom-out {
  font-size: 32px;
}
.uil-full-modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
.uil-alert-link {
  text-decoration: underline;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
  line-height: 15px;
}
.uil-alert-danger-text,
.uil-danger-title,
.uil-notification-text {
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
  line-height: 15px;
}
.uil-nav-tabs,
.uil-navbar-tabs {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  border: none;
  border-bottom: 1px solid #ddd;
}
.uil-nav-tabs > li > a,
.uil-navbar-tabs > li > a {
  margin-right: 0;
  background-color: transparent;
  border: none !important;
  border-bottom-color: transparent;
  color: #393a3e;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  height: 100%;
  padding: 10px 24px 12px 24px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (prefers-reduced-motion: reduce) {
  .uil-nav-tabs > li > a,
  .uil-navbar-tabs > li > a {
    -webkit-transition: none;
    transition: none;
  }
}
@media screen and (max-width: 1280px) {
  .uil-nav-tabs > li > a,
  .uil-navbar-tabs > li > a {
    padding: 10px 12px 12px 12px;
  }
}
.uil-nav-tabs > li > a:hover,
.uil-navbar-tabs > li > a:hover {
  background-color: transparent;
  -webkit-box-shadow: inset 0 -4px 0 var(--brand-01);
  box-shadow: inset 0 -4px 0 var(--brand-01);
  color: #393a3e;
}
.uil-nav-tabs > li.active > a,
.uil-nav-tabs > li.active > a:focus,
.uil-nav-tabs > li > a.active,
.uil-nav-tabs > li > a.active:focus,
.uil-navbar-tabs > li.active > a,
.uil-navbar-tabs > li > a.active {
  border: none !important;
  border-bottom-color: transparent;
  -webkit-box-shadow: inset 0 -4px 0 var(--primary-01);
  box-shadow: inset 0 -4px 0 var(--primary-01);
  color: #393a3e;
  background: 0 0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-nav-tabs > li.active > a:focus:hover,
.uil-nav-tabs > li.active > a:hover,
.uil-nav-tabs > li > a.active:focus:hover,
.uil-nav-tabs > li > a.active:hover,
.uil-navbar-tabs > li.active > a:hover,
.uil-navbar-tabs > li > a.active:hover {
  border: none;
  color: #393a3e;
}
.uil-nav-tabs > li.disabled > a,
.uil-navbar-tabs > li.disabled > a {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #c2c7cb !important;
}
.nav-justified.uil-navbar-tabs > .active > a,
.nav-justified.uil-navbar-tabs > li > a,
.uil-nav-tabs.nav-justified > .active > a,
.uil-nav-tabs.nav-justified > .active > a:focus,
.uil-nav-tabs.nav-justified > .active > a:hover,
.uil-nav-tabs.nav-justified > li > a,
.uil-nav-tabs.nav-justified > li > a:focus,
.uil-nav-tabs.nav-justified > li > a:hover {
  border: none;
}
.uil-nav-tabs .dropdown-menu,
.uil-nav-tabs .uil-app-menu,
.uil-nav-tabs .uil-megamenu-dropdown,
.uil-nav-tabs .uil-megamenu-horizontal-expansion-area,
.uil-nav-tabs .uil-toolbar-dropdown,
.uil-nav-tabs .uil-toolbar-guide-menu,
.uil-nav-tabs .uil-toolbar-option-menu,
.uil-nav-tabs .uil-toolbar-search-menu,
.uil-nav-tabs .uil-toolbar-user-menu,
.uil-navbar-tabs .dropdown-menu,
.uil-navbar-tabs .uil-app-menu,
.uil-navbar-tabs .uil-megamenu-dropdown,
.uil-navbar-tabs .uil-megamenu-horizontal-expansion-area,
.uil-navbar-tabs .uil-toolbar-dropdown,
.uil-navbar-tabs .uil-toolbar-guide-menu,
.uil-navbar-tabs .uil-toolbar-option-menu,
.uil-navbar-tabs .uil-toolbar-search-menu,
.uil-navbar-tabs .uil-toolbar-user-menu {
  margin-top: 0;
}
.uil-megamenu-area {
  position: relative;
  height: 56px;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.uil-megamenu-area a {
  color: #393a3e;
}
.uil-megamenu-area.disabled,
.uil-megamenu-area:disabled,
.uil-megamenu-area[disabled] {
  cursor: not-allowed;
}
.uil-megamenu-area.disabled a,
.uil-megamenu-area:disabled a,
.uil-megamenu-area[disabled] a {
  pointer-events: none;
  color: #c2c7cb;
}
.uil-megamenu-area.disabled .uil-megamenu-subtitle,
.uil-megamenu-area.disabled .uil-megamenu-title,
.uil-megamenu-area:disabled .uil-megamenu-subtitle,
.uil-megamenu-area:disabled .uil-megamenu-title,
.uil-megamenu-area[disabled] .uil-megamenu-subtitle,
.uil-megamenu-area[disabled] .uil-megamenu-title {
  color: #c2c7cb;
}
.uil-megamenu-area.disabled .uil-megamenu-titlearea::before,
.uil-megamenu-area:disabled .uil-megamenu-titlearea::before,
.uil-megamenu-area[disabled] .uil-megamenu-titlearea::before {
  color: #c2c7cb;
}
.uil-megamenu-titlearea {
  background: #fff;
  min-width: 160px;
  max-width: 320px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  height: 56px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
}
.uil-megamenu-titlearea::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393a3e;
  position: absolute;
  font-size: 24px;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  height: 24px;
  width: 24px;
  margin: auto 12px;
}
.uil-megamenu-titlearea.collapsed::before,
.uil-megamenu-titlearea[aria-expanded="false"]::before {
  transform: rotate(0);
  -webkit-transform: rotate(0);
}
@media (max-width: 1023px) {
  .uil-megamenu-titlearea {
    min-width: 120px;
    max-width: 240px;
  }
}
.uil-megamenu-titlearea:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f1f2f4 !important;
}
@media (hover: hover) {
  .uil-megamenu-titlearea:hover
    .uil-megamenu-titlearea:not(.disabled)
    .uil-megamenu-titlearea:not([disabled]) {
    background-color: #f1f2f4 !important;
  }
}
@media (hover: none) {
  .uil-megamenu-titlearea {
    background-color: transparent !important;
  }
}
.uil-megamenu-titlearea[aria-expanded="true"] {
  background-color: #f1f2f4 !important;
}
.uil-megamenu-titlearea .uil-megamenu-title {
  margin-right: 24px;
}
.uil-megamenu-titlearea .uil-megamenu-subtitle {
  padding-right: 0;
  margin-right: 24px;
}
.uil-megamenu-dropdown {
  border-radius: 0;
  position: absolute;
  border: none;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 320px;
  background-color: #fff;
  margin: 0;
  padding: 0;
}
@media (max-width: 1023px) {
  .uil-megamenu-dropdown {
    width: 240px;
  }
}
.uil-megamenu-dropdown.collapse.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-megamenu-dropdown.collapse:not(.show) {
  height: 0;
  display: none;
}
.uil-megamenu-dropdown .uil-megamenu-title {
  font-size: inherit;
  color: inherit;
}
.uil-megamenu-dropdown .uil-megamenu-subtitle {
  color: inherit;
}
.uil-megamenu-title {
  color: #393a3e;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  overflow: hidden;
  line-height: 16px;
}
.uil-megamenu-subtitle {
  color: #393a3e;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  overflow: hidden;
}
.uil-megamenu-list {
  width: 320px;
  padding: 0;
  margin: 0;
}
@media (max-width: 1023px) {
  .uil-megamenu-list {
    width: 240px;
  }
}
.uil-megamenu-list .uil-megamenu-list {
  width: 100%;
}
.uil-megamenu-list .uil-megamenu-list .uil-megamenu-list-item {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
}
.uil-megamenu-list .uil-alerts-container {
  min-width: 20px;
}
.uil-megamenu-list-item {
  width: inherit;
  background: #fff;
  line-height: 1.25;
  color: #393a3e;
  -webkit-box-shadow: 0 -1px 0 #c1c1c1;
  box-shadow: 0 -1px 0 #c1c1c1;
  display: block;
  min-height: 48px;
  cursor: pointer;
}
.uil-megamenu-list-item .uil-megamenu-title {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 1.3;
  margin-right: 12px;
}
.uil-megamenu-list-item .uil-list-item-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-megamenu-list-item .uil-list-item-left .uil-megamenu-title {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-megamenu-list-item.disabled,
.uil-megamenu-list-item[disabled] {
  color: #c2c7cb;
  cursor: not-allowed !important;
}
.uil-megamenu-list-item.disabled a,
.uil-megamenu-list-item[disabled] a {
  color: #c2c7cb;
  cursor: not-allowed !important;
}
.uil-megamenu-list-item .uil-alerts-container {
  float: right;
}
.uil-megamenu-expandable {
  background: #fff;
  padding: 14px 24px;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.uil-megamenu-expandable[aria-expanded="true"] {
  background-color: #f1f2f4 !important;
}
.uil-megamenu-expandable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f1f2f4 !important;
}
@media (hover: hover) {
  .uil-megamenu-expandable:hover
    .uil-megamenu-expandable:not(.disabled)
    .uil-megamenu-expandable:not([disabled]) {
    background-color: #f1f2f4 !important;
  }
}
@media (hover: none) {
  .uil-megamenu-expandable {
    background-color: transparent !important;
  }
}
.uil-megamenu-expandable.disabled,
.uil-megamenu-expandable[disabled] {
  color: #c2c7cb;
  cursor: not-allowed !important;
}
.uil-megamenu-expandable.disabled a,
.uil-megamenu-expandable[disabled] a {
  color: #c2c7cb;
  cursor: not-allowed !important;
}
.uil-megamenu-expandable.uil-megamenu-vertical-expand {
  cursor: pointer;
  padding-right: 40px;
}
.uil-megamenu-expandable.uil-megamenu-vertical-expand::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393a3e;
  position: absolute;
  font-size: 24px;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  height: 24px;
  width: 24px;
  margin: auto 12px;
}
.uil-megamenu-expandable.uil-megamenu-vertical-expand.collapsed::before,
.uil-megamenu-expandable.uil-megamenu-vertical-expand[aria-expanded="false"]::before {
  transform: rotate(0);
  -webkit-transform: rotate(0);
}
.uil-megamenu-expandable.uil-megamenu-expandable-current::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393a3e;
  position: absolute;
  font-size: 24px;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  height: 24px;
  width: 24px;
  margin: auto 12px;
}
.uil-megamenu-expandable.uil-megamenu-expandable-current[aria-expanded="true"]::before {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.uil-megamenu-horizontal-expansion-area {
  border-radius: 0;
  position: absolute;
  left: 320px;
  top: 0;
  border: none;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  display: block;
  background-color: #fff;
  margin: 0;
  padding: 0;
}
@media (max-width: 1023px) {
  .uil-megamenu-horizontal-expansion-area {
    left: 240px;
  }
}
.uil-megamenu-horizontal-expansion-area .uil-megamenu-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-megamenu-horizontal-expansion-area .uil-megamenu-subtitle {
  color: inherit;
}
.uil-megamenu-horizontal-expansion-area .uil-megamenu-subtitle a {
  color: inherit;
}
.uil-megamenu-list-item-expansion {
  padding: 12px 16px;
  overflow-y: auto;
  margin: 0;
  line-height: 1.25;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #393a3e;
  padding-bottom: 0;
}
.uil-megamenu-list-item-expansion:not(.show) {
  overflow-y: hidden;
}
.uil-megamenu-list-item-expansion[aria-expanded="true"] {
  background-color: #f1f2f4 !important;
}
.uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand {
  width: 100%;
  padding: 0;
  background-color: #fff;
  width: 320px;
  max-height: 400px;
}
@media (max-width: 1023px) {
  .uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand {
    width: 240px;
  }
}
.uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand li {
  padding: 12px 16px;
}
.uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
  .uil-megamenu-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
  .uil-megamenu-list-item:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f1f2f4 !important;
}
@media (hover: hover) {
  .uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
    .uil-megamenu-list-item:hover
    .uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
    .uil-megamenu-list-item:not(.disabled)
    .uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
    .uil-megamenu-list-item:not([disabled]) {
    background-color: #f1f2f4 !important;
  }
}
@media (hover: none) {
  .uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
    .uil-megamenu-list-item {
    background-color: transparent !important;
  }
}
.uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
  .uil-megamenu-list-item
  .uil-toggle-icon {
  font-size: 1.5rem;
}
.uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expand
  .uil-megamenu-list-item
  .uil-megamenu-subtitle {
  line-height: 1.25rem;
}
.uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expansion-container {
  position: fixed;
  margin-left: 320px;
  list-style: none;
  margin-top: -46px;
  padding: 0;
  width: inherit;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1019607843);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1019607843);
}
@media (max-width: 1023px) {
  .uil-megamenu-list-item-expansion.uil-megamenu-horizontal-expansion-container {
    margin-left: 240px;
  }
}
.uil-megamenu-list-item-expansion.uil-megamenu-vertical-expand {
  padding: 0;
}
.uil-megamenu-list-item-expansion .uil-megamenu-expandable {
  background-color: inherit;
}
.uil-megamenu-list-item-expansion
  .uil-megamenu-expandable[aria-expanded="true"] {
  background-color: #f1f2f4 !important;
}
.uil-megamenu-list-item-expansion
  .uil-megamenu-expandable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f1f2f4 !important;
}
@media (hover: hover) {
  .uil-megamenu-list-item-expansion
    .uil-megamenu-expandable:hover
    .uil-megamenu-list-item-expansion
    .uil-megamenu-expandable:not(.disabled)
    .uil-megamenu-list-item-expansion
    .uil-megamenu-expandable:not([disabled]) {
    background-color: #f1f2f4 !important;
  }
}
@media (hover: none) {
  .uil-megamenu-list-item-expansion .uil-megamenu-expandable {
    background-color: transparent !important;
  }
}
.uil-horizontal-nav-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 24px;
  z-index: 1000;
  background-color: transparent;
  color: #393a3e;
  height: 56px;
  margin-right: -24px;
  position: relative;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  opacity: 1;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-horizontal-nav-heading .uil-horizontal-nav-heading-actionarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  width: 56px;
  background-color: transparent;
  color: #393a3e;
  padding: 16px;
  margin-left: -24px;
}
.uil-horizontal-nav-heading .uil-horizontal-nav-heading-titlearea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  width: 100%;
  padding-left: 16px;
  background-color: #fff;
  color: #393a3e;
  margin-right: -24px;
}
.uil-horizontal-nav {
  z-index: 1000;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 25px;
  position: relative;
  min-height: 56px;
  height: 56px;
  padding-right: 0;
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}
.uil-horizontal-nav::after {
  display: block;
  clear: both;
  content: "";
}
.uil-horizontal-nav .uil-tri-pointer-grid,
.uil-horizontal-nav .uil-tri-pointer-notification,
.uil-horizontal-nav .uil-tri-pointer-option,
.uil-horizontal-nav .uil-tri-pointer-user {
  top: 32px;
  left: 2px;
  position: absolute;
  display: none;
}
.uil-horizontal-nav .uil-tri-pointer-grid .uil-tri-pointer-blk,
.uil-horizontal-nav .uil-tri-pointer-notification .uil-tri-pointer-blk,
.uil-horizontal-nav .uil-tri-pointer-option .uil-tri-pointer-blk,
.uil-horizontal-nav .uil-tri-pointer-user .uil-tri-pointer-blk {
  position: absolute;
  top: 1px;
  left: 0.5px;
  width: 0;
  height: 0;
  z-index: 1011;
  border-left: 12.5px solid transparent;
  border-right: 12.5px solid transparent;
  border-bottom: 14.5px solid #fff;
}
.uil-horizontal-nav .uil-tri-pointer-grid .uil-tri-pointer,
.uil-horizontal-nav .uil-tri-pointer-notification .uil-tri-pointer,
.uil-horizontal-nav .uil-tri-pointer-option .uil-tri-pointer,
.uil-horizontal-nav .uil-tri-pointer-user .uil-tri-pointer {
  z-index: 1010;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid rgba(0, 0, 0, 0.1);
}
.uil-horizontal-nav.uil-show-shadow {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  z-index: 1000 !important;
}
.uil-horizontal-navarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.uil-horizontal-navarea.minimize {
  height: 0;
}
.uil-horizontal-navarea.minimize > a {
  z-index: 1000;
}
.uil-horizontal-navarea.minimize.active {
  display: block;
  width: 240px;
  min-width: 240px;
  height: 100vh;
  background: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  position: absolute;
  top: 0;
}
.uil-horizontal-navarea.minimize.active .uil-horizontal-nav-titlearea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
}
.uil-horizontal-navarea.minimize.active .uil-main-nav-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: sticky;
}
.uil-horizontal-navarea.minimize.active .uil-main-nav-footer.top {
  top: 0;
  height: 64px;
}
.uil-horizontal-navarea.minimize.active
  .uil-main-nav-footer.top
  .mainbar-collapse {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.uil-horizontal-navarea.minimize.active .uil-horizontal-nav-titlearea {
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
.uil-horizontal-navarea.minimize.active .uil-horizontal-nav-titlearea a {
  color: #393a3e !important;
}
.uil-horizontal-navarea.minimize.active
  .uil-horizontal-nav-titlearea
  .uil-horizontal-nav-logo {
  display: none;
}
.uil-horizontal-navarea.minimize ~ .search-open {
  background-color: #fff;
  width: calc(100% - 24px);
}
.uil-horizontal-navarea.minimize ~ .search-open .uil-toolbar-search.active {
  width: 100%;
}
.uil-horizontal-navarea.minimize ~ .search-open .uil-toolbar-search-menu {
  left: 24px;
}
.uil-horizontal-nav-titlearea {
  height: 56px;
  margin-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: transparent;
  color: #393a3e;
  position: relative;
  padding-right: 0;
  overflow: hidden;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  z-index: 1000;
  min-width: 328px;
  width: auto;
}
.uil-horizontal-nav-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-right: 0;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 1.1;
  position: relative;
  padding-left: 16px;
  padding-right: 0;
  color: #393a3e !important;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: 18px;
}
.uil-horizontal-nav-title > a,
.uil-horizontal-nav-title > li > a,
.uil-horizontal-nav-title > li > a:focus {
  color: #393a3e !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding-left: 0 !important;
}
@media (prefers-reduced-motion: reduce) {
  .uil-horizontal-nav-title > a,
  .uil-horizontal-nav-title > li > a,
  .uil-horizontal-nav-title > li > a:focus {
    -webkit-transition: none;
    transition: none;
  }
}
.uil-horizontal-nav-title > a:hover,
.uil-horizontal-nav-title > li > a:focus:hover,
.uil-horizontal-nav-title > li > a:hover {
  color: #393a3e;
}
.uil-horizontal-nav-title > li.active > a,
.uil-horizontal-nav-title > li.active > a:focus {
  color: #393a3e !important;
}
.uil-horizontal-nav-title > li.active > a:focus:hover,
.uil-horizontal-nav-title > li.active > a:hover {
  color: #393a3e !important;
}
.uil-horizontal-nav-logo {
  float: left;
  margin-left: 16px;
  min-width: 40px;
  font-size: 8px;
  text-align: center;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-bottom: 22px;
}
.uil-horizontal-nav-logo a,
.uil-horizontal-nav-logo a > img,
.uil-horizontal-nav-logo img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}
.uil-horizontal-nav-navigationarea {
  white-space: nowrap;
  height: 56px;
  padding-top: 0;
  padding-right: 0;
  position: relative;
  z-index: 1000;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-right: auto;
}
.uil-horizontal-nav-navigationarea.uil-horizontal-nav-navbar-right {
  margin-left: auto;
  margin-right: unset;
}
.uil-horizontal-nav-navigationarea.uil-horizontal-nav-navbar-middle {
  margin-left: auto;
  margin-right: auto;
}
.uil-horizontal-nav-navigationarea.minimize .uil-horizontal-nav-navbar,
.uil-horizontal-nav-navigationarea.minimize .uil-toolbar-list {
  display: none;
}
.uil-horizontal-nav-navigationarea.minimize.active {
  display: block;
  overflow: auto;
  width: 240px;
  min-width: 240px;
  height: 100%;
  max-height: calc(100% - 32px - 64px - 64px);
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-horizontal-nav-navigationarea.minimize.active .uil-main-nav-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: sticky;
}
.uil-horizontal-nav-navigationarea.minimize.active .uil-horizontal-nav-navbar,
.uil-horizontal-nav-navigationarea.minimize.active .uil-toolbar-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  max-width: 240px;
  width: 100%;
  margin: 0;
  padding-top: 24px;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  li:focus,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  li:hover,
.uil-horizontal-nav-navigationarea.minimize.active .uil-toolbar-list li:focus,
.uil-horizontal-nav-navigationarea.minimize.active .uil-toolbar-list li:hover {
  display: block;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  li.active
  ul,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  li.active
  ul {
  display: block;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  li.active
  .uil-horizontal-nav-submenu,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  li.active
  .uil-horizontal-nav-submenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-title,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-title {
  display: block;
  color: #393a3e !important;
  width: 200px;
  margin-left: 24px;
  padding-top: 8px;
  height: 50px;
  max-height: 50px;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-title
  a,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-title
  a {
  color: #393a3e !important;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu {
  display: block;
  padding-left: 2px;
  width: 100%;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item {
  width: 100%;
  height: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  border: none !important;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item:hover,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item:hover {
  padding-left: 22px !important;
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item.opened:hover,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item.opened:hover {
  padding-left: 24px !important;
  border: none !important;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a {
  display: inline-block;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #393a3e;
  text-align: left;
  width: 100%;
  height: 100% !important;
  padding: 0 10px 0 0;
  line-height: inherit;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a:hover,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a:hover {
  color: #393a3e;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="false"]::before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 1.5rem 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 1.5rem;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"]::before,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 1.5rem 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 1.5rem;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"],
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  a[aria-expanded="true"] {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item.active,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item.active {
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
  padding-left: 22px !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  min-width: 100%;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  z-index: 1000;
  background: 0 0 !important;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list {
  background: 0 0 !important;
  padding-top: 8px;
  padding-bottom: 0;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list
  > .uil-submenuitem,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list
  > .uil-submenuitem {
  width: 100%;
  background: 0 0 !important;
  height: auto !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  padding-left: 24px;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list
  > .uil-submenuitem
  a,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list
  > .uil-submenuitem
  a {
  line-height: 40px;
  padding: 0 !important;
  text-align: start;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list
  > .uil-submenuitem:hover,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu
  > .uil-horizontal-nav-submenu-list
  > .uil-submenuitem:hover {
  border: none !important;
  padding-left: 22px !important;
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
}
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-horizontal-nav-navbar
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc,
.uil-horizontal-nav-navigationarea.minimize.active
  .uil-toolbar-list
  > .uil-horizontal-nav-mainmenu
  > .uil-horizontal-nav-item
  > .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc {
  display: none;
}
.uil-horizontal-nav-navigationarea ul li ul {
  display: inline-block;
}
.uil-horizontal-nav-navigationarea ul li ul a:focus,
.uil-horizontal-nav-navigationarea ul li ul a:hover {
  background-color: transparent;
}
.uil-horizontal-nav-navigationarea ul li > .uil-horizontal-nav-submenu {
  display: none;
}
.uil-horizontal-nav-navigationarea .uil-horizontal-nav-submenu {
  display: none;
}
.uil-horizontal-nav-navbar,
.uil-toolbar-list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  max-width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}
.uil-horizontal-nav-navbar li:hover,
.uil-toolbar-list li:hover {
  overflow: visible;
}
@media (max-width: 767px) {
  .ui-dropdown-menu.uil-toolbar-list,
  .uil-horizontal-nav-navbar.ui-dropdown-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 1023px) {
  .ui-dropdown-menu.uil-toolbar-list,
  .uil-horizontal-nav-navbar.ui-dropdown-menu {
    display: none;
  }
}
.uil-horizontal-nav-navigationarea ul.ui-dropdown-menu.uil-toolbar-list,
.uil-horizontal-nav-navigationarea
  ul.uil-horizontal-nav-navbar.ui-dropdown-menu {
  background-color: red;
}
@media (max-width: 767px) {
  .uil-horizontal-nav-navigationarea ul.ui-dropdown-menu.uil-toolbar-list,
  .uil-horizontal-nav-navigationarea
    ul.uil-horizontal-nav-navbar.ui-dropdown-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 1023px) {
  .uil-horizontal-nav-navigationarea ul.ui-dropdown-menu.uil-toolbar-list,
  .uil-horizontal-nav-navigationarea
    ul.uil-horizontal-nav-navbar.ui-dropdown-menu {
    display: none;
  }
}
.uil-navbar-tabs {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #393a3e;
  border: none;
}
.uil-navbar-tabs > li > a,
.uil-navbar-tabs > li > a:focus {
  padding: 0 24px 18px 24px;
  margin-right: 0;
  background-color: transparent;
  border: none;
  color: #393a3e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  line-height: 1;
  height: 56px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .uil-navbar-tabs > li > a,
  .uil-navbar-tabs > li > a:focus {
    -webkit-transition: none;
    transition: none;
  }
}
.uil-navbar-tabs > li > a:focus:hover,
.uil-navbar-tabs > li > a:hover {
  background-color: transparent;
  -webkit-box-shadow: inset 0 -4px 0 var(--brand-01);
  box-shadow: inset 0 -4px 0 var(--brand-01);
  color: #393a3e;
}
.uil-navbar-tabs > li > a:focus:hover.disabled,
.uil-navbar-tabs > li > a:hover.disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-navbar-tabs > li.disabled > a:focus:hover,
.uil-navbar-tabs > li.disabled > a:hover {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #c2c7cb;
}
.uil-navbar-tabs > li.active > a,
.uil-navbar-tabs > li.active > a:focus {
  border: none;
  -webkit-box-shadow: inset 0 -4px 0 var(--brand-01);
  box-shadow: inset 0 -4px 0 var(--brand-01);
  color: #393a3e;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 1;
}
.uil-navbar-tabs > li.active > a:focus:hover,
.uil-navbar-tabs > li.active > a:hover {
  border: none;
  color: #393a3e;
}
.uil-horizontal-nav-navbar li.uil-navbar-menu-active,
.uil-horizontal-nav-navbar li:focus,
.uil-horizontal-nav-navbar li:hover,
.uil-toolbar-list li.uil-navbar-menu-active,
.uil-toolbar-list li:focus,
.uil-toolbar-list li:hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
}
.uil-horizontal-nav-navbar li.uil-navbar-menu-active ul,
.uil-horizontal-nav-navbar li:focus ul,
.uil-horizontal-nav-navbar li:hover ul,
.uil-toolbar-list li.uil-navbar-menu-active ul,
.uil-toolbar-list li:focus ul,
.uil-toolbar-list li:hover ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  overflow: visible;
}
.uil-horizontal-nav-navbar
  li.uil-navbar-menu-active
  .uil-horizontal-nav-submenu,
.uil-horizontal-nav-navbar li:focus .uil-horizontal-nav-submenu,
.uil-horizontal-nav-navbar li:hover .uil-horizontal-nav-submenu,
.uil-toolbar-list li.uil-navbar-menu-active .uil-horizontal-nav-submenu,
.uil-toolbar-list li:focus .uil-horizontal-nav-submenu,
.uil-toolbar-list li:hover .uil-horizontal-nav-submenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.uil-horizontal-nav-navbar
  li.uil-navbar-menu-active
  .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc.active,
.uil-horizontal-nav-navbar
  li:focus
  .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc.active,
.uil-horizontal-nav-navbar
  li:hover
  .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc.active,
.uil-toolbar-list
  li.uil-navbar-menu-active
  .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc.active,
.uil-toolbar-list
  li:focus
  .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc.active,
.uil-toolbar-list
  li:hover
  .uil-horizontal-nav-submenu:hover
  .uil-horizontal-nav-submenu-desc.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-horizontal-nav-navbar li a:focus,
.uil-horizontal-nav-navbar li a:hover,
.uil-toolbar-list li a:focus,
.uil-toolbar-list li a:hover {
  background-color: transparent;
}
.uil-horizontal-nav-item {
  height: 100%;
  vertical-align: middle;
  text-align: center;
}
.uil-horizontal-nav-item li {
  text-align: left;
}
.uil-horizontal-nav-item a {
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: sans-serif;
  vertical-align: middle;
  text-align: left;
}
.uil-horizontal-nav-item.active {
  background: 0 0 !important;
}
.uil-horizontal-nav-item.disabled {
  color: #c2c7cb;
}
.uil-horizontal-nav-item.disabled a {
  color: #c2c7cb !important;
}
.uil-horizontal-nav-navigationarea ul li > .uil-horizontal-nav-submenu {
  border-radius: 0;
  min-width: 172px;
  width: auto;
  list-style: none;
  position: absolute;
  margin-left: 0;
  margin-top: 56px;
  padding: 0;
  text-decoration: none;
  z-index: 1000;
  overflow: hidden;
  display: none;
  background-color: #fff;
  color: #393a3e;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.uil-horizontal-nav-withsubmenu {
  padding-bottom: 24px;
  padding-top: 24px;
}
.uil-horizontal-nav-mainmenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
}
.uil-horizontal-nav-mainmenu li {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 12px 0;
  position: relative;
}
.uil-horizontal-nav-submenu {
  border-radius: 0;
  min-width: 172px;
  width: auto;
  list-style: none;
  position: absolute;
  margin-left: 0;
  margin-top: 56px;
  padding: 0;
  text-decoration: none;
  z-index: 1000;
  overflow: hidden;
  display: none;
  background-color: #fff;
  color: #393a3e;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.uil-horizontal-nav-submenu:hover.active {
  width: 500px;
}
.uil-horizontal-nav-submenu:hover.active > .uil-horizontal-nav-submenu-list {
  color: var(--primary-01);
  min-width: 172px;
  width: auto;
  overflow: hidden;
  height: 99%;
}
.uil-horizontal-nav-submenu.right {
  right: -12px;
}
.uil-horizontal-nav-submenu.left {
  left: 0;
}
.uil-horizontal-nav-submenu-desc {
  white-space: normal;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 20px;
  border-left: 1px solid var(--primary-02);
  text-align: left;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 50%;
  height: inherit;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin-left: 0;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}
.uil-horizontal-nav-submenu-desc a {
  text-transform: uppercase;
  color: var(--primary-02);
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.4;
  line-height: 16px;
  text-align: right;
  height: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.uil-horizontal-nav-submenu-desc.left {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  border-left: 0 solid var(--primary-02);
  border-right: 1px solid var(--primary-02);
}
.uil-horizontal-nav-submenu-list {
  display: inline-block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  margin-left: 0;
  background-color: #fff;
}
.uil-submenuitem {
  width: 100%;
  margin: 0 0 !important;
  height: 40px !important;
}
.uil-submenuitem:hover {
  background: #f8f9fa !important;
  color: #393a3e;
}
.uil-submenuitem:hover .uil-horizontal-nav-submenu-desc.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-submenuitem a {
  padding: 0 12px !important;
  color: #393a3e;
  background-color: transparent;
  text-align: left;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-submenuitem a::before {
  font-size: 24px;
  margin-right: 12px;
}
.uil-submenuitem a:hover {
  color: #393a3e;
}
.uil-app-menu,
.uil-toolbar-dropdown,
.uil-toolbar-guide-menu,
.uil-toolbar-option-menu,
.uil-toolbar-search-menu,
.uil-toolbar-user-menu {
  border-color: #f1f2f4;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  position: absolute;
  right: 0;
  left: auto;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  max-height: 664px;
  overflow-y: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 400px;
  background-color: #fff;
  margin: 4px 0;
  padding: 8px 0 0 0;
  z-index: 1010;
}
@media (max-width: 1023px) {
  .uil-app-menu,
  .uil-toolbar-dropdown,
  .uil-toolbar-guide-menu,
  .uil-toolbar-option-menu,
  .uil-toolbar-search-menu,
  .uil-toolbar-user-menu {
    width: 320px;
  }
}
.collapse.in.uil-app-menu,
.collapse.in.uil-toolbar-guide-menu,
.collapse.in.uil-toolbar-option-menu,
.collapse.in.uil-toolbar-search-menu,
.collapse.in.uil-toolbar-user-menu,
.collapse.show.uil-app-menu,
.collapse.show.uil-toolbar-guide-menu,
.collapse.show.uil-toolbar-option-menu,
.collapse.show.uil-toolbar-search-menu,
.collapse.show.uil-toolbar-user-menu,
.uil-toolbar-dropdown.collapse.in,
.uil-toolbar-dropdown.collapse.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-y: hidden;
  height: auto;
}
.collapse.uil-app-menu:not(.in, .show),
.collapse.uil-toolbar-guide-menu:not(.in, .show),
.collapse.uil-toolbar-option-menu:not(.in, .show),
.collapse.uil-toolbar-search-menu:not(.in, .show),
.collapse.uil-toolbar-user-menu:not(.in, .show),
.uil-toolbar-dropdown.collapse:not(.in, .show) {
  height: 0;
  display: none;
}
.uil-app-menu .uil-expandable_list-multi,
.uil-toolbar-dropdown .uil-expandable_list-multi,
.uil-toolbar-guide-menu .uil-expandable_list-multi,
.uil-toolbar-option-menu .uil-expandable_list-multi,
.uil-toolbar-search-menu .uil-expandable_list-multi,
.uil-toolbar-user-menu .uil-expandable_list-multi {
  margin: 0;
  max-height: 600px;
  overflow: hidden;
}
.uil-app-menu .uil-expandable_list-multi .uil-expandable_list-multi-item,
.uil-toolbar-dropdown
  .uil-expandable_list-multi
  .uil-expandable_list-multi-item,
.uil-toolbar-guide-menu
  .uil-expandable_list-multi
  .uil-expandable_list-multi-item,
.uil-toolbar-option-menu
  .uil-expandable_list-multi
  .uil-expandable_list-multi-item,
.uil-toolbar-search-menu
  .uil-expandable_list-multi
  .uil-expandable_list-multi-item,
.uil-toolbar-user-menu
  .uil-expandable_list-multi
  .uil-expandable_list-multi-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-app-menu .uil-alerts-container,
.uil-toolbar-dropdown .uil-alerts-container,
.uil-toolbar-guide-menu .uil-alerts-container,
.uil-toolbar-option-menu .uil-alerts-container,
.uil-toolbar-search-menu .uil-alerts-container,
.uil-toolbar-user-menu .uil-alerts-container {
  min-width: 20px;
  padding-right: 16px;
}
.uil-app-menu .uil-collapse-mainitem-noexpand,
.uil-toolbar-dropdown .uil-collapse-mainitem-noexpand,
.uil-toolbar-guide-menu .uil-collapse-mainitem-noexpand,
.uil-toolbar-option-menu .uil-collapse-mainitem-noexpand,
.uil-toolbar-search-menu .uil-collapse-mainitem-noexpand,
.uil-toolbar-user-menu .uil-collapse-mainitem-noexpand {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px;
}
.uil-app-menu .uil-expanded-row-list li,
.uil-toolbar-dropdown .uil-expanded-row-list li,
.uil-toolbar-guide-menu .uil-expanded-row-list li,
.uil-toolbar-option-menu .uil-expanded-row-list li,
.uil-toolbar-search-menu .uil-expanded-row-list li,
.uil-toolbar-user-menu .uil-expanded-row-list li {
  line-height: 20px;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-notification-title-area {
  min-height: 58px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  line-height: 32px;
  border-bottom: 1px solid #eaebee;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.uil-toolbar .uil-notification-title-area {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-toolbar .uil-notification-title-area .uil-count-status-icon {
  float: right;
}
.uil-toolbar .uil-notification-title-area .uil-notification-title {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.uil-notification-title {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.2;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: 8px;
}
.uil-notification-subtitle {
  background-color: #f4f4f4;
}
.uil-toolbar-list {
  max-width: 200px;
  height: 56px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  line-height: 56px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.uil-toolbar-list li {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
  line-height: 20px;
  padding-top: calc((56px - 24px) / 2);
  padding-bottom: calc((56px - 24px) / 2);
  margin-left: 8px;
}
.uil-toolbar-list li.uil-toolbar-list-subitem,
.uil-toolbar-list li.uil-toolbar-submenu-subitem,
.uil-toolbar-list li.uil-toolbar-user-subitem {
  display: none;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.uil-toolbar-list li:hover.option-toolicon,
.uil-toolbar-list li:hover.uil-toolicon,
.uil-toolbar-list li:hover.uil-toolicon-visible-always {
  background-color: #f8f9fa66;
  border-radius: 50%;
  height: 24px;
  width: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto 8px auto 16px;
}
@media (max-width: 1280px) {
  .uil-toolbar-list {
    max-width: 112px;
  }
  .uil-toolbar-list li.option-toolicon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    float: right;
  }
  .uil-toolbar-list li.uil-toolicon {
    display: none;
  }
  .uil-toolbar-list li.uil-toolicon.option-toolicon,
  .uil-toolbar-list li.uil-toolicon.uil-toolicon-visible-always {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .uil-toolbar-list
    li.uil-toolicon.uil-toolbar-list-subitem.uil-toolicon-visible-always,
  .uil-toolbar-list
    li.uil-toolicon.uil-toolbar-user-subitem.uil-toolicon-visible-always {
    display: none;
  }
  .uil-toolbar-list.active.userprofile li.uil-toolbar-list-subitem {
    display: none !important;
  }
  .uil-toolbar-list.active li.uil-toolicon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .uil-toolbar-list.active li.uil-toolicon.uil-toolicon-visible-always {
    display: none;
  }
  .uil-toolbar-list.active li.uil-toolicon.uil-toolbar-list-user {
    display: none;
  }
  .uil-toolbar-list.active
    li.uil-toolicon.uil-toolbar-list-user.uil-toolicon-visible-always {
    display: none;
  }
  .uil-toolbar-list.active li.uil-toolicon.uil-toolbar-user-subitem {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .uil-toolbar-list.active
    li.uil-toolicon.uil-toolbar-list-subitem.uil-toolicon-visible-always {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 1023px) {
  .uil-toolbar-list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    max-width: 112px;
  }
}
.uil-toolbar-list.active {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  height: 0;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  margin-top: 68px;
  padding: 8px 0;
  min-width: 320px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1010;
  background: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-toolbar-list.active .uil-toolicon-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
}
.uil-toolbar-list.active li {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
  padding: 8px 16px;
  margin: 0;
  min-height: 40px;
  width: 100%;
  display: none;
}
.uil-toolbar-list.active
  li:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f8f9fa !important;
}
@media (hover: hover) {
  .uil-toolbar-list.active
    li:hover
    .uil-toolbar-list.active
    li:not(.disabled)
    .uil-toolbar-list.active
    li:not([disabled]) {
    background-color: #f8f9fa !important;
  }
}
@media (hover: none) {
  .uil-toolbar-list.active li {
    background-color: transparent !important;
  }
}
.uil-toolbar-list.active li.active {
  background-color: #f8f9fa !important;
}
.uil-toolbar-list.active li.option-toolicon {
  display: none;
}
.uil-toolbar-list.active li.uil-toolbar-list-subitem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
}
.uil-toolbar-list.active li a {
  color: #393a3e;
  width: 100%;
}
.uil-toolbar-list.active li .uil-toolicon-image {
  display: none;
}
.uil-toolbar-list.active li > .glyphicon {
  display: none;
}
.uil-toolbar-list.active.userprofile .uil-toolicon {
  display: none;
}
.uil-toolbar-list.active.userprofile li.uil-toolbar-list-subitem {
  display: none;
}
.uil-toolbar-list.active.userprofile li.uil-toolbar-user-subitem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
}
.uil-toolbar-list.active.userprofile
  li.uil-toolbar-user-subitem
  .uil-toolicon-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-toolicon-visible-always[data-toggle="collapse"]:hover {
  background-color: #f8f9fa66;
  border-radius: 50%;
  height: 24px;
  width: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto 8px auto 16px;
  margin-left: 8px;
}
li.uil-toolicon-visible-always[data-toggle="collapse"]:hover,
li.uil-toolicon[data-toggle="collapse"]:hover {
  margin-left: 16px;
}
.uil-app-menu,
.uil-toolbar-guide-menu {
  border-color: #f1f2f4;
  border-style: solid;
  border-width: 1px;
  margin-left: 0;
  z-index: 1010;
  height: auto;
  max-width: 320px;
  float: right;
  padding: 8px 16px;
  margin-left: 8px;
  text-align: center;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: inline-block;
}
.uil-app-menu a,
.uil-toolbar-guide-menu a {
  padding: 8px 0 0 0 !important;
}
.uil-app-menu.uil-horizontal-nav-withsubmenu,
.uil-toolbar-guide-menu.uil-horizontal-nav-withsubmenu {
  padding-top: 0;
  padding-bottom: 0;
}
.uil-app-menu > .uil-horizontal-nav-submenu,
.uil-toolbar-guide-menu > .uil-horizontal-nav-submenu {
  margin-top: 50px;
  right: 0;
  margin-right: -20px;
}
.uil-app-menu > .uil-horizontal-nav-submenu:hover,
.uil-toolbar-guide-menu > .uil-horizontal-nav-submenu:hover {
  width: 200px;
}
.uil-app-menu
  > .uil-horizontal-nav-submenu:hover
  > .uil-horizontal-nav-submenu-list,
.uil-toolbar-guide-menu
  > .uil-horizontal-nav-submenu:hover
  > .uil-horizontal-nav-submenu-list {
  width: 200px;
}
.uil-app-menu[aria-expanded="true"],
.uil-toolbar-guide-menu[aria-expanded="true"] {
  display: inline-block !important;
  text-align: center;
  z-index: 1010;
}
.uil-app-menu .uil-app-menu-subitem,
.uil-app-menu .uil-guide-menu-subitem,
.uil-toolbar-guide-menu .uil-app-menu-subitem,
.uil-toolbar-guide-menu .uil-guide-menu-subitem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  max-width: 288px !important;
  margin: 0 !important;
  padding: 8px 0 !important;
}
.uil-app-menu .uil-app-menu-title,
.uil-app-menu .uil-guide-menu-title,
.uil-toolbar-guide-menu .uil-app-menu-title,
.uil-toolbar-guide-menu .uil-guide-menu-title {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 1.1;
  padding: 8px 0 !important;
  width: 100%;
}
.uil-app-menu .uil-app-menu-subtitle,
.uil-app-menu .uil-guide-menu-subtitle,
.uil-toolbar-guide-menu .uil-app-menu-subtitle,
.uil-toolbar-guide-menu .uil-guide-menu-subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  font-weight: 300;
  line-height: 1.1;
  padding: 8px 0 !important;
  width: 100%;
}
.uil-toolbar-option-menu,
.uil-toolbar-search-menu {
  border-color: #f1f2f4;
  border-style: solid;
  border-width: 1px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding: 8px 0;
  min-width: 320px;
  max-width: 320px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1010;
  background: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-toolbar-option-menu:hover,
.uil-toolbar-search-menu:hover {
  background: #fff;
}
.uil-toolbar-option-menu ul,
.uil-toolbar-search-menu ul {
  overflow: hidden;
}
.uil-toolbar-option-menu li,
.uil-toolbar-search-menu li {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
  padding: 8px 16px !important;
  margin: 0;
  min-height: 40px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
}
.uil-toolbar-option-menu li:hover:not(.disabled):not(.selected):not([disabled]),
.uil-toolbar-search-menu
  li:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f8f9fa !important;
}
@media (hover: hover) {
  .uil-toolbar-option-menu
    li:hover
    .uil-toolbar-option-menu
    li:not(.disabled)
    .uil-toolbar-option-menu
    li:not([disabled]),
  .uil-toolbar-option-menu
    li:hover
    .uil-toolbar-option-menu
    li:not(.disabled)
    .uil-toolbar-search-menu
    li:not([disabled]),
  .uil-toolbar-option-menu
    li:hover
    .uil-toolbar-search-menu
    li:not(.disabled)
    .uil-toolbar-option-menu
    li:not([disabled]),
  .uil-toolbar-option-menu
    li:hover
    .uil-toolbar-search-menu
    li:not(.disabled)
    .uil-toolbar-search-menu
    li:not([disabled]),
  .uil-toolbar-search-menu
    li:hover
    .uil-toolbar-option-menu
    li:not(.disabled)
    .uil-toolbar-option-menu
    li:not([disabled]),
  .uil-toolbar-search-menu
    li:hover
    .uil-toolbar-option-menu
    li:not(.disabled)
    .uil-toolbar-search-menu
    li:not([disabled]),
  .uil-toolbar-search-menu
    li:hover
    .uil-toolbar-search-menu
    li:not(.disabled)
    .uil-toolbar-option-menu
    li:not([disabled]),
  .uil-toolbar-search-menu
    li:hover
    .uil-toolbar-search-menu
    li:not(.disabled)
    .uil-toolbar-search-menu
    li:not([disabled]) {
    background-color: #f8f9fa !important;
  }
}
@media (hover: none) {
  .uil-toolbar-option-menu li,
  .uil-toolbar-search-menu li {
    background-color: transparent !important;
  }
}
.uil-toolbar-option-menu li.active,
.uil-toolbar-search-menu li.active {
  background-color: #f8f9fa !important;
}
.uil-toolbar-option-menu li a,
.uil-toolbar-search-menu li a {
  width: 320px;
  margin: -8px -16px;
  padding: 8px 16px;
}
.uil-toolbar-option-menu li:not(.uil-toolicon-visible-always),
.uil-toolbar-search-menu li:not(.uil-toolicon-visible-always) {
  display: none !important;
}
@media (max-width: 1280px) {
  .uil-toolbar-option-menu li:not(.uil-toolicon-visible-always),
  .uil-toolbar-search-menu li:not(.uil-toolicon-visible-always) {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}
.uil-toolbar-user-menu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0;
  min-width: 180px;
  max-width: 320px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1010;
  background: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.1);
}
.uil-toolbar-user-menu:hover {
  background: #fff;
}
.uil-toolbar-user-menu .uil-font-icon {
  padding-right: 8px;
}
.uil-toolbar-user-menu .uil-font-icon .uil-icon-user-menu,
.uil-toolbar-user-menu .uil-font-icon .uil-icon-user-menu-item {
  float: right;
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
}
.uil-toolbar-user-menu .uil-font-icon .uil-icon-user-menu-item::before,
.uil-toolbar-user-menu .uil-font-icon .uil-icon-user-menu::before {
  color: #393a3e;
}
.uil-toolbar-user-menu li:first-of-type {
  border-bottom: 1px solid #e8ecee;
}
.uil-toolbar-user-menu li:first-of-type .uil-toolbar-username-item {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  padding: 4px 0 8px;
}
.uil-toolbar-user-menu li {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
  padding: 16px 16px 8px !important;
  margin: 0;
  min-height: 40px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  min-height: 40px;
}
.uil-toolbar-user-menu li:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f8f9fa !important;
}
@media (hover: hover) {
  .uil-toolbar-user-menu
    li:hover
    .uil-toolbar-user-menu
    li:not(.disabled)
    .uil-toolbar-user-menu
    li:not([disabled]) {
    background-color: #f8f9fa !important;
  }
}
@media (hover: none) {
  .uil-toolbar-user-menu li {
    background-color: transparent !important;
  }
}
.uil-toolbar-user-menu li.active {
  background-color: #f8f9fa !important;
}
.uil-toolbar-user-menu li .uil-toolbar-username-item {
  padding: 4px 0 8px;
}
.uil-toolbar-user-menu li:hover {
  background-color: transparent;
}
.uil-toolbar-user-menu li a {
  color: #393a3e;
  width: 100%;
}
.uil-toolbar-search-menu {
  height: 300px;
  max-width: 50%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  left: 0;
  margin-left: 0;
}
.uil-toolbar-search-menu ul {
  overflow: auto;
}
.uil-toolbar-search-menu ul li {
  padding: 0 !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-toolbar-search-menu ul li a {
  margin: 0;
  padding: 8px 16px;
  width: 100%;
}
.uil-toolbar {
  width: auto;
  height: 56px;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1000;
  margin-left: auto;
}
@media (max-width: 1280px) {
  .uil-toolbar {
    width: 144px;
  }
}
.uil-toolbar.search-open {
  width: 100%;
  max-width: calc(100% - 248px - 80px);
  width: calc(100% - 248px - 80px);
}
@media (max-width: 767px) {
  .uil-toolbar.search-open {
    max-width: calc(100% - 24px - 16px - 16px);
    width: calc(100% - 24px - 16px - 16px);
  }
}
.uil-toolbar.search-open .uil-toolbar-list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.uil-toolbar .uil-dropdown .uil-toolbar-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 64px;
  padding: 0;
  margin: 0;
}
.uil-toolbar .uil-dropdown .uil-toolbar-search .uil-toolbar-search-icon {
  margin-bottom: 0 !important;
  margin-top: 8px;
}
.uil-toolbar .uil-dropdown .uil-toolbar-search .uil-toolbar-search-field-close {
  margin-bottom: 10px !important;
}
.uil-toolicon {
  float: right;
  font-size: 22px;
  text-align: center;
  position: relative;
}
@media (max-width: 1280px) {
  .uil-toolicon {
    display: none;
  }
}
.uil-toolicon a {
  padding: 0 !important;
}
.uil-toolicon.uil-horizontal-nav-withsubmenu {
  padding-top: 0;
  padding-bottom: 0;
}
.uil-toolicon > .uil-horizontal-nav-submenu {
  margin-top: 50px;
  right: 0;
  margin-right: -20px;
}
.uil-toolicon > .uil-horizontal-nav-submenu:hover {
  width: 200px;
}
.uil-toolicon
  > .uil-horizontal-nav-submenu:hover
  > .uil-horizontal-nav-submenu-list {
  width: 200px;
}
.uil-card-toolbar .uil-toolbar-search.active .uil-toolbar-search-field-close {
  margin-bottom: 0 !important;
}
.uil-card-toolbar .uil-toolbar-search.active .uil-toolbar-search-field {
  margin-bottom: 0 !important;
}
.uil-toolbar .uil-toolbar-search:hover:not(.active) {
  background-color: #f8f9fa66;
  border-radius: 50%;
  height: 24px;
  width: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto 8px auto 16px;
  margin-left: 8px;
}
.uil-toolbar .uil-toolbar-search.active {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f8f9fa;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  margin-left: 0;
}
.uil-toolbar .uil-toolbar-search.active .uil-toolbar-search-field-close {
  margin-bottom: 0;
  -ms-flex-item-align: center;
  align-self: center;
}
.uil-toolbar .uil-toolbar-search.active .uil-toolbar-search-field {
  margin-bottom: 0 !important;
  width: 100%;
}
.uil-toolbar
  .uil-toolbar-search.active
  .uil-toolbar-search-icon
  .uil-toolicon-image {
  color: #393a3e;
  padding-left: 16px;
  padding-right: 0;
  width: 40px;
}
.uil-toolbar .uil-toolbar-search.active:hover {
  background-color: #f8f9fa66;
}
.uil-toolbar-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 100%;
}
.uil-toolbar-search .uil-toolbar-search-icon {
  margin-bottom: 0;
  cursor: pointer;
}
.uil-toolbar-search.active .uil-toolbar-search-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
}
@media (max-width: 480px) {
  .uil-toolbar-search.active .uil-toolbar-search-field {
    width: 96px;
  }
}
.uil-toolbar-search.active .uil-toolbar-search-field input[type="search"] {
  line-height: 1.4;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-toolbar-search.active
  .uil-toolbar-search-field
  input[type="search"]::-webkit-input-placeholder {
  color: #8b9298;
}
.uil-toolbar-search.active
  .uil-toolbar-search-field
  input[type="search"]:-moz-placeholder {
  color: #8b9298;
}
.uil-toolbar-search.active
  .uil-toolbar-search-field
  input[type="search"]::-moz-placeholder {
  color: #8b9298;
}
.uil-toolbar-search.active
  .uil-toolbar-search-field
  input[type="search"]:-ms-input-placeholder {
  color: #8b9298;
}
.uil-toolbar-search.active
  .uil-toolbar-search-field
  input[type="search"]::-ms-input-placeholder {
  color: #8b9298;
}
.uil-toolbar-search.active .uil-toolbar-search-field-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-toolbar-search.active .uil-toolbar-search-field-close .svg {
  height: 20px;
  width: 20px;
}
.uil-toolbar-search-field {
  display: none;
  background: 0 0;
  margin-left: 8px;
  margin-right: 8px;
}
.uil-toolbar-search-field > input.uil-textbox {
  margin-top: 0;
  border: none;
  background-color: transparent;
}
.uil-toolbar-search-field-close {
  display: none;
  background: 0 0;
  cursor: pointer;
}
.uil-toolicon-title {
  display: none;
}
.uil-toolbar-username-item {
  padding-bottom: 8px;
  min-height: 32px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uil-horizontal-nav-hamburger-area {
  padding-left: 0;
  display: none;
}
.uil-toolicon-hamburger {
  float: left;
  height: 24px;
  width: 24px;
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  background-size: 24px;
  cursor: pointer;
}
.uil-toolicon-hamburger::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393a3e;
  height: 24px;
  width: 24px;
  font-size: 24px;
}
.uil-toolicon-hamburger.uil-horizontal-nav-withsubmenu {
  padding-top: 0;
  padding-bottom: 0;
}
.uil-toolicon-hamburger > .uil-horizontal-nav-submenu {
  margin-top: 50px;
  right: -20px;
  margin-right: 0;
}
.uil-toolicon-hamburger > .uil-horizontal-nav-submenu:hover {
  width: 200px;
}
.uil-toolicon-hamburger
  > .uil-horizontal-nav-submenu:hover
  > .uil-horizontal-nav-submenu-list {
  width: 200px;
}
.svg:hover path {
  fill: var(--primary-01);
}
.uil-toolicon-image {
  display: block;
  border: 0;
  padding: 0 16px;
  width: 56px;
  height: 24px;
  font-size: 1.5rem;
  color: #393a3e;
  cursor: pointer;
  position: relative;
}
.uil-main-nav-footer {
  max-width: 240px;
  z-index: 1000;
  height: 32px;
  width: 100%;
  display: none;
  background-color: #fff;
  position: sticky;
  left: 0;
  bottom: 0;
}
.uil-main-nav-footer.active {
  max-width: 80px;
}
.uil-main-nav-footer.active .mainbar-collapse {
  display: none;
  height: 100%;
}
.uil-main-nav-footer.active .mainbar-open {
  display: block;
}
.uil-main-nav-footer .mainbar-open {
  display: none;
}
.uil-main-nav-footer > .uil-btn,
.uil-main-nav-footer > .uil-login-card-btn {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.uil-main-nav-footer > .uil-btn:hover,
.uil-main-nav-footer > .uil-login-card-btn:hover {
  background: var(--primary-01);
}
.uil-line-indicator {
  height: 4px;
  position: absolute !important;
  background-color: var(--primary-01);
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
}
.uil-line-indicator-wrapper li:not(.disabled) a:hover {
  -webkit-box-shadow: inset 0 -4px 0 var(--primary-01) !important;
  box-shadow: inset 0 -4px 0 var(--primary-01) !important;
}
.uil-icon-more-vertical {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-icon-user-menu {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-icon-user-menu-placeholder-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-side-nav-configurable {
  background: 0 0;
  width: 248px;
  overflow-y: overlay;
  height: 100%;
  padding: 0;
  margin: 0;
}
.uil-side-nav-configurable ul.uil-side-nav-configurable-list {
  list-style: none;
  height: auto;
  padding-left: 0;
  margin-bottom: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item {
  padding-left: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 10px 10px 12px;
  color: #393a3e;
  margin-left: 28px;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a::before {
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  margin-left: calc((12px + 16px) * -1);
  font-size: 16px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a.uil-side-nav-icon {
  padding: 10px 15px 10px 8px;
  margin-left: 32px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a.uil-side-nav-icon::before {
  height: 20px;
  width: 24px;
  margin-top: 0;
  margin-right: 8px;
  margin-left: calc((8px + 24px) * -1);
  font-size: 24px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a:not(.uil-side-nav-icon)[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a:not(.uil-side-nav-icon)[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active
  a:not(.uil-side-nav-icon)::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:hover
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:hover
  a:not(.uil-side-nav-icon)::before {
  color: var(--brand-02);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active.uil-merged-item
  > a {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 4px;
  cursor: default;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active.uil-merged-item
  > a::before {
  content: "";
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active.uil-merged-item
  + ul {
  border-bottom: solid 1px #e8ecee;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:not(.active).uil-merged-item.hasSubmenu
  > a {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 4px;
  cursor: default;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:not(.active).uil-merged-item.hasSubmenu
  > a::before {
  content: "";
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:not(.active).uil-merged-item.hasSubmenu
  + ul {
  border-bottom: solid 1px #e8ecee;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  a {
  padding-left: 6px;
  margin-left: 60px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
  margin-right: 6px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
  margin-right: 6px;
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.active
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.active
  a:not(.uil-side-nav-icon)::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item:hover
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item:hover
  a:not(.uil-side-nav-icon)::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item {
  margin-left: 16px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item
  a {
  padding: 12px 10px 12px 20px;
  margin-left: 44px;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.active
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item:hover
  a {
  padding-left: calc(20px - 2px);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.active
  a {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item
  a {
  padding-left: 20px;
  margin-left: 40px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item.active
  a {
  padding-left: calc(20px - 2px);
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item:hover
  a {
  padding-left: calc(20px - 2px);
  border-left: 2px solid var(--brand-02) !important;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px 10px 14px;
  margin-left: 2px;
  color: #393a3e;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
  -webkit-transition: none;
  transition: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a::before {
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  margin-left: calc((12px + 16px) * -1);
  font-size: 16px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a.uil-side-nav-icon {
  padding: 10px 15px 10px 8px;
  margin-left: 32px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a.uil-side-nav-icon::before {
  height: 20px;
  width: 24px;
  margin-top: 0;
  margin-right: 8px;
  margin-left: calc((8px + 24px) * -1);
  font-size: 24px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a.uil-clickable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: transparent !important;
}
@media (hover: hover) {
  .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable:hover
    .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable:not(.disabled)
    .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable:not([disabled]) {
    background-color: transparent !important;
  }
}
@media (hover: none) {
  .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable {
    background-color: transparent !important;
  }
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.active
  a {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.active
  a:not(.uil-side-nav-icon) {
  padding-left: calc(14px - 4px);
  border-left: 4px solid var(--brand-01) !important;
  border-radius: 0;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.active
  a::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.uil-merged-item {
  border-bottom: solid 1px #e8ecee;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item:hover
  a:not(.uil-side-nav-icon) {
  padding-left: calc(14px - 4px);
  border-left: 4px solid var(--brand-02) !important;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item:hover
  a::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-merged-item.hasSubmenu
  + ul:last-child,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-merged-item:last-child,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.uil-merged-item
  + ul:last-child,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.uil-merged-item.hasSubmenu
  + ul:last-child {
  border-bottom: none;
}
.uil-side-nav {
  min-width: 248px;
  max-width: 248px;
  overflow-y: auto;
  overflow-y: overlay;
  color: #000 !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: #fff !important;
  -webkit-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24),
    0 8px 8px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
}
.uil-side-nav.active {
  min-width: 80px;
  max-width: 80px;
  overflow: initial;
}
.uil-side-nav.active .uil-side-nav-item,
.uil-side-nav.active .uil-side-nav-menu-item {
  height: 44px;
  overflow: hidden;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  white-space: nowrap !important;
}
.uil-side-nav.active .uil-side-nav-item a,
.uil-side-nav.active .uil-side-nav-menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: transparent !important;
  white-space: nowrap !important;
}
.uil-side-nav.active .uil-side-nav-item a[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-item a[aria-expanded="true"]::before,
.uil-side-nav.active .uil-side-nav-menu-item a[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-menu-item a[aria-expanded="true"]::before {
  display: none;
}
.uil-side-nav.active .uil-side-nav-item:focus,
.uil-side-nav.active .uil-side-nav-item:hover,
.uil-side-nav.active .uil-side-nav-menu-item:focus,
.uil-side-nav.active .uil-side-nav-menu-item:hover {
  color: #000 !important;
}
.uil-side-nav.active .uil-side-nav-item[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-item[aria-expanded="true"]::before,
.uil-side-nav.active .uil-side-nav-menu-item[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-menu-item[aria-expanded="true"]::before {
  display: none;
}
.uil-side-nav.without-footer .uil-collapsed-sidemenu-items,
.uil-side-nav.without-footer .uil-side-nav-list {
  height: auto !important;
}
.uil-nav-icon-large {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  font-size: 32px;
  vertical-align: middle;
}
.uil-nav-icon-small {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
  font-size: 24px;
  vertical-align: middle;
}
.uil-nav-icon-footer {
  width: 13px;
  height: 13px;
  min-width: 13px;
  min-height: 13px;
  margin-right: 8px;
  font-size: 13px;
}
.uil-collapsed-sidemenu-items,
.uil-side-nav-list {
  padding-left: 0;
  list-style: none;
  display: block;
  margin-bottom: 0;
  width: 100%;
  padding-top: 0;
  min-height: calc(100% - 32px);
  overflow: auto;
}
.nonactive.uil-collapsed-sidemenu-items,
.uil-side-nav-list.nonactive {
  display: none;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item,
.uil-side-nav-list > .uil-side-nav-content-item {
  width: 100%;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item.active a,
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item:active a,
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item:focus a,
.uil-side-nav-list > .uil-side-nav-content-item.active a,
.uil-side-nav-list > .uil-side-nav-content-item.active a:focus,
.uil-side-nav-list > .uil-side-nav-content-item:active a,
.uil-side-nav-list > .uil-side-nav-content-item:active a:focus,
.uil-side-nav-list > .uil-side-nav-content-item:focus a,
.uil-side-nav-list > .uil-side-nav-content-item:focus a:focus {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
  margin-left: 16px !important;
  padding-left: calc(14px - 4px) !important;
  border-left: 4px solid var(--brand-01) !important;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item:hover a,
.uil-side-nav-list > .uil-side-nav-content-item:hover a {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  margin-left: 16px !important;
  padding-left: calc(14px - 4px) !important;
  border-left: 4px solid var(--brand-01) !important;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item a,
.uil-side-nav-list > .uil-side-nav-content-item a {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  margin-left: 16px !important;
  padding-left: 14px !important;
  width: calc(100% - 16px);
  -webkit-transition: none;
  transition: none;
}
.uil-collapsed-sidemenu-items.collapse {
  display: none;
}
.uil-collapsed-sidemenu-items.collapse.in,
.uil-collapsed-sidemenu-items.collapse.show {
  display: block;
}
nav ul .uil-side-nav-menu-item > a,
nav ul .uil-side-nav-menu-item > a.collapse,
nav ul .uil-side-nav-menu-item > a.collapse.in,
nav ul .uil-side-nav-menu-item > a.collapse.show {
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
nav ul li.uil-side-nav-content-item:hover > a,
nav ul li.uil-side-nav-content-item > a {
  line-height: 20px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
li.uil-side-nav-menu-item {
  min-height: 44px;
  padding-left: 0 !important;
  width: 100%;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  margin-bottom: 0 !important;
  white-space: nowrap !important;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  outline: 0 !important;
  border: none !important;
  padding-left: 0;
  color: #000 !important;
}
li.uil-side-nav-menu-item.with-icon ul {
  margin-left: 6px;
  width: 238px;
}
li.uil-side-nav-menu-item.without-arrow a[aria-expanded="false"]::before,
li.uil-side-nav-menu-item.without-arrow a[aria-expanded="true"]::before,
li.uil-side-nav-menu-item.without-arrow[aria-expanded="false"]::before,
li.uil-side-nav-menu-item.without-arrow[aria-expanded="true"]::before {
  display: none;
}
li.uil-side-nav-menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 10px 10px 12px !important;
  width: calc(100% - 36px);
  min-height: inherit;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-color: transparent !important;
  color: #000 !important;
  -webkit-transition: none;
  transition: none;
  margin-left: 36px;
  margin-right: 0;
  white-space: normal;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-menu-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 12px;
}
li.uil-side-nav-menu-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 12px;
}
li.uil-side-nav-menu-item a:hover::before {
  color: var(--brand-02);
}
li.uil-side-nav-menu-item a[aria-expanded="true"] {
  border: none !important;
}
li.uil-side-nav-menu-item a[data-toggle="collapse"] {
  position: relative;
}
li.uil-side-nav-menu-item a:hover {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-menu-item a:hover.active {
  padding-left: 12px !important;
}
li.uil-side-nav-menu-item a:hover[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-menu-item a span {
  color: #000;
  vertical-align: middle;
  font-size: 24px;
}
li.uil-side-nav-menu-item[data-toggle="collapse"] {
  position: relative;
}
li.uil-side-nav-menu-item[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-menu-item[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-menu-item[aria-expanded="true"] {
  border: none !important;
}
li.uil-side-nav-menu-item.active,
li.uil-side-nav-menu-item:active,
li.uil-side-nav-menu-item:focus,
li.uil-side-nav-menu-item:hover {
  outline: 0 !important;
}
li.uil-side-nav-menu-item.active > .svg path,
li.uil-side-nav-menu-item:active > .svg path,
li.uil-side-nav-menu-item:focus > .svg path,
li.uil-side-nav-menu-item:hover > .svg path {
  fill: var(--primary-01);
}
li.uil-side-nav-menu-item ul {
  width: 100%;
}
li.uil-side-nav-menu-item.disabled,
li.uil-side-nav-menu-item[disabled] {
  color: #c2c7cb;
}
li.uil-side-nav-menu-item.disabled a,
li.uil-side-nav-menu-item[disabled] a {
  color: #c2c7cb !important;
}
li.uil-side-nav-menu-item.active {
  color: #000 !important;
  border: none !important;
  padding-left: 2px;
  background: 0 0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-menu-item.active > a {
  color: #000 !important;
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
  padding-left: 12px;
}
li.uil-side-nav-menu-item.active > a:hover {
  padding-left: 12px !important;
}
li.uil-side-nav-menu-item.active > a[aria-expanded="false"],
li.uil-side-nav-menu-item.active > a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px;
}
li.uil-side-nav-menu-item.active > a div span {
  color: var(--primary-light);
}
li.uil-side-nav-menu-item.active > a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
li.uil-side-nav-menu-item.active > a > .svg path {
  fill: var(--primary-01);
}
.uil-side-nav-item {
  min-height: 44px;
  padding-left: 2px !important;
  width: 100%;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  margin-bottom: 0 !important;
  white-space: nowrap !important;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  outline: 0 !important;
  border: none !important;
  padding-left: 0;
  color: #000 !important;
}
.uil-side-nav-item.with-icon ul {
  margin-left: 6px;
  width: 238px;
}
.uil-side-nav-item.without-arrow a[aria-expanded="false"]::before,
.uil-side-nav-item.without-arrow a[aria-expanded="true"]::before,
.uil-side-nav-item.without-arrow[aria-expanded="false"]::before,
.uil-side-nav-item.without-arrow[aria-expanded="true"]::before {
  display: none;
}
.uil-side-nav-item a {
  padding: 10px 10px 10px 12px !important;
  width: 100%;
  min-height: inherit;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-color: transparent !important;
  color: #000 !important;
  -webkit-transition: none;
  transition: none;
}
.uil-side-nav-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item a[aria-expanded="true"] {
  border: none !important;
}
.uil-side-nav-item a[data-toggle="collapse"] {
  position: relative;
}
.uil-side-nav-item a:hover {
  border-left: 2px solid var(--brand-02) !important;
  border-radius: 0;
  border-radius: 0;
  padding-left: 10px !important;
}
.uil-side-nav-item a:hover.active {
  padding-left: 12px !important;
}
.uil-side-nav-item a:hover[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
.uil-side-nav-item a span {
  color: #000;
  vertical-align: middle;
  font-size: 24px;
}
.uil-side-nav-item[data-toggle="collapse"] {
  position: relative;
}
.uil-side-nav-item[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item[aria-expanded="true"] {
  border: none !important;
}
.uil-side-nav-item.active,
.uil-side-nav-item:active,
.uil-side-nav-item:focus,
.uil-side-nav-item:hover {
  outline: 0 !important;
}
.uil-side-nav-item.active > .svg path,
.uil-side-nav-item:active > .svg path,
.uil-side-nav-item:focus > .svg path,
.uil-side-nav-item:hover > .svg path {
  fill: var(--primary-01);
}
.uil-side-nav-item ul {
  width: 246px;
  margin-left: -2px;
}
.uil-side-nav-item.disabled,
.uil-side-nav-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-item.disabled a,
.uil-side-nav-item[disabled] a {
  color: #c2c7cb !important;
}
.uil-side-nav-item.active {
  color: #000 !important;
  border: none !important;
  padding-left: 2px !important;
  background: 0 0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-item.active a {
  color: #000 !important;
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
  padding-left: 10px !important;
}
.uil-side-nav-item.active a[aria-expanded="false"],
.uil-side-nav-item.active a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
.uil-side-nav-item.active a div span {
  color: var(--primary-light);
}
.uil-side-nav-item.active a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-item.active a > .svg path {
  fill: var(--primary-01);
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item a,
.uil-side-nav-sub-menu-item:not(.active) .uil-side-nav-content-item a {
  margin-left: 64px !important;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:active a,
.uil-side-nav-sub-menu-item:not(.active) .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item:not(.active) .uil-side-nav-content-item:active a {
  padding-left: calc(16px - 2px) !important;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:not(.active) a,
.uil-side-nav-sub-menu-item:not(.active)
  .uil-side-nav-content-item:not(.active)
  a {
  padding-left: 16px !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item.active
  .uil-side-nav-content-item:not(.active):hover
  a,
.uil-side-nav-sub-menu-item:not(.active)
  .uil-side-nav-content-item:not(.active):hover
  a {
  padding-left: calc(16px - 2px) !important;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:hover a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item:hover a {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:active a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item:active a {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item.active:hover a,
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:active:hover a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item.active:hover a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item:active:hover a {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item {
  width: calc(100% - 12px) !important;
  margin-left: 12px !important;
  padding-left: 0;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item a {
  width: calc(100% - 68px) !important;
  padding-left: 16px !important;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item:hover a {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
  padding-left: 16px !important;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item .uil-side-nav-content-item:active a,
.uil-side-nav-sub-menu-item .uil-side-nav-content-item:focus a {
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
  padding-left: 16px !important;
}
li.uil-side-nav-sub-menu-item {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 40px;
  height: auto;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  background: 0 0 !important;
}
li.uil-side-nav-sub-menu-item[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-sub-menu-item[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-sub-menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% - 68px);
  padding-left: 12px !important;
  margin-left: 68px !important;
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
li.uil-side-nav-sub-menu-item a:hover {
  padding-left: calc(12px - 2px) !important;
}
li.uil-side-nav-sub-menu-item a {
  white-space: normal;
}
li.uil-side-nav-sub-menu-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 8px;
}
li.uil-side-nav-sub-menu-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 8px;
}
li.uil-side-nav-sub-menu-item a:hover[aria-expanded="false"] {
  border-left: 2px solid var(--brand-01) !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item a:hover[aria-expanded="true"] {
  border: none !important;
  padding-left: calc(12px - 2px) !important;
}
li.uil-side-nav-sub-menu-item:hover {
  border: none !important;
}
li.uil-side-nav-sub-menu-item:hover a {
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item:hover a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
li.uil-side-nav-sub-menu-item:hover a > .svg path {
  fill: var(--primary-01);
}
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="false"] {
  border-left: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="false"]::before,
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="true"]::before {
  color: var(--brand-02);
}
li.uil-side-nav-sub-menu-item.active,
li.uil-side-nav-sub-menu-item:active,
li.uil-side-nav-sub-menu-item:focus {
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-sub-menu-item.active a,
li.uil-side-nav-sub-menu-item:active a,
li.uil-side-nav-sub-menu-item:focus a {
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  padding-left: 10px !important;
}
li.uil-side-nav-sub-menu-item.active a:focus,
li.uil-side-nav-sub-menu-item:active a:focus,
li.uil-side-nav-sub-menu-item:focus a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-left: 2px solid var(--brand-02) !important;
  border-radius: 0;
}
li.uil-side-nav-sub-menu-item.active a > .svg path,
li.uil-side-nav-sub-menu-item:active a > .svg path,
li.uil-side-nav-sub-menu-item:focus a > .svg path {
  fill: var(--primary-01);
}
li.uil-side-nav-sub-menu-item.active a[aria-expanded="false"],
li.uil-side-nav-sub-menu-item:active a[aria-expanded="false"],
li.uil-side-nav-sub-menu-item:focus a[aria-expanded="false"] {
  border: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item.active a[aria-expanded="true"],
li.uil-side-nav-sub-menu-item:active a[aria-expanded="true"],
li.uil-side-nav-sub-menu-item:focus a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
.uil-side-nav-menu-item > ul > .uil-side-nav-content-item a {
  width: calc(100% - 48px) !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item,
li.uil-side-nav-content-item {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 40px;
  height: auto;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  background: 0 0 !important;
  white-space: initial;
}
.uil-side-nav-menu-item .uil-side-nav-content-item a,
li.uil-side-nav-content-item a {
  border: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 28px !important;
  margin-left: 48px;
  width: calc(100% - 68px);
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item a:hover,
li.uil-side-nav-content-item a:hover {
  padding-left: 12px !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item
  .uil-side-nav-content-item
  a[aria-expanded="false"]::before,
li.uil-side-nav-content-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
.uil-side-nav-menu-item
  .uil-side-nav-content-item
  a[aria-expanded="true"]::before,
li.uil-side-nav-content-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover,
li.uil-side-nav-content-item:hover {
  border: none !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover a,
li.uil-side-nav-content-item:hover a {
  padding-left: calc(28px - 2px) !important;
  border-left: 2px solid var(--brand-02) !important;
  border-radius: 0;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover a:focus,
li.uil-side-nav-content-item:hover a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover a > .svg path,
li.uil-side-nav-content-item:hover a > .svg path {
  fill: var(--primary-01);
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active,
.uil-side-nav-menu-item .uil-side-nav-content-item:active,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus,
li.uil-side-nav-content-item.active,
li.uil-side-nav-content-item:active,
li.uil-side-nav-content-item:focus {
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active:hover a,
.uil-side-nav-menu-item .uil-side-nav-content-item:active:hover a,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus:hover a,
li.uil-side-nav-content-item.active:hover a,
li.uil-side-nav-content-item:active:hover a,
li.uil-side-nav-content-item:focus:hover a {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active a,
.uil-side-nav-menu-item .uil-side-nav-content-item:active a,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus a,
li.uil-side-nav-content-item.active a,
li.uil-side-nav-content-item:active a,
li.uil-side-nav-content-item:focus a {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  padding-left: calc(28px - 2px) !important;
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active a:focus,
.uil-side-nav-menu-item .uil-side-nav-content-item:active a:focus,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus a:focus,
li.uil-side-nav-content-item.active a:focus,
li.uil-side-nav-content-item:active a:focus,
li.uil-side-nav-content-item:focus a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-left: 2px solid var(--brand-02) !important;
  border-radius: 0;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active a > .svg path,
.uil-side-nav-menu-item .uil-side-nav-content-item:active a > .svg path,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus a > .svg path,
li.uil-side-nav-content-item.active a > .svg path,
li.uil-side-nav-content-item:active a > .svg path,
li.uil-side-nav-content-item:focus a > .svg path {
  fill: var(--primary-01);
}
.uil-side-nav-menu-item .uil-side-nav-content-item.disabled,
.uil-side-nav-menu-item .uil-side-nav-content-item[disabled],
li.uil-side-nav-content-item.disabled,
li.uil-side-nav-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.disabled a,
.uil-side-nav-menu-item .uil-side-nav-content-item[disabled] a,
li.uil-side-nav-content-item.disabled a,
li.uil-side-nav-content-item[disabled] a {
  color: #c2c7cb !important;
}
.uil-side-nav-item .uil-side-nav-subitem,
.uil-side-nav-subitem {
  padding-left: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  background: #fff !important;
}
.uil-side-nav-item .uil-side-nav-subitem a,
.uil-side-nav-subitem a {
  margin-left: 12px;
  padding-left: 12px !important;
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-item .uil-side-nav-subitem a:hover,
.uil-side-nav-subitem a:hover {
  padding-left: 12px !important;
}
.uil-side-nav-item .uil-side-nav-subitem:hover,
.uil-side-nav-subitem:hover {
  border: none !important;
}
.uil-side-nav-item .uil-side-nav-subitem:hover a,
.uil-side-nav-subitem:hover a {
  padding-left: 10px !important;
  border-left: 2px solid var(--brand-02) !important;
  border-radius: 0;
}
.uil-side-nav-item .uil-side-nav-subitem:hover a:focus,
.uil-side-nav-subitem:hover a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-item .uil-side-nav-subitem:hover a > .svg path,
.uil-side-nav-subitem:hover a > .svg path {
  fill: var(--primary-01);
}
.uil-side-nav-item .uil-side-nav-subitem.active,
.uil-side-nav-item .uil-side-nav-subitem:active,
.uil-side-nav-item .uil-side-nav-subitem:focus,
.uil-side-nav-subitem.active,
.uil-side-nav-subitem:active,
.uil-side-nav-subitem:focus {
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-item .uil-side-nav-subitem.active a,
.uil-side-nav-item .uil-side-nav-subitem:active a,
.uil-side-nav-item .uil-side-nav-subitem:focus a,
.uil-side-nav-subitem.active a,
.uil-side-nav-subitem:active a,
.uil-side-nav-subitem:focus a {
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  padding-left: 10px !important;
  border-left: 2px solid var(--brand-01) !important;
  border-radius: 0;
}
.uil-side-nav-item .uil-side-nav-subitem.active a:focus,
.uil-side-nav-item .uil-side-nav-subitem:active a:focus,
.uil-side-nav-item .uil-side-nav-subitem:focus a:focus,
.uil-side-nav-subitem.active a:focus,
.uil-side-nav-subitem:active a:focus,
.uil-side-nav-subitem:focus a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-left: 2px solid var(--brand-02) !important;
  border-radius: 0;
}
.uil-side-nav-item .uil-side-nav-subitem.active a > .svg path,
.uil-side-nav-item .uil-side-nav-subitem:active a > .svg path,
.uil-side-nav-item .uil-side-nav-subitem:focus a > .svg path,
.uil-side-nav-subitem.active a > .svg path,
.uil-side-nav-subitem:active a > .svg path,
.uil-side-nav-subitem:focus a > .svg path {
  fill: var(--primary-01);
}
.uil-side-nav .uil-side-nav-footer {
  max-width: 248px;
  height: 32px;
  width: 100%;
  display: block;
  background-color: transparent;
  position: sticky;
  bottom: 0;
  border-radius: 0;
}
.uil-side-nav .uil-side-nav-footer.active {
  max-width: 80px;
}
.uil-side-nav .uil-side-nav-footer.active .sidebar-open {
  display: block;
}
.uil-side-nav .uil-side-nav-footer.active .sidebar-collapse {
  display: none;
}
.uil-side-nav .uil-side-nav-footer .sidebar-open {
  display: none;
}
.sidebarCollapse {
  background: 0 0;
  outline: 0;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  display: block;
  padding-top: 3px;
  padding-bottom: 4px;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
}
.sidebarCollapse:active,
.sidebarCollapse:focus,
.sidebarCollapse:focus:hover,
.sidebarCollapse:hover {
  background: 0 0;
}
.uil-side-nav-menu-item.uil-merged-item a {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item.uil-merged-item a[aria-expanded="true"] {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-single-row-list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}
.uil-expanded-row-list,
.uil-list-multi .uil-row-list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.uil-expanded-row-list > li,
.uil-list-multi .uil-row-list > li {
  min-height: 40px;
}
.uil-expanded-row-list > li .uil-collapse-mainitem,
.uil-expanded-row-list > li a,
.uil-list-multi .uil-row-list > li .uil-collapse-mainitem,
.uil-list-multi .uil-row-list > li > div .uil-list-multi-item-icon-area > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  font-size: var(--font-size-title-lg);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-font-dark);
}
.uil-expandable_list-multi,
.uil-list-multi {
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: var(--color-bg-light);
}
.uil-expandable_list-multi .uil-list_item_collapser,
.uil-list-multi .uil-list_item_collapser {
  cursor: pointer;
  padding-right: 32px;
  position: relative;
}
.uil-expandable_list-multi .uil-list_item_collapser::before,
.uil-expandable_list-multi
  .uil-list_item_collapser[aria-expanded="false"]::before,
.uil-list-multi .uil-list_item_collapser::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 24px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 0;
  margin-right: 4px;
  color: var(--color-font-disabled);
}
.uil-expandable_list-multi
  .uil-list_item_collapser.expanded[aria-expanded="true"]::before,
.uil-list-multi
  .uil-list_item_collapser.expanded[aria-expanded="true"]::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 24px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 0;
  margin-right: 4px;
  color: var(--color-font-disabled);
}
.uil-list-multi .uil-row-list li.uil-list-multi-item.basic {
  line-height: 1.5;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  padding: 24px;
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-expandable_list-multi-item-wrapper.basic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 8px;
  overflow: hidden;
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-expandable_list-multi-item-wrapper
  > .uil-expandable_list-multi-item-icon.basic {
  height: 48px;
  width: 48px;
  font-size: var(--font-size-heading-lg);
  margin-right: 24px;
}
.uil-advanced-banner .uil-card-template .uil-card-title-area .uil-banner-title,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-underline
  .uil-banner-title,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-banner-title,
.uil-card-template .uil-card-title-area .uil-advanced-banner .uil-banner-title,
.uil-card-template .uil-card-title-area .uil-card-title,
.uil-card-template
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-title,
.uil-card-template .uil-card-title-area-underline .uil-card-title,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-title,
.uil-card-template .uil-card-title-area-with-icon .uil-card-title,
.uil-multiline-title {
  font-family: var(--font-family);
  font-size: var(--font-size-title-md);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-font-dark);
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area
  .uil-banner-strapline,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-underline
  .uil-banner-strapline,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-banner-strapline,
.uil-card-template
  .uil-card-title-area
  .uil-advanced-banner
  .uil-banner-strapline,
.uil-card-template .uil-card-title-area .uil-card-strapline,
.uil-card-template
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-strapline,
.uil-card-template .uil-card-title-area-underline .uil-card-strapline,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-strapline,
.uil-card-template .uil-card-title-area-with-icon .uil-card-strapline,
.uil-multiline-list-content {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  font-style: normal;
  padding-top: 8px;
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-font-greyMedium);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area
  .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-underline
  .uil-banner-subtitle,
.uil-advanced-banner
  .uil-card-template
  .uil-card-title-area-with-icon
  .uil-banner-subtitle,
.uil-card-template
  .uil-card-title-area
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template .uil-card-title-area .uil-card-subtitle,
.uil-card-template
  .uil-card-title-area-underline
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template .uil-card-title-area-underline .uil-card-subtitle,
.uil-card-template
  .uil-card-title-area-with-icon
  .uil-advanced-banner
  .uil-banner-subtitle,
.uil-card-template .uil-card-title-area-with-icon .uil-card-subtitle,
.uil-multiline-additionalText {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  color: var(--color-font-greyDark);
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-multiline-description {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3;
  line-height: 20px;
  color: #393a3e;
}
.uil-list-separator {
  padding-left: 8px;
  padding-right: 8px;
  color: var(--color-font-disabled);
  font-size: var(--font-size-title-md);
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-list-multi-item-icon-area {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  gap: 24px;
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-list-multi-item-icon-area
  > *
  :hover {
  color: var(--color-font-primaryLight);
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-list-multi-item-icon-area
  > *
  :hover::before {
  color: var(--color-font-primaryLight);
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item.disabled
  .uil-list-multi-item-icon-area
  > *
  :hover {
  color: var(--color-font-dark);
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item.disabled
  .uil-list-multi-item-icon-area
  > *
  :hover::before {
  color: var(--color-font-dark);
}
.uil-list-icon-share:before {
  font-family: var(--font-family);
  content: "\e9eb";
}
.uil-list-icon-chat:before {
  font-family: var(--font-family);
  content: "\e9c9";
}
.uil-list-icon-heart:before {
  font-family: var(--font-family);
  content: "\ea1c";
}
.uil-list-icon-more-vertical:before {
  font-family: var(--font-family);
  content: "\e9e6";
}
.uil-list-multi-title {
  background: 0 0;
}
.uil-list-multi .uil-row-list > li:hover {
  border-left: 4px solid var(--color-border-primaryLight);
  -webkit-box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
}
.uil-list-multi > li {
  border-left: 4px solid transparent;
}
.uil-list-multi .uil-row-list .uil-list-multi-item {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--color-bg-light);
  border-left: 4px solid transparent;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-expandable_list-multi-item-wrapper
  .uil-expandable_list-multi-item-icon.detailed {
  width: 24px;
  height: 24px;
  font-size: var(--font-size-title-lg);
  margin-right: 16px;
}
.uil-list-multi .uil-row-list li.uil-list-multi-item.detailed {
  line-height: 1.5;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  padding: 24px;
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-expandable_list-multi-item-wrapper.detailed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
.uil-list-multi
  .uil-row-list
  li.uil-list-multi-item.basic.border-enable:not(:last-child) {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(var(--color-bg-disabledDark)),
      to(var(--color-bg-disabledDark))
    )
    bottom/100% 1px no-repeat;
  background: linear-gradient(
      var(--color-bg-disabledDark),
      var(--color-bg-disabledDark)
    )
    bottom/100% 1px no-repeat;
}
.uil-list-multi
  .uil-row-list
  li.uil-list-multi-item.detailed.border-enable:not(:last-child) {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(var(--color-bg-disabledDark)),
      to(var(--color-bg-disabledDark))
    )
    bottom/100% 1px no-repeat;
  background: linear-gradient(
      var(--color-bg-disabledDark),
      var(--color-bg-disabledDark)
    )
    bottom/100% 1px no-repeat;
}
.uil-list-multi .uil-row-list .uil-list-multi-item {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--color-bg-light);
  border-left: 4px solid transparent;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.uil-list-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
}
.uil-list-multi
  .uil-row-list
  .uil-list-multi-item
  .uil-list-multi-item-titlearea {
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-list-multi .uil-row-list li.uil-list-multi-item.basic.disabled {
  cursor: not-allowed;
}
.uil-list-multi .uil-row-list li.uil-list-multi-item.basic.disabled:hover {
  border-left: 4px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-list-multi .uil-row-list li.uil-list-multi-item.detailed.disabled {
  cursor: not-allowed;
}
.uil-list-multi .uil-row-list li.uil-list-multi-item.detailed.disabled:hover {
  border-left: 4px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-tree {
  padding-left: 0;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-tree-sm .uil-tree-row-item {
  font-size: 0.75rem;
  min-height: calc(1rem + 0.5rem);
  height: 100%;
}
.uil-tree-sm .uil-tree-row-item span:last-of-type {
  margin-top: 0.125rem;
}
.uil-tree-sm [class*="uil-dl-icon-"],
.uil-tree-sm [class*="uil-icon-"],
.uil-tree-sm [class^="uil-dl-icon-"],
.uil-tree-sm [class^="uil-icon-"] {
  font-size: 1rem;
}
.uil-tree-sm .uil-tree-row .uil-collapse-content {
  padding: 0;
}
.uil-tree-sm .uil-tree-row > .uil-tree-row-item {
  margin-left: calc(1.5rem - 4px) !important;
}
.uil-tree-md .uil-tree-row-item {
  min-height: calc(1.25rem + 0.5rem);
  height: 100%;
}
.uil-tree-md .uil-tree-row-item span:last-of-type {
  margin-top: 0.25rem;
}
.uil-tree-md [class*="uil-dl-icon-"],
.uil-tree-md [class*="uil-icon-"],
.uil-tree-md [class^="uil-dl-icon-"],
.uil-tree-md [class^="uil-icon-"] {
  font-size: 1.25rem;
}
.uil-tree-md .uil-tree-row .uil-collapse-content {
  padding: 0 0 0 4px;
}
.uil-tree-md .uil-tree-row > .uil-tree-row-item {
  margin-left: 1.5rem !important;
}
.uil-tree-lg .uil-tree-row-item {
  font-size: 1.25rem;
  min-height: calc(1.25rem + 1rem);
  height: 100%;
}
.uil-tree-lg .uil-tree-row-item span:last-of-type {
  margin-top: 0.25rem;
}
.uil-tree-lg [class*="uil-dl-icon-"],
.uil-tree-lg [class*="uil-icon-"],
.uil-tree-lg [class^="uil-dl-icon-"],
.uil-tree-lg [class^="uil-icon-"] {
  font-size: 1.5rem;
}
.uil-tree-lg .uil-tree-row .uil-collapse-content {
  padding: 0 0 0 8px;
}
.uil-tree-lg .uil-tree-row > .uil-tree-row-item {
  margin-left: calc(1.5rem + 4px) !important;
}
.uil-singleselect-tree,
.uil-tree-single-select {
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0;
  padding-bottom: 8px;
}
.uil-multiselect-tree,
.uil-tree-multi-select {
  padding-left: 8px;
  padding-bottom: 8px;
}
.uil-multiselect-tree > div > label,
.uil-tree-multi-select > div > label {
  padding-left: 8px;
}
.uil-multi-select-tree-item,
.uil-multi-select-tree-row,
.uil-tree-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  cursor: pointer;
  padding: 8px 0;
  padding-right: 0 !important;
  padding-left: 8px !important;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.uil-multi-select-tree-item > .uil-checkbox,
.uil-multi-select-tree-row > .uil-checkbox,
.uil-tree-row > .uil-checkbox {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  margin-top: 4px;
}
.uil-multi-select-tree-item > .uil-checkbox label,
.uil-multi-select-tree-row > .uil-checkbox label,
.uil-tree-row > .uil-checkbox label {
  display: block;
  position: absolute;
  bottom: 13px;
  top: 9px;
}
.uil-multi-select-tree-item > .uil-checkbox label.uil-form-item-help-text,
.uil-multi-select-tree-row > .uil-checkbox label.uil-form-item-help-text,
.uil-tree-row > .uil-checkbox label.uil-form-item-help-text {
  top: 16px;
  margin-bottom: 12px;
}
.disabled.uil-multi-select-tree-row,
.disabled.uil-tree-row,
.uil-max-reached.uil-multi-select-tree-row,
.uil-max-reached.uil-tree-row,
.uil-multi-select-tree-item.disabled,
.uil-multi-select-tree-item.uil-max-reached {
  cursor: not-allowed;
  color: #c2c7cb;
  border-left-color: #c2c7cb;
}
.disabled.uil-multi-select-tree-row .uil-checkbox,
.disabled.uil-tree-row .uil-checkbox,
.uil-max-reached.uil-multi-select-tree-row .uil-checkbox,
.uil-max-reached.uil-tree-row .uil-checkbox,
.uil-multi-select-tree-item.disabled .uil-checkbox,
.uil-multi-select-tree-item.uil-max-reached .uil-checkbox {
  cursor: not-allowed;
}
.disabled.uil-multi-select-tree-row input[type="checkbox"],
.disabled.uil-tree-row input[type="checkbox"],
.uil-max-reached.uil-multi-select-tree-row input[type="checkbox"],
.uil-max-reached.uil-tree-row input[type="checkbox"],
.uil-multi-select-tree-item.disabled input[type="checkbox"],
.uil-multi-select-tree-item.uil-max-reached input[type="checkbox"] {
  cursor: not-allowed;
}
.disabled.uil-multi-select-tree-row label,
.disabled.uil-tree-row label,
.uil-max-reached.uil-multi-select-tree-row label,
.uil-max-reached.uil-tree-row label,
.uil-multi-select-tree-item.disabled label,
.uil-multi-select-tree-item.uil-max-reached label {
  cursor: not-allowed;
}
.disabled.uil-multi-select-tree-row a,
.disabled.uil-tree-row a,
.uil-max-reached.uil-multi-select-tree-row a,
.uil-max-reached.uil-tree-row a,
.uil-multi-select-tree-item.disabled a,
.uil-multi-select-tree-item.uil-max-reached a {
  cursor: not-allowed;
  color: #c2c7cb;
}
.uil-multi-select-tree-row,
.uil-tree-row {
  line-height: 24px !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-multi-select-tree-row ul,
.uil-tree-row ul {
  padding-left: 20px;
}
.uil-multi-select-tree-row .uil-collapse-content,
.uil-tree-row .uil-collapse-content {
  padding: 0;
}
.uil-multi-select-tree-row .uil-collapse-content .uil-tree-row-item,
.uil-tree-row .uil-collapse-content .uil-tree-row-item {
  padding: 0;
  margin-left: 0;
}
.uil-multi-select-tree-row
  .uil-collapse-content
  .uil-tree-row-item
  span:first-of-type,
.uil-tree-row .uil-collapse-content .uil-tree-row-item span:first-of-type {
  padding-left: 0;
}
.uil-multi-select-tree-row
  .uil-collapse-content
  .uil-tree-row-item
  span:only-child,
.uil-tree-row .uil-collapse-content .uil-tree-row-item span:only-child {
  padding-left: 0;
  margin-right: 0;
}
.uil-multi-select-tree-row [class*="uil-dl-icon-"],
.uil-multi-select-tree-row [class*="uil-icon-"],
.uil-multi-select-tree-row [class^="uil-dl-icon-"],
.uil-multi-select-tree-row [class^="uil-icon-"],
.uil-tree-row [class*="uil-dl-icon-"],
.uil-tree-row [class*="uil-icon-"],
.uil-tree-row [class^="uil-dl-icon-"],
.uil-tree-row [class^="uil-icon-"] {
  margin-right: 4px;
}
.uil-multi-select-tree-row .uil-tree-row-item,
.uil-tree-row .uil-tree-row-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-multi-select-tree-row .uil-tree-row-item.active,
.uil-tree-row .uil-tree-row-item.active {
  background-color: #f8f9fa;
  font-weight: 500;
}
.uil-multi-select-tree-row .collapse.in.show .uil-tree-row,
.uil-tree-row .collapse.in.show .uil-tree-row {
  height: 100%;
}
.uil-tree-row > .uil-tree-row-item {
  padding: 0 !important;
  margin-left: 1.5rem !important;
}
.uil-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 8px;
  background-color: var(--color-bg-light);
  color: var(--color-font-dark);
  height: 32px;
  border: 1px solid var(--color-border-default);
  border-radius: 0;
}
.uil-tag .uil-chip-close-icon,
.uil-tag .uil-dl-icon-Close,
.uil-tag .uil-icon-close_icon,
.uil-tag .uil-status-card-close-icon,
.uil-tag .uil-tag-text {
  color: var(--color-font-dark);
}
.uil-tag:focus,
.uil-tag:hover {
  border: 1px solid var(--color-border-primaryLight);
}
.uil-tag:focus .uil-chip-close-icon,
.uil-tag:focus .uil-dl-icon-Close,
.uil-tag:focus .uil-icon-close_icon,
.uil-tag:focus .uil-status-card-close-icon,
.uil-tag:focus .uil-tag-text,
.uil-tag:hover .uil-chip-close-icon,
.uil-tag:hover .uil-dl-icon-Close,
.uil-tag:hover .uil-icon-close_icon,
.uil-tag:hover .uil-status-card-close-icon,
.uil-tag:hover .uil-tag-text {
  color: var(--color-border-primaryLight);
}
.uil-tag.disabled,
.uil-tag[disabled],
fieldset[disabled] .uil-tag {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
  cursor: not-allowed;
}
.uil-tag.disabled .uil-chip-close-icon,
.uil-tag.disabled .uil-dl-icon-Close,
.uil-tag.disabled .uil-icon-close_icon,
.uil-tag.disabled .uil-status-card-close-icon,
.uil-tag.disabled .uil-tag-text,
.uil-tag[disabled] .uil-chip-close-icon,
.uil-tag[disabled] .uil-dl-icon-Close,
.uil-tag[disabled] .uil-icon-close_icon,
.uil-tag[disabled] .uil-status-card-close-icon,
.uil-tag[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag .uil-chip-close-icon,
fieldset[disabled] .uil-tag .uil-dl-icon-Close,
fieldset[disabled] .uil-tag .uil-icon-close_icon,
fieldset[disabled] .uil-tag .uil-status-card-close-icon,
fieldset[disabled] .uil-tag .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.disabled:hover,
.uil-tag[disabled]:hover,
fieldset[disabled]:hover .uil-tag {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-success {
  background-color: #008731;
}
.uil-tag.uil-type-success .uil-chip-close-icon,
.uil-tag.uil-type-success .uil-dl-icon-Close,
.uil-tag.uil-type-success .uil-icon-close_icon,
.uil-tag.uil-type-success .uil-status-card-close-icon,
.uil-tag.uil-type-success .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-success:focus .uil-chip-close-icon,
.uil-tag.uil-type-success:focus .uil-dl-icon-Close,
.uil-tag.uil-type-success:focus .uil-icon-close_icon,
.uil-tag.uil-type-success:focus .uil-status-card-close-icon,
.uil-tag.uil-type-success:focus .uil-tag-text,
.uil-tag.uil-type-success:hover .uil-chip-close-icon,
.uil-tag.uil-type-success:hover .uil-dl-icon-Close,
.uil-tag.uil-type-success:hover .uil-icon-close_icon,
.uil-tag.uil-type-success:hover .uil-status-card-close-icon,
.uil-tag.uil-type-success:hover .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-success.disabled,
.uil-tag.uil-type-success[disabled],
fieldset[disabled] .uil-tag.uil-type-success {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-success.disabled .uil-chip-close-icon,
.uil-tag.uil-type-success.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-success.disabled .uil-icon-close_icon,
.uil-tag.uil-type-success.disabled .uil-status-card-close-icon,
.uil-tag.uil-type-success.disabled .uil-tag-text,
.uil-tag.uil-type-success[disabled] .uil-chip-close-icon,
.uil-tag.uil-type-success[disabled] .uil-dl-icon-Close,
.uil-tag.uil-type-success[disabled] .uil-icon-close_icon,
.uil-tag.uil-type-success[disabled] .uil-status-card-close-icon,
.uil-tag.uil-type-success[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-success .uil-chip-close-icon,
fieldset[disabled] .uil-tag.uil-type-success .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-success .uil-icon-close_icon,
fieldset[disabled] .uil-tag.uil-type-success .uil-status-card-close-icon,
fieldset[disabled] .uil-tag.uil-type-success .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-primary {
  background-color: var(--color-bg-primaryLight);
}
.uil-tag.uil-type-primary .uil-chip-close-icon,
.uil-tag.uil-type-primary .uil-dl-icon-Close,
.uil-tag.uil-type-primary .uil-icon-close_icon,
.uil-tag.uil-type-primary .uil-status-card-close-icon,
.uil-tag.uil-type-primary .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-primary:focus .uil-chip-close-icon,
.uil-tag.uil-type-primary:focus .uil-dl-icon-Close,
.uil-tag.uil-type-primary:focus .uil-icon-close_icon,
.uil-tag.uil-type-primary:focus .uil-status-card-close-icon,
.uil-tag.uil-type-primary:focus .uil-tag-text,
.uil-tag.uil-type-primary:hover .uil-chip-close-icon,
.uil-tag.uil-type-primary:hover .uil-dl-icon-Close,
.uil-tag.uil-type-primary:hover .uil-icon-close_icon,
.uil-tag.uil-type-primary:hover .uil-status-card-close-icon,
.uil-tag.uil-type-primary:hover .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-primary.disabled,
.uil-tag.uil-type-primary[disabled],
fieldset[disabled] .uil-tag.uil-type-primary {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-primary.disabled .uil-chip-close-icon,
.uil-tag.uil-type-primary.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-primary.disabled .uil-icon-close_icon,
.uil-tag.uil-type-primary.disabled .uil-status-card-close-icon,
.uil-tag.uil-type-primary.disabled .uil-tag-text,
.uil-tag.uil-type-primary[disabled] .uil-chip-close-icon,
.uil-tag.uil-type-primary[disabled] .uil-dl-icon-Close,
.uil-tag.uil-type-primary[disabled] .uil-icon-close_icon,
.uil-tag.uil-type-primary[disabled] .uil-status-card-close-icon,
.uil-tag.uil-type-primary[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-primary .uil-chip-close-icon,
fieldset[disabled] .uil-tag.uil-type-primary .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-primary .uil-icon-close_icon,
fieldset[disabled] .uil-tag.uil-type-primary .uil-status-card-close-icon,
fieldset[disabled] .uil-tag.uil-type-primary .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-secondary {
  background-color: var(--color-bg-light);
  border: 1px solid var(--color-border-dark);
}
.uil-tag.uil-type-secondary .uil-chip-close-icon,
.uil-tag.uil-type-secondary .uil-dl-icon-Close,
.uil-tag.uil-type-secondary .uil-icon-close_icon,
.uil-tag.uil-type-secondary .uil-status-card-close-icon,
.uil-tag.uil-type-secondary .uil-tag-text {
  color: var(--color-font-dark);
}
.uil-tag.uil-type-secondary:focus,
.uil-tag.uil-type-secondary:hover {
  border: 1px solid var(--color-bg-primaryLight);
}
.uil-tag.uil-type-secondary:focus .uil-chip-close-icon,
.uil-tag.uil-type-secondary:focus .uil-dl-icon-Close,
.uil-tag.uil-type-secondary:focus .uil-icon-close_icon,
.uil-tag.uil-type-secondary:focus .uil-status-card-close-icon,
.uil-tag.uil-type-secondary:focus .uil-tag-text,
.uil-tag.uil-type-secondary:hover .uil-chip-close-icon,
.uil-tag.uil-type-secondary:hover .uil-dl-icon-Close,
.uil-tag.uil-type-secondary:hover .uil-icon-close_icon,
.uil-tag.uil-type-secondary:hover .uil-status-card-close-icon,
.uil-tag.uil-type-secondary:hover .uil-tag-text {
  color: var(--color-font-primaryLight);
}
.uil-tag.uil-type-secondary.disabled {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-secondary.disabled .uil-chip-close-icon,
.uil-tag.uil-type-secondary.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-secondary.disabled .uil-icon-close_icon,
.uil-tag.uil-type-secondary.disabled .uil-status-card-close-icon,
.uil-tag.uil-type-secondary.disabled .uil-tag-text {
  color: var(--color-font-disabled);
  background: 0 0;
  cursor: not-allowed;
}
.uil-tag.uil-type-selected {
  border: 1px solid var(--color-border-primaryLight);
}
.uil-tag.uil-type-selected:is(.disabled) {
  border: 1px solid var(--color-border-default);
}
.uil-tag.uil-type-warning {
  background-color: #ff9f00;
}
.uil-tag.uil-type-warning .uil-chip-close-icon,
.uil-tag.uil-type-warning .uil-dl-icon-Close,
.uil-tag.uil-type-warning .uil-icon-close_icon,
.uil-tag.uil-type-warning .uil-status-card-close-icon,
.uil-tag.uil-type-warning .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-warning:focus .uil-chip-close-icon,
.uil-tag.uil-type-warning:focus .uil-dl-icon-Close,
.uil-tag.uil-type-warning:focus .uil-icon-close_icon,
.uil-tag.uil-type-warning:focus .uil-status-card-close-icon,
.uil-tag.uil-type-warning:focus .uil-tag-text,
.uil-tag.uil-type-warning:hover .uil-chip-close-icon,
.uil-tag.uil-type-warning:hover .uil-dl-icon-Close,
.uil-tag.uil-type-warning:hover .uil-icon-close_icon,
.uil-tag.uil-type-warning:hover .uil-status-card-close-icon,
.uil-tag.uil-type-warning:hover .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-warning.disabled,
.uil-tag.uil-type-warning[disabled],
fieldset[disabled] .uil-tag.uil-type-warning {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-warning.disabled .uil-chip-close-icon,
.uil-tag.uil-type-warning.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-warning.disabled .uil-icon-close_icon,
.uil-tag.uil-type-warning.disabled .uil-status-card-close-icon,
.uil-tag.uil-type-warning.disabled .uil-tag-text,
.uil-tag.uil-type-warning[disabled] .uil-chip-close-icon,
.uil-tag.uil-type-warning[disabled] .uil-dl-icon-Close,
.uil-tag.uil-type-warning[disabled] .uil-icon-close_icon,
.uil-tag.uil-type-warning[disabled] .uil-status-card-close-icon,
.uil-tag.uil-type-warning[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-warning .uil-chip-close-icon,
fieldset[disabled] .uil-tag.uil-type-warning .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-warning .uil-icon-close_icon,
fieldset[disabled] .uil-tag.uil-type-warning .uil-status-card-close-icon,
fieldset[disabled] .uil-tag.uil-type-warning .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-error {
  background-color: #ff2825;
}
.uil-tag.uil-type-error .uil-chip-close-icon,
.uil-tag.uil-type-error .uil-dl-icon-Close,
.uil-tag.uil-type-error .uil-icon-close_icon,
.uil-tag.uil-type-error .uil-status-card-close-icon,
.uil-tag.uil-type-error .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-error:focus .uil-chip-close-icon,
.uil-tag.uil-type-error:focus .uil-dl-icon-Close,
.uil-tag.uil-type-error:focus .uil-icon-close_icon,
.uil-tag.uil-type-error:focus .uil-status-card-close-icon,
.uil-tag.uil-type-error:focus .uil-tag-text,
.uil-tag.uil-type-error:hover .uil-chip-close-icon,
.uil-tag.uil-type-error:hover .uil-dl-icon-Close,
.uil-tag.uil-type-error:hover .uil-icon-close_icon,
.uil-tag.uil-type-error:hover .uil-status-card-close-icon,
.uil-tag.uil-type-error:hover .uil-tag-text {
  color: var(--color-font-light);
}
.uil-tag.uil-type-error.disabled,
.uil-tag.uil-type-error[disabled],
fieldset[disabled] .uil-tag.uil-type-error {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-error.disabled .uil-chip-close-icon,
.uil-tag.uil-type-error.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-error.disabled .uil-icon-close_icon,
.uil-tag.uil-type-error.disabled .uil-status-card-close-icon,
.uil-tag.uil-type-error.disabled .uil-tag-text,
.uil-tag.uil-type-error[disabled] .uil-chip-close-icon,
.uil-tag.uil-type-error[disabled] .uil-dl-icon-Close,
.uil-tag.uil-type-error[disabled] .uil-icon-close_icon,
.uil-tag.uil-type-error[disabled] .uil-status-card-close-icon,
.uil-tag.uil-type-error[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-error .uil-chip-close-icon,
fieldset[disabled] .uil-tag.uil-type-error .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-error .uil-icon-close_icon,
fieldset[disabled] .uil-tag.uil-type-error .uil-status-card-close-icon,
fieldset[disabled] .uil-tag.uil-type-error .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-type-info {
  color: #004dff;
  background-color: rgba(0, 77, 255, 0.1490196078);
}
.uil-tag.uil-type-info .uil-chip-close-icon,
.uil-tag.uil-type-info .uil-dl-icon-Close,
.uil-tag.uil-type-info .uil-icon-close_icon,
.uil-tag.uil-type-info .uil-status-card-close-icon,
.uil-tag.uil-type-info .uil-tag-text {
  color: #004dff;
}
.uil-tag.uil-type-info:focus .uil-chip-close-icon,
.uil-tag.uil-type-info:focus .uil-dl-icon-Close,
.uil-tag.uil-type-info:focus .uil-icon-close_icon,
.uil-tag.uil-type-info:focus .uil-status-card-close-icon,
.uil-tag.uil-type-info:focus .uil-tag-text,
.uil-tag.uil-type-info:hover .uil-chip-close-icon,
.uil-tag.uil-type-info:hover .uil-dl-icon-Close,
.uil-tag.uil-type-info:hover .uil-icon-close_icon,
.uil-tag.uil-type-info:hover .uil-status-card-close-icon,
.uil-tag.uil-type-info:hover .uil-tag-text {
  color: #004dff;
}
.uil-tag.uil-type-info.disabled,
.uil-tag.uil-type-info[disabled],
fieldset[disabled] .uil-tag.uil-type-info {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag.uil-type-info.disabled .uil-chip-close-icon,
.uil-tag.uil-type-info.disabled .uil-dl-icon-Close,
.uil-tag.uil-type-info.disabled .uil-icon-close_icon,
.uil-tag.uil-type-info.disabled .uil-status-card-close-icon,
.uil-tag.uil-type-info.disabled .uil-tag-text,
.uil-tag.uil-type-info[disabled] .uil-chip-close-icon,
.uil-tag.uil-type-info[disabled] .uil-dl-icon-Close,
.uil-tag.uil-type-info[disabled] .uil-icon-close_icon,
.uil-tag.uil-type-info[disabled] .uil-status-card-close-icon,
.uil-tag.uil-type-info[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag.uil-type-info .uil-chip-close-icon,
fieldset[disabled] .uil-tag.uil-type-info .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-type-info .uil-icon-close_icon,
fieldset[disabled] .uil-tag.uil-type-info .uil-status-card-close-icon,
fieldset[disabled] .uil-tag.uil-type-info .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-tag-link {
  background-color: transparent;
  border: none;
}
.uil-tag.uil-tag-link .uil-chip-close-icon,
.uil-tag.uil-tag-link .uil-dl-icon-Close,
.uil-tag.uil-tag-link .uil-icon-close_icon,
.uil-tag.uil-tag-link .uil-status-card-close-icon,
.uil-tag.uil-tag-link .uil-tag-text {
  color: var(--color-font-primaryLight);
}
.uil-tag.uil-tag-link:focus .uil-chip-close-icon,
.uil-tag.uil-tag-link:focus .uil-dl-icon-Close,
.uil-tag.uil-tag-link:focus .uil-icon-close_icon,
.uil-tag.uil-tag-link:focus .uil-status-card-close-icon,
.uil-tag.uil-tag-link:focus .uil-tag-text,
.uil-tag.uil-tag-link:hover .uil-chip-close-icon,
.uil-tag.uil-tag-link:hover .uil-dl-icon-Close,
.uil-tag.uil-tag-link:hover .uil-icon-close_icon,
.uil-tag.uil-tag-link:hover .uil-status-card-close-icon,
.uil-tag.uil-tag-link:hover .uil-tag-text {
  color: var(--color-font-primaryLight);
}
.uil-tag.uil-tag-link.disabled,
.uil-tag.uil-tag-link[disabled],
fieldset[disabled] .uil-tag.uil-tag-link {
  border: none;
  background: 0 0;
}
.uil-tag.uil-tag-link.disabled .uil-chip-close-icon,
.uil-tag.uil-tag-link.disabled .uil-dl-icon-Close,
.uil-tag.uil-tag-link.disabled .uil-icon-close_icon,
.uil-tag.uil-tag-link.disabled .uil-status-card-close-icon,
.uil-tag.uil-tag-link.disabled .uil-tag-text,
.uil-tag.uil-tag-link[disabled] .uil-chip-close-icon,
.uil-tag.uil-tag-link[disabled] .uil-dl-icon-Close,
.uil-tag.uil-tag-link[disabled] .uil-icon-close_icon,
.uil-tag.uil-tag-link[disabled] .uil-status-card-close-icon,
.uil-tag.uil-tag-link[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag.uil-tag-link .uil-chip-close-icon,
fieldset[disabled] .uil-tag.uil-tag-link .uil-dl-icon-Close,
fieldset[disabled] .uil-tag.uil-tag-link .uil-icon-close_icon,
fieldset[disabled] .uil-tag.uil-tag-link .uil-status-card-close-icon,
fieldset[disabled] .uil-tag.uil-tag-link .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag.uil-tag-link.disabled:hover,
.uil-tag.uil-tag-link[disabled]:hover,
fieldset[disabled]:hover .uil-tag.uil-tag-link {
  border: none;
  background: 0 0;
}
.uil-tag.fullwidth {
  width: 100%;
}
.uil-tag.uil-size-small {
  height: 24px;
  width: 50px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}
.uil-tag.uil-size-small > .uil-chip-close-icon:before,
.uil-tag.uil-size-small > .uil-dl-icon-Close:before,
.uil-tag.uil-size-small > .uil-icon-close_icon:before,
.uil-tag.uil-size-small > .uil-status-card-close-icon:before {
  font-size: 10px;
}
.uil-tag.uil-size-small .uil-tag-text {
  font-size: 10px;
}
.uil-tag.uil-size-medium {
  height: 32px;
  width: 65px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}
.uil-tag.uil-size-medium > .uil-chip-close-icon:before,
.uil-tag.uil-size-medium > .uil-dl-icon-Close:before,
.uil-tag.uil-size-medium > .uil-icon-close_icon:before,
.uil-tag.uil-size-medium > .uil-status-card-close-icon:before {
  font-size: var(--font-size-caption-xs);
}
.uil-tag.uil-size-medium .uil-tag-text {
  font-size: var(--font-size-caption-xs);
}
.uil-tag.uil-size-large {
  height: 48px;
  width: 75px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}
.uil-tag.uil-size-large > .uil-chip-close-icon:before,
.uil-tag.uil-size-large > .uil-dl-icon-Close:before,
.uil-tag.uil-size-large > .uil-icon-close_icon:before,
.uil-tag.uil-size-large > .uil-status-card-close-icon:before {
  font-size: var(--font-size-body-sm);
}
.uil-tag.uil-size-large .uil-tag-text {
  font-size: var(--font-size-body-sm);
}
.uil-tag-link.uil-type-secondary:focus,
.uil-tag-link.uil-type-secondary:hover {
  border: none;
}
.uil-tag-text {
  width: auto;
  height: 20px;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: var(--color-font-dark);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uil-tag-text:hover {
  cursor: pointer;
}

.uil-chip-close-icon:before:focus .uil-chip-close-icon:before:hover,
.uil-chip-close-icon:before:focus .uil-dl-icon-Close:before:hover,
.uil-chip-close-icon:before:focus .uil-icon-close_icon:before:hover,
.uil-chip-close-icon:before:focus .uil-status-card-close-icon:before:hover,
.uil-dl-icon-Close:before:focus .uil-chip-close-icon:before:hover,
.uil-dl-icon-Close:before:focus .uil-dl-icon-Close:before:hover,
.uil-dl-icon-Close:before:focus .uil-icon-close_icon:before:hover,
.uil-dl-icon-Close:before:focus .uil-status-card-close-icon:before:hover,
.uil-icon-close_icon:before:focus .uil-chip-close-icon:before:hover,
.uil-icon-close_icon:before:focus .uil-dl-icon-Close:before:hover,
.uil-icon-close_icon:before:focus .uil-icon-close_icon:before:hover,
.uil-icon-close_icon:before:focus .uil-status-card-close-icon:before:hover,
.uil-status-card-close-icon:before:focus .uil-chip-close-icon:before:hover,
.uil-status-card-close-icon:before:focus .uil-dl-icon-Close:before:hover,
.uil-status-card-close-icon:before:focus .uil-icon-close_icon:before:hover,
.uil-status-card-close-icon:before:focus
  .uil-status-card-close-icon:before:hover {
  cursor: pointer;
  color: var(--color-font-primaryLight);
}
.uil-chip-close-icon:before.disabled,
.uil-chip-close-icon:before[disabled],
.uil-dl-icon-Close:before.disabled,
.uil-dl-icon-Close:before[disabled],
.uil-icon-close_icon:before.disabled,
.uil-icon-close_icon:before[disabled],
.uil-status-card-close-icon:before.disabled,
.uil-status-card-close-icon:before[disabled],
fieldset[disabled] .uil-chip-close-icon:before,
fieldset[disabled] .uil-dl-icon-Close:before,
fieldset[disabled] .uil-icon-close_icon:before,
fieldset[disabled] .uil-status-card-close-icon:before {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag-square {
  border-radius: 0;
  color: var(--color-font-dark);
  border-color: var(--color-border-default);
}
.uil-tag-square.active,
.uil-tag-square:active,
.uil-tag-square:focus:active,
.uil-tag-square:focus:hover:active,
.uil-tag-square:hover:active {
  border: 1px solid var(--color-font-primaryLight);
}
.uil-tag-square:focus .uil-chip-close-icon,
.uil-tag-square:focus .uil-dl-icon-Close,
.uil-tag-square:focus .uil-icon-close_icon,
.uil-tag-square:focus .uil-status-card-close-icon,
.uil-tag-square:focus .uil-tag-text,
.uil-tag-square:hover .uil-chip-close-icon,
.uil-tag-square:hover .uil-dl-icon-Close,
.uil-tag-square:hover .uil-icon-close_icon,
.uil-tag-square:hover .uil-status-card-close-icon,
.uil-tag-square:hover .uil-tag-text {
  color: var(--color-font-primaryLight);
}
.uil-tag-square.disabled,
.uil-tag-square[disabled],
fieldset[disabled] .uil-tag-square {
  border: 1px solid var(--color-border-default);
}
.uil-tag-square.disabled .uil-chip-close-icon,
.uil-tag-square.disabled .uil-dl-icon-Close,
.uil-tag-square.disabled .uil-icon-close_icon,
.uil-tag-square.disabled .uil-status-card-close-icon,
.uil-tag-square.disabled .uil-tag-text,
.uil-tag-square[disabled] .uil-chip-close-icon,
.uil-tag-square[disabled] .uil-dl-icon-Close,
.uil-tag-square[disabled] .uil-icon-close_icon,
.uil-tag-square[disabled] .uil-status-card-close-icon,
.uil-tag-square[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag-square .uil-chip-close-icon,
fieldset[disabled] .uil-tag-square .uil-dl-icon-Close,
fieldset[disabled] .uil-tag-square .uil-icon-close_icon,
fieldset[disabled] .uil-tag-square .uil-status-card-close-icon,
fieldset[disabled] .uil-tag-square .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag-square.disabled:hover,
.uil-tag-square[disabled]:hover,
fieldset[disabled]:hover .uil-tag-square {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag-round {
  border-radius: 35px;
  border-color: var(--color-border-default);
  padding: 8px 16px;
}
.uil-tag-round .uil-chip-close-icon,
.uil-tag-round .uil-dl-icon-Close,
.uil-tag-round .uil-icon-close_icon,
.uil-tag-round .uil-status-card-close-icon,
.uil-tag-round .uil-tag-text {
  color: var(--color-font-dark);
}
.uil-tag-round.active,
.uil-tag-round:active,
.uil-tag-round:focus:active,
.uil-tag-round:focus:hover:active,
.uil-tag-round:hover:active {
  border: 1px solid var(--color-font-primaryLight);
}
.uil-tag-round:focus .uil-chip-close-icon,
.uil-tag-round:focus .uil-dl-icon-Close,
.uil-tag-round:focus .uil-icon-close_icon,
.uil-tag-round:focus .uil-status-card-close-icon,
.uil-tag-round:focus .uil-tag-text,
.uil-tag-round:hover .uil-chip-close-icon,
.uil-tag-round:hover .uil-dl-icon-Close,
.uil-tag-round:hover .uil-icon-close_icon,
.uil-tag-round:hover .uil-status-card-close-icon,
.uil-tag-round:hover .uil-tag-text {
  color: var(--color-font-primaryLight);
}
.uil-tag-round.disabled,
.uil-tag-round[disabled],
fieldset[disabled] .uil-tag-round {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-tag-round.disabled .uil-chip-close-icon,
.uil-tag-round.disabled .uil-dl-icon-Close,
.uil-tag-round.disabled .uil-icon-close_icon,
.uil-tag-round.disabled .uil-status-card-close-icon,
.uil-tag-round.disabled .uil-tag-text,
.uil-tag-round[disabled] .uil-chip-close-icon,
.uil-tag-round[disabled] .uil-dl-icon-Close,
.uil-tag-round[disabled] .uil-icon-close_icon,
.uil-tag-round[disabled] .uil-status-card-close-icon,
.uil-tag-round[disabled] .uil-tag-text,
fieldset[disabled] .uil-tag-round .uil-chip-close-icon,
fieldset[disabled] .uil-tag-round .uil-dl-icon-Close,
fieldset[disabled] .uil-tag-round .uil-icon-close_icon,
fieldset[disabled] .uil-tag-round .uil-status-card-close-icon,
fieldset[disabled] .uil-tag-round .uil-tag-text {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-tag-round.disabled:hover,
.uil-tag-round[disabled]:hover,
fieldset[disabled]:hover .uil-tag-round {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-bg-light);
}
.uil-chip-tree {
  overflow-y: initial !important;
  overflow-x: initial !important;
}
.uil-chip,
.uil-chip-colored,
.uil-chip-colored.active,
.uil-chip-default,
.uil-chip-filled,
.uil-chip-opaque {
  position: relative;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0;
  border: solid 1px #ccd1d7;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 8px;
  padding: 4px 4px 4px 8px !important;
  background-color: #fff;
  color: #393a3e;
  overflow: visible;
}
.active.uil-chip-colored,
.active.uil-chip-default,
.active.uil-chip-filled,
.active.uil-chip-opaque,
.uil-chip.active {
  background-color: #fff !important;
  color: #393a3e;
  -webkit-box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: solid 1px var(--primary-01);
  margin: 8px;
}
.active.uil-chip-colored .uil-chip-icon,
.active.uil-chip-default .uil-chip-icon,
.active.uil-chip-filled .uil-chip-icon,
.active.uil-chip-opaque .uil-chip-icon,
.uil-chip.active .uil-chip-icon {
  color: var(--primary-01);
  visibility: visible;
}
.uil-chip .uil-chip-icon,
.uil-chip-colored .uil-chip-icon,
.uil-chip-default .uil-chip-icon,
.uil-chip-filled .uil-chip-icon,
.uil-chip-opaque .uil-chip-icon {
  visibility: hidden;
}
.uil-chip .uil-chip-info-icon,
.uil-chip-colored .uil-chip-info-icon,
.uil-chip-default .uil-chip-info-icon,
.uil-chip-filled .uil-chip-info-icon,
.uil-chip-opaque .uil-chip-info-icon {
  visibility: visible;
}
.uil-chip-colored:not(.active):hover .uil-chip-icon,
.uil-chip-default:not(.active):hover .uil-chip-icon,
.uil-chip-filled:not(.active):hover .uil-chip-icon,
.uil-chip-opaque:not(.active):hover .uil-chip-icon,
.uil-chip:not(.active):hover .uil-chip-icon {
  visibility: visible;
}
.uil-chip-colored:not(.active):hover .uil-chip-info-icon,
.uil-chip-default:not(.active):hover .uil-chip-info-icon,
.uil-chip-filled:not(.active):hover .uil-chip-info-icon,
.uil-chip-opaque:not(.active):hover .uil-chip-info-icon,
.uil-chip:not(.active):hover .uil-chip-info-icon {
  visibility: visible;
}
.uil-chip .uil-font-icon + .uil-font-icon,
.uil-chip-colored .uil-font-icon + .uil-font-icon,
.uil-chip-default .uil-font-icon + .uil-font-icon,
.uil-chip-filled .uil-font-icon + .uil-font-icon,
.uil-chip-opaque .uil-font-icon + .uil-font-icon {
  margin: 0 2px;
}
.disabled.uil-chip-colored,
.disabled.uil-chip-default,
.disabled.uil-chip-filled,
.disabled.uil-chip-opaque,
.uil-chip.disabled,
.uil-chip.disabled:hover {
  cursor: not-allowed;
  color: #c2c7cb;
  border: solid 1px #ccd1d7;
}
.disabled.uil-chip-colored .uil-chip-icon,
.disabled.uil-chip-default .uil-chip-icon,
.disabled.uil-chip-filled .uil-chip-icon,
.disabled.uil-chip-opaque .uil-chip-icon,
.uil-chip.disabled .uil-chip-icon,
.uil-chip.disabled:hover .uil-chip-icon {
  visibility: hidden;
  color: #c2c7cb;
}
.disabled.active.uil-chip-colored,
.disabled.active.uil-chip-default,
.disabled.active.uil-chip-filled,
.disabled.active.uil-chip-opaque,
.uil-chip.disabled.active,
.uil-chip.disabled:hover.active {
  border: solid 1px #ccd1d7;
  color: #c2c7cb;
}
.disabled.active.uil-chip-colored .uil-chip-icon,
.disabled.active.uil-chip-default .uil-chip-icon,
.disabled.active.uil-chip-filled .uil-chip-icon,
.disabled.active.uil-chip-opaque .uil-chip-icon,
.uil-chip.disabled.active .uil-chip-icon,
.uil-chip.disabled:hover.active .uil-chip-icon {
  visibility: visible;
  color: #c2c7cb;
}
.uil-chip.uil-locked,
.uil-chip.uil-locked:hover,
.uil-locked.uil-chip-colored,
.uil-locked.uil-chip-default,
.uil-locked.uil-chip-filled,
.uil-locked.uil-chip-opaque {
  cursor: not-allowed;
}
.uil-chip.uil-locked .uil-chip-icon,
.uil-chip.uil-locked:hover .uil-chip-icon,
.uil-locked.uil-chip-colored .uil-chip-icon,
.uil-locked.uil-chip-default .uil-chip-icon,
.uil-locked.uil-chip-filled .uil-chip-icon,
.uil-locked.uil-chip-opaque .uil-chip-icon {
  visibility: visible;
}
.uil-chip.uil-deletable-chip,
.uil-chip.uil-deletable-chip:hover,
.uil-deletable-chip.uil-chip-colored,
.uil-deletable-chip.uil-chip-default,
.uil-deletable-chip.uil-chip-filled,
.uil-deletable-chip.uil-chip-opaque {
  cursor: default;
}
.uil-chip.uil-deletable-chip .uil-chip-icon,
.uil-chip.uil-deletable-chip:hover .uil-chip-icon,
.uil-deletable-chip.uil-chip-colored .uil-chip-icon,
.uil-deletable-chip.uil-chip-default .uil-chip-icon,
.uil-deletable-chip.uil-chip-filled .uil-chip-icon,
.uil-deletable-chip.uil-chip-opaque .uil-chip-icon {
  visibility: visible;
  cursor: pointer;
}
.uil-chip-static.uil-chip-colored,
.uil-chip-static.uil-chip-default,
.uil-chip-static.uil-chip-filled,
.uil-chip-static.uil-chip-opaque,
.uil-chip.uil-chip-static,
.uil-chip.uil-chip-static:hover {
  cursor: default;
}
.uil-chip-static.uil-chip-colored .uil-chip-icon,
.uil-chip-static.uil-chip-default .uil-chip-icon,
.uil-chip-static.uil-chip-filled .uil-chip-icon,
.uil-chip-static.uil-chip-opaque .uil-chip-icon,
.uil-chip.uil-chip-static .uil-chip-icon,
.uil-chip.uil-chip-static:hover .uil-chip-icon {
  visibility: visible;
  cursor: pointer;
}
.uil-chip-action.disabled.uil-chip-colored,
.uil-chip-action.disabled.uil-chip-default,
.uil-chip-action.disabled.uil-chip-filled,
.uil-chip-action.disabled.uil-chip-opaque,
.uil-chip-action[disabled].uil-chip-colored,
.uil-chip-action[disabled].uil-chip-default,
.uil-chip-action[disabled].uil-chip-filled,
.uil-chip-action[disabled].uil-chip-opaque,
.uil-chip.uil-chip-action.disabled,
.uil-chip.uil-chip-action:hover.disabled,
.uil-chip.uil-chip-action:hover[disabled],
.uil-chip.uil-chip-action[disabled],
fieldset[disabled] .uil-chip-action.uil-chip-colored,
fieldset[disabled] .uil-chip-action.uil-chip-default,
fieldset[disabled] .uil-chip-action.uil-chip-filled,
fieldset[disabled] .uil-chip-action.uil-chip-opaque,
fieldset[disabled] .uil-chip.uil-chip-action,
fieldset[disabled] .uil-chip.uil-chip-action:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-chip-action.disabled.uil-chip-colored .uil-chip-icon:hover,
.uil-chip-action.disabled.uil-chip-default .uil-chip-icon:hover,
.uil-chip-action.disabled.uil-chip-filled .uil-chip-icon:hover,
.uil-chip-action.disabled.uil-chip-opaque .uil-chip-icon:hover,
.uil-chip-action[disabled].uil-chip-colored .uil-chip-icon:hover,
.uil-chip-action[disabled].uil-chip-default .uil-chip-icon:hover,
.uil-chip-action[disabled].uil-chip-filled .uil-chip-icon:hover,
.uil-chip-action[disabled].uil-chip-opaque .uil-chip-icon:hover,
.uil-chip.uil-chip-action.disabled .uil-chip-icon:hover,
.uil-chip.uil-chip-action:hover.disabled .uil-chip-icon:hover,
.uil-chip.uil-chip-action:hover[disabled] .uil-chip-icon:hover,
.uil-chip.uil-chip-action[disabled] .uil-chip-icon:hover,
fieldset[disabled] .uil-chip-action.uil-chip-colored .uil-chip-icon:hover,
fieldset[disabled] .uil-chip-action.uil-chip-default .uil-chip-icon:hover,
fieldset[disabled] .uil-chip-action.uil-chip-filled .uil-chip-icon:hover,
fieldset[disabled] .uil-chip-action.uil-chip-opaque .uil-chip-icon:hover,
fieldset[disabled] .uil-chip.uil-chip-action .uil-chip-icon:hover,
fieldset[disabled] .uil-chip.uil-chip-action:hover .uil-chip-icon:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-chip-action.uil-chip-colored button .uil-chip-icon,
.uil-chip-action.uil-chip-default button .uil-chip-icon,
.uil-chip-action.uil-chip-filled button .uil-chip-icon,
.uil-chip-action.uil-chip-opaque button .uil-chip-icon,
.uil-chip.uil-chip-action button .uil-chip-icon,
.uil-chip.uil-chip-action:hover button .uil-chip-icon {
  visibility: visible;
  cursor: pointer;
}
.uil-chip-action.uil-chip-colored button .uil-chip-icon:hover,
.uil-chip-action.uil-chip-default button .uil-chip-icon:hover,
.uil-chip-action.uil-chip-filled button .uil-chip-icon:hover,
.uil-chip-action.uil-chip-opaque button .uil-chip-icon:hover,
.uil-chip.uil-chip-action button .uil-chip-icon:hover,
.uil-chip.uil-chip-action:hover button .uil-chip-icon:hover {
  color: var(--primary-01) !important;
}
.uil-chip-action.uil-chip-colored button .uil-chip-icon:hover.disabled,
.uil-chip-action.uil-chip-colored button .uil-chip-icon:hover[disabled],
.uil-chip-action.uil-chip-default button .uil-chip-icon:hover.disabled,
.uil-chip-action.uil-chip-default button .uil-chip-icon:hover[disabled],
.uil-chip-action.uil-chip-filled button .uil-chip-icon:hover.disabled,
.uil-chip-action.uil-chip-filled button .uil-chip-icon:hover[disabled],
.uil-chip-action.uil-chip-opaque button .uil-chip-icon:hover.disabled,
.uil-chip-action.uil-chip-opaque button .uil-chip-icon:hover[disabled],
.uil-chip.uil-chip-action button .uil-chip-icon:hover.disabled,
.uil-chip.uil-chip-action button .uil-chip-icon:hover[disabled],
.uil-chip.uil-chip-action:hover button .uil-chip-icon:hover.disabled,
.uil-chip.uil-chip-action:hover button .uil-chip-icon:hover[disabled],
fieldset[disabled]
  .uil-chip-action.uil-chip-colored
  button
  .uil-chip-icon:hover,
fieldset[disabled]
  .uil-chip-action.uil-chip-default
  button
  .uil-chip-icon:hover,
fieldset[disabled] .uil-chip-action.uil-chip-filled button .uil-chip-icon:hover,
fieldset[disabled] .uil-chip-action.uil-chip-opaque button .uil-chip-icon:hover,
fieldset[disabled] .uil-chip.uil-chip-action button .uil-chip-icon:hover,
fieldset[disabled] .uil-chip.uil-chip-action:hover button .uil-chip-icon:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-chip-colored:hover,
.uil-chip-default:hover,
.uil-chip-filled:hover,
.uil-chip-opaque:hover,
.uil-chip:hover {
  background-color: #f8f9fa;
}
.uil-chip-default {
  background-color: #fff;
  color: #393a3e;
}
.uil-chip-default.active {
  background-color: #fff !important;
  color: #393a3e;
  -webkit-box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: solid 1px var(--primary-01);
  margin: 8px;
}
.uil-chip-default.active .uil-chip-icon {
  color: var(--primary-01);
  visibility: visible;
}
.uil-chip-default .uil-chip-icon {
  color: #767676;
}
.uil-chip-filled {
  background-color: #393a3e;
  color: #fff;
  border: solid 1px transparent;
}
.uil-chip-filled.active {
  background-color: #fff !important;
  color: #393a3e;
  -webkit-box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: solid 1px var(--primary-01);
  margin: 8px;
}
.uil-chip-filled.active .uil-chip-icon {
  color: var(--primary-01);
  visibility: visible;
}
.uil-chip-filled .uil-chip-icon {
  color: #fff;
}
.uil-chip-filled:hover {
  background-color: #222;
}
.uil-chip-filled.disabled,
.uil-chip-filled.disabled:hover {
  cursor: not-allowed;
  color: #c2c7cb;
  border-left-color: #c2c7cb;
}
.uil-chip-filled.disabled .uil-chip-icon,
.uil-chip-filled.disabled:hover .uil-chip-icon {
  visibility: hidden;
  color: #c2c7cb;
}
.uil-chip-opaque {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.uil-chip-opaque.active {
  background-color: #fff !important;
  color: #393a3e;
  -webkit-box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  box-shadow: 0 4px 9px 2px rgba(57, 58, 62, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: solid 1px var(--primary-01);
  margin: 8px;
}
.uil-chip-opaque.active .uil-chip-icon {
  color: var(--primary-01);
  visibility: visible;
}
.uil-chip-opaque.active,
.uil-chip-opaque:hover {
  background-color: #fff;
  color: #393a3e;
}
.uil-chip-opaque .uil-chip-icon {
  color: #767676;
}
.uil-chip-static {
  background-color: var(--primary-01) 26;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .uil-chip-static {
    border: solid 1px #ccd1d7;
    background-color: #fff;
  }
}
.uil-chip-static:hover {
  background-color: var(--primary-01) 26;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .uil-chip-static:hover {
    background-color: #fff;
  }
}
.uil-chip-static.active {
  background-color: var(--primary-01) 26 !important;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-chip-static .uil-chip-icon {
  visibility: visible;
  cursor: pointer;
}
.uil-chip-static .uil-chip-title,
.uil-chip-static .uil-chip-title-with-subtitle {
  color: var(--primary-01);
}
.uil-chip-static.disabled,
.uil-chip-static.disabled:hover {
  cursor: not-allowed;
  color: #c2c7cb;
  border-left-color: #c2c7cb;
}
.uil-chip-static.disabled .uil-chip-title,
.uil-chip-static.disabled .uil-chip-title-with-subtitle,
.uil-chip-static.disabled:hover .uil-chip-title {
  color: #c2c7cb;
}
.uil-chip-static.disabled .uil-chip-icon,
.uil-chip-static.disabled:hover .uil-chip-icon {
  visibility: visible;
  cursor: not-allowed;
  color: #c2c7cb;
}
.uil-chip-colored,
.uil-chip-colored.active {
  border-left: solid 4px;
  border-left-color: var(--primary-light);
}
.uil-chip-colored.active.disabled,
.uil-chip-colored.active.disabled:hover,
.uil-chip-colored.disabled,
.uil-chip-colored.disabled:hover {
  cursor: not-allowed;
  color: #c2c7cb;
  border-left: solid 4px #c2c7cb;
}
.uil-chip-colored.active.disabled .uil-chip-title,
.uil-chip-colored.active.disabled:hover .uil-chip-title,
.uil-chip-colored.disabled .uil-chip-title,
.uil-chip-colored.disabled .uil-chip-title-with-subtitle,
.uil-chip-colored.disabled:hover .uil-chip-title {
  color: #c2c7cb;
}
.uil-chip-colored.active.disabled .uil-chip-icon,
.uil-chip-colored.active.disabled:hover .uil-chip-icon,
.uil-chip-colored.disabled .uil-chip-icon,
.uil-chip-colored.disabled:hover .uil-chip-icon {
  visibility: hidden;
  cursor: not-allowed;
  color: #c2c7cb;
}
.uil-chip-colored.active.disabled.active,
.uil-chip-colored.active.disabled:hover.active,
.uil-chip-colored.disabled.active,
.uil-chip-colored.disabled:hover.active {
  border-left: solid 4px;
}
.uil-chip-colored.active.disabled.active .uil-chip-icon,
.uil-chip-colored.active.disabled:hover.active .uil-chip-icon,
.uil-chip-colored.disabled.active .uil-chip-icon,
.uil-chip-colored.disabled:hover.active .uil-chip-icon {
  visibility: visible;
}
.uil-chip-titlearea {
  margin: 0 4px 0 0;
  width: calc(100% - 24px - 0px);
}
.uil-chip-titlearea.no-trailing-icon {
  width: 100%;
}
.uil-chip-icon-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-chip-title,
.uil-chip-title-with-subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
  max-width: 30ch;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.uil-chip-title-with-subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-chip-subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 13px;
  margin: 3px 0 0 0 !important;
  max-width: 30ch;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.uil-chip-icon {
  float: right;
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
}
.uil-chip-selected-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-chip-locked-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-chip-unlocked-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-chip-close-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-chip-placeholder-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-in-page-navigation-horizontal {
  border: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  position: absolute;
  z-index: 1000;
}
.uil-in-page-navigation-horizontal::before {
  display: none;
  content: "in_page_nav_collapsed";
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal::before {
    display: none;
    content: "in_page_nav_not_collapsed";
  }
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal {
    width: 100%;
    float: none;
  }
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal {
    background-color: var(--color-bg-light);
  }
  .uil-in-page-navigation-horizontal.uil-align-with-content {
    padding-left: 80px !important;
    margin-left: -80px !important;
  }
}
.uil-in-page-navigation-horizontal ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: 0;
  margin-right: 0;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow: revert;
  width: 100%;
  background-color: transparent;
  list-style-type: none;
}
.uil-in-page-navigation-horizontal ul li {
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: transparent;
}
.uil-in-page-navigation-horizontal ul li.active {
  border-bottom-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation-horizontal ul li.active a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
}
.uil-in-page-navigation-horizontal ul li:hover {
  border-bottom-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation-horizontal ul li:hover[disabled] {
  cursor: not-allowed;
  border-bottom-color: var(--color-border-light);
}
.uil-in-page-navigation-horizontal ul li a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-left: 22px;
  padding-right: 22px;
  white-space: nowrap;
  padding: 12px 8px;
  color: var(--color-font-dark);
  -webkit-transition: none;
  transition: none;
}
.uil-in-page-navigation-horizontal ul li a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal ul li a {
    color: #393a3e;
  }
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: revert;
  scroll-behavior: smooth;
  width: 100%;
  margin-left: -30px;
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-column.on-scrollbar,
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-column.on-scrollbar-static {
  padding-bottom: 0;
}
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-column.on-scrollbar
  ul,
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-column.on-scrollbar-static
  ul {
  margin-bottom: 0;
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-left {
  width: 40px;
  height: 100%;
  color: #393a3e;
  font-size: var(--font-size-heading-lg);
  left: 0;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  cursor: pointer;
}
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-horizontal-left[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-left {
    display: none;
  }
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-right {
  width: 40px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #393a3e;
  font-size: var(--font-size-heading-lg);
  right: 0;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  cursor: pointer;
}
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-horizontal-right[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-right {
    display: none;
  }
}
.uil-in-page-navigation {
  border: none;
  padding: 0;
  margin: 0;
  top: 0;
  position: sticky;
  float: right;
  overflow: revert;
  padding-top: 40px !important;
  margin-top: -12px !important;
  width: 0;
  padding-right: 0 !important;
  padding-left: 12px !important;
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation {
    overflow: revert;
  }
  .uil-in-page-navigation:hover {
    overflow: revert;
    overflow-x: revert;
  }
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation {
    width: calc(248px + 12px + 12px + 24px);
    padding-right: 24px !important;
  }
}
.uil-in-page-navigation ul {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 0;
  margin-bottom: 28px;
  overflow: revert;
  width: 248px;
  background-color: transparent;
  list-style-type: none !important;
}
.uil-in-page-navigation ul li {
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: var(--color-border-default);
}
.uil-in-page-navigation ul li:hover {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation ul li[disabled] {
  cursor: not-allowed;
  border-left-color: var(--color-border-light1);
}
.uil-in-page-navigation ul li a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 12px 8px;
  color: var(--color-font-dark);
  -webkit-transition: none;
  transition: none;
}
.uil-in-page-navigation ul li a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  pointer-events: none;
}
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"] {
  width: 248px;
  padding-bottom: 16px;
  padding-right: 16px;
}
.uil-card-template
  .uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"]
  .uil-card-title-area-with-icon,
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"]
  .uil-card-template
  .uil-card-title-area-with-icon,
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"]
  .uil-card-title-area,
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"]
  .uil-card-title-area-underline {
  margin-bottom: 8px;
}
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"]
  .uil-card-footer {
  padding-top: 8px;
}
.uil-in-page-navigation .uil-in-page-navigation-column {
  display: none;
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation .uil-in-page-navigation-column {
    display: block;
  }
}
.uil-align-with-content {
  margin-right: -80px !important;
}
.uil-in-page-navigation .uil-curtain,
.uil-in-page-navigation-horizontal .uil-curtain {
  position: absolute;
  right: -12px;
  top: 0;
  overflow: revert;
  border: none;
  width: 0;
  padding-right: 0;
  right: 0;
}
.uil-in-page-navigation .uil-curtain.active,
.uil-in-page-navigation-horizontal .uil-curtain.active {
  width: calc(248px + 12px + 12px + 24px);
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation .uil-curtain,
  .uil-in-page-navigation-horizontal .uil-curtain {
    display: none;
  }
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel,
.uil-in-page-navigation-horizontal .uil-curtain .uil-curtain-panel {
  width: 0;
  top: 0;
  -webkit-box-shadow: 0 0 8px 0 #393a3e26;
  box-shadow: 0 0 8px 0 #393a3e26;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel.active,
.uil-in-page-navigation-horizontal .uil-curtain .uil-curtain-panel.active {
  width: 100%;
  left: 0;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-toggle,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-toggle {
  -webkit-box-shadow: 6px 0 0 #fff, 0 0 8px 0 #393a3e26;
  box-shadow: 6px 0 0 #fff, 0 0 8px 0 #393a3e26;
  height: 48px;
  width: 48px;
  left: -48px;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-open,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open {
  border: none;
  overflow: revert;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-open:hover,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open:hover {
  overflow: revert;
}
.uil-in-page-navigation
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-panel-content,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-panel-content {
  padding-top: 40px;
  padding-left: 12px;
  float: left;
}
.uil-in-page-navigation
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-toggle,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-toggle {
  top: 40px;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-close,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-close {
  border: none;
}
.uil-in-page-navigation
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-close
  .uil-curtain-toggle,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-close
  .uil-curtain-toggle {
  top: 40px;
  margin: 0;
}
.uil-in-page-navigation .uil-curtain ul,
.uil-in-page-navigation-horizontal .uil-curtain ul {
  display: block;
}
.uil-in-page-navigation .uil-curtain ul li,
.uil-in-page-navigation-horizontal .uil-curtain ul li {
  border-bottom-style: none;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: var(--color-border-default);
}
.uil-in-page-navigation .uil-curtain ul li.active,
.uil-in-page-navigation-horizontal .uil-curtain ul li.active {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation .uil-curtain ul li.active a,
.uil-in-page-navigation-horizontal .uil-curtain ul li.active a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
}
.uil-in-page-navigation .uil-curtain ul li:hover,
.uil-in-page-navigation-horizontal .uil-curtain ul li:hover {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation .uil-curtain ul li:hover[disabled],
.uil-in-page-navigation-horizontal .uil-curtain ul li:hover[disabled] {
  cursor: not-allowed;
  border-left-color: var(--color-border-light1);
}
@media screen and (max-width: 768px) {
  .uil-in-page-navigation-horizontal {
    background-color: var(--color-bg-light);
  }
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal .uil-curtain {
    display: none;
  }
}
.uil-content-with-in-page-navigation {
  float: left;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .uil-content-with-in-page-navigation {
    width: calc(100% - (248px + 12px + 12px) + (80px - 24px - 0px));
  }
}
.uil-in-page-navigation-toggle-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 24px;
  font-size: 24px;
}
.uil-in-page-navigation-toggle-icon:before {
  content: "\e98d";
}
.uil-in-page-navigation ul li a:hover {
  color: var(--color-font-primaryLight);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: var(--font-weight-md);
}
.uil-in-page-navigation ul li a:hover[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-in-page-navigation-horizontal ul li a:hover {
  color: var(--color-font-primaryLight);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: var(--font-weight-md);
}
.uil-in-page-navigation-horizontal ul li a:hover[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-in-page-navigation ul li[class="active"] {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation ul li[class="active"][disabled] {
  cursor: not-allowed;
  border-left-color: var(--color-border-light1);
}
.uil-in-page-navigation ul li[class="active"] a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  color: var(--color-font-primaryLight);
  font-style: normal;
}
.uil-in-page-navigation ul li[class="active"] a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-in-page-navigation-horizontal ul li[class="active"] {
  border-bottom: 4px solid var(--color-border-primaryLight);
}
.uil-in-page-navigation-horizontal ul li[class="active"][disabled] {
  cursor: not-allowed;
  border-bottom-color: var(--color-border-light);
}
.uil-in-page-navigation-horizontal ul li[class="active"] a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  color: var(--color-font-primaryLight);
  font-style: normal;
}
.uil-in-page-navigation-horizontal ul li[class="active"] a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-link-icon,
.uil-plain-icon,
.uil-popup-button,
.uil-popup-button-hover,
.uil-toggle-icon {
  display: inline-block;
  text-align: center;
  margin: 0 2px;
  outline: 0;
  max-width: calc(1em + 48px + 16px);
  max-height: calc(1em + 48px + 8px);
  cursor: pointer;
}
.uil-link-icon,
.uil-popup-button,
.uil-popup-button-hover {
  cursor: pointer;
  color: #767676;
  border: none;
  background: 0 0;
  padding: 0;
}
.disabled.uil-popup-button,
.disabled.uil-popup-button-hover,
.uil-link-icon.disabled,
.uil-link-icon[disabled],
[disabled].uil-popup-button,
[disabled].uil-popup-button-hover,
fieldset[disabled] .uil-link-icon,
fieldset[disabled] .uil-popup-button,
fieldset[disabled] .uil-popup-button-hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-link-icon:focus,
.uil-link-icon:hover,
.uil-popup-button-hover:focus,
.uil-popup-button-hover:hover,
.uil-popup-button:focus,
.uil-popup-button:hover {
  color: var(--primary-01) !important;
}
.uil-link-icon:focus.disabled,
.uil-link-icon:focus[disabled],
.uil-link-icon:hover.disabled,
.uil-link-icon:hover[disabled],
.uil-popup-button-hover:focus.disabled,
.uil-popup-button-hover:focus[disabled],
.uil-popup-button-hover:hover.disabled,
.uil-popup-button-hover:hover[disabled],
.uil-popup-button:focus.disabled,
.uil-popup-button:focus[disabled],
.uil-popup-button:hover.disabled,
.uil-popup-button:hover[disabled],
fieldset[disabled] .uil-link-icon:focus,
fieldset[disabled] .uil-link-icon:hover,
fieldset[disabled] .uil-popup-button-hover:focus,
fieldset[disabled] .uil-popup-button-hover:hover,
fieldset[disabled] .uil-popup-button:focus,
fieldset[disabled] .uil-popup-button:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-link-icon .uil-font-icon,
.uil-popup-button .uil-font-icon,
.uil-popup-button-hover .uil-font-icon {
  display: block;
  vertical-align: top;
  text-align: center;
}
.uil-link-icon .uil-font-icon img,
.uil-link-icon .uil-font-icon span,
.uil-popup-button .uil-font-icon img,
.uil-popup-button .uil-font-icon span,
.uil-popup-button-hover .uil-font-icon img,
.uil-popup-button-hover .uil-font-icon span {
  width: 1em;
  height: 1em;
}
.uil-table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
}
.uil-table tbody.uil-table-no-border tr,
.uil-table.uil-table-no-border tbody tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, transparent),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    transparent 100%,
    var(--color-border-dark)
  );
  background-position: top;
  background-size: 3px 0;
  background-repeat: repeat-x;
}
.uil-table.uil-table-no-border tbody tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, transparent),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    transparent 100%,
    var(--color-border-dark)
  );
  background-position: top;
  background-size: 3px 0;
  background-repeat: repeat-x;
}
.uil-table tbody.uil-table-bottom-border tr,
.uil-table tbody.uil-table-bottom-border tr:first-child,
.uil-table.uil-table-bottom-border tbody tr,
.uil-table.uil-table-bottom-border tbody tr:first-child,
.uil-table.uil-table-no-border.uil-table-bottom-border tbody tr:first-child {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, transparent),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    transparent 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}
.uil-table.uil-table-bottom-border tbody tr:last-child {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, var(--color-bg-disabledLight)),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    var(--color-bg-disabledLight) 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}
.uil-table > tbody > tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, var(--color-bg-disabledLight)),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    var(--color-bg-disabledLight) 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
  background-color: var(--color-bg-light);
}
.uil-table > thead > tr > th {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  vertical-align: middle;
  height: 48px;
}
.uil-table-head-content {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  display: block;
  line-height: normal !important;
}
.uil-table-head-content.disabled {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  display: block;
  line-height: normal !important;
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.uil-table > tbody > tr > td {
  font-family: var(--font-family);
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  padding: 16px 8px;
  height: 56px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uil-table-content {
  font-family: var(--font-family);
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  line-height: normal !important;
  max-width: 180px;
}
.disabled.uil-table-content {
  font-family: var(--font-family);
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  line-height: normal !important;
  color: var(--color-font-disabled);
  cursor: not-allowed;
  max-width: 180px;
}
.uil-table > thead > tr > th:first-child {
  padding-left: 24px !important;
}
.uil-table > tbody > tr > td:last-child {
  padding-right: 24px;
}
.uil-table > tbody > tr > td:first-child {
  padding-left: 24px;
}
.uil-table > thead > tr:first-child {
  padding-left: 24px;
}
.uil-table > tbody > tr:hover:not(.disabled):not(.selected):not([disabled]) {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, rgba(117, 0, 192, 0.5)),
    color-stop(20%, rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to right,
    rgba(117, 0, 192, 0.5) 100%,
    rgba(255, 255, 255, 0) 20%
  );
  background-color: #f5e5ff !important;
}
.uil-table > thead:first-child > tr:first-child > td,
.uil-table > thead:first-child > tr:first-child > th {
  border-top: 0;
  background: var(--color-bg-disabledDark);
  padding-left: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 8px;
}
.uil-table-striped tr:nth-child(even) {
  background-color: #f4f4f4;
}
.uil-table > tbody > tr > td.rowcheck,
.uil-table > tbody > tr > th.rowcheck,
.uil-table > thead > tr > td.rowcheck,
.uil-table > thead > tr > th.rowcheck {
  width: 16px;
  vertical-align: middle;
  margin-top: 0;
  margin-left: 0;
}
.uil-table > tbody > tr > td.rowcheck > .uil-checkbox,
.uil-table
  > thead
  > tr
  > td.rowcheck
  > .uil-checkbox
  .uil-table
  > tbody
  > tr
  > th.rowcheck
  > .uil-checkbox,
.uil-table > thead > tr > th.rowcheck > .uil-checkbox {
  min-height: 20px;
  line-height: 14px;
  margin-top: 0;
  margin-left: 0;
  padding: 0;
}
.uil-table > tbody > tr > td.rowcheck > .uil-checkbox label,
.uil-table > tbody > tr > th.rowcheck > .uil-checkbox label,
.uil-table > tfoot > tr > td.rowcheck > .uil-checkbox label,
.uil-table > tfoot > tr > th.rowcheck > .uil-checkbox label,
.uil-table > thead > tr > td.rowcheck > .uil-checkbox label,
.uil-table > thead > tr > th.rowcheck > .uil-checkbox label {
  top: 7px;
  margin-left: -18px;
}
.uil-table > thead > tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, var(--color-bg-disabledLight)),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    var(--color-bg-disabledLight) 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 2px;
  background-repeat: repeat-x;
  background: var(--color-bg-disabledDark);
  border-top: 2px solid var(--color-border-primaryLight) !important;
  height: 48px;
  font-weight: 500;
}
.uil-dl-icon-ascarrow::before {
  content: "⏶";
  font-size: 10px;
  text-align: center;
  bottom: 7px;
  position: absolute;
  color: var(--color-font-disabled);
}
.uil-dl-icon-ascarrow::after {
  content: "⏷";
  font-size: 10px;
  text-align: center;
  position: absolute;
  padding-top: 4px;
  color: var(--color-font-dark);
}
.uil-dl-icon-descarrow::before {
  content: "⏶";
  font-size: 10px;
  text-align: center;
  bottom: 7px;
  position: absolute;
  color: var(--color-font-dark);
}
.uil-dl-icon-descarrow::after {
  content: "⏷";
  font-size: 10px;
  text-align: center;
  position: absolute;
  padding-top: 4px;
  color: var(--color-font-disabled);
}
.uil-dl-icon-sort::after {
  content: "⏷";
  font-size: 10px;
  text-align: center;
  position: absolute;
  padding-top: 4px;
  color: var(--color-font-dark);
}
.uil-dl-icon-sort::before {
  content: "⏶";
  font-size: 10px;
  text-align: center;
  bottom: 7px;
  position: absolute;
  color: var(--color-font-dark);
}
.uil-table
  > thead
  > tr
  > th.uil-clickable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: var(--color-bg-disabledDark) !important;
}
.uil-table[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.disabled .uil-dl-icon-ascarrow::after,
.disabled .uil-dl-icon-ascarrow::before,
.disabled .uil-dl-icon-descarrow::after,
.disabled .uil-dl-icon-descarrow::before,
.disabled .uil-dl-icon-sort::after,
.disabled .uil-dl-icon-sort::before,
.uil-table > tbody [disabled],
.uil-table > thead [disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
  pointer-events: none !important;
}
.uil-table > thead > tr[disabled] {
  border-top: 2px solid var(--color-font-disabled) !important;
}
.uil-table-header-checkbox {
  padding: 8px 8px 8px 0 !important;
}
.uil-table-header-checkbox > span {
  position: relative;
  top: 8px;
}
.uil-checkbox-table-coloumn {
  position: relative;
  top: 8px;
}
.uil-table.uil-table-defaultHeader > thead > tr > th {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  vertical-align: middle;
  height: 48px;
}
.uil-table.uil-table-largeHeader > thead > tr > th {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  vertical-align: middle;
  height: 56px;
}
.table-container.horizontal-scrollbar {
  max-width: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 16px;
}
[disabled].disabled-scrollbar {
  cursor: not-allowed !important;
  pointer-events: none !important;
}
::-webkit-scrollbar-track {
  background-color: var(--color-bg-whiteLight);
}
.table-container {
  max-width: 100%;
}
.table-container.horizontal-scrollbar::-webkit-scrollbar {
  height: 8px;
}
.table-container::-webkit-scrollbar {
  width: 12px;
}
.table-container::-webkit-scrollbar-track {
  background-color: var(--color-bg-whiteLight);
}
.table-container::-webkit-scrollbar-thumb {
  background-color: var(--color-font-primaryLight);
  border-radius: 10px;
}
.disabled-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-bg-disabledDark);
  cursor: not-allowed;
  pointer-events: none;
}
.disabled-scrollbar::-webkit-scrollbar-track {
  background-color: var(--color-bg-whiteLight);
  cursor: not-allowed;
  pointer-events: none;
}
.table-container.horizontal-scrollbar.disabled-scrollbar {
  cursor: not-allowed !important;
  pointer-events: none;
}
table[disabled] {
  cursor: not-allowed;
}
.disabled.uil-table > thead > tr {
  background: var(--color-bg-disabled);
  border-top: 2px solid var(--color-border-default) !important;
  cursor: not-allowed;
  pointer-events: none;
}
.disabled.uil-table > thead:first-child > tr:first-child > td,
.disabled.uil-table > thead:first-child > tr:first-child > th {
  background: var(--color-bg-disabledDark);
  color: #747474;
  border-top: 2px solid var(--color-border-default) !important;
  cursor: not-allowed;
  pointer-events: none;
}
.uil-table-textfield {
  width: auto;
  height: 24px !important;
}
.tooltip > .tooltip-innerbox,
.uil-tooltip + .tooltip > .tooltip-innerbox {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25) !important;
  color: #000 !important;
  width: 126px !important;
  height: 40px !important;
  font-size: 12px !important;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 0.75rem;
  text-align: center;
  opacity: 1;
  z-index: 10000;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
}
.tooltip .tooltip-arrow,
.uil-tooltip + .tooltip .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top,
.uil-tooltip + .tooltip.top {
  padding-bottom: 5px;
}
.tooltip.bottom,
.uil-tooltip + .tooltip.bottom {
  padding-top: 5px;
}
.tooltip.left,
.uil-tooltip + .tooltip.left {
  padding-right: 5px;
}
.tooltip.right,
.uil-tooltip + .tooltip.right {
  padding-left: 5px;
}
.tooltip.top > .tooltip-arrow,
.uil-tooltip + .tooltip.top > .tooltip-arrow {
  border-top-color: #fff !important;
  width: 10px !important;
  border-style: solid !important;
}
.tooltip.bottom > .tooltip-arrow,
.uil-tooltip + .tooltip.bottom > .tooltip-arrow {
  top: 0;
  border-bottom-color: #fff !important;
  border-width: 0 5px 5px;
}
.tooltip.left > .tooltip-arrow,
.uil-tooltip + .tooltip.left > .tooltip-arrow {
  right: 0;
  left: auto;
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff !important;
  height: 10px !important;
  border-style: solid !important;
}
.tooltip.right > .tooltip-arrow,
.uil-tooltip + .tooltip.right > .tooltip-arrow {
  left: 5px;
  top: 50%;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff !important;
  height: 10px !important;
  border-style: solid !important;
}
.uil-round-badge-outer-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  background: #fff;
}
.uil-round-badge-inner-container {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  top: 4px;
  left: 4px;
}
.uil-round-badge-icon {
  position: absolute;
  top: 9px;
  left: 9px;
}
.uil-tooltip-notification-container {
  position: absolute;
  top: 100%;
  background: #fff;
  padding: 4px;
  border: 1px solid #c1c1c1;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  color: #393a3e;
}
.uil-tooltip-notification-container.dark {
  color: #fff;
  background: #393a3e;
  border: none;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.uil-tooltip-notification-container.top {
  bottom: 100%;
  top: initial;
  margin-bottom: -4px;
}
.uil-tooltip-notification-container.left {
  right: 50%;
  left: initial;
  top: initial;
  margin-right: 4px;
}
.uil-tooltip-notification-container.right {
  left: 50%;
  right: initial;
  top: initial;
  margin-left: 4px;
}
.uil-tooltip-notification-container.bottom {
  top: 100%;
}
.uil-tooltip-notification {
  margin-right: 4px;
  padding: 0 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-tooltip-notification:last-child {
  margin-right: 0;
}
.uil-tooltip-notification-icon {
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 2px;
}
.uil-tooltip-notification-text {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-tooltip-notification-text:only-child {
  padding: 0 4px;
}
.uil-pin-notification-circle {
  position: absolute;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  top: -30px;
  left: -30px;
  opacity: 8%;
}
.uil-pin-notification-circle.pulse {
  -webkit-animation: pulse-circle 1s infinite;
  animation: pulse-circle 1s infinite;
}
.uil-pin-notification-circle.growing {
  -webkit-animation: growing-circle 1s infinite;
  animation: growing-circle 1s infinite;
}
@-webkit-keyframes growing-circle {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
  }
}
@keyframes growing-circle {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
  }
}
@-webkit-keyframes pulse-circle {
  0% {
    opacity: 8%;
  }
  50% {
    opacity: 40%;
  }
  100% {
    opacity: 8%;
  }
}
@keyframes pulse-circle {
  0% {
    opacity: 8%;
  }
  50% {
    opacity: 40%;
  }
  100% {
    opacity: 8%;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}
.uil-footer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  width: auto;
  background: var(--color-bg-light);
  border-top: 1px solid var(--color-border-default);
}
.uil-footer-basic {
  padding: 24px;
  position: absolute;
  height: auto;
  width: 100%;
  overflow: hidden;
}
.uil-footer-text {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-greyDark);
}
.uil-footer-text-right {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  padding: 6px 8px;
  color: var(--color-font-greyDark);
  float: right;
}
.uil-left {
  padding-left: 16px;
  border-left: 1px solid var(--color-border-default);
}
.uil-right {
  padding-right: 16px;
}
.uil-footer-basic.uil-footer-padding-small,
.uil-footer-detailed.uil-footer-padding-small {
  padding-left: 32px;
  padding-right: 32px;
}
.uil-footer-basic.uil-footer-padding-medium,
.uil-footer-detailed.uil-footer-padding-medium {
  padding-left: 80px;
  padding-right: 80px;
}
.uil-footer-basic.uil-footer-padding-large,
.uil-footer-detailed.uil-footer-padding-large {
  padding-left: 96px;
  padding-right: 80px;
}
.uil-footer-detailed {
  padding: 56px 24px;
  position: absolute;
  height: 261px;
  width: 1040px;
}
.uil-footer-brand-copyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 305px;
  height: auto;
}
.uil-footer-logos {
  margin-bottom: 21px;
}
.uil-footer-logo-image {
  width: 101px;
  height: 28px;
  border: 0;
  margin-right: 16px;
}
.uil-footer-appcoe {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-title-lg);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-dark);
  padding-left: 16px;
  border-left: 2px solid var(--color-border-default);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
}
.uil-footer-hyperlinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  position: relative;
  right: 20px;
  width: 126px;
  height: 100%;
  list-style-type: none;
}
.uil-footer-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 8px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -ms-flex-positive: 0;
  flex-grow: 0;
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-bg-dark) !important;
}
.uil-footer-helpfeedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 437px;
  height: 100%;
}
.uil-footer-feedback {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-md);
  line-height: 120%;
  color: var(--color-font-dark);
}
.uil-footer-button {
  width: 200px;
  padding: 14px 40px;
  margin-bottom: 10px;
}
.uil-footer-small-links {
  list-style-type: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.uil-footer-small-links > li + li:before {
  content: "|";
  color: var(--color-font-disabled);
  font-weight: 400;
  padding: 0 16px;
}
.uil-footer a {
  color: var(--color-font-greyDark);
}
.uil-progress-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}
.uil-progress-value-container {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-height: 8px;
  width: 100%;
}
.uil-progress-value-container[disabled] {
  cursor: not-allowed;
}
.uil-progress-scale-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  line-height: 0;
  font-size: var(--font-size-md2);
  background-color: var(--color-bg-disabledDark);
  position: relative;
  z-index: auto;
  height: 8px;
  border-radius: 4px !important;
  margin-bottom: 4px;
  display: block;
}
.uil-progress .uil-progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: var(--color-font-light);
  text-align: center;
  white-space: nowrap;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  float: left;
  height: 100%;
  background-color: var(--color-bg-primaryLight);
}
.uil-progress .uil-progress-bar:last-child:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: -1;
}
.uil-progress .uil-progress-bar:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-primary {
  background-color: var(--color-bg-secondaryDark);
}
.uil-progress .uil-progress-bar-primary:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-success {
  background-color: var(--color-bg-green);
}
.uil-progress .uil-progress-bar-success:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-danger {
  background-color: var(--color-bg-red);
}
.uil-progress .uil-progress-bar-danger:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-danger[disabled],
.uil-progress .uil-progress-bar-primary[disabled],
.uil-progress .uil-progress-bar-success[disabled] {
  background-color: var(--color-bg-disabledDark);
  cursor: not-allowed;
}

.uil-progress-icon-right {
  margin-left: 8px;
}
.uil-progress-icon-left {
  margin-right: 8px;
}

.uil-dl-icon-Closeprogress:before {
  color: var(--color-font-greyDark);
  content: "\e98e";
  position: relative;
  display: inline;
  font-weight: var(--font-weight-lg);
  margin-left: 16px;
  cursor: pointer;
}
.uil-dl-icon-Closeerrorprogress:before {
  color: var(--color-font-secondaryDark);
  content: "\e98e";
  position: relative;
  display: inline;
  font-weight: var(--font-weight-lg);
  margin-left: 16px;
  cursor: pointer;
}
[disabled].uil-dl-icon-Danger:before,
[disabled].uil-dl-icon-Play:before,
[disabled].uil-dl-icon-Success:before {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
[disabled].uil-dl-icon-Closeerrorprogress:before,
[disabled].uil-dl-icon-Closeprogress:before,
[disabled].uil-dl-icon-Pause:before,
[disabled].uil-dl-icon-Refresh:before,
[disabled].uil-size-note {
  color: var(--color-font-disabled);
  cursor: not-allowed;
  display: inline;
}
.uil-size-note {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  line-height: 16px;
  display: inline;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-font-greyMedium);
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  float: right;
  margin-left: auto;
  padding-top: 8px;
}
.uil-progress-footnote {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: inherit;
}
.uil-progress-secondtd {
  width: 50px;
  position: relative;
  top: 5px;
  caret-color: transparent;
}
.uil-breadcrumb {
  background-color: transparent;
  color: var(--color-font-greyDark);
  padding: 12px 24px;
  margin-bottom: 0;
}
.uil-breadcrumb > li {
  text-align: center;
}
.uil-breadcrumb > li a {
  width: 100%;
  color: var(--color-font-greyDark);
  line-height: 1rem;
  font-size: var(--font-size-caption-xs);
  white-space: nowrap;
  caret-color: transparent;
}
.uil-breadcrumb > li a:focus {
  color: var(--primary-light);
  border-bottom: 1.5px solid var(--primary-light);
}
.uil-breadcrumb > li a:hover {
  color: var(--primary-light);
  border-bottom: 1.5px solid var(--primary-light);
}
.uil-breadcrumb > li.disabled,
.uil-breadcrumb > li.disabled a,
.uil-breadcrumb > li[disabled],
.uil-breadcrumb > li[disabled] a {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  border-bottom: none;
  caret-color: transparent;
  font-size: var(--font-size-caption-xs);
}
.uil-extra-breadcrumbs > * {
  display: none;
}
.uil-extra-breadcrumbs > li {
  text-align: center;
}
.uil-extra-breadcrumbs > li a {
  width: 100%;
  color: var(--color-font-greyDark);
  white-space: nowrap;
  line-height: 1rem;
}
.uil-extra-breadcrumbs > li a:focus {
  color: var(--primary-light);
  border-bottom: 1.5px solid var(--primary-light);
}
.uil-extra-breadcrumbs > li a:hover {
  color: var(--color-font-greyDark);
  border-bottom: 1.5px solid var(--primary-light);
}
.uil-extra-breadcrumbs > li.disabled,
.uil-extra-breadcrumbs > li.disabled a,
.uil-extra-breadcrumbs > li[disabled],
.uil-extra-breadcrumbs > li[disabled] a {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-extra-breadcrumbs:after {
  content: "...";
  color: var(--color-font-primaryLight);
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-caption-xs);
}
.uil-extra-breadcrumbs.cursor-notallowed:after {
  color: var(--color-font-disabled);
}
.uil-extra-breadcrumbs:before:focus {
  content: "?";
}
.uil-extra-breadcrumbs:focus {
  outline-style: none;
}
.uil-extra-breadcrumbs:focus > * {
  display: inline-block;
}
.uil-extra-breadcrumbs:focus:after {
  display: none;
}
.uil-breadcrumb > li + li:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb > li + span ~ li:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb .uil-extra-breadcrumbs:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb .uil-extra-breadcrumbs:focus > li ~ li:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow > li + li:before {
  content: ">";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow > li + span ~ li:before {
  content: ">";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow .uil-extra-breadcrumbs:before {
  content: ">";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow
  .uil-extra-breadcrumbs:focus
  > li
  ~ li:before {
  content: ">";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-slash > li + li:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-slash > li + span ~ li:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-slash .uil-extra-breadcrumbs:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb.uil-breadcrumb-separator-slash
  .uil-extra-breadcrumbs:focus
  > li
  ~ li:before {
  content: "/";
  color: var(--color-font-disabled);
  font-weight: var(--font-weight-md);
  padding: 0 5px;
}
.uil-breadcrumb-dropspan {
  color: var(--primary-light);
  position: relative;
  background-color: transparent;
  display: inline-block;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  border: none;
  cursor: pointer;
  caret-color: transparent;
}
.uil-breadcrumbdropdown-content {
  display: block;
  position: absolute;
  background-color: var(--color-bg-light);
  min-width: 92px;
  min-height: 40px;
  max-height: 216px;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-bottom: 16px;
  left: 215px;
}
.uil-breadcrumbdropdown-content > li {
  text-align: center;
}
.uil-breadcrumbdropdown-content > li a {
  color: var(--color-font-dark);
  text-decoration: none;
  display: block;
  background-color: #fff;
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  padding: 10px 16px 10px 16px;
  min-height: 20px;
  min-width: 39px;
  top: 40px;
  text-align: left;
  line-height: 1rem;
}
.uil-breadcrumbdropdown-content > li a:hover {
  color: var(--primary-light);
  background-color: var(--grey-light2);
  -webkit-text-decoration: 1.5px var(--primary-light);
  text-decoration: 1.5px var(--primary-light);
}
.uil-breadcrumbdropdown-content > li a.disabled,
.uil-breadcrumbdropdown-content > li a[disabled],
fieldset[disabled] .uil-breadcrumbdropdown-content > li a {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-breadcrumb-dropdown:hover .uil-breadcrumbdropdown-content {
  display: block;
}
.uil-breadcrumb-dropspan:hover {
  -webkit-text-decoration: 1.5px var(--primary-light);
  text-decoration: 1.5px var(--primary-light);
  color: var(--primary-light);
}
.uil-breadcrumb-dropspan:focus {
  color: var(--primary-light);
}
.uil-breadcrumb-dropspan.cursor-notallowed {
  color: var(--color-font-disabled) !important;
}
.uil-extra-breadcrumbs.uil-extra-breadcrumbs:hover:after {
  -webkit-text-decoration: 1.5px var(--primary-light);
  text-decoration: 1.5px var(--primary-light);
}
.uil-breadcrumbdropdown-content > li[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  text-decoration: none;
  display: block;
  background-color: var(--color-bg-light);
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 20px;
  padding: 10px 16px 10px 16px;
  min-height: 20px;
  min-width: 39px;
  top: 40px;
  letter-spacing: 0.3px;
  text-align: left;
}
.uil-breadcrumb > li:last-of-type a {
  color: var(--color-font-greyDark);
  content: "/";
  font-family: Graphik;
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 16px;
  white-space: nowrap;
  pointer-events: none;
}
a:hover {
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.uil-breadcrumb.uil-breadcrumb-disabled > li + span ~ li:hover:before {
  color: var(--grey-light1);
}
.uil-breadcrumb.uil-breadcrumb-disabled > li + li:hover:before {
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-disabled .uil-extra-breadcrumbs:hover::before {
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb.uil-breadcrumb-disabled
  > li
  + span
  ~ li:hover:before {
  color: var(--grey-light1);
}
.uil-breadcrumb.uil-breadcrumb-disabled > li + li:hover:before {
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-disabled .uil-extra-breadcrumbs:hover::before {
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow > li + li:before {
  content: ">";
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow > li + li:before {
  content: ">";
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow .uil-extra-breadcrumbs:before {
  content: ">";
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-separator-arrow > li + span ~ li:before {
  content: ">";
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-separator-slash > li + li:before {
  content: "/";
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-separator-slash .uil-extra-breadcrumbs:before {
  content: "/";
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb.uil-breadcrumb-separator-slash > li + span ~ li:before {
  content: "/";
  color: var(--grey-light1);
  font-weight: var(--font-weight-sm);
  padding: 0 8px;
}
.uil-breadcrumb {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-breadcrumb-disabled > span.uil-extra-breadcrumbs:hover:after {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.uil-breadcrumb-disabled span.uil-breadcrumb-dropspan:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--color-font-greyDark);
}
.uil-breadcrumb-hidecontent {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer :hover :after {
  cursor: pointer;
}
.cursor-notallowed,
.cursor-notallowed:hover,
.cursor-notallowed:hover:after {
  cursor: not-allowed;
  font-size: var(--font-size-caption-xs);
  caret-color: transparent;
}
.uil-extra-breadcrumbs .cursor-notallowed,
.uil-extra-breadcrumbs:focus .cursor-notallowed {
  color: var(--color-font-dark);
}
.uil-timepicker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 8px;
  gap: 8px;
  width: 108px;
  height: 48px;
}
.uil-timepicker-default {
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-default);
  color: var(--color-font-grey-medium);
  height: 48px;
}
.uil-timepicker-default.active {
  border: 1px solid var(--color-border-dark);
}
.uil-timepicker-error_text_area {
  border: 1px solid var(--color-border-error);
  color: var(--color-font-dark);
  background: var(--color-bg-light);
}
.uil-dl-icon-Closing:before {
  content: "\e98e";
  position: relative;
  color: var(--color-font-dark);
}
.uil-dl-icon-TimeIcon:before {
  content: "\e972";
  position: relative;
  color: var(--color-font-dark);
}
[disabled].uil-dl-icon-TimeIcon:before {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
[disabled].uil-dl-icon-Closing:before {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.disabled.uil-timepicker-default,
.disabled.uil-timepicker-error_text_area {
  border: 1px solid var(--color-border-default);
  color: var(--color-font-disabled) !important;
  cursor: not-allowed;
}
.filled-disabled.uil-timepicker-default,
.filled-disabled.uil-timepicker-error_text_area {
  background: var(--color-bg-disabled);
  border: 1px solid var(--color-border-default);
  color: var(--color-font-disabled) !important;
  cursor: not-allowed;
}
.uil-timepicker-icons {
  height: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.uil-timepicker-icons.left {
  padding-left: 8px;
}
.uil-timepicker-icons.right {
  padding-right: 8px;
}
.uil-timepicker-textfield {
  padding-left: 8px;
  padding-right: 8px;
  color: var(--color-font-dark);
  width: 64px;
}
body {
  -ms-scrollbar-arrow-color: #767676;
  -ms-scrollbar-base-color: transparent;
  scrollbar-track-color: #f4f4f4;
  scrollbar-face-color: #c1c1c1;
  scrollbar-highlight-color: transparent;
  scrollbar-darkshadow-color: transparent;
  scrollbar-shadow-color: transparent;
}
.uil-scrollbar-thumb {
  position: absolute;
  height: 0;
  width: 6px;
  z-index: 1000;
  background-color: #000;
  opacity: 0.6;
  top: 0;
  right: 0;
  border-radius: 19px;
  visibility: hidden;
}
.uil-scrollbar-thumb.visible {
  visibility: visible;
}
.uil-scrollbar-thumb.dragging,
.uil-scrollbar-thumb:hover {
  width: 12px;
  visibility: visible;
}
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.uil-dropdown-menu,
.uil-dropdown-menu-list {
  scrollbar-width: thin;
  -ms-overflow-style: none;
}
.uil-dropdown-menu-list::-webkit-scrollbar,
.uil-dropdown-menu::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
  border-radius: 19px;
  background-color: #fff;
}
.uil-dropdown-menu-list::-webkit-scrollbar-thumb,
.uil-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.6;
}
.on-scrollbar,
.on-scrollbar-static {
  scrollbar-width: thin;
  -ms-overflow-style: none;
}
.uil-dynamic-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
  background-color: transparent;
}
.uil-dynamic-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent;
  display: none;
  opacity: 0;
}
.uil-dynamic-scrollbar::-webkit-scrollbar-track-piece {
  display: none;
  opacity: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 19px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.6;
}
.on-scrollbar-static::-webkit-scrollbar,
.on-scrollbar::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
  border-radius: 19px;
}
.content-footer-wrapper.on-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  border-radius: 19px;
}
.on-scrollbar-static::-webkit-scrollbar-track,
.on-scrollbar::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  display: none;
  opacity: 0;
}
.on-scrollbar-static::-webkit-scrollbar-track-piece,
.on-scrollbar::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-track-piece {
  display: none;
  opacity: 0;
}
.on-scrollbar-static::-webkit-scrollbar-thumb,
.on-scrollbar::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.6;
}
.uil-scrollbar-area {
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 100vh;
  background-color: transparent;
  z-index: 1000;
}
.uil-scrollbar-area:hover .uil-scrollbar-thumb {
  visibility: visible;
}
@-webkit-keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.uil-spinner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f5f5f5;
}
.circle.large {
  width: 6px;
  height: 6px;
  background-color: #d72996;
  border-radius: 50%;
  margin-left: 70px;
  margin-top: 7px;
  z-index: 2;
  position: absolute;
}
.spinner.large {
  width: 80px;
  height: 80px;
  border-top: 8px solid #d72996;
  border-right: 5px solid transparent;
  border-radius: 50%;
  margin: auto;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
  position: relative;
  z-index: 1;
}
.spinner.small {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-top: 3px solid #d72996;
  border-right: 3px solid transparent;
  border-radius: 50%;
  margin: auto;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
}
.spinner.small .circle {
  position: relative;
  z-index: 3;
}
.white-spinner {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #e5e5e5;
  margin: auto;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.circle.small {
  width: 3px;
  height: 3px;
  background-color: #d72996;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: 0;
}
.spinner.large,
.spinner.small,
.white-spinner {
  -webkit-box-sizing: unset;
  box-sizing: unset;
}
.uil-toggle {
  display: inline-block;
  position: relative;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 16px;
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.25px;
  line-height: 1.4;
}
.uil-toggle label {
  display: block;
  position: relative;
  min-width: 34px;
  height: 48px;
  border-radius: 50%;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: absolute;
  border-radius: 2px;
  opacity: 1;
  margin-top: 0;
}
.uil-toggle label.uil-form-item-error-text,
.uil-toggle label.uil-form-item-help-text {
  margin-left: 0;
  margin-top: 4px;
  margin-bottom: 8px;
  padding-left: 0;
  border: none;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  max-height: 100%;
  top: 0;
}
.uil-toggle
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 13px;
  background-color: var(--color-bg-disabledDark);
  content: "";
}
.uil-toggle
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  color: var(--color-font-disabled);
  display: block;
  position: absolute;
  top: 1px;
  left: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 50%;
  background-color: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  content: "";
}
.uil-toggle input[type="checkbox"] {
  position: absolute;
  top: 22px;
  left: -14px;
  width: 100%;
  height: 15px;
  visibility: visible;
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.uil-toggle input[type="checkbox"]:checked {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}
.uil-toggle input[type="checkbox"]:checked ~ label::before {
  color: var(--color-font-disabled);
  background-color: var(--color-bg-primaryLight);
}
.uil-toggle input[type="checkbox"]:checked ~ label::after {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
  background-color: var(--color-bg-light);
}
.uil-toggle input[type="checkbox"]:checked:disabled ~ label::after {
  background-color: var(--color-font-disabled);
}
.uil-toggle input[type="checkbox"]:checked[disabled] ~ label::after {
  background-color: var(--color-font-disabled);
}
.uil-toggle input[type="checkbox"]:disabled,
.uil-toggle input[type="checkbox"][disabled] {
  cursor: not-allowed;
}
.uil-toggle input[type="checkbox"]:disabled ~ .uil-toggle-background::before,
.uil-toggle input[type="checkbox"][disabled] ~ .uil-toggle-background::before {
  background-color: var(--color-bg-disabledDark);
}
.uil-toggle input[type="checkbox"]:disabled .uil-toggle-knob,
.uil-toggle input[type="checkbox"][disabled] .uil-toggle-knob {
  border-width: 1px;
  border-color: var(--color-border-light);
  background-color: var(--color-bg-disabledDark);
}
.uil-toggle
  input[type="checkbox"]:focus
  ~ .uil-toggle-background
  .uil-toggle-knob::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.uil-toggle
  input[type="checkbox"]
  label:not(.uil-form-item-help-text):not(
    .uil-form-item-error-text
  ):focus:after,
.uil-toggle input[type="checkbox"]:focus ~ label:first-of-type:after {
  outline: 0;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-toggle-positive input[type="checkbox"]:checked ~ label::after {
  background-color: var(--color-font-greenLight);
}
.uil-toggle-positive input[type="checkbox"]:checked:disabled ~ label::after {
  background-color: var(--color-bg-red);
}
.uil-toggle.disabled,
.uil-toggle[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-toggle.disabled label,
.uil-toggle[disabled] label {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-toggle.disabled label::after,
.uil-toggle[disabled] label::after {
  color: var(--color-font-disabled);
  background-color: var(--color-font-disabled);
}
label + .uil-toggle {
  padding-top: 6px;
}
label + .uil-toggle label {
  top: 8px;
}
.uil-toggleSwitch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
  margin-left: 40px;
  padding-top: 22px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 16px;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.25px;
  line-height: 1.4;
}
.uil-toggleSwitch label {
  display: block;
  position: relative;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  border-radius: 2px;
  opacity: 1;
  margin-top: 0;
  margin-left: -70px;
}
.uil-toggleSwitch label.uil-form-item-error-text,
.uil-toggleSwitch label.uil-form-item-help-text {
  margin-left: 0;
  margin-top: 4px;
  margin-bottom: 8px;
  padding-left: 0;
  border: none;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  max-height: 100%;
  top: 0;
  left: -1px;
}
.uil-toggleSwitch
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--color-bg-disabledDark);
  content: "";
}
.uil-toggleSwitch
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  color: var(--color-font-disabled);
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}
.uil-toggleSwitch label.Square {
  border-radius: 0;
}
.uil-toggleSwitch input[type="checkbox"] {
  position: absolute;
  top: 22px;
  left: -14px;
  visibility: visible;
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.uil-toggleSwitch input[type="checkbox"]:checked {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
  margin-left: 0;
}
.uil-toggleSwitch input[type="checkbox"]:checked ~ label::before {
  color: var(--color-font-disabled);
  background-color: var(--color-font-disabled);
}
.uil-toggleSwitch input[type="checkbox"]:checked ~ label::after {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}
.uil-toggleSwitch input[type="checkbox"]:disabled,
.uil-toggleSwitch input[type="checkbox"][disabled] {
  cursor: not-allowed;
}
.uil-toggleSwitch
  input[type="checkbox"]:disabled
  ~ uil-toggle-background::before,
.uil-toggleSwitch
  input[type="checkbox"][disabled]
  ~ uil-toggle-background::before {
  background-color: var(--color-bg-disabledDark);
}
.uil-toggleSwitch input[type="checkbox"]:disabled uil-toggle-knob,
.uil-toggleSwitch input[type="checkbox"][disabled] uil-toggle-knob {
  border-width: 1px;
  border-color: var(--color-border-light);
  background-color: var(--color-bg-disabledDark);
}
.uil-toggleSwitch
  input[type="checkbox"]:focus
  ~ uil-toggle-background
  uil-toggle-knob::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.uil-toggleSwitch
  input[type="checkbox"]
  label:not(.uil-form-item-help-text):not(
    .uil-form-item-error-text
  ):focus:after,
.uil-toggleSwitch input[type="checkbox"]:focus ~ label:first-of-type:after {
  outline: 0;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-toggleSwitch .leftContent {
  margin-left: -210px;
}
.uil-toggleSwitch .rightContent {
  margin-left: 85px;
}
.uil-toggleSwitch-positive input[type="checkbox"]:checked ~ label::after {
  background-color: var(--color-font-greenLight);
}
.uil-toggleSwitch-styleSquare input[type="checkbox"]:checked ~ label::after {
  border-radius: 0;
  color: var(--color-font-primarylight);
  left: 12px;
}
.uil-toggleSwitch-styleSquare input[type="checkbox"]:checked ~ label::before {
  border-radius: 0;
  background-color: var(--color-bg-light);
}
.uil-toggleSwitch-styleSquare::after {
  border-radius: 2px;
  background-color: var(--color-bg-light);
}
.uil-toggleSwitch-styleSquare::before {
  border-radius: 2px;
  border: 1px solid var(--color-border-default);
}
.uil-toggleSwitch-stylePositiveSquare
  input[type="checkbox"]:checked
  ~ label::after {
  border-radius: 0;
  color: var(--color-font-greenLight);
  left: 12px;
}
.uil-toggleSwitch-stylePositiveSquare
  input[type="checkbox"]:checked
  ~ label::before {
  background-color: var(--color-font-greenLight);
  border: 1px solid var(--color-border-default);
}
.uil-toggleSwitch-stylePositiveSquare::after {
  border-radius: 2px;
  background-color: var(--color-bg-light);
}
.uil-toggleSwitch-stylePositiveSquare::before {
  border-radius: 2px;
  border: 1px solid var(--color-border-default);
}
.uil-toggleSwitch-styleRound {
  border-radius: 20px;
}
.uil-toggleSwitch-styleRound input[type="checkbox"]:checked ~ label::after {
  background-color: var(--color-bg-light);
  left: 12px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
}
.uil-toggleSwitch-styleRound input[type="checkbox"]:checked ~ label::before {
  background-color: var(--color-bg-primaryLight);
  border-radius: 20px;
}
.uil-toggleSwitch-styleRound::after {
  background-color: var(--color-bg-light);
  border-radius: 12px;
}
.uil-toggleSwitch-styleRound::before {
  border-radius: 15px;
}
.uil-toggleSwitch.uil-togglelarge
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  height: 27px;
  width: 27px;
  top: 2.5px;
  margin-left: 2px;
}
.uil-toggleSwitch.uil-togglelarge
  input[type="checkbox"]:checked
  ~ label::after {
  left: 19px;
}
.uil-toggleSwitch.uil-togglemedium
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  height: 21px;
  width: 21px;
  top: 1.5px;
  margin-left: 2px;
}
.uil-toggleSwitch.uil-togglemedium
  input[type="checkbox"]:checked
  ~ label::after {
  left: 10px;
}
.uil-toggleSwitch.uil-togglesmall
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  height: 13px;
  width: 13px;
  top: 1.5px;
  margin-left: 2px;
}
.uil-toggleSwitch.uil-togglesmall
  input[type="checkbox"]:checked
  ~ label::after {
  left: 2px;
}
.uil-toggleSwitch-stylePositiveRound
  input[type="checkbox"]:checked
  ~ label::after {
  background-color: var(--color-bg-light);
  left: 12px;
}
.uil-toggleSwitch-stylePositiveRound
  input[type="checkbox"]:checked
  ~ label::before {
  background-color: var(--color-font-greenLight);
}
.uil-toggleSwitch-stylePositiveRound::after {
  background-color: var(--color-bg-light);
  border-radius: 12px;
}
.uil-toggleSwitch-stylePositiveRound::before {
  border-radius: 15px;
}
.uil-toggleSwitch.disabled,
.uil-toggleSwitch[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-toggleSwitch.disabled label,
.uil-toggleSwitch[disabled] label {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-toggleSwitch.disabled label::after,
.uil-toggleSwitch[disabled] label::after {
  color: var(--color-font-disabled);
  background-color: var(--color-font-disabled);
}
label + .uil-toggleSwitch {
  padding-top: 6px;
}
label + .uil-toggleSwitch label {
  left: -28px;
}
.uil-toggle-container- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.toggle-left {
  position: relative;
  top: 20px;
  padding-top: 3px;
  font-size: var(--font-size-body-sm);
  left: 30px;
  width: 150px;
  text-align: right;
}
.toggle-right {
  position: relative;
  top: 20px;
  margin-left: 30px;
  padding-top: 3px;
  font-size: var(--font-size-body-sm);
  right: 20px;
  width: 150px;
}
.uil-togglelarge .toggle-label {
  margin-left: 0;
  top: 0;
}
.uil-togglemedium .toggle-label {
  margin-left: 0;
  top: 3px;
}
.uil-togglesmall .toggle-label {
  margin-left: 0;
  top: 12px;
}
.uil-togglelarge .toggle-input {
  margin-left: 10px;
}
.uil-togglemedium .toggle-input {
  margin-left: 15px;
  top: 11px;
}
.uil-togglesmall .toggle-input {
  margin-left: 15px;
  top: 12px;
}
.uil-togglelarge {
  height: 32px;
  min-width: 64px;
}
.uil-togglemedium {
  height: 24px;
  min-width: 48px;
}
.uil-togglesmall {
  height: 16px;
  min-width: 32px;
}
.toggle-leftcontent {
  padding-top: 3px;
  font-size: var(--font-size-body-sm);
  padding-right: 8px;
  width: 120px;
  text-align: left;
  font-family: Graphik;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-dark);
}
.toggle-leftcontent-bold {
  padding-top: 3px;
  font-size: var(--font-size-body-sm);
  padding-right: 8px;
  width: 120px;
  text-align: left;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: var(--color-font-dark);
}
.toggle-leftcontent[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.toggle-leftcontent-bold[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.toggle-rightcontent {
  padding-top: 3px;
  padding-left: 8px;
  font-size: var(--font-size-body-sm);
  left: 0;
  width: 120px;
  font-family: Graphik;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-dark);
}
.toggle-rightcontent-bold {
  padding-top: 3px;
  padding-left: 8px;
  font-size: var(--font-size-body-sm);
  left: 0;
  width: 120px;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: var(--color-font-dark);
}
.toggle-rightcontent[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.toggle-rightcontent-bold[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-toggle-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  left: -3px;
}
.toggle-table-leftcontent {
  text-align: right;
  padding-right: 8px;
}
.toggle-table-rightcontent {
  text-align: right;
  padding-left: 8px;
}
.uil-toggle-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 16px;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  max-width: 100px;
  margin-left: 0;
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.25px;
  line-height: 1.4;
}
.uil-toggle-switch label {
  display: block;
  position: relative;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  border-radius: 2px;
  opacity: 1;
  margin-top: 0;
}
.uil-toggle-switch label.uil-form-item-error-text,
.uil-toggle-switch label.uil-form-item-help-text {
  margin-left: 0;
  margin-top: 4px;
  margin-bottom: 8px;
  padding-left: 0;
  border: none;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  max-height: 100%;
  top: 0;
  left: -1px;
}
.uil-toggle-switch
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--color-bg-disabledDark);
  content: "";
  border: 1px solid var(--color-border-default);
}
.uil-toggle-switch
  label:not(.uil-form-item-help-text):not(
    .uil-form-item-error-text
  )[disabled]::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--color-bg-disabled);
  content: "";
  border: 1px solid var(--color-border-light1);
}
.uil-toggle-switch
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  color: var(--color-font-disabled);
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}
.uil-toggle-switch label.Square {
  border-radius: 0;
}
.uil-toggle-switch input[type="checkbox"] {
  position: absolute;
  top: 22px;
  visibility: visible;
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 110ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 110ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.uil-toggle-switch input[type="checkbox"][disabled] ~ label::before {
  background-color: var(--color-bg-disabled);
  border: 1px solid var(--color-border-light1);
}
.uil-toggle-switch input[type="checkbox"]:checked {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
  right: 12px;
}
.uil-toggle-switch input[type="checkbox"][disabled]:checked ~ label::before {
  background-color: var(--color-bg-disabled);
  border: 1px solid var(--color-border-light1);
}
.uil-toggle-switch input[type="checkbox"]:checked ~ label::before {
  color: var(--color-font-disabled);
  background-color: var(--color-font-disabled);
}
.uil-toggle-switch input[type="checkbox"]:checked:disabled ~ label::before {
  color: var(--color-font-disabled);
  background-color: var(--color-bg-disabled);
}
.uil-toggle-switch input[type="checkbox"]:checked ~ label::after {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}
.uil-toggle-switch input[type="checkbox"]:checked:disabled ~ label::after {
  background-color: var(--color-font-disabled);
}
.uil-toggle-switch input[type="checkbox"]:checked[disabled] ~ label::after {
  background-color: var(--color-bg-disabledDark);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-toggle-switch input[type="checkbox"]:disabled,
.uil-toggle-switch input[type="checkbox"][disabled] {
  cursor: not-allowed;
}
.uil-toggle-switch
  input[type="checkbox"]:disabled
  ~ uil-toggle-background::before,
.uil-toggle-switch
  input[type="checkbox"][disabled]
  ~ uil-toggle-background::before {
  background-color: var(--color-bg-disabledDark);
}
.uil-toggle-switch input[type="checkbox"]:disabled uil-toggle-knob,
.uil-toggle-switch input[type="checkbox"][disabled] uil-toggle-knob {
  border-width: 1px;
  background-color: var(--color-bg-disabledDark);
}
.uil-toggle-switch
  input[type="checkbox"]:focus
  ~ uil-toggle-background
  uil-toggle-knob::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.uil-toggle-switch
  input[type="checkbox"]
  label:not(.uil-form-item-help-text):not(
    .uil-form-item-error-text
  ):focus:after,
.uil-toggle-switch input[type="checkbox"]:focus ~ label:first-of-type:after {
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
.uil-toggle-switch .leftContent {
  margin-left: -210px;
}
.uil-toggle-switch .rightContent {
  margin-left: 85px;
}
.uil-toggle-switch-positive input[type="checkbox"]:checked ~ label::after {
  background-color: var(--color-font-greenLight);
}
.uil-toggle-switch-styleSquare input[type="checkbox"]:checked ~ label::after {
  border-radius: 3px;
  color: var(--color-font-primarylight);
  left: 12px;
}
.uil-toggle-switch-styleSquare input[type="checkbox"]:checked ~ label::before {
  border-radius: 3px;
  background-color: var(--color-bg-light);
}
.uil-toggle-switch-styleSquare::after {
  border-radius: 3px;
  background-color: var(--color-bg-primaryLight);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
}
.uil-toggle-switch-styleSquare
  input[type="checkbox"]:not(:checked)
  ~ label::after {
  border-radius: 3px;
  background-color: var(--color-bg-light);
}
.uil-toggle-switch-styleSquare[disabled]::after {
  border-radius: 3px;
  background-color: var(--color-bg-disabledDark) !important;
}
.uil-toggle-switch-styleSquare::before {
  border-radius: 3px;
  border: 1px solid var(--color-border-default);
}
.uil-toggle-switch-stylePositiveSquare
  input[type="checkbox"]:checked
  ~ label::after {
  border-radius: 3px;
  color: var(--color-font-greenLight);
  left: 12px;
}
.uil-toggle-switch-stylePositiveSquare
  input[type="checkbox"]:checked
  ~ label::before {
  background-color: var(--color-bg-light);
  border: 1px solid var(--color-border-default);
}
.uil-toggle-switch-stylePositiveSquare::after {
  border-radius: 3px;
  background-color: var(--color-font-greenLight);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
}
.uil-toggle-switch-stylePositiveSquare
  input[type="checkbox"]:not(:checked)
  ~ label::after {
  border-radius: 3px;
  background-color: var(--color-bg-light);
}
.uil-toggle-switch-stylePositiveSquare[disabled]::after {
  border-radius: 3px;
  background-color: var(--color-bg-disabledDark) !important;
}
.uil-toggle-switch-stylePositiveSquare::before {
  border-radius: 3px;
  border: 1px solid var(--color-border-default);
}
.uil-toggle-switch-styleRound input[type="checkbox"]:checked ~ label::after {
  background-color: var(--color-bg-light);
  left: 12px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
}
.uil-toggle-switch-styleRound input[type="checkbox"]:checked ~ label::before {
  background-color: var(--color-bg-primaryLight);
  border-radius: 50px;
}
.uil-toggle-switch-styleRound
  input[type="checkbox"]:checked:disabled
  ~ label::before {
  background-color: var(--color-bg-disabled);
  border-radius: 50px;
  border: 1px solid var(--color-border-default);
}
[disabled].uil-toggle-switch-styleRound
  input[type="checkbox"]:checked
  ~ label::before {
  border-radius: 50px;
}
.uil-toggle-switch-styleRound::after {
  background-color: var(--color-bg-light);
  border-radius: 50px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
}
.uil-toggle-switch-styleRound[disabled]::after {
  background-color: var(--color-bg-disabledDark) !important;
  border-radius: 12px;
}
.uil-toggle-switch-styleRound::before {
  border-radius: 50px;
}
.uil-toggle-switch.uil-togglelarge
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  height: 26px;
  width: 26px;
  top: 3px;
  margin-left: 2.5px;
}
.uil-toggle-switch.uil-togglelarge
  input[type="checkbox"]:checked
  ~ label::after {
  left: 18px;
}
.uil-toggle-switch.uil-togglemedium
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  height: 20px;
  width: 20px;
  top: 2px;
  margin-left: 3px;
}
.uil-toggle-switch.uil-togglemedium
  input[type="checkbox"]:checked
  ~ label::after {
  left: 9px;
}
.uil-toggle-switch.uil-togglesmall
  label:not(.uil-form-item-help-text):not(.uil-form-item-error-text)::after {
  height: 12px;
  width: 12px;
  top: 2px;
  margin-left: 2.5px;
}
.uil-toggle-switch.uil-togglesmall
  input[type="checkbox"]:checked
  ~ label::after {
  left: 1.5px;
}
.uil-toggle-switch-stylePositiveRound
  input[type="checkbox"]:checked
  ~ label::after {
  background-color: var(--color-bg-light);
  left: 12px;
}
.uil-toggle-switch-stylePositiveRound
  input[type="checkbox"]:checked
  ~ label::before {
  background-color: var(--color-font-greenLight);
}
.uil-toggle-switch-stylePositiveRound::after {
  background-color: var(--color-bg-light);
  border-radius: 12px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
}
.uil-toggle-switch-stylePositiveRound[disabled]::after {
  background-color: var(--color-bg-disabledDark) !important;
  border-radius: 12px;
}
.uil-toggle-switch-stylePositiveRound::before {
  border-radius: 15px;
}
.uil-toggle-switch.disabled,
.uil-toggle-switch[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-toggle-switch.disabled label,
.uil-toggle-switch[disabled] label {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-toggle-switch.disabled label::after,
.uil-toggle-switch[disabled] label::after {
  background-color: var(--color-bg-disabled);
  -webkit-box-shadow: none;
  box-shadow: none;
}
label + .uil-toggle-switch {
  padding-top: 6px;
}
label + .uil-toggle-switch label {
  left: -28px;
}
.toggle-content {
  position: relative;
  top: 3px;
  padding-left: 25px;
  font-size: var(--font-size-body-sm);
  left: 0;
  width: 120px;
  font-family: Graphik;
  font-style: normal;
  font-weight: var(--font-weight-sm);
  line-height: 120%;
}
.uil-slider-thumb {
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 9px);
  background: var(--color-bg-primaryLight);
  border-radius: 50%;
}
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
}
input[type="range"]:focus ~ .uil-slider-track-fill {
  outline: 0;
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
input[type="range"]:focus ~ .uil-slider-thumb {
  -webkit-transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: -webkit-box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s;
  transition: box-shadow 0.25s ease-out 0s, -webkit-box-shadow 0.25s ease-out 0s;
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 8px;
  width: 100%;
  background: 0 0;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  background: 0 0;
  cursor: pointer;
  margin-top: -8px;
}
input[type="range"]::-moz-range-track {
  height: 8px;
  width: 100%;
  background: 0 0;
}
input[type="range"]::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: 0 0;
  cursor: pointer;
}
input[type="range"]::-moz-range-progress {
  background: 0 0;
  height: 50%;
  border-radius: 12px;
}
input[type="range"]::-ms-track {
  height: 8px;
  width: 100%;
  background: 0 0;
  background: 0 0;
  border-color: transparent;
  border-width: 10px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  border-radius: 12px;
}
input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 12px;
}
input[type="range"]::-ms-thumb {
  width: 12px;
  height: 12px;
  background: 0 0;
  cursor: pointer;
}
.uil-slider-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 0;
  width: 100%;
}
.uil-slider-container.disabled,
.uil-slider-container[disabled] {
  cursor: not-allowed;
}
.uil-slider-container.disabled .uil-slider-track-fill,
.uil-slider-container[disabled] .uil-slider-track-fill {
  background: var(--color-bg-disabledDark);
  cursor: not-allowed;
}
.uil-slider-container.disabled .uil-slider-track,
.uil-slider-container[disabled] .uil-slider-track {
  cursor: not-allowed;
}
.uil-slider-container.disabled .uil-slider-thumb,
.uil-slider-container[disabled] .uil-slider-thumb {
  cursor: not-allowed;
  background: var(--color-bg-disabledDark);
}
.uil-slider-container.uil-input-error-state {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
.uil-slider-track {
  position: absolute;
  width: 652px;
  height: 2px;
  left: 18px;
  top: 56px;
  margin-left: 12px;
  background: var(--color-bg-disabledDark);
}
.uil-slider-track-fill {
  position: absolute;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 2px;
  top: 0;
  background: var(--color-bg-primaryLight);
}
.uil-slider {
  -webkit-appearance: none;
  position: absolute;
  width: 100%;
  outline: 0;
  opacity: 0.9;
  background: 0 0;
  z-index: 1010;
  -webkit-transition: 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.uil-slider.disabled,
.uil-slider[disabled] {
  cursor: not-allowed;
}
input[type="range"][disabled] {
  cursor: not-allowed;
  background: 0 0;
}
input[type="range"][disabled]::-ms-thumb {
  cursor: not-allowed;
  background: 0 0;
}
input[type="range"][disabled]::-moz-range-thumb {
  cursor: not-allowed;
  background: 0 0;
}
input[type="range"][disabled]::-webkit-slider-thumb {
  cursor: not-allowed;
  background: 0 0;
}
input[type="range"][disabled]::-moz-range-progress {
  background: 0 0;
}
input[type="range"][disabled]::-ms-fill-lower {
  background: 0 0;
}
.uil-slider-minimum-value {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  line-height: 120%;
  bottom: 2px;
  position: relative;
  color: var(--color-bg-dark);
}
.uil-slider-minimum-value.disabled,
.uil-slider-minimum-value[disabled] {
  cursor: not-allowed;
  color: var(--color-bg-disabledDark);
}
.uil-slider-maximum-value {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  position: relative;
  width: 26px;
  height: 17px;
  left: 668px;
  bottom: 11px;
  font-style: normal;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-bg-dark);
}
.uil-slider-maximum-value.disabled,
.uil-slider-maximum-value[disabled] {
  cursor: not-allowed;
  color: var(--color-bg-disabledDark);
}
.uil-slider-size-note {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  width: 46px;
  height: 32px;
  font-style: normal;
  line-height: 120%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: var(--color-font-greyDark);
  position: relative;
  left: 710px;
  bottom: 34px;
  border: 1px solid var(--color-border-default);
  padding-top: 6px;
}
.uil-slider-size-note.disabled,
.uil-slider-size-note[disabled] {
  cursor: not-allowed;
  color: var(--color-bg-disabledDark);
  background: var(--color-bg-disabled);
}
.uil-slider-error-size-note {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  width: 46px;
  height: 32px;
  font-style: normal;
  line-height: 120%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: var(--color-font-greyDark);
  position: relative;
  left: 710px;
  bottom: 34px;
  border: 1px solid var(--color-border-error);
  padding-top: 6px;
}
.uil-slider-error-size-note.disabled,
.uil-slider-error-size-note[disabled] {
  cursor: not-allowed;
  border: 1px solid var(--color-border-default);
  color: var(--color-bg-disabledDark);
  background: var(--color-bg-disabled);
}
.uil-percentage {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  width: 12px;
  height: 17px;
  font-style: normal;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-bg-dark);
  position: relative;
  left: 765px;
  bottom: 60px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-percentage.disabled,
.uil-percentage[disabled] {
  cursor: not-allowed;
  color: var(--color-bg-disabledDark);
}
.uil-slider-errormessage {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  height: 30px;
  font-style: normal;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-font-red);
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.uil-slider-errormessage.disabled,
.uil-slider-errormessage[disabled] {
  cursor: not-allowed;
  color: var(--color-bg-disabledDark);
}
.uil-slider-footnote {
  position: relative;
  bottom: 20px;
}
.uil-slider-tooltip {
  width: 16px;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
}
.uil-curtain {
  height: 100%;
  overflow: hidden;
  padding: 0;
  border: 1px solid #f1f2f4;
}
.uil-curtain-wrapper {
  width: 100%;
  height: 100%;
}
.uil-curtain-panel {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
@media (prefers-reduced-motion: reduce) {
  .uil-curtain-panel {
    -webkit-transition: none;
    transition: none;
  }
}
.uil-curtain-prize {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.uil-curtain-prize.curtain-prize-hidden:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .uil-curtain-prize.curtain-prize-hidden:after {
    -webkit-transition: none;
    transition: none;
  }
}
.uil-curtain-prize:after {
  content: "";
}
.uil-curtain-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-curtain-modal-navbar {
  top: 60px;
  bottom: auto;
  z-index: 999;
}
.uil-curtain-open {
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #f1f2f4;
}
.uil-curtain-close {
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #f1f2f4;
}
.uil-curtain-panel-content {
  direction: ltr;
  height: 100%;
}
.uil-curtain-panel-left {
  text-align: left;
}
.uil-curtain-panel-left > .uil-curtain-open {
  display: none;
  direction: rtl;
}
.uil-curtain-panel-left > .uil-curtain-open * {
  direction: ltr;
}
.uil-curtain-panel-left > .uil-curtain-close {
  display: inherit;
  direction: rtl;
  height: 100%;
  float: right;
}
.uil-curtain-panel-left > .uil-curtain-close * {
  direction: ltr;
}
.uil-curtain-panel-left.active > .uil-curtain-open {
  display: inherit;
  height: 100%;
  float: right;
  direction: rtl;
}
.uil-curtain-panel-left.active > .uil-curtain-open * {
  direction: ltr;
}
.uil-curtain-panel-left.active > .uil-curtain-close {
  display: none;
  direction: rtl;
}
.uil-curtain-panel-left.active > .uil-curtain-close * {
  direction: ltr;
}
.uil-curtain-panel-right {
  text-align: left;
}
.uil-curtain-panel-right > .uil-curtain-open {
  display: none;
}
.uil-curtain-panel-right > .uil-curtain-close {
  display: inherit;
  height: 100%;
  float: left;
}
.uil-curtain-panel-right.active > .uil-curtain-open {
  display: inherit;
  height: 100%;
  float: left;
}
.uil-curtain-panel-right.active > .uil-curtain-close,
.uil-curtain-panel-right.active > .uil-curtain-open ~ .uil-curtain-close {
  display: none;
}
.uil-curtain-panel-top {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.uil-curtain-panel-top .uil-curtain-open {
  display: none;
}
.uil-curtain-panel-top .uil-curtain-close {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-top.active .uil-curtain-open {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-top.active .uil-curtain-close {
  display: none;
}
.uil-curtain-panel-bottom {
  text-align: left;
}
.uil-curtain-panel-bottom .uil-curtain-open {
  display: none;
}
.uil-curtain-panel-bottom .uil-curtain-close {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-bottom.active .uil-curtain-open {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-bottom.active .uil-curtain-close {
  display: none;
}
.uil-curtain-open-0.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-left .uil-curtain-open {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-left .uil-curtain-close {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-right .uil-curtain-open {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-right .uil-curtain-close {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-top .uil-curtain-open {
  height: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-top .uil-curtain-close {
  height: 0%;
  top: 100%;
}
.uil-curtain-open-0.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 0%;
}
.uil-curtain-open-5.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-95%);
  transform: translateX(-95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-left .uil-curtain-open {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-left .uil-curtain-close {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-right {
  -webkit-transform: translateX(95%);
  transform: translateX(95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-right .uil-curtain-open {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-right .uil-curtain-close {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-95%);
  transform: translateY(-95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-top .uil-curtain-open {
  height: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-top .uil-curtain-close {
  height: 5%;
  top: 95%;
}
.uil-curtain-open-5.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(95%);
  transform: translateY(95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 5%;
}
.uil-curtain-open-10.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-90%);
  transform: translateX(-90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-left .uil-curtain-open {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-left .uil-curtain-close {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-right {
  -webkit-transform: translateX(90%);
  transform: translateX(90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-right .uil-curtain-open {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-right .uil-curtain-close {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-90%);
  transform: translateY(-90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-top .uil-curtain-open {
  height: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-top .uil-curtain-close {
  height: 10%;
  top: 90%;
}
.uil-curtain-open-10.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(90%);
  transform: translateY(90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 10%;
}
.uil-curtain-open-15.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-85%);
  transform: translateX(-85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-left .uil-curtain-open {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-left .uil-curtain-close {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-right {
  -webkit-transform: translateX(85%);
  transform: translateX(85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-right .uil-curtain-open {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-right .uil-curtain-close {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-85%);
  transform: translateY(-85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-top .uil-curtain-open {
  height: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-top .uil-curtain-close {
  height: 15%;
  top: 85%;
}
.uil-curtain-open-15.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(85%);
  transform: translateY(85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 15%;
}
.uil-curtain-open-20.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-80%);
  transform: translateX(-80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-left .uil-curtain-open {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-left .uil-curtain-close {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-right {
  -webkit-transform: translateX(80%);
  transform: translateX(80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-right .uil-curtain-open {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-right .uil-curtain-close {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-80%);
  transform: translateY(-80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-top .uil-curtain-open {
  height: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-top .uil-curtain-close {
  height: 20%;
  top: 80%;
}
.uil-curtain-open-20.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(80%);
  transform: translateY(80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 20%;
}
.uil-curtain-open-25.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-75%);
  transform: translateX(-75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-left .uil-curtain-open {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-left .uil-curtain-close {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-right {
  -webkit-transform: translateX(75%);
  transform: translateX(75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-right .uil-curtain-open {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-right .uil-curtain-close {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-75%);
  transform: translateY(-75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-top .uil-curtain-open {
  height: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-top .uil-curtain-close {
  height: 25%;
  top: 75%;
}
.uil-curtain-open-25.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(75%);
  transform: translateY(75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 25%;
}
.uil-curtain-open-30.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-left .uil-curtain-open {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-left .uil-curtain-close {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-right {
  -webkit-transform: translateX(70%);
  transform: translateX(70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-right .uil-curtain-open {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-right .uil-curtain-close {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-top .uil-curtain-open {
  height: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-top .uil-curtain-close {
  height: 30%;
  top: 70%;
}
.uil-curtain-open-30.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(70%);
  transform: translateY(70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 30%;
}
.uil-curtain-open-35.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-65%);
  transform: translateX(-65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-left .uil-curtain-open {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-left .uil-curtain-close {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-right {
  -webkit-transform: translateX(65%);
  transform: translateX(65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-right .uil-curtain-open {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-right .uil-curtain-close {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-65%);
  transform: translateY(-65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-top .uil-curtain-open {
  height: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-top .uil-curtain-close {
  height: 35%;
  top: 65%;
}
.uil-curtain-open-35.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(65%);
  transform: translateY(65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 35%;
}
.uil-curtain-open-40.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-60%);
  transform: translateX(-60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-left .uil-curtain-open {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-left .uil-curtain-close {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-right {
  -webkit-transform: translateX(60%);
  transform: translateX(60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-right .uil-curtain-open {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-right .uil-curtain-close {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-top .uil-curtain-open {
  height: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-top .uil-curtain-close {
  height: 40%;
  top: 60%;
}
.uil-curtain-open-40.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(60%);
  transform: translateY(60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 40%;
}
.uil-curtain-open-45.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-55%);
  transform: translateX(-55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-left .uil-curtain-open {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-left .uil-curtain-close {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-right {
  -webkit-transform: translateX(55%);
  transform: translateX(55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-right .uil-curtain-open {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-right .uil-curtain-close {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-top .uil-curtain-open {
  height: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-top .uil-curtain-close {
  height: 45%;
  top: 55%;
}
.uil-curtain-open-45.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(55%);
  transform: translateY(55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 45%;
}
.uil-curtain-open-50.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-left .uil-curtain-open {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-left .uil-curtain-close {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-right {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-right .uil-curtain-open {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-right .uil-curtain-close {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-top .uil-curtain-open {
  height: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-top .uil-curtain-close {
  height: 50%;
  top: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 50%;
}
.uil-curtain-open-55.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-45%);
  transform: translateX(-45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-left .uil-curtain-open {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-left .uil-curtain-close {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-right {
  -webkit-transform: translateX(45%);
  transform: translateX(45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-right .uil-curtain-open {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-right .uil-curtain-close {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-top .uil-curtain-open {
  height: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-top .uil-curtain-close {
  height: 55%;
  top: 45%;
}
.uil-curtain-open-55.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(45%);
  transform: translateY(45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 55%;
}
.uil-curtain-open-60.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-40%);
  transform: translateX(-40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-left .uil-curtain-open {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-left .uil-curtain-close {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-right {
  -webkit-transform: translateX(40%);
  transform: translateX(40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-right .uil-curtain-open {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-right .uil-curtain-close {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-top .uil-curtain-open {
  height: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-top .uil-curtain-close {
  height: 60%;
  top: 40%;
}
.uil-curtain-open-60.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(40%);
  transform: translateY(40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 60%;
}
.uil-curtain-open-65.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-35%);
  transform: translateX(-35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-left .uil-curtain-open {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-left .uil-curtain-close {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-right {
  -webkit-transform: translateX(35%);
  transform: translateX(35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-right .uil-curtain-open {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-right .uil-curtain-close {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-35%);
  transform: translateY(-35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-top .uil-curtain-open {
  height: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-top .uil-curtain-close {
  height: 65%;
  top: 35%;
}
.uil-curtain-open-65.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(35%);
  transform: translateY(35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 65%;
}
.uil-curtain-open-70.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-left .uil-curtain-open {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-left .uil-curtain-close {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-right {
  -webkit-transform: translateX(30%);
  transform: translateX(30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-right .uil-curtain-open {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-right .uil-curtain-close {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-top .uil-curtain-open {
  height: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-top .uil-curtain-close {
  height: 70%;
  top: 30%;
}
.uil-curtain-open-70.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 70%;
}
.uil-curtain-open-75.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-left .uil-curtain-open {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-left .uil-curtain-close {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-right {
  -webkit-transform: translateX(25%);
  transform: translateX(25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-right .uil-curtain-open {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-right .uil-curtain-close {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-25%);
  transform: translateY(-25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-top .uil-curtain-open {
  height: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-top .uil-curtain-close {
  height: 75%;
  top: 25%;
}
.uil-curtain-open-75.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(25%);
  transform: translateY(25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 75%;
}
.uil-curtain-open-80.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-20%);
  transform: translateX(-20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-left .uil-curtain-open {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-left .uil-curtain-close {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-right {
  -webkit-transform: translateX(20%);
  transform: translateX(20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-right .uil-curtain-open {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-right .uil-curtain-close {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-top .uil-curtain-open {
  height: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-top .uil-curtain-close {
  height: 80%;
  top: 20%;
}
.uil-curtain-open-80.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 80%;
}
.uil-curtain-open-85.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-15%);
  transform: translateX(-15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-left .uil-curtain-open {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-left .uil-curtain-close {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-right {
  -webkit-transform: translateX(15%);
  transform: translateX(15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-right .uil-curtain-open {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-right .uil-curtain-close {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-15%);
  transform: translateY(-15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-top .uil-curtain-open {
  height: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-top .uil-curtain-close {
  height: 85%;
  top: 15%;
}
.uil-curtain-open-85.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(15%);
  transform: translateY(15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 85%;
}
.uil-curtain-open-90.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-left .uil-curtain-open {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-left .uil-curtain-close {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-right {
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-right .uil-curtain-open {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-right .uil-curtain-close {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-top .uil-curtain-open {
  height: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-top .uil-curtain-close {
  height: 90%;
  top: 10%;
}
.uil-curtain-open-90.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 90%;
}
.uil-curtain-open-95.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-5%);
  transform: translateX(-5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-left .uil-curtain-open {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-left .uil-curtain-close {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-right {
  -webkit-transform: translateX(5%);
  transform: translateX(5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-right .uil-curtain-open {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-right .uil-curtain-close {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-5%);
  transform: translateY(-5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-top .uil-curtain-open {
  height: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-top .uil-curtain-close {
  height: 95%;
  top: 5%;
}
.uil-curtain-open-95.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(5%);
  transform: translateY(5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 95%;
}
.uil-curtain-open-100.active.uil-curtain-panel-left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-left .uil-curtain-open {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-left .uil-curtain-close {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-right .uil-curtain-open {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-right .uil-curtain-close {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-top {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-top .uil-curtain-open {
  height: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-top .uil-curtain-close {
  height: 100%;
  top: 0;
}
.uil-curtain-open-100.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 100%;
}
.uil-curtain-close-0.uil-curtain-panel-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.uil-curtain-close-0.uil-curtain-panel-left .uil-curtain-open {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-left .uil-curtain-close {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.uil-curtain-close-0.uil-curtain-panel-right .uil-curtain-open {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-right .uil-curtain-close {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.uil-curtain-close-0.uil-curtain-panel-top .uil-curtain-open {
  height: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-top .uil-curtain-close {
  height: 0%;
  top: 100%;
}
.uil-curtain-close-0.uil-curtain-panel-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.uil-curtain-close-0.uil-curtain-panel-bottom .uil-curtain-open {
  height: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-bottom .uil-curtain-close {
  height: 0%;
}
.uil-curtain-close-10.uil-curtain-panel-left {
  -webkit-transform: translateX(-90%);
  transform: translateX(-90%);
}
.uil-curtain-close-10.uil-curtain-panel-left .uil-curtain-open {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-left .uil-curtain-close {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-right {
  -webkit-transform: translateX(90%);
  transform: translateX(90%);
}
.uil-curtain-close-10.uil-curtain-panel-right .uil-curtain-open {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-right .uil-curtain-close {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-top {
  -webkit-transform: translateY(-90%);
  transform: translateY(-90%);
}
.uil-curtain-close-10.uil-curtain-panel-top .uil-curtain-open {
  height: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-top .uil-curtain-close {
  height: 10%;
  top: 90%;
}
.uil-curtain-close-10.uil-curtain-panel-bottom {
  -webkit-transform: translateY(90%);
  transform: translateY(90%);
}
.uil-curtain-close-10.uil-curtain-panel-bottom .uil-curtain-open {
  height: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-bottom .uil-curtain-close {
  height: 10%;
}
.uil-curtain-close-20.uil-curtain-panel-left {
  -webkit-transform: translateX(-80%);
  transform: translateX(-80%);
}
.uil-curtain-close-20.uil-curtain-panel-left .uil-curtain-open {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-left .uil-curtain-close {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-right {
  -webkit-transform: translateX(80%);
  transform: translateX(80%);
}
.uil-curtain-close-20.uil-curtain-panel-right .uil-curtain-open {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-right .uil-curtain-close {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-top {
  -webkit-transform: translateY(-80%);
  transform: translateY(-80%);
}
.uil-curtain-close-20.uil-curtain-panel-top .uil-curtain-open {
  height: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-top .uil-curtain-close {
  height: 20%;
  top: 80%;
}
.uil-curtain-close-20.uil-curtain-panel-bottom {
  -webkit-transform: translateY(80%);
  transform: translateY(80%);
}
.uil-curtain-close-20.uil-curtain-panel-bottom .uil-curtain-open {
  height: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-bottom .uil-curtain-close {
  height: 20%;
}
.uil-curtain-close-30.uil-curtain-panel-left {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
}
.uil-curtain-close-30.uil-curtain-panel-left .uil-curtain-open {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-left .uil-curtain-close {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-right {
  -webkit-transform: translateX(70%);
  transform: translateX(70%);
}
.uil-curtain-close-30.uil-curtain-panel-right .uil-curtain-open {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-right .uil-curtain-close {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-top {
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
}
.uil-curtain-close-30.uil-curtain-panel-top .uil-curtain-open {
  height: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-top .uil-curtain-close {
  height: 30%;
  top: 70%;
}
.uil-curtain-close-30.uil-curtain-panel-bottom {
  -webkit-transform: translateY(70%);
  transform: translateY(70%);
}
.uil-curtain-close-30.uil-curtain-panel-bottom .uil-curtain-open {
  height: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-bottom .uil-curtain-close {
  height: 30%;
}
.uil-curtain-close-40.uil-curtain-panel-left {
  -webkit-transform: translateX(-60%);
  transform: translateX(-60%);
}
.uil-curtain-close-40.uil-curtain-panel-left .uil-curtain-open {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-left .uil-curtain-close {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-right {
  -webkit-transform: translateX(60%);
  transform: translateX(60%);
}
.uil-curtain-close-40.uil-curtain-panel-right .uil-curtain-open {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-right .uil-curtain-close {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-top {
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
}
.uil-curtain-close-40.uil-curtain-panel-top .uil-curtain-open {
  height: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-top .uil-curtain-close {
  height: 40%;
  top: 60%;
}
.uil-curtain-close-40.uil-curtain-panel-bottom {
  -webkit-transform: translateY(60%);
  transform: translateY(60%);
}
.uil-curtain-close-40.uil-curtain-panel-bottom .uil-curtain-open {
  height: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-bottom .uil-curtain-close {
  height: 40%;
}
.uil-curtain-close-50.uil-curtain-panel-left {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.uil-curtain-close-50.uil-curtain-panel-left .uil-curtain-open {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-left .uil-curtain-close {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-right {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}
.uil-curtain-close-50.uil-curtain-panel-right .uil-curtain-open {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-right .uil-curtain-close {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-top {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.uil-curtain-close-50.uil-curtain-panel-top .uil-curtain-open {
  height: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-top .uil-curtain-close {
  height: 50%;
  top: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-bottom {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.uil-curtain-close-50.uil-curtain-panel-bottom .uil-curtain-open {
  height: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-bottom .uil-curtain-close {
  height: 50%;
}
.uil-curtain-close-60.uil-curtain-panel-left {
  -webkit-transform: translateX(-40%);
  transform: translateX(-40%);
}
.uil-curtain-close-60.uil-curtain-panel-left .uil-curtain-open {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-left .uil-curtain-close {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-right {
  -webkit-transform: translateX(40%);
  transform: translateX(40%);
}
.uil-curtain-close-60.uil-curtain-panel-right .uil-curtain-open {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-right .uil-curtain-close {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-top {
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%);
}
.uil-curtain-close-60.uil-curtain-panel-top .uil-curtain-open {
  height: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-top .uil-curtain-close {
  height: 60%;
  top: 40%;
}
.uil-curtain-close-60.uil-curtain-panel-bottom {
  -webkit-transform: translateY(40%);
  transform: translateY(40%);
}
.uil-curtain-close-60.uil-curtain-panel-bottom .uil-curtain-open {
  height: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-bottom .uil-curtain-close {
  height: 60%;
}
.uil-curtain-close-70.uil-curtain-panel-left {
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%);
}
.uil-curtain-close-70.uil-curtain-panel-left .uil-curtain-open {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-left .uil-curtain-close {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-right {
  -webkit-transform: translateX(30%);
  transform: translateX(30%);
}
.uil-curtain-close-70.uil-curtain-panel-right .uil-curtain-open {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-right .uil-curtain-close {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-top {
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
}
.uil-curtain-close-70.uil-curtain-panel-top .uil-curtain-open {
  height: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-top .uil-curtain-close {
  height: 70%;
  top: 30%;
}
.uil-curtain-close-70.uil-curtain-panel-bottom {
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
}
.uil-curtain-close-70.uil-curtain-panel-bottom .uil-curtain-open {
  height: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-bottom .uil-curtain-close {
  height: 70%;
}
.uil-curtain-close-80.uil-curtain-panel-left {
  -webkit-transform: translateX(-20%);
  transform: translateX(-20%);
}
.uil-curtain-close-80.uil-curtain-panel-left .uil-curtain-open {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-left .uil-curtain-close {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-right {
  -webkit-transform: translateX(20%);
  transform: translateX(20%);
}
.uil-curtain-close-80.uil-curtain-panel-right .uil-curtain-open {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-right .uil-curtain-close {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-top {
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
}
.uil-curtain-close-80.uil-curtain-panel-top .uil-curtain-open {
  height: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-top .uil-curtain-close {
  height: 80%;
  top: 20%;
}
.uil-curtain-close-80.uil-curtain-panel-bottom {
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
}
.uil-curtain-close-80.uil-curtain-panel-bottom .uil-curtain-open {
  height: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-bottom .uil-curtain-close {
  height: 80%;
}
.uil-curtain-close-90.uil-curtain-panel-left {
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
}
.uil-curtain-close-90.uil-curtain-panel-left .uil-curtain-open {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-left .uil-curtain-close {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-right {
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
}
.uil-curtain-close-90.uil-curtain-panel-right .uil-curtain-open {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-right .uil-curtain-close {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-top {
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}
.uil-curtain-close-90.uil-curtain-panel-top .uil-curtain-open {
  height: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-top .uil-curtain-close {
  height: 90%;
  top: 10%;
}
.uil-curtain-close-90.uil-curtain-panel-bottom {
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
}
.uil-curtain-close-90.uil-curtain-panel-bottom .uil-curtain-open {
  height: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-bottom .uil-curtain-close {
  height: 90%;
}
.uil-curtain-close-100.uil-curtain-panel-left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-close-100.uil-curtain-panel-left .uil-curtain-open {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-left .uil-curtain-close {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-close-100.uil-curtain-panel-right .uil-curtain-open {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-right .uil-curtain-close {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-top {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-close-100.uil-curtain-panel-top .uil-curtain-open {
  height: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-top .uil-curtain-close {
  height: 100%;
  top: 0;
}
.uil-curtain-close-100.uil-curtain-panel-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-close-100.uil-curtain-panel-bottom .uil-curtain-open {
  height: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-bottom .uil-curtain-close {
  height: 100%;
}
.uil-curtain-toggle-button-default {
  z-index: 2;
  position: absolute;
  top: 10px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #f1f2f4;
}
.uil-curtain-text-button-default {
  height: 30px;
  width: auto;
}
.uil-curtain-panel-left .uil-curtain-toggle-button-default {
  right: calc(30px * (-1));
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 0 3px 3px 0;
  border-left: none;
}
.uil-curtain-panel-right .uil-curtain-toggle-button-default {
  left: calc(30px * (-1));
  -webkit-box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 3px 0 0 3px;
  border-right: none;
}
.uil-curtain-panel-top .uil-curtain-toggle-button-default {
  left: calc(10px);
  top: auto;
  bottom: calc(30px * (-1));
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 0 0 3px 3px;
  border-top: none;
}
.uil-curtain-panel-bottom .uil-curtain-toggle-button-default {
  left: calc(10px);
  top: calc(30px * (-1));
  -webkit-box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 3px 3px 0 0;
  border-bottom: none;
}
.uil-side-close-toolicon {
  right: 200px !important;
  z-index: 1000;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  width: 36px;
  height: 64px;
  top: 0;
}
.uil-curtain-gradient-toggle-button {
  position: absolute;
  left: -2px;
  top: 0;
  z-index: 100;
  height: 120px;
  width: 120px;
  clip-path: polygon(100% 0, 0 0, 0 100%);
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .uil-curtain-gradient-toggle-button {
    background: -ms-linear-gradient(
      -48deg,
      var(--primary-light) 0,
      var(--primary-light) 50%,
      transparent 51%,
      transparent 100%
    );
    background-color: transparent !important;
  }
}
@supports (-ms-ime-align: auto) {
  .uil-curtain-gradient-toggle-button {
    background-image: -webkit-gradient(
      linear,
      left top,
      right bottom,
      from(var(--primary-light)),
      color-stop(50%, var(--primary-light)),
      color-stop(51%, transparent),
      to(transparent)
    );
    background-image: linear-gradient(
      to bottom right,
      var(--primary-light) 0,
      var(--primary-light) 50%,
      transparent 51%,
      transparent 100%
    );
    background-color: transparent !important;
  }
}
.uil-carousel {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (min-width: 480px) {
  .uil-carousel.uil-carousel-height-adjustment {
    height: 248px;
  }
}
@media screen and (min-width: 767px) {
  .uil-carousel.uil-carousel-height-adjustment {
    height: 152px;
  }
}
@media screen and (min-width: 1023px) {
  .uil-carousel.uil-carousel-height-adjustment {
    height: 120px;
  }
}
@media screen and (min-width: 1280px) {
  .uil-carousel.uil-carousel-height-adjustment {
    height: 120px;
  }
}
.carousel-indicators {
  bottom: -20px;
  margin-bottom: 0;
}
.carousel-indicators li {
  margin: 0 12px 0 12px;
  height: 16px;
  width: 16px;
  background-color: #c1c1c1;
  border: none;
  border-radius: 50%;
}
.carousel-indicators li.active {
  margin: 0 12px 0 12px;
  height: 16px;
  width: 16px;
  background-color: var(--primary-01);
  border: none;
}
.uil-carousel-item {
  color: rgba(0, 0, 0, 0.87);
  font-family: Graphik, sans-serif;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 32px;
  text-align: center;
}
.uil-carousel-slide-title {
  position: relative;
  left: 0;
  text-align: center;
  top: 0;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
  text-shadow: none;
}
.uil-carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  background-image: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  height: calc(100% - 40px);
  text-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.uil-carousel-control.left {
  left: 0;
}
.uil-carousel-control.right {
  right: 0;
  left: auto;
}
.uil-carousel-control:focus,
.uil-carousel-control:hover {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.8;
}
.carousel-inner .carousel-item .uil-carousel-slide-items,
.carousel-inner .item .uil-carousel-slide-items {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  display: none;
}
.carousel-inner .carousel-item.active .uil-carousel-slide-items,
.carousel-inner .item.active .uil-carousel-slide-items {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.splitButtonHorizontal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 8px;
  gap: 8px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
}
.splitButtonVertical {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  width: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  background: var(--color-bg-light);
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
}
.uil-SplitBtn {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  background-color: var(--color-bg-light);
  color: var(--color-font-dark);
  gap: 8px;
  padding: 0 16px;
  border: none;
  font-style: normal;
  line-height: 16px;
}
.uil-SplitBtn fieldset[disabled],
.uil-SplitBtn.disabled,
.uil-SplitBtn[disabled] {
  color: var(--color-font-disabled);
  background: var(--color-bg-disabledLight);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}
.uil-SplitBtn:not(.disabled):hover {
  color: var(--color-border-primaryLight);
}
.uil-SplitBtn-option {
  padding: 0 8px;
  bottom: 4px;
  position: relative;
}
.uil-SplitButtonSelected {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  color: var(--color-border-primaryLight);
  background: var(--color-bg-secondaryLight);
  border-radius: 4px;
  border: none;
}
.uil-SplitButtonSelected fieldset[disabled],
.uil-SplitButtonSelected.disabled,
.uil-SplitButtonSelected[disabled] {
  color: var(--color-font-disabled);
  background: var(--color-bg-disabledLight);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}
.uil-iconStyles {
  font-size: x-large;
  font-weight: 300;
  color: var(--color-font-greyDark);
}
.uil_file_upload {
  height: 240px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.uil_file_upload-browseSection {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
  gap: 8px;
  font-style: normal;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  cursor: text;
}
.uil_file_upload-input {
  display: none;
}
.uil_file_upload-label {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--color-bg-light);
  border: 1px dashed var(--color-font-disabled);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: context-menu;
}
.uil_file_upload-button {
  cursor: pointer;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  color: var(--color-font-dark);
  margin-bottom: 0;
}
.uil_file_upload-button:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: var(--primary-light);
  text-decoration-color: var(--primary-light);
  text-decoration-thickness: 2px;
}
.uil_file_upload-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
  padding-top: 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.uil_file_upload-check {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  font-size: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-bg-dark);
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil_file_upload-drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.uil_file_upload-filename,
.uil_file_upload-filesize {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xxs);
  font-weight: var(--font-weight-sm);
  line-height: 12px;
  letter-spacing: 0;
  text-align: center;
  color: var(--color-font-greyMedium);
  cursor: text;
}
.uil_file_upload-dragdrop {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 17px;
  letter-spacing: 0;
  color: var(--color-font-dark);
}

.uil-empty-label {
  font-family: Graphik;
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-md);
  line-height: 120%;
  color: var(--black);
  padding-bottom: 16px;
  padding-top: 32px;
}
.uil-empty-subHeading {
  font-family: Graphik;
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  line-height: 140%;
  text-align: center;
  color: var(--black);
}
.emptyPageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.emptyPage {
  padding: 20px;
  display: block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.uil-empty-btn {
  margin-top: 32px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -ms-flex-item-align: center;
  align-self: center;
  gap: 8px;
}
.uil-progress-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}
.uil-progress-value-container {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-height: 8px;
  width: 100%;
}
.uil-progress-value-container[disabled] {
  cursor: not-allowed;
}
.uil-progress-scale-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.uil-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  line-height: 0;
  font-size: var(--font-size-md2);
  background-color: var(--color-bg-disabledDark);
  position: relative;
  z-index: auto;
  height: 8px;
  border-radius: 4px !important;
  margin-bottom: 4px;
  display: block;
}
.uil-progress .uil-progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: var(--color-font-light);
  text-align: center;
  white-space: nowrap;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  float: left;
  height: 100%;
  background-color: var(--color-bg-primaryLight);
}
.uil-progress .uil-progress-bar:last-child:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: -1;
}
.uil-progress .uil-progress-bar:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-primary {
  background-color: var(--color-bg-secondaryDark);
}
.uil-progress .uil-progress-bar-primary:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-success {
  background-color: var(--color-bg-green);
}
.uil-progress .uil-progress-bar-success:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-danger {
  background-color: var(--color-bg-red);
}
.uil-progress .uil-progress-bar-danger:last-child.uil-progress-bar:before {
  background-color: var(--color-bg-disabledDark);
}
.uil-progress .uil-progress-bar-danger[disabled],
.uil-progress .uil-progress-bar-primary[disabled],
.uil-progress .uil-progress-bar-success[disabled] {
  background-color: var(--color-bg-disabledDark);
  cursor: not-allowed;
}

.uil-progress-icon-right {
  margin-left: 8px;
}
.uil-progress-icon-left {
  margin-right: 8px;
}

.uil-dl-icon-Closeprogress:before {
  color: var(--color-font-greyDark);
  content: "\e98e";
  position: relative;
  display: inline;
  font-weight: var(--font-weight-lg);
  margin-left: 16px;
  cursor: pointer;
}
.uil-dl-icon-Closeerrorprogress:before {
  color: var(--color-font-secondaryDark);
  content: "\e98e";
  position: relative;
  display: inline;
  font-weight: var(--font-weight-lg);
  margin-left: 16px;
  cursor: pointer;
}
[disabled].uil-dl-icon-Danger:before,
[disabled].uil-dl-icon-Play:before,
[disabled].uil-dl-icon-Success:before {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
[disabled].uil-dl-icon-Closeerrorprogress:before,
[disabled].uil-dl-icon-Closeprogress:before,
[disabled].uil-dl-icon-Pause:before,
[disabled].uil-dl-icon-Refresh:before,
[disabled].uil-size-note {
  color: var(--color-font-disabled);
  cursor: not-allowed;
  display: inline;
}
.uil-size-note {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  line-height: 16px;
  display: inline;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-font-greyMedium);
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  float: right;
  margin-left: auto;
  padding-top: 8px;
}
.uil-progress-footnote {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: inherit;
}
.uil-progress-secondtd {
  width: 50px;
  position: relative;
  top: 5px;
  caret-color: transparent;
}
.uil-shadow {
  width: 350px;
  height: 200px;
  background: #fff;
}
.uil-shadow-dark {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.uil-shadow-light {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.uil-shadow-lightg {
  -webkit-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.08);
}
.uil-shadow-spreaded {
  -webkit-box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.1);
}
.uil-shadow-tile {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.uil-shadow-disabled {
  cursor: not-allowed;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
}
.uil-custom-height {
  max-height: 70px;
}
.uil-custom-width {
  max-height: 80px;
}
.uil-disable {
  max-height: 35px;
}
.uil-footnote-label {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  color: var(--color-font-greyMedium);
  font-style: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.3px;
  padding-top: 8px;
}
.uil-footnote-disabled[disabled],
.uil-footnote-label[disabled],
.uil-footnote-none[disabled],
.uil-text-error[disabled],
.uil-text-info[disabled],
.uil-text-success[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-footnote-button-link[disabled],
.uil-text-footnote-error[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--color-font-disabled);
}
.uil-footnote-none {
  color: var(--color-font-greyMedium);
}
.uil-text-error {
  color: var(--color-font-red);
}
.uil-text-success {
  color: var(--color-font-green);
}
.uil-text-info {
  color: var(--color-font-greyMedium);
}
.uil-text-footnote-error {
  color: var(--color-font-red);
}
.uil-footnote-button-link {
  padding-left: 16px;
  color: var(--color-font-greyMedium);
}
@font-face {
  font-family: 'uil-dl-shadow';
  src:  url('../fonts/fonticons/uil-dl-shadow.eot');
  src:  url('../fonts/fonticons/uil-dl-shadow.eot') format('embedded-opentype'),
    url('../fonts/fonticons/uil-dl-shadow.ttf') format('truetype'),
    url('../fonts/fonticons/uil-dl-shadow.woff') format('woff'),
    url('../fonts/fonticons/uil-dl-shadow.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/* NEW SHADOW ICON */
[class^="uil-dl-new-"], [class*=" uil-dl-new-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'uil-dl-shadow' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
 
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
.uil-dl-new-Shadow:before {
  content: "\eac22";
}