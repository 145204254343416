/* Copyright (C) 2020 Accenture. All rights reserved. */
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
/** variable used in this file only*/
.uil-textfield-search {
  height: 48px;
}
.uil-textfield-search-large {
  height: 56px;
}
.uil-textfield-search-wrapper {
  height: 100%;
  background-color: var(--color-bg-light);
  padding: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0;
  border: 1px solid #abb0b6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.uil-textfield-search-wrapper[disabled] {
  cursor: not-allowed;
}
.uil-textfield-search-wrapper:not(.uil-textfield-search-fordropdown):not(.uil-textfield-search-error):focus-within {
  border: 1px solid var(--color-border-dark);
}
.uil-textfield-search-wrapper-background {
  background-color: var(--color-bg-disabled);
}
.uil-textfield-search-wrapper-background input {
  background: unset;
}
.uil-textfield-search-wrapper .uil-search-cancel-icon:not(.disabled):hover,
.uil-textfield-search-wrapper .uil-dl-icon-Star:not(.disabled):hover,
.uil-textfield-search-wrapper .uil-dl-icon-Filter:not(.disabled):hover {
  color: var(--color-font-primaryLight);
  font-weight: var(--font-weight-lg);
}
.uil-textfield-search-wrapper .uil-search-cancel-icon {
  padding: 0;
  font-size: var(--font-size-body-md);
  height: 15px;
  width: 23px;
  color: var(--color-font-dark);
  cursor: pointer;
}
.uil-textfield-search-wrapper .uil-search-cancel-icon:is(.disabled) {
  color: #ccd1d7;
  cursor: not-allowed;
}
.uil-textfield-search-field {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
.uil-textfield-search-icon-wrapper {
  margin: auto 8px;
  font-size: var(--font-size-body-md);
  line-height: 1;
  color: var(--color-font-dark);
  cursor: pointer;
}
.uil-textfield-search-searchIcon.disabled {
  color: #ccd1d7 !important;
  cursor: not-allowed !important;
}
.uil-textfield-search-searchIcon:not(.disabled) {
  color: var(--color-font-dark);
}
.uil-textfield-search-searchIcon:not(.disabled):hover {
  color: var(--color-font-primaryLight);
  font-weight: var(--font-weight-lg);
}
.uil-textfield-search-field-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 8px;
  visibility: hidden;
}
.uil-textfield-search-field-close-visible {
  visibility: visible;
}
.uil-textfield-search-input {
  line-height: 19.8px;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  width: 100%;
  background: unset;
}
.uil-textfield-search-input:disabled {
  cursor: not-allowed;
}
.uil-textfield-search-fordropdown {
  border: unset;
  border-bottom: 1px solid var(--color-border-default);
}
.uil-textfield-search-error {
  border: 1px solid var(--color-border-error);
}
.uil-textfield-search-error[disabled] {
  border: 1px solid var(--color-border-default);
}
.uil-textfield-search-dropdownError {
  border-bottom: 1px solid var(--color-border-error);
}
.uil-textfield-search-extraIcon {
  font-size: var(--font-size-title-md);
  border-left: 1px solid var(--color-border-default);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  cursor: pointer;
}
.uil-textfield-search-extraIcon.disabled {
  cursor: not-allowed;
}
.uil-textfield-search-filter {
  font-size: 18px;
  -ms-flex-item-align: center;
      align-self: center;
  padding: 0 8px;
}
.uil-textfield-search-filter > span:not(.disabled):hover {
  color: var(--color-font-primaryLight);
  font-weight: var(--font-weight-lg);
}
.uil-textfield-search-filter > span:is(.disabled) {
  color: #ccd1d7;
  cursor: not-allowed;
}
.uil-textfield-search-star {
  padding: 0 8px 0 16px;
}
.uil-textfield-search-star > span:not(.disabled):hover {
  color: var(--color-font-primaryLight);
  font-weight: var(--font-weight-lg);
}
.uil-textfield-search-star > span:is(.disabled) {
  color: #ccd1d7;
  cursor: not-allowed;
}
.uil-textfield-search-star-leftborder {
  border-left: 1px solid var(--color-border-dark);
}
.uil-textfield-search-clearAll {
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 14px;
  letter-spacing: 0em;
  padding-left: 8px;
  cursor: pointer;
  padding-right: 8px;
  white-space: nowrap;
}
.uil-textfield-search .uil-dl-icon-Star:is(.disabled),
.uil-textfield-search .uil-dl-icon-Filter:is(.disabled) {
  color: #ccd1d7;
}
.uil-textfield-search .uil-textfield-search-input:disabled::-webkit-input-placeholder {
  color: #ccd1d7;
}
.uil-textfield-search .uil-textfield-search-input:disabled::-moz-placeholder {
  color: #ccd1d7;
}
.uil-textfield-search .uil-textfield-search-input:disabled:-ms-input-placeholder {
  color: #ccd1d7;
}
.uil-textfield-search .uil-textfield-search-input:disabled::-ms-input-placeholder {
  color: #ccd1d7;
}
.uil-textfield-search .uil-textfield-search-input:disabled,
.uil-textfield-search .uil-textfield-search-input:disabled::placeholder {
  color: #ccd1d7;
}

input.uil-textfield-search-input[type=search]::-webkit-search-decoration,
input.uil-textfield-search-input[type=search]::-webkit-search-cancel-button,
input.uil-textfield-search-input[type=search]::-webkit-search-results-button,
input.uil-textfield-search-input[type=search]::-webkit-search-results-decoration {
  display: none;
}

.uil-search-list {
  display: inline-block;
  width: 100%;
  background-color: white;
}

.uil-search-list > ul {
  list-style-type: none;
  height: 42px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: block;
  padding: 0px;
  margin: 0;
}

.uil-search-list > ul > li {
  overflow: hidden;
  line-height: 20px;
  padding: 12px 16px;
}

.uil-search-list > ul > li:hover {
  background-color: #f5f5f5;
  color: #a100ff;
  cursor: pointer;
}

.uil-dl-icon-Search::before{
  content: "\e910";
}
.uil-dl-icon-Close::before{
  content: "\e98e";
}
.uil-dl-icon-Star::before{
  content: "\e9c0";
}
.uil-dl-icon-Filter::before{
  content: "\e9f6";
}

.uil-textfield-search-searchIcon::before{
  font-size:24px
}
/*# sourceMappingURL=uil_textfield_search.module.css.map */