@charset "UTF-8";
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
/* Copyright (C) 2020 Accenture. All rights reserved. */
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
/* Copyright (C) 2020 Accenture. All rights reserved. */
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
/* .................................... */

.table-box {
  height: 1000px;
  overflow-y: scroll;
}
.uil-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.uil-table-headercell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  gap: 8px;
  margin-bottom: 16px;
}

.uil-table-action-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-table-action-col:last-child {
  margin-left: 7px;
}

.uil-table-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
}

.table-filter {
  width: 48px;
  padding: 10px;
  border: 1px solid var(--color-border-default);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-filter span {
  font-size: 20px;
}

.uil-table.uil-table-no-border tbody tr,
.uil-table tbody.uil-table-no-border tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, transparent),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    transparent 100%,
    var(--color-border-dark)
  );
  background-position: top;
  background-size: 3px 0px;
  background-repeat: repeat-x;
}

.uil-table.uil-table-no-border tbody tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, transparent),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    transparent 100%,
    var(--color-border-dark)
  );
  background-position: top;
  background-size: 3px 0;
  background-repeat: repeat-x;
}

.uil-table.uil-table-no-border.uil-table-bottom-border tbody tr:first-child,
.uil-table tbody.uil-table-bottom-border tr,
.uil-table tbody.uil-table-bottom-border tr:first-child,
.uil-table.uil-table-bottom-border tbody tr,
.uil-table.uil-table-bottom-border tbody tr:first-child {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, transparent),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    transparent 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

.uil-table.uil-table-bottom-border tbody tr:last-child {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, var(--color-bg-disabledLight)),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    var(--color-bg-disabledLight) 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

.uil-table > tbody > tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, var(--color-bg-disabledLight)),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    var(--color-bg-disabledLight) 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
  background-color: var(--color-bg-light);
}

.uil-table > thead > tr > th {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  vertical-align: middle;
  height: 48px;
  position: sticky;
  top: 0;
}

/* .uil-table > thead {
  z-index: 2000;
  position: sticky;
} */

.uil-table-head-content {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  display: block;
  line-height: normal !important;
}

.uil-table-head-content.disabled {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  display: block;
  line-height: normal !important;
  color: var(--color-font-disabled);
  cursor: not-allowed;
}

.uil-table > tbody > tr > td {
  font-family: var(--font-family);
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  padding: 16px 8px;
  height: 56px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.uil-table-content {
  font-family: var(--font-family);
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  line-height: normal !important;
  max-width: 180px;
}

.disabled.uil-table-content {
  font-family: var(--font-family);
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  line-height: normal !important;
  color: var(--color-font-disabled);
  cursor: not-allowed;
  max-width: 180px;
}

.uil-table > thead > tr > th:first-child {
  padding-left: 24px !important;
}

.uil-table > tbody > tr > td:last-child {
  padding-right: 24px;
}

.uil-table > tbody > tr > td:first-child {
  padding-left: 24px;
}

.uil-table > thead > tr:first-child {
  padding-left: 24px;
}

.uil-table
  > tbody
  > .uil-table-lightpink-row:hover:not(.disabled):not(.selected):not(
    [disabled]
  ) {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, rgba(117, 0, 192, 0.5)),
    color-stop(20%, rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to right,
    rgba(117, 0, 192, 0.5) 100%,
    rgba(255, 255, 255, 0) 20%
  );
  background-color: #f5e5ff !important;
}

.uil-table > thead:first-child > tr:first-child > th,
.uil-table > thead:first-child > tr:first-child > td {
  border-top: 0;
  background: var(--color-bg-disabledDark);
  padding-left: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 8px;
}

.uil-table-striped tr:nth-child(even) {
  background-color: #f4f4f4;
}

.uil-table > thead > tr > th.rowcheck,
.uil-table > thead > tr > td.rowcheck,
.uil-table > tbody > tr > th.rowcheck,
.uil-table > tbody > tr > td.rowcheck {
  width: 16px;
  vertical-align: middle;
  margin-top: 0px;
  margin-left: 0px;
}

.uil-table > thead > tr > th.rowcheck > .uil-checkbox,
.uil-table
  > thead
  > tr
  > td.rowcheck
  > .uil-checkbox
  .uil-table
  > tbody
  > tr
  > th.rowcheck
  > .uil-checkbox,
.uil-table > tbody > tr > td.rowcheck > .uil-checkbox {
  min-height: 20px;
  line-height: 14px;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0;
}

.uil-table > tfoot > tr > th.rowcheck > .uil-checkbox label,
.uil-table > tfoot > tr > td.rowcheck > .uil-checkbox label,
.uil-table > thead > tr > th.rowcheck > .uil-checkbox label,
.uil-table > thead > tr > td.rowcheck > .uil-checkbox label,
.uil-table > tbody > tr > th.rowcheck > .uil-checkbox label,
.uil-table > tbody > tr > td.rowcheck > .uil-checkbox label {
  top: 7px;
  margin-left: -18px;
}

.uil-table > thead > tr {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(100%, var(--color-bg-disabledLight)),
    to(var(--color-border-dark))
  );
  background-image: linear-gradient(
    to right,
    var(--color-bg-disabledLight) 100%,
    var(--color-border-dark)
  );
  background-position: bottom;
  background-size: 3px 2px;
  background-repeat: repeat-x;
  background: var(--color-bg-disabledDark);
  /* border-top: 2px solid var(--color-border-primaryLight) !important; */
  border-bottom: 2px solid var(--color-border-greyLight3);
  height: 48px;
  font-weight: 500;
}

.uil-dl-icon-ascarrow::before {
  content: "⏶";
  font-size: 10px;
  text-align: center;
  position: absolute;
  color: var(--color-font-disabled);
}

.uil-dl-icon-ascarrow::after {
  content: "⏷";
  font-size: 10px;
  text-align: center;
  position: absolute;
  padding-top: 7px;
  color: var(--color-font-dark);
}

.uil-dl-icon-descarrow::before {
  content: "⏶";
  font-size: 10px;
  text-align: center;
  position: absolute;
  color: var(--color-font-dark);
}

.uil-dl-icon-descarrow::after {
  content: "⏷";
  font-size: 10px;
  text-align: center;
  position: absolute;
  padding-top: 7px;
  color: var(--color-font-disabled);
}

.uil-dl-icon-sort::after {
  content: "⏷";
  font-size: 10px;
  text-align: center;
  position: absolute;
  padding-top: 7px;
  color: var(--color-font-dark);
}

.uil-dl-icon-sort::before {
  content: "⏶";
  font-size: 10px;
  text-align: center;
  /* bottom: 7px; */
  position: absolute;
  color: var(--color-font-dark);
}

/* .uil-table > thead > tr > th.uil-clickable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: var(--color-bg-disabledDark) !important;
} */

.uil-table[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}

.uil-table > thead [disabled],
.uil-table > tbody [disabled],
.disabled .uil-dl-icon-sort::before,
.disabled .uil-dl-icon-sort::after,
.disabled .uil-dl-icon-ascarrow::after,
.disabled .uil-dl-icon-ascarrow::before,
.disabled .uil-dl-icon-descarrow::after,
.disabled .uil-dl-icon-descarrow::before {
  color: var(--color-font-disabled);
  cursor: not-allowed;
  pointer-events: none !important;
}

.uil-table > thead > tr[disabled] {
  border-top: 2px solid var(--color-font-disabled) !important;
}

.uil-table-header-checkbox {
  padding: 8px 8px 8px 0px !important;
}

.uil-table-header-checkbox > span {
  position: relative;
  top: 8px;
}

.uil-checkbox-table-coloumn {
  position: relative;
  top: 8px;
}

.uil-table.uil-table-defaultHeader > thead > tr > th {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  vertical-align: middle;
  height: 48px;
}

.uil-table.uil-table-largeHeader > thead > tr > th {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  vertical-align: middle;
  height: 56px;
}

.table-container.horizontal-scrollbar {
  max-width: 99%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 40px;
  margin-top: 24px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

[disabled].disabled-scrollbar {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

::-webkit-scrollbar-track {
  background-color: var(--color-bg-whiteLight);
}

.table-container {
  max-width: 100%;
}

.table-container.horizontal-scrollbar::-webkit-scrollbar {
  height: 8px;
}

.table-container::-webkit-scrollbar {
  width: 12px;
}

.table-container::-webkit-scrollbar-track {
  background-color: var(--color-bg-whiteLight);
}

.table-container::-webkit-scrollbar-thumb {
  background-color: var(--color-font-primaryLight);
  border-radius: 10px;
}

.disabled-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-bg-disabledDark);
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-scrollbar::-webkit-scrollbar-track {
  background-color: var(--color-bg-whiteLight);
  cursor: not-allowed;
  pointer-events: none;
}

.table-container.horizontal-scrollbar.disabled-scrollbar {
  cursor: not-allowed !important;
  pointer-events: none;
}

table[disabled] {
  cursor: not-allowed;
}

.disabled.uil-table > thead > tr {
  background: var(--color-bg-disabled);
  border-top: 2px solid var(--color-border-default) !important;
  cursor: not-allowed;
  pointer-events: none;
}

.disabled.uil-table > thead:first-child > tr:first-child > td,
.disabled.uil-table > thead:first-child > tr:first-child > th {
  background: var(--color-bg-disabledDark);
  color: #747474;
  border-top: 2px solid var(--color-border-default) !important;
  cursor: not-allowed;
  pointer-events: none;
}

.uil-table-textfield {
  width: auto;
  height: 24px !important;
}
/*# sourceMappingURL=uil_table.module.css.map */
