@import "../src/assets/css/uil_root.css";
@import "../src/assets/css/uil_dls.css";

.contentBlock {
  display: flex;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.uil-sidenavbar {
  z-index: 1000;
  position: relative;
}
.contentBlock {
  position: relative;
  width: 100%;
  background: #f0f0f0;
  /* //min-height: calc((100vh - 42px) + 1500px); */
}
.divContent {
  display: flex;
  flex-direction: row;
}
.styleContentSection {
  position: relative;
  left: 160px;
  width: 800px;
  text-align: initial;
}

.colorHeadings {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #000000;
}

.colorContent {
  font-family: Graphik;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 24px;
  display: flex;
  color: #000000;
}
.colorSections {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}
.tableContent {
  padding-top: 40px;
}

.uil-primary-btn-custom {
  background-color: inherit !important;
}
.uil-custom-banner {
  width: 100%;
  position: relative;
  left: 80px;
}

.searchTable {
  width: 100%;
  margin: 30px 0 0 0;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
}

.uil-footer {
  left: 84px;
  position: relative;
}
.side-navbar {
  position: fixed;
  z-index: 2;
}

.uil-img-section {
  margin-bottom: 100px;
}
.image-card-section-sub-header {
  right: 50px;
}

.uil-tag-section {
  width: 100rem;
  height: 353px;
  position: relative;
  left: 80px;
  border: 1px;
  background-color: #f5f5f5;
}

.uil-tag-section-header {
  width: 445px;
  height: 52px;
  top: 50px;
  left: 100px;
  position: relative;
  font-family: Graphik;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-tag-section-subheader {
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 888px;
  height: 27px;
  top: 100px;
  left: 100px;
  position: relative;
}

.uil-tag-styles {
  top: 185px;
  display: inline-flex;
  gap: 8px;
  left: 240px;
  position: absolute;
}

.uil-tag-header {
  width: 135px;
  height: 30px;
  top: 150px;
  left: 100px;
  position: absolute;
  font-family: Graphik;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-tag-section-header {
  width: 445px;
  height: 52px;
  top: 50px;
  left: 100px;
  position: absolute;
  font-family: Graphik;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.image-card-section-sub-header {
  right: 50px;
}

.div-tabbar {
  background: #f0f0f0;
}

table > thead > tr {
  border-bottom: 1px solid var(--color-border-default) !important;
  border-top:none !important;
}

table > thead:first-child > tr:first-child > th
{
  background: var(--color-bg-disabled) !important;
}