/* Copyright (C) 2020 Accenture. All rights reserved. */
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
.uil-accordion {
  background: var(--color-bg-light);
  border-top: 1px solid var(--color-border-default);
  border-bottom: 1px solid var(--color-border-default);
}
.uil-accordion[disabled] {
  cursor: not-allowed;
}
.uil-accordion.uil-accordion-regular {
  padding: 16px 16px;
}
.uil-accordion.uil-accordion-large {
  padding: 24px 16px;
}
.uil-accordion-counter {
  font-family: "Graphik";
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-md);
  line-height: 120%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  margin-left: 8px;
  color: var(--grey-medium);
}
.uil-accordion-counter[disabled] {
  color: var(--color-font-disabled);
}
.uil-accordion-content {
  font-family: "Graphik";
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-sm);
  color: var(--color-font-greyMedium);
  margin-top: 20px;
  cursor: text;
}
.uil-accordion-content[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.uil-accordion.disabled {
  cursor: not-allowed;
}
.uil-accordion.uil-accordion-large .uil-accordion-title {
  font-size: var(--font-size-title-md);
}
.uil-accordion.expand {
  border-top: 1px solid var(--color-border-primaryLight);
}
.uil-accordion.expand[disabled] {
  border-top: 1px solid var(--color-border-default);
}
.uil-accordion-title {
  width: 100%;
  font-family: "Graphik";
  font-style: normal;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-md);
  line-height: 120%;
  cursor: pointer;
  caret-color: transparent;
}
.uil-accordion-title[disabled] {
  color: var(--color-font-disabled);
  cursor: not-allowed;
}
.uil-accordion-title.expand {
  color: var(--color-font-primaryLight);
  font-family: "Graphik";
  font-style: normal;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-body-md);
  height: 24px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.uil-accordion-title.expand[disabled] {
  color: var(--color-font-disabled);
}
.uil-accordion-title-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  caret-color: transparent;
}
.uil-accordion-title-area:hover {
  color: var(--color-font-primaryLight);
}
.uil-accordion-title-area[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.uil-dl-icon-chevron-down:before {
  content: "\e92c";
}
.uil-dl-icon-chevron-down::before {
  cursor: pointer;
  font-family: uilicon_dl, Graphik, sans-serif;
  font-weight: var(--font-weight-sm);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: var(--font-size-title-lg);
  position: relative;
}
.uil-dl-icon-chevron-down.expand::before {
  cursor: pointer;
  font-family: uilicon_dl, Graphik, sans-serif;
  font-weight: var(--font-weight-sm);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: var(--font-size-title-lg);
  position: relative;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  color: var(--color-font-primaryLight);
}
.uil-dl-icon-chevron-down[disabled]:before {
  color: var(--color-font-disabled);
}
/*# sourceMappingURL=uil_accordion.module.css.map */