.uil-content-block {
  width: 100rem;
  height: 1324px;
  top:50px;
  position: relative;
}

.uil-content-block-header {
  width: 816px;
  height: 52px;
  top: 50px;
  left: 30px;
  position: relative;
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-content-block-desc {
  font-family: Graphik;
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 22.4px;
  letter-spacing: 0em;
  text-align: left;
  width: 800px;
  height: 242px;
  top: 50px;
  left: 30px;
  position: relative;
  display: inline-flex;
}

.uil-header-2 {
  width: 816px;
  height: 52px;
  top: 40px;
  left: 30px;
  position: relative;
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-dark);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.uil-card-section {
  display: inline-grid;
  gap: 20px;
  top: 5px;
  position: relative;
}

.uil-arrow-icon.uil-dl-icon-Arrow-right-up {
  display: initial;
  margin-left: 150px;
  position: relative;
  top: 5px;
  color: var(--color-font-greyDark);
}
.uil-card-header {
  width: 300px;
  height: 48px;
  gap:123px;
  padding: 24px 24px 0px 24px;
  font-family: Graphik;
  font-size: var(--font-size-caption-xs);
  font-weight: var(--font-weight-sm);
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-font-primaryLight);
}

.uil-arrow-icon {
  display: inline;
  margin-left: 150px;
  margin-top: 10px;
}

.uil-basic-card-title {
  height: 24px;
  width: 24px;
}

.uil-base-card {
  width: 300px;
  height: 180px;
  border-radius: 4px;
}

.uil-card .uil-basic-card-caption {
  font-family: Graphik;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  color:var(--color-font-greyMedium);
  line-height: 19.6px;
  letter-spacing: 0em;
  text-align: left;
  width: 300px;
  padding: 0px 24px 16px 24px;
}

.uil-card .uil-card-title {
  font-size: var(--font-size-title-md);
  font-weight: var(--font-weight-md);
  color:var(--color-font-dark);
  font-family: "Graphik";
  width: 300px;
  height: 40px;
  padding: 8px 48px 0px 24px;
  display: flex;
}

.uil-cards-section {
  display: inline-grid;
  gap: 20px;
  top: 5px;
  left: 200px;
  position: relative;
}

.uil-accordion-styles{
    margin-top: 40px;
    line-height: 20px;
}

