[class*="uil-dl-icon-"],
[class^="uil-dl-icon-"] {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uil-dl-icon-AIP_Logo:before {
  content: "\ea29";
}
.uil-dl-icon-Acc_Digital_Logo_All_Black-mod_17px:before {
  content: "\e900";
}
.uil-dl-icon-accenture-greater-than:before {
  content: "\e901";
}
.uil-dl-icon-Arrow-left:before {
  content: "\e9ee";
}
.uil-dl-icon-Arrow-right:before {
  content: "\e93c";
}
.uil-dl-icon-Arrow-up:before {
  content: "\e9da";
}
.uil-dl-icon-Arrow-down:before {
  content: "\e902";
}
.uil-dl-icon-Arrow-left-up:before {
  content: "\e93b";
}
.uil-dl-icon-Arrow-right-up:before {
  content: "\e9b3";
}
.uil-dl-icon-Arrow-right-down:before {
  content: "\e9d9";
}
.uil-dl-icon-Arrow-left-down:before {
  content: "\e961";
}
.uil-dl-icon-Direction-left:before {
  content: "\e915";
}
.uil-dl-icon-Direction-right:before {
  content: "\e9a1";
}
.uil-dl-icon-Turn-left:before {
  content: "\e9d7";
}
.uil-dl-icon-Turn-right:before {
  content: "\e94b";
}
.uil-dl-icon-Back-left:before {
  content: "\e9c7";
}
.uil-dl-icon-Back-right:before {
  content: "\e98a";
}
.uil-dl-icon-Undo:before {
  content: "\e9fd";
}
.uil-dl-icon-Redo:before {
  content: "\e9bd";
}
.uil-dl-icon-Refresh:before {
  content: "\e998";
}
.uil-dl-icon-Trending-up:before {
  content: "\ea26";
}
.uil-dl-icon-Trending-down:before {
  content: "\ea13";
}
.uil-dl-icon-Crossing:before {
  content: "\e9cf";
}
.uil-dl-icon-Chevron-left:before {
  content: "\ea19";
}
.uil-dl-icon-Chevron-right:before {
  content: "\e93e";
}
.uil-dl-icon-Chevron-up:before {
  content: "\e92d";
}
.uil-dl-icon-Chevron-down:before,
.uil-side-nav-item a[aria-expanded="false"]:before,
.uil-side-nav-item a[aria-expanded="true"]:before,
.uil-side-nav-item[aria-expanded="false"]:before,
.uil-side-nav-item[aria-expanded="true"]:before {
  content: "\e92c";
}
.uil-dl-icon-Chevrons-right:before {
  content: "\e906";
}
.uil-dl-icon-Chevrons-left:before {
  content: "\e9f0";
}
.uil-dl-icon-Chevrons-up:before {
  content: "\e9ca";
}
.uil-dl-icon-Chevrons-down:before {
  content: "\e905";
}
.uil-dl-icon-Sorting:before {
  content: "\e937";
}
.uil-dl-icon-Return:before {
  content: "\e95b";
}
.uil-dl-icon-Shuffle:before {
  content: "\e9fa";
}
.uil-dl-icon-Repeat:before {
  content: "\e90f";
}
.uil-dl-icon-Rotate:before {
  content: "\e936";
}
.uil-dl-icon-Plus:before {
  content: "\e981";
}
.uil-dl-icon-Minus:before {
  content: "\ea1e";
}
.uil-dl-icon-Check:before {
  content: "\e979";
}
.uil-dl-icon-Close:before {
  content: "\e98e";
}
.uil-dl-icon-Back:before {
  content: "\e99e";
}
.uil-dl-icon-Forward:before {
  content: "\ea08";
}
.uil-dl-icon-Pause:before {
  content: "\ea0e";
}
.uil-dl-icon-Previous:before {
  content: "\e9ab";
}
.uil-dl-icon-Next:before {
  content: "\e9d2";
}
.uil-dl-icon-Video:before {
  content: "\e9fe";
}
.uil-dl-icon-Send:before {
  content: "\e9be";
}
.uil-dl-icon-Back-alt:before {
  content: "\e9c6";
}
.uil-dl-icon-Play:before {
  content: "\e9bc";
}
.uil-dl-icon-Power:before {
  content: "\e935";
}
.uil-dl-icon-Time:before {
  content: "\e972";
}
.uil-dl-icon-History:before {
  content: "\ea1d";
}
.uil-dl-icon-Timer:before {
  content: "\ea25";
}
.uil-dl-icon-Stopwatch:before {
  content: "\e960";
}
.uil-dl-icon-Alarm:before {
  content: "\ea28";
}
.uil-dl-icon-Upload:before {
  content: "\ea14";
}
.uil-dl-icon-Download:before {
  content: "\e953";
}
.uil-dl-icon-Shift:before {
  content: "\e91e";
}
.uil-dl-icon-Cloud-upload:before {
  content: "\e940";
}
.uil-dl-icon-Loading:before {
  content: "\e9a8";
}
.uil-dl-icon-Sound-low:before {
  content: "\e9d5";
}
.uil-dl-icon-Sound-medium:before {
  content: "\e95e";
}
.uil-dl-icon-Sound-high:before {
  content: "\e999";
}
.uil-dl-icon-Mute:before {
  content: "\e9bb";
}
.uil-dl-icon-Voicemail:before {
  content: "\e94d";
}
.uil-dl-icon-Music:before {
  content: "\e934";
}
.uil-dl-icon-Microphone:before {
  content: "\e933";
}
.uil-dl-icon-Cellular:before {
  content: "\e904";
}
.uil-dl-icon-Battery-orizontal:before {
  content: "\e903";
}
.uil-dl-icon-Battery-vertical:before {
  content: "\e98c";
}
.uil-dl-icon-Battery-full:before {
  content: "\e977";
}
.uil-dl-icon-Battery-medium:before {
  content: "\e963";
}
.uil-dl-icon-Battery-low:before {
  content: "\e9b4";
}
.uil-dl-icon-Battery-charging:before {
  content: "\e962";
}
.uil-dl-icon-Danger:before {
  content: "\e9b9";
}
.uil-dl-icon-Error:before {
  content: "\e9f4";
}
.uil-dl-icon-Info:before {
  content: "\e994";
}
.uil-dl-icon-Help:before {
  content: "\ea0a";
}
.uil-dl-icon-Spam:before {
  content: "\e95f";
}
.uil-dl-icon-No-entry:before {
  content: "\e944";
}
.uil-dl-icon-Add:before {
  content: "\e975";
}
.uil-dl-icon-Remove:before {
  content: "\e9ea";
}
.uil-dl-icon-Success:before {
  content: "\e9ec";
}
.uil-dl-icon-Cancel:before {
  content: "\e92b";
}
.uil-dl-icon-Not-allowed:before {
  content: "\e96e";
}
.uil-dl-icon-Circle-arrow-up:before {
  content: "\e9f1";
}
.uil-dl-icon-Circle-arrow-down:before {
  content: "\e9cb";
}
.uil-dl-icon-Circle-arrow-left:before {
  content: "\e967";
}
.uil-dl-icon-Circle-arrow-right:before {
  content: "\e9b8";
}
.uil-dl-icon-Circle:before {
  content: "\e914";
}
.uil-dl-icon-Octagon:before {
  content: "\e9e8";
}
.uil-dl-icon-Esagon:before {
  content: "\e918";
}
.uil-dl-icon-Pentagon:before {
  content: "\ea22";
}
.uil-dl-icon-Star:before {
  content: "\e9c0";
}
.uil-dl-icon-Triangle:before {
  content: "\e912";
}
.uil-dl-icon-Heart:before {
  content: "\ea1c";
}
.uil-dl-icon-Square-small:before {
  content: "\e9bf";
}
.uil-dl-icon-Square:before {
  content: "\e9ad";
}
.uil-dl-icon-Placeholder:before {
  content: "\ea23";
}
.uil-dl-icon-Home:before {
  content: "\e919";
}
.uil-dl-icon-Home-alt:before {
  content: "\e942";
}
.uil-dl-icon-Hand_1:before {
  content: "\e9a3";
}
.uil-dl-icon-Hand:before {
  content: "\e90a";
}
.uil-dl-icon-Thumb-up:before {
  content: "\e922";
}
.uil-dl-icon-Thumb-down:before {
  content: "\e9ae";
}
.uil-dl-icon-People:before {
  content: "\e996";
}
.uil-dl-icon-Person:before {
  content: "\e91c";
}
.uil-dl-icon-User:before {
  content: "\e926";
}
.uil-dl-icon-Face-happy:before {
  content: "\e96c";
}
.uil-dl-icon-Face-neutral:before {
  content: "\e97c";
}
.uil-dl-icon-Face-sad:before {
  content: "\e930";
}
.uil-dl-icon-Chat:before {
  content: "\e9c9";
}
.uil-dl-icon-Chat-alt:before {
  content: "\e966";
}
.uil-dl-icon-Hide-alt:before {
  content: "\e9e3";
}
.uil-dl-icon-Eye:before {
  content: "\e9e1";
}
.uil-dl-icon-Hide:before {
  content: "\e9a4";
}
.uil-dl-icon-Wheelchair:before {
  content: "\e9d8";
}
.uil-dl-icon-Compass:before {
  content: "\e9cc";
}
.uil-dl-icon-Search:before {
  content: "\e910";
}
.uil-dl-icon-Lightbulb:before {
  content: "\e9a7";
}
.uil-dl-icon-Zoom-in:before {
  content: "\e99c";
}
.uil-dl-icon-Zoom-out:before {
  content: "\e9b0";
}
.uil-dl-icon-Lock-alt-open:before {
  content: "\e958";
}
.uil-dl-icon-Lock-alt-closed:before {
  content: "\e90d";
}
.uil-dl-icon-Lock-open:before {
  content: "\ea0d";
}
.uil-dl-icon-Lock-closed:before {
  content: "\e9f9";
}
.uil-dl-icon-Key:before {
  content: "\e956";
}
.uil-dl-icon-Theft:before {
  content: "\e99a";
}
.uil-dl-icon-Table-horizontal:before {
  content: "\e949";
}
.uil-dl-icon-Table-vertical:before {
  content: "\e985";
}
.uil-dl-icon-Grid-9-circles:before {
  content: "\e993";
}
.uil-dl-icon-Grid-9-squares:before {
  content: "\e908";
}
.uil-dl-icon-Grid-small:before {
  content: "\e9d1";
}
.uil-dl-icon-Grid:before {
  content: "\e909";
}
.uil-dl-icon-Component:before {
  content: "\ea03";
}
.uil-dl-icon-List-alt:before {
  content: "\e90b";
}
.uil-dl-icon-List:before {
  content: "\e90c";
}
.uil-dl-icon-Dashboard:before {
  content: "\e96b";
}
.uil-dl-icon-Align-type-justify:before {
  content: "\e928";
}
.uil-dl-icon-Vertical:before {
  content: "\e93a";
}
.uil-dl-icon-Stack:before {
  content: "\e9fb";
}
.uil-dl-icon-Filter-alt:before {
  content: "\e9f5";
}
.uil-dl-icon-Filter:before {
  content: "\e9f6";
}
.uil-dl-icon-More-horizontal:before {
  content: "\e91b";
}
.uil-dl-icon-More-vertical:before {
  content: "\e9e6";
}
.uil-dl-icon-Burger:before {
  content: "\e98d";
}
.uil-dl-icon-Bell:before {
  content: "\e964";
}
.uil-dl-icon-Feed:before {
  content: "\e97d";
}
.uil-dl-icon-Pie-chart:before {
  content: "\e980";
}
.uil-dl-icon-Bar-chart:before {
  content: "\e93d";
}
.uil-dl-icon-Line-chart:before {
  content: "\e9e4";
}
.uil-dl-icon-Activity:before {
  content: "\e9ed";
}
.uil-dl-icon-Keyboard:before {
  content: "\e943";
}
.uil-dl-icon-Mouse:before {
  content: "\ea20";
}
.uil-dl-icon-Print:before {
  content: "\ea24";
}
.uil-dl-icon-Laptop:before {
  content: "\e9f8";
}
.uil-dl-icon-Tv:before {
  content: "\e974";
}
.uil-dl-icon-Tablet:before {
  content: "\ea12";
}
.uil-dl-icon-Smartphone:before {
  content: "\e91f";
}
.uil-dl-icon-iPhone:before {
  content: "\ea0b";
}
.uil-dl-icon-Contact-book:before {
  content: "\e96a";
}
.uil-dl-icon-Phone:before {
  content: "\e9e9";
}
.uil-dl-icon-Suitcase-alt:before {
  content: "\e911";
}
.uil-dl-icon-Suitcase:before {
  content: "\e9fc";
}
.uil-dl-icon-Credit-card:before {
  content: "\e9ce";
}
.uil-dl-icon-Coffee:before {
  content: "\e968";
}
.uil-dl-icon-Restaurant:before {
  content: "\e91d";
}
.uil-dl-icon-Wine-glass:before {
  content: "\e9c4";
}
.uil-dl-icon-Glasses:before {
  content: "\e992";
}
.uil-dl-icon-Watch:before {
  content: "\e99b";
}
.uil-dl-icon-News:before {
  content: "\e995";
}
.uil-dl-icon-Hourglass:before {
  content: "\e91a";
}
.uil-dl-icon-Cup:before {
  content: "\ea04";
}
.uil-dl-icon-Anchor:before {
  content: "\e9c5";
}
.uil-dl-icon-Support:before {
  content: "\e984";
}
.uil-dl-icon-Headphones:before {
  content: "\e97f";
}
.uil-dl-icon-Megaphone:before {
  content: "\e90e";
}
.uil-dl-icon-Typography:before {
  content: "\e94c";
}
.uil-dl-icon-Typography-alt:before {
  content: "\e924";
}
.uil-dl-icon-Stats:before {
  content: "\ea11";
}
.uil-dl-icon-Align-type-left:before {
  content: "\e9b1";
}
.uil-dl-icon-Align-type-center:before {
  content: "\e989";
}
.uil-dl-icon-Align-type-right:before {
  content: "\e9b2";
}
.uil-dl-icon-Envelope-alt:before {
  content: "\e92f";
}
.uil-dl-icon-Envelope:before {
  content: "\e954";
}
.uil-dl-icon-At:before {
  content: "\e94f";
}
.uil-dl-icon-Paperclip:before {
  content: "\e9aa";
}
.uil-dl-icon-Inbox:before {
  content: "\e931";
}
.uil-dl-icon-Box-alt:before {
  content: "\e92a";
}
.uil-dl-icon-Box:before {
  content: "\e9b5";
}
.uil-dl-icon-Save:before {
  content: "\e9ac";
}
.uil-dl-icon-Folder-add:before {
  content: "\e9e2";
}
.uil-dl-icon-Folder:before {
  content: "\ea1b";
}
.uil-dl-icon-Book-open:before {
  content: "\e951";
}
.uil-dl-icon-Book:before {
  content: "\e965";
}
.uil-dl-icon-Notebook:before {
  content: "\ea21";
}
.uil-dl-icon-Edit:before {
  content: "\e9d0";
}
.uil-dl-icon-New:before {
  content: "\e9e7";
}
.uil-dl-icon-Clipboard-alt:before {
  content: "\e99f";
}
.uil-dl-icon-Calendar:before {
  content: "\e9ef";
}
.uil-dl-icon-Clipboard:before {
  content: "\e93f";
}
.uil-dl-icon-Copy:before {
  content: "\e990";
}
.uil-dl-icon-Cards:before {
  content: "\ea18";
}
.uil-dl-icon-Sticker:before {
  content: "\e9c1";
}
.uil-dl-icon-Page:before {
  content: "\e9d3";
}
.uil-dl-icon-File-success:before {
  content: "\ea07";
}
.uil-dl-icon-File-error:before {
  content: "\e907";
}
.uil-dl-icon-Bin:before {
  content: "\e9db";
}
.uil-dl-icon-Delete:before {
  content: "\e9de";
}
.uil-dl-icon-Cut:before {
  content: "\e9f3";
}
.uil-dl-icon-Crop:before {
  content: "\e92e";
}
.uil-dl-icon-Minimize:before {
  content: "\e959";
}
.uil-dl-icon-Maximise:before {
  content: "\e9a9";
}
.uil-dl-icon-Map:before {
  content: "\e932";
}
.uil-dl-icon-Location:before {
  content: "\e9e5";
}
.uil-dl-icon-Geofence-violation:before {
  content: "\e9a2";
}
.uil-dl-icon-Cursor:before {
  content: "\e97a";
}
.uil-dl-icon-Bike:before {
  content: "\e929";
}
.uil-dl-icon-Plane:before {
  content: "\e945";
}
.uil-dl-icon-Train:before {
  content: "\e973";
}
.uil-dl-icon-Car:before {
  content: "\e9dc";
}
.uil-dl-icon-Fleet:before {
  content: "\e991";
}
.uil-dl-icon-Car-alt:before {
  content: "\ea02";
}
.uil-dl-icon-Crash:before {
  content: "\e9dd";
}
.uil-dl-icon-Truck:before {
  content: "\e9c3";
}
.uil-dl-icon-Speed:before {
  content: "\e938";
}
.uil-dl-icon-Cart:before {
  content: "\e952";
}
.uil-dl-icon-Basket:before {
  content: "\ea17";
}
.uil-dl-icon-Bag:before {
  content: "\e98b";
}
.uil-dl-icon-Tag:before {
  content: "\e939";
}
.uil-dl-icon-Tag-alt:before {
  content: "\e986";
}
.uil-dl-icon-Signal-alt:before {
  content: "\e946";
}
.uil-dl-icon-Signal:before {
  content: "\e947";
}
.uil-dl-icon-Wifi:before {
  content: "\e988";
}
.uil-dl-icon-Rss:before {
  content: "\e982";
}
.uil-dl-icon-Radio:before {
  content: "\e96f";
}
.uil-dl-icon-Bluetooth:before {
  content: "\e978";
}
.uil-dl-icon-Euro:before {
  content: "\e955";
}
.uil-dl-icon-Dollar:before {
  content: "\e917";
}
.uil-dl-icon-Yen:before {
  content: "\ea00";
}
.uil-dl-icon-Film:before {
  content: "\ea1a";
}
.uil-dl-icon-Camera:before {
  content: "\e9b7";
}
.uil-dl-icon-Camera-rear:before {
  content: "\e9b6";
}
.uil-dl-icon-Aperture:before {
  content: "\e94e";
}
.uil-dl-icon-Bolt:before {
  content: "\e950";
}
.uil-dl-icon-Settings:before {
  content: "\e95c";
}
.uil-dl-icon-Tool:before {
  content: "\e987";
}
.uil-dl-icon-Controls:before {
  content: "\e9cd";
}
.uil-dl-icon-Link:before {
  content: "\e957";
}
.uil-dl-icon-QRcode:before {
  content: "\e95a";
}
.uil-dl-icon-Image:before {
  content: "\e9a5";
}
.uil-dl-icon-Colors:before {
  content: "\e969";
}
.uil-dl-icon-Drop:before {
  content: "\ea05";
}
.uil-dl-icon-Layers:before {
  content: "\e96d";
}
.uil-dl-icon-Window:before {
  content: "\e9ff";
}
.uil-dl-icon-Layout-left:before {
  content: "\ea0c";
}
.uil-dl-icon-Layout-right:before {
  content: "\e9a6";
}
.uil-dl-icon-Align-left:before {
  content: "\ea15";
}
.uil-dl-icon-Align-top:before {
  content: "\ea16";
}
.uil-dl-icon-Align-center-horizontally:before {
  content: "\e976";
}
.uil-dl-icon-Align-center-vertically:before {
  content: "\e913";
}
.uil-dl-icon-Align-right:before {
  content: "\e99d";
}
.uil-dl-icon-Align-bottom:before {
  content: "\e927";
}
.uil-dl-icon-Share-ios:before {
  content: "\e983";
}
.uil-dl-icon-Entrace:before {
  content: "\ea06";
}
.uil-dl-icon-Exit:before {
  content: "\e9e0";
}
.uil-dl-icon-Share-android:before {
  content: "\e9eb";
}
.uil-dl-icon-Flag:before {
  content: "\e97e";
}
.uil-dl-icon-Shield:before {
  content: "\e95d";
}
.uil-dl-icon-Education:before {
  content: "\ea2a";
}
.uil-dl-icon-Diploma:before {
  content: "\ea2b";
}
.uil-dl-icon-Award:before {
  content: "\ea2c";
}
.uil-dl-icon-HDR:before {
  content: "\ea09";
}
.uil-dl-icon-PDF:before {
  content: "\e9d4";
}
.uil-dl-icon-XLSX:before {
  content: "\e9af";
}
.uil-dl-icon-XLS:before {
  content: "\ea27";
}
.uil-dl-icon-CSV:before {
  content: "\e9a0";
}
.uil-dl-icon-TXT:before {
  content: "\e923";
}
.uil-dl-icon-DOCX:before {
  content: "\e916";
}
.uil-dl-icon-DOC:before {
  content: "\e941";
}
.uil-dl-icon-Globe:before {
  content: "\e9f7";
}
.uil-dl-icon-Thermometer:before {
  content: "\e971";
}
.uil-dl-icon-Sun-cloud:before {
  content: "\e9d6";
}
.uil-dl-icon-Cloud:before {
  content: "\e9f2";
}
.uil-dl-icon-Snow:before {
  content: "\e920";
}
.uil-dl-icon-Thunder:before {
  content: "\e94a";
}
.uil-dl-icon-Rain:before {
  content: "\e997";
}
.uil-dl-icon-Umbrella:before {
  content: "\e925";
}
.uil-dl-icon-Sun:before {
  content: "\e9c2";
}
.uil-dl-icon-Sunrise:before {
  content: "\e921";
}
.uil-dl-icon-Moon:before {
  content: "\ea1f";
}
.uil-dl-icon-Sea:before {
  content: "\ea10";
}
.uil-dl-icon-Coding:before {
  content: "\e98f";
}
.uil-dl-icon-Bug:before {
  content: "\e9c8";
}
.uil-dl-icon-Hashtag:before {
  content: "\e9ba";
}
.uil-dl-icon-Diamond:before {
  content: "\e9df";
}
.uil-dl-icon-Rocket:before {
  content: "\ea0f";
}
.uil-dl-icon-Stark:before {
  content: "\e948";
}
.uil-dl-icon-Shield-alt:before {
  content: "\e970";
}
.uil-dl-icon-Disc:before {
  content: "\e97b";
}
.uil-dl-icon-Circle-filled:before {
  content: "\ea2d";
}
.uil-dl-icon-information-point:before {
  content: "\ea2e";
}
.uil-dl-icon-question-point:before {
  content: "\ea2f";
}
.uil-dl-icon-Disc-filled:before {
  content: "\ea30";
}
.uil-dl-icon-Import:before {
  content: "\ea31";
}
.uil-dl-icon-Distance:before {
  content: "\ea34";
}
.uil-dl-icon-Crowd:before {
  content: "\ea35";
}
.uil-dl-icon-Facemask:before {
  content: "\ea32";
}
.uil-dl-icon-Flag-filled:before {
  content: "\ea33";
}
.uil-dl-icon-Bookmark:before {
  content: "\ea01";
}
.uil-dl-icon-Bookmark-filled:before {
  content: "\ea36";
}
.uil-dl-icon-Heart-filled:before {
  content: "\ea37";
}
.uil-dl-icon-Star-filled:before {
  content: "\ea38";
}
.uil-dl-icon-Dynamic-Devices-Default:before {
  content: "\ea39";
}
.uil-dl-icon-Object-Detector:before {
  content: "\ea3a";
}
.uil-dl-icon-Helmet-Detector:before {
  content: "\ea3b";
}
.uil-dl-icon-Bracelet:before {
  content: "\ea3c";
}
.uil-dl-icon-Industrial-Machine:before {
  content: "\ea3d";
}
.uil-dl-icon-Path-Checking-Detector:before {
  content: "\ea3e";
}
.uil-dl-icon-People-Counter:before {
  content: "\ea3f";
}
.uil-dl-icon-Tank:before {
  content: "\ea40";
}
.uil-dl-icon-Occupancy:before {
  content: "\ea41";
}
.uil-dl-icon-Circle-chevron-down:before {
  content: "\ea42";
}
.uil-dl-icon-Circle-chevron-up:before {
  content: "\ea43";
}
.uil-dl-icon-Circle-chevron-left:before {
  content: "\ea44";
}
.uil-dl-icon-Circle-chevron-right:before {
  content: "\ea45";
}
.uil-dl-icon-JPG:before {
  content: "\ea46";
}
.uil-dl-icon-GIF:before {
  content: "\ea47";
}
.uil-dl-icon-MP3:before {
  content: "\ea48";
}
.uil-dl-icon-MP4:before {
  content: "\ea49";
}
.uil-dl-icon-PNG:before {
  content: "\ea4a";
}
.uil-dl-icon-Fog:before {
  content: "\ea4b";
}
.uil-dl-icon-Haze:before {
  content: "\ea4c";
}
.uil-dl-icon-Sandstorm:before {
  content: "\ea4e";
}
.uil-dl-icon-Storm:before {
  content: "\ea50";
}
.uil-dl-icon-External-link:before {
  content: "\ea4d";
}
.uil-dl-icon-Factory:before {
  content: "\ea4f";
}
.uil-dl-icon-Manufacturing:before {
  content: "\ea51";
}
.uil-dl-icon-Manufacture:before {
  content: "\ea52";
}
.uil-dl-icon-Folder-Open:before {
  content: "\ea53";
}
.uil-in-page-navigation-horizontal {
  border: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  position: sticky;
  overflow: revert;
  z-index: 1000;
  margin-top: -12px !important;
  width: 0;
  float: right;
  padding-right: 0 !important;
  padding-left: 12px !important;
}
.uil-in-page-navigation-horizontal::before {
  display: none;
  content: "in_page_nav_collapsed";
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal::before {
    display: none;
    content: "in_page_nav_not_collapsed";
  }
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal {
    width: auto;
    float: none;
  }
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal {
    background-color: var(--color-bg-light);
  }
  .uil-in-page-navigation-horizontal.uil-align-with-content {
    padding-left: 80px !important;
    margin-left: -80px !important;
  }
}
.uil-in-page-navigation-horizontal ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: 0;
  margin-right: 0;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow: revert;
  width: 100%;
  background-color: transparent;
  list-style-type: none;
}
.uil-in-page-navigation-horizontal ul li {
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: transparent;
}
.uil-in-page-navigation-horizontal ul li.active {
  border-bottom-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation-horizontal ul li.active a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
}
.uil-in-page-navigation-horizontal ul li:hover {
  border-bottom-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation-horizontal ul li:hover[disabled] {
  cursor: not-allowed;
  border-bottom-color: var(--color-border-light);
}
.uil-in-page-navigation-horizontal ul li a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-left: 22px;
  padding-right: 22px;
  white-space: nowrap;
  padding: 12px 8px;
  color: var(--color-font-dark);
  -webkit-transition: none;
  transition: none;
}
.uil-in-page-navigation-horizontal ul li a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal ul li a {
    color: #393a3e;
  }
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: revert;
  scroll-behavior: smooth;
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-column.on-scrollbar,
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-column.on-scrollbar-static {
  padding-bottom: 0;
}
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-column.on-scrollbar
  ul,
.uil-in-page-navigation-horizontal
  .uil-in-page-navigation-column.on-scrollbar-static
  ul {
  margin-bottom: 0;
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-left {
  width: 40px;
  height: 100%;
  color: #393a3e;
  font-size: var(--font-size-heading-lg);
  left: 0;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  cursor: pointer;
  display: none;
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-right {
  width: 40px;
  height: 100%;
  color: #393a3e;
  font-size: var(--font-size-heading-lg);
  right: 0;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  cursor: pointer;
  display: none;
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal .uil-in-page-navigation-horizontal-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.uil-in-page-navigation {
  border: none;
  padding: 0;
  margin: 0;
  top: 0;
  position: sticky;
  float: right;
  overflow: revert;
  padding-top: 40px !important;
  margin-top: -12px !important;
  width: 0;
  padding-right: 0 !important;
  padding-left: 12px !important;
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation {
    overflow: revert;
  }
  .uil-in-page-navigation:hover {
    overflow: revert;
    overflow-x: revert;
  }
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation {
    width: calc(248px + 12px + 12px + 24px);
    padding-right: 24px !important;
  }
}
.uil-in-page-navigation ul {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 0;
  margin-bottom: 28px;
  overflow: revert;
  width: 248px;
  background-color: transparent;
  list-style-type: none !important;
}
.uil-in-page-navigation ul li {
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: var(--color-border-default);
}
.uil-in-page-navigation ul li:hover {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation ul li[disabled] {
  cursor: not-allowed;
  border-left-color: var(--color-border-light1);
}
.uil-in-page-navigation ul li a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 12px 8px;
  color: var(--color-font-dark);
  -webkit-transition: none;
  transition: none;
}
.uil-in-page-navigation ul li a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  pointer-events: none;
}
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"] {
  width: 248px;
  padding-bottom: 16px;
  padding-right: 16px;
}
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"]
  .uil-card-title-area {
  margin-bottom: 8px;
}
.uil-in-page-navigation
  .uil-cut-corner-card-shadow
  div[class*="cut-corner-card"]
  .uil-card-footer {
  padding-top: 8px;
}
.uil-in-page-navigation .uil-in-page-navigation-column {
  display: none;
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation .uil-in-page-navigation-column {
    display: block;
  }
}
.uil-align-with-content {
  margin-right: -80px !important;
}
.uil-in-page-navigation .uil-curtain,
.uil-in-page-navigation-horizontal .uil-curtain {
  position: absolute;
  right: -12px;
  top: 0;
  overflow: revert;
  border: none;
  width: 0;
  padding-right: 0;
  right: 0;
}
.uil-in-page-navigation .uil-curtain.active,
.uil-in-page-navigation-horizontal .uil-curtain.active {
  width: calc(248px + 12px + 12px + 24px);
}
@media screen and (min-width: 992px) {
  .uil-in-page-navigation .uil-curtain,
  .uil-in-page-navigation-horizontal .uil-curtain {
    display: none;
  }
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel,
.uil-in-page-navigation-horizontal .uil-curtain .uil-curtain-panel {
  width: 0;
  top: 0;
  -webkit-box-shadow: 0 0 8px 0 #393a3e26;
  box-shadow: 0 0 8px 0 #393a3e26;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel.active,
.uil-in-page-navigation-horizontal .uil-curtain .uil-curtain-panel.active {
  width: 100%;
  left: 0;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-toggle,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-toggle {
  -webkit-box-shadow: 6px 0 0 #fff, 0 0 8px 0 #393a3e26;
  box-shadow: 6px 0 0 #fff, 0 0 8px 0 #393a3e26;
  height: 48px;
  width: 48px;
  left: -48px;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-open,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open {
  border: none;
  overflow: revert;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-open:hover,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open:hover {
  overflow: revert;
}
.uil-in-page-navigation
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-panel-content,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-panel-content {
  padding-top: 40px;
  padding-left: 12px;
  float: left;
}
.uil-in-page-navigation
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-toggle,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-open
  .uil-curtain-toggle {
  top: 40px;
}
.uil-in-page-navigation .uil-curtain .uil-curtain-panel .uil-curtain-close,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-close {
  border: none;
}
.uil-in-page-navigation
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-close
  .uil-curtain-toggle,
.uil-in-page-navigation-horizontal
  .uil-curtain
  .uil-curtain-panel
  .uil-curtain-close
  .uil-curtain-toggle {
  top: 40px;
  margin: 0;
}
.uil-in-page-navigation .uil-curtain ul,
.uil-in-page-navigation-horizontal .uil-curtain ul {
  display: block;
}
.uil-in-page-navigation .uil-curtain ul li,
.uil-in-page-navigation-horizontal .uil-curtain ul li {
  border-bottom-style: none;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: var(--color-border-default);
}
.uil-in-page-navigation .uil-curtain ul li.active,
.uil-in-page-navigation-horizontal .uil-curtain ul li.active {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation .uil-curtain ul li.active a,
.uil-in-page-navigation-horizontal .uil-curtain ul li.active a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  letter-spacing: 0.3px;
  line-height: 120%;
}
.uil-in-page-navigation .uil-curtain ul li:hover,
.uil-in-page-navigation-horizontal .uil-curtain ul li:hover {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation .uil-curtain ul li:hover[disabled],
.uil-in-page-navigation-horizontal .uil-curtain ul li:hover[disabled] {
  cursor: not-allowed;
  border-left-color: var(--color-border-light1);
}
@media screen and (max-width: 768px) {
  .uil-in-page-navigation-horizontal {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .uil-in-page-navigation-horizontal .uil-curtain {
    display: none;
  }
}
.uil-content-with-in-page-navigation {
  float: left;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .uil-content-with-in-page-navigation {
    width: calc(100% - (248px + 12px + 12px) + (80px - 24px - 0px));
  }
}
.uil-in-page-navigation-toggle-icon {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 24px;
  font-size: 24px;
}
.uil-in-page-navigation-toggle-icon:before {
  content: "\e98d";
}
.uil-in-page-navigation ul li a:hover {
  color: var(--color-font-primaryLight);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: var(--font-weight-md);
}
.uil-in-page-navigation ul li a:hover[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-in-page-navigation-horizontal ul li a:hover {
  color: var(--color-font-primaryLight);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: var(--font-weight-md);
}
.uil-in-page-navigation-horizontal ul li a:hover[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-in-page-navigation ul li[class="active"] {
  border-left-color: var(--color-border-primaryLight);
}
.uil-in-page-navigation ul li[class="active"][disabled] {
  cursor: not-allowed;
  border-left-color: var(--color-border-light1);
}
.uil-in-page-navigation ul li[class="active"] a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  color: var(--color-font-primaryLight);
  font-style: normal;
}
.uil-in-page-navigation ul li[class="active"] a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-in-page-navigation-horizontal ul li[class="active"] {
  border-bottom: 4px solid var(--color-border-primaryLight);
}
.uil-in-page-navigation-horizontal ul li[class="active"][disabled] {
  cursor: not-allowed;
  border-bottom-color: var(--color-border-light);
}
.uil-in-page-navigation-horizontal ul li[class="active"] a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  letter-spacing: 0.3px;
  line-height: 120%;
  color: var(--color-font-primaryLight);
  font-style: normal;
}
.uil-in-page-navigation-horizontal ul li[class="active"] a[disabled] {
  cursor: not-allowed;
  color: var(--color-font-disabled);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
}
.uil-link-icon,
.uil-plain-icon {
  display: inline-block;
  text-align: center;
  margin: 0 2px;
  outline: 0;
  max-width: calc(1em + 48px + 16px);
  max-height: calc(1em + 48px + 8px);
  cursor: pointer;
}
.uil-link-icon {
  cursor: pointer;
  color: #767676;
  border: none;
  background: 0 0;
  padding: 0;
}
.uil-link-icon.disabled,
.uil-link-icon[disabled],
fieldset[disabled] .uil-link-icon {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-link-icon:focus,
.uil-link-icon:hover {
  color: #460073 !important;
}
.uil-link-icon:focus.disabled,
.uil-link-icon:focus[disabled],
.uil-link-icon:hover.disabled,
.uil-link-icon:hover[disabled],
fieldset[disabled] .uil-link-icon:focus,
fieldset[disabled] .uil-link-icon:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-link-icon .uil-font-icon {
  display: block;
  vertical-align: top;
  text-align: center;
}
.uil-link-icon .uil-font-icon img,
.uil-link-icon .uil-font-icon span {
  width: 1em;
  height: 1em;
}
.fade-scale {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fade-scale.in,
.fade-scale.show {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fade-slide {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fade-slide.left {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.fade-slide.right {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.fade-slide.in,
.fade-slide.show {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.uil-focused-scroll-x {
  overflow-x: hidden !important;
}
.uil-focused-scroll-x:focus,
.uil-focused-scroll-x:hover {
  overflow-x: auto !important;
}
.uil-focused-scroll-y {
  overflow-y: hidden !important;
}
.uil-focused-scroll-y:focus,
.uil-focused-scroll-y:hover {
  overflow-y: auto !important;
}
.uil-focused-scroll {
  overflow: hidden !important;
}
.uil-focused-scroll:focus,
.uil-focused-scroll:hover {
  overflow: auto !important;
}
.uil-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
}
.uil-inline-edit-mode .uil-show-on-hover {
  display: none !important;
}
.uil-inline-edit-mode:hover .uil-show-on-hover {
  display: initial !important;
}
.without-arrow a[aria-expanded="false"]::before,
.without-arrow a[aria-expanded="true"]::before,
.without-arrow[aria-expanded="false"]::before,
.without-arrow[aria-expanded="true"]::before {
  display: none !important;
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  /* src: url(../fonts/graphik/Graphik-Regular-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Regular-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Regular-Web.woff2) format("woff2"); */
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 300;
  /* src: url(../fonts/graphik/Graphik-Light-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Light-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Light-Web.woff2) format("woff2"); */
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  /* src: url(../fonts/graphik/Graphik-Medium-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Medium-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Medium-Web.woff2) format("woff2"); */
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 100;
  /* src: url(../fonts/graphik/Graphik-Thin-Web.eot?) format("eot"),
    url(../fonts/graphik/Graphik-Thin-Web.woff) format("woff"),
    url(../fonts/graphik/Graphik-Thin-Web.woff2) format("woff2"); */
}
@font-face {
  font-family: uilicon;
  font-style: normal;
  font-weight: 500;
  /* src: url(../fonts/fonticons/uil.eot?) format("eot"),
    url(../fonts/fonticons/uil.woff) format("woff"),
    url(../fonts/fonticons/uil.woff2) format("woff2"); */
}
body,
html {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.1px;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-size: 16px;
  background-color: #f4f4f4;
  color: #393a3e;
  line-height: 1.3;
}
@media (max-width: 767px) {
  body,
  html {
    font-size: 14px;
  }
}
p {
  margin: 0 0 1rem;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 1.67;
}
h1,
h2,
h3 {
  margin: 1.25rem 0 0.625rem 0;
}
label {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 12px;
  color: #393a3e;
  margin-bottom: 4px;
}
pre {
  display: block;
  padding: 8px;
  margin: 0 0 10px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
h4,
h5,
h6 {
  margin: 0.625rem 0 0.3rem 0;
}
h1 {
  font-size: 2.125rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 1.2;
}
h2 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
}
h3 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.2;
}
h4 {
  font-size: 0.9375rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 1.4;
}
h5 {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
}
h6 {
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 1.4;
}
hr {
  border: none;
  border-bottom: 1px solid #ddd;
  margin: 4px 0;
}
button,
input {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.1px;
}
button:focus,
input:focus {
  outline: 0;
}
button {
  cursor: pointer;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
a {
  color: #460073;
  text-decoration: none;
  cursor: pointer;
}
a:focus,
a:hover {
  color: var(--primary-01);
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
b,
strong {
  font-weight: 500;
}
.uil-clickable-active-table.active {
  background-color: rgba(244, 244, 244, 0.5) !important;
  border-bottom: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-clickable-active-table.selected {
  background-color: rgba(244, 244, 244, 0.5) !important;
  border-bottom: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uil-clickable {
  cursor: pointer;
}
.uil-clickable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: #f8f9fa !important;
}
@media (hover: hover) {
  .uil-clickable:hover
    .uil-clickable:not(.disabled)
    .uil-clickable:not([disabled]) {
    background-color: #f8f9fa !important;
  }
}
@media (hover: none) {
  .uil-clickable {
    background-color: transparent !important;
  }
}
.uil-clickable.active {
  background-color: #f8f9fa !important;
}
.uil-link-colored {
  color: #460073 !important;
}
.uil-search-result-title {
  font-size: 1.25rem;
  font-weight: 300;
  color: #460073 !important;
  line-height: 1.5;
  padding-top: 16px;
}
.uil-search-result-subtitle {
  font-size: 0.875rem;
  font-weight: 300;
  color: #7500c0 !important;
  line-height: 1.5;
  padding-bottom: 16px;
}
.uil-search-result-description {
  font-size: 0.875rem;
  font-weight: 300;
  color: #393a3e !important;
  padding-bottom: 16px;
}
.contextual-region {
  position: static;
}
.modal-header {
  display: none;
}
.modal-content {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-body {
  padding: 0;
}
.form-group {
  margin-bottom: 0 !important;
}
.uil-overlay {
  z-index: 1050;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.uil-overlay .uil-dialog-card {
  background-color: transparent;
  color: #f4f4f4;
  border: none;
}
.uil-overlay .uil-btn,
.uil-overlay .uil-btn-bordered,
.uil-overlay .uil-btn-flat,
.uil-overlay .uil-btn-secondary {
  margin-right: 4px;
}
.uil-fullwidth {
  width: 100%;
}
.alignRight {
  text-align: right;
}
.uil-font-banner-title {
  font-size: 4rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-banner-text {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-header {
  font-size: 2.125rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-header2 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
}
.uil-font-title {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1px !important;
  line-height: 1.2;
}
.uil-font-button {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.75px !important;
  line-height: 1.4;
  text-transform: uppercase;
}
.uil-font-subheader {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
}
.uil-font-subheader2 {
  font-size: 0.9375rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.15px !important;
  line-height: 1.4;
}
.uil-font-subheader3 {
  font-size: 0.9375rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.15px !important;
  line-height: 1.4;
}
.uil-font-body1 {
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1px !important;
  line-height: 1.4;
}
.uil-font-body2 {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.4;
}
.uil-font-body3 {
  font-size: 0.625rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.4;
}
.uil-font-paragraph {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.4;
  line-height: 1.67;
}
.uil-font-size-header2 {
  font-size: 1.5rem !important;
}
.uil-font-large {
  font-size: 1rem !important;
}
.uil-font-medium {
  font-size: 0.875rem !important;
}
.uil-font-low {
  font-size: 0.75rem !important;
}
.uil-font-light,
.uil-font-light-weight {
  font-weight: 300 !important;
}
.uil-font-medium-weight {
  font-weight: 500 !important;
}
.uil-font-regular,
.uil-font-regular-weight {
  font-weight: 400 !important;
}
.uil-font-bold,
.uil-font-bold-weight {
  font-weight: 600 !important;
}
.uil-font-oblique {
  font-style: oblique !important;
}
.uil-font-italic {
  font-style: italic !important;
}
.uil-font-normal {
  font-style: normal !important;
}
.uil-font-underline {
  text-decoration: underline !important;
  text-underline-position: under;
  -ms-text-underline-position: under;
  -webkit-text-underline-position: under;
}
.uil-font-overline {
  text-decoration: overline !important;
}
.uil-font-linethrough {
  text-decoration: line-through !important;
}
.uil-form-item-label-font {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.4;
  line-height: 12px;
}
.uil-color-primary,
.uil-color-primary-01 {
  color: #460073 !important;
}
.uil-backgroundcolor-transparent {
  background-color: transparent !important;
}
.uil-backgroundcolor-primary,
.uil-backgroundcolor-primary-01 {
  background-color: #460073 !important;
}
.uil-color-primary-focused,
.uil-color-secondary-01 {
  color: #7500c0 !important;
}
.uil-backgroundcolor-primary-focused,
.uil-backgroundcolor-secondary-01 {
  background-color: #7500c0 !important;
}
.uil-color-primary-02,
.uil-color-primary-pressed {
  color: #a100ff !important;
}
.uil-backgroundcolor-primary-02,
.uil-backgroundcolor-primary-pressed {
  background-color: #a100ff !important;
}
.uil-color-primary-03 {
  color: var(--primary-03) !important;
}
.uil-backgroundcolor-primary-03 {
  background-color: var(--primary-03) !important;
}
.uil-color-secondary-02 {
  color: var(--secondary-02) !important;
}
.uil-backgroundcolor-secondary-02 {
  background-color: var(--secondary-02) !important;
}
.uil-color-branding1 {
  color: #a100ff !important;
}
.uil-backgroundcolor-branding1 {
  background-color: #a100ff !important;
}
.uil-color-branding2 {
  color: #7500c0 !important;
}
.uil-backgroundcolor-branding2 {
  background-color: #7500c0 !important;
}
.uil-color-branding3 {
  color: #460073 !important;
}
.uil-backgroundcolor-branding3 {
  background-color: #460073 !important;
}
.uil-color-darkgrey {
  color: #767676 !important;
}
.uil-backgroundcolor-darkgrey {
  background-color: #767676 !important;
}
.uil-color-middlegrey {
  color: #c1c1c1 !important;
}
.uil-backgroundcolor-middlegrey {
  background-color: #c1c1c1 !important;
}
.uil-color-lightgrey {
  color: #f4f4f4 !important;
}
.uil-backgroundcolor-lightgrey {
  background-color: #f4f4f4 !important;
}
.uil-backgroundcolor-lightgrey-op {
  background-color: rgba(244, 244, 244, 0.5) !important;
}
.uil-color-headerline-grey {
  color: #a1a1a1 !important;
}
.uil-backgroundcolor-headerline-grey {
  background-color: #a1a1a1 !important;
}
.uil-color-rowline-grey {
  color: #ddd !important;
}
.uil-backgroundcolor-rowline-grey {
  background-color: #ddd !important;
}
.uil-color-black {
  color: #000 !important;
}
.uil-backgroundcolor-black {
  background-color: #000 !important;
}
.uil-backgroundcolor-overlay-op {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.uil-color-default-text {
  color: #393a3e !important;
}
.uil-color-disabled-text {
  color: #c2c7cb !important;
}
.uil-color-default-label {
  color: #393a3e !important;
}
.uil-color-highlight {
  color: #460073 !important;
}
.uil-color-white {
  color: #fff !important;
}
.uil-backgroundcolor-white {
  background-color: #fff !important;
}
.uil-color-red {
  color: #ff3246 !important;
}
.uil-backgroundcolor-red {
  background-color: #ff3246 !important;
}
.uil-color-green {
  color: #4ab43e !important;
}
.uil-backgroundcolor-green {
  background-color: #4ab43e !important;
}
.uil-color-yellow {
  color: #f2d80a !important;
}
.uil-backgroundcolor-yellow {
  background-color: #f2d80a !important;
}
.uil-color-orange {
  color: #ff9f00 !important;
}
.uil-backgroundcolor-orange {
  background-color: #ff9f00 !important;
}
.uil-gradient-background {
  background-image: linear-gradient(-135deg, #a100ff 0, #460073 100%);
}
.uil-horizontal-line {
  border-bottom: 1px solid #ddd !important;
}
.uil-horizontal-header-line {
  border-bottom: 2px solid #a1a1a1 !important;
}
.uil-search-font {
  font-size: 1.5rem !important;
}
.ngb-dp-navigation-chevron {
  border-color: transparent;
  -webkit-transform: rotate(0) !important;
  transform: rotate(0) !important;
  color: #767676;
  border: none !important;
  background: 0 0;
  padding: 0;
}
.ngb-dp-navigation-chevron.disabled,
.ngb-dp-navigation-chevron[disabled],
fieldset[disabled] .ngb-dp-navigation-chevron {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.ngb-dp-navigation-chevron:hover {
  color: #460073 !important;
}
.ngb-dp-navigation-chevron:hover.disabled,
.ngb-dp-navigation-chevron:hover[disabled],
fieldset[disabled] .ngb-dp-navigation-chevron:hover {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.ngb-dp-arrow-btn:active,
.ngb-dp-arrow-btn:focus,
.ngb-dp-arrow-btn:focus:active {
  outline: 0 !important;
}
.hidden {
  display: none !important;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.pull-none {
  float: none !important;
}
@font-face {
  font-family: uilicon_dl;
  font-style: normal;
  font-weight: 500;
  /* src: url(../fonts/fonticons/uil-dl.eot?) format("eot"),
    url(../fonts/fonticons/uil-dl.woff) format("woff"),
    url(../fonts/fonticons/uil-dl.woff2) format("woff2"); */
}
.uil-form-item-label-font {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-dls-font-heading-04 {
  font-size: 4.25rem !important;
  font-weight: 400 !important;
  letter-spacing: -1px !important;
  line-height: 1.2;
  line-height: 5rem;
}
.uil-dls-font-heading-03 {
  font-size: 3rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 4rem;
}
.uil-dls-font-heading-01 {
  font-size: 1.75rem !important;
  font-weight: 300 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 2rem;
}
.uil-dls-font-heading-02 {
  font-size: 2.125rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 2.5rem;
}
.uil-dls-font-title-00,
.uil-textbox.uil-search-font {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
  line-height: 1.2;
  line-height: 1.5rem;
}
.uil-dls-font-title-01 {
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-dls-font-title-02 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 1.75rem;
}
.uil-dls-font-caption-00 {
  font-size: 0.625rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 0.75rem;
}
.uil-dls-font-caption-01 {
  font-size: 0.625rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 0.75rem;
}
.uil-dls-font-caption-02 {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-dls-font-caption-03 {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-dls-font-body-01 {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-dls-font-body-02 {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-banner-title {
  font-size: 4.25rem !important;
  font-weight: 400 !important;
  letter-spacing: -1px !important;
  line-height: 1.2;
  line-height: 5rem;
}
.uil-font-header {
  font-size: 3rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 4rem;
}
.uil-font-header2 {
  font-size: 2.125rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.2;
  line-height: 2.5rem;
}
.uil-font-button {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-subheader {
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-body1 {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-body2 {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-body3 {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-font-paragraph {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-font-size-header2 {
  font-size: 2.125rem !important;
}
.uil-font-light,
.uil-font-light-weight {
  font-weight: 300 !important;
}
.uil-font-medium-weight {
  font-weight: 500 !important;
}
.uil-font-regular,
.uil-font-regular-weight {
  font-weight: 400 !important;
}
.uil-font-bold,
.uil-font-bold-weight {
  font-weight: 600 !important;
}
h1 {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 4rem;
}
h2 {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 2.5rem;
}
h3 {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  line-height: 1.75rem;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
h5 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
h6 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
p {
  margin: 0 0 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-color-branding1 {
  color: #a100ff;
}
.uil-backgroundcolor-branding1 {
  background-color: #a100ff;
}
.uil-color-branding2 {
  color: #7500c0;
}
.uil-backgroundcolor-branding2 {
  background-color: #7500c0;
}
.uil-color-branding3 {
  color: #460073;
}
.uil-backgroundcolor-branding3 {
  background-color: #460073;
}
.uil-color-branding4 {
  color: var(--brand-04);
}
.uil-backgroundcolor-branding4 {
  background-color: var(--brand-04);
}
.uil-overlay {
  z-index: 1050;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.uil-overlay .uil-btn,
.uil-overlay .uil-btn-bordered,
.uil-overlay .uil-btn-flat,
.uil-overlay .uil-btn-secondary {
  height: 32px;
  margin-right: 4px;
}
label {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  color: #393a3e;
  margin-bottom: 4px;
}
label.disabled,
label[disabled] {
  color: #c2c7cb;
  cursor: not-allowed;
}
.uil-dl-specific {
  display: block !important;
}
.uil-color-red-01 {
  color: #e6242f !important;
}
.uil-backgroundcolor-red-01 {
  background-color: #e6242f !important;
}
.uil-color-red-02 {
  color: #b41a22 !important;
}
.uil-backgroundcolor-red-02 {
  background-color: #b41a22 !important;
}
.uil-color-red-03 {
  color: #ff7b7b !important;
}
.uil-backgroundcolor-red-03 {
  background-color: #ff7b7b !important;
}
.uil-color-red-04 {
  color: #d94747 !important;
}
.uil-backgroundcolor-red-04 {
  background-color: #d94747 !important;
}
.uil-color-green-01 {
  color: #008731 !important;
}
.uil-backgroundcolor-green-01 {
  background-color: #008731 !important;
}
.uil-color-green-02 {
  color: #3edd7b !important;
}
.uil-backgroundcolor-green-02 {
  background-color: #3edd7b !important;
}
.uil-color-yellow-01 {
  color: #ffbe00 !important;
}
.uil-backgroundcolor-yellow-01 {
  background-color: #ffbe00 !important;
}
.uil-color-yellow-02 {
  color: #ffdf39 !important;
}
.uil-backgroundcolor-yellow-02 {
  background-color: #ffdf39 !important;
}
.uil-color-yellow-03 {
  color: #ff7400 !important;
}
.uil-backgroundcolor-yellow-03 {
  background-color: #ff7400 !important;
}
.uil-color-gray-01 {
  color: #f8f9fa !important;
}
.uil-backgroundcolor-gray-01 {
  background-color: #f8f9fa !important;
}
.uil-color-gray-02 {
  color: #f1f2f4 !important;
}
.uil-backgroundcolor-gray-02 {
  background-color: #f1f2f4 !important;
}
.uil-color-gray-03 {
  color: #e8ecee !important;
}
.uil-backgroundcolor-gray-03 {
  background-color: #e8ecee !important;
}
.uil-color-gray-04 {
  color: #ccd1d7 !important;
}
.uil-backgroundcolor-gray-04 {
  background-color: #ccd1d7 !important;
}
.uil-color-gray-05 {
  color: #c2c7cb !important;
}
.uil-backgroundcolor-gray-05 {
  background-color: #c2c7cb !important;
}
.uil-color-gray-06 {
  color: #abb0b6 !important;
}
.uil-backgroundcolor-gray-06 {
  background-color: #abb0b6 !important;
}
.uil-color-gray-07 {
  color: #8b9298 !important;
}
.uil-backgroundcolor-gray-07 {
  background-color: #8b9298 !important;
}
.uil-color-gray-08 {
  color: #575961 !important;
}
.uil-backgroundcolor-gray-08 {
  background-color: #575961 !important;
}
.uil-color-gray-09 {
  color: #393a3e !important;
}
.uil-backgroundcolor-gray-09 {
  background-color: #393a3e !important;
}
.uil-color-gray-10 {
  color: #222 !important;
}
.uil-backgroundcolor-gray-10 {
  background-color: #222 !important;
}
.uil-side-nav-configurable {
  background: 0 0;
  width: 248px;
  overflow-y: overlay;
  height: 100%;
  padding: 0;
  margin: 0;
}
.uil-side-nav-configurable ul.uil-side-nav-configurable-list {
  list-style: none;
  height: auto;
  padding-left: 0;
  margin-bottom: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item {
  padding-left: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 10px 10px 12px;
  color: #393a3e;
  margin-left: 28px;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a::before {
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  margin-left: calc((12px + 16px) * -1);
  font-size: 16px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a.uil-side-nav-icon {
  padding: 10px 15px 10px 8px;
  margin-left: 32px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a.uil-side-nav-icon::before {
  height: 20px;
  width: 24px;
  margin-top: 0;
  margin-right: 8px;
  margin-left: calc((8px + 24px) * -1);
  font-size: 24px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a:not(.uil-side-nav-icon)[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  a:not(.uil-side-nav-icon)[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active
  a:not(.uil-side-nav-icon)::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:hover
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:hover
  a:not(.uil-side-nav-icon)::before {
  color: var(--brand-02);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active.uil-merged-item
  > a {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 4px;
  cursor: default;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active.uil-merged-item
  > a::before {
  content: "";
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.active.uil-merged-item
  + ul {
  border-bottom: solid 1px #e8ecee;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:not(.active).uil-merged-item.hasSubmenu
  > a {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 4px;
  cursor: default;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:not(.active).uil-merged-item.hasSubmenu
  > a::before {
  content: "";
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item:not(.active).uil-merged-item.hasSubmenu
  + ul {
  border-bottom: solid 1px #e8ecee;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  a {
  padding-left: 6px;
  margin-left: 60px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
  margin-right: 6px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 16px 16px;
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-right: 12px;
  margin-top: 0;
  margin-right: 6px;
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.active
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.active
  a:not(.uil-side-nav-icon)::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item:hover
  a::before,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item:hover
  a:not(.uil-side-nav-icon)::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item {
  margin-left: 16px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item
  a {
  padding: 12px 10px 12px 20px;
  margin-left: 44px;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.active
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item:hover
  a {
  padding-left: calc(20px - 2px);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.active
  a {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1rem;
  line-height: 16px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-sub-menu-item
  li.uil-side-nav-configurable-sub-menu-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item
  a {
  padding-left: 20px;
  margin-left: 40px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item.active
  a {
  padding-left: calc(20px - 2px);
  border-left: 2px solid #460073 !important;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item:hover
  a {
  padding-left: calc(20px - 2px);
  border-left: 2px solid #7500c0 !important;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item
  ul
  li.uil-side-nav-configurable-content-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px 10px 14px;
  margin-left: 2px;
  color: #393a3e;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
  -webkit-transition: none;
  transition: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a::before {
  height: 20px;
  width: 16px;
  margin-top: 0;
  margin-right: 12px;
  margin-left: calc((12px + 16px) * -1);
  font-size: 16px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a.uil-side-nav-icon {
  padding: 10px 15px 10px 8px;
  margin-left: 32px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a.uil-side-nav-icon::before {
  height: 20px;
  width: 24px;
  margin-top: 0;
  margin-right: 8px;
  margin-left: calc((8px + 24px) * -1);
  font-size: 24px;
  color: #393a3e;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item
  a.uil-clickable:hover:not(.disabled):not(.selected):not([disabled]) {
  background-color: transparent !important;
}
@media (hover: hover) {
  .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable:hover
    .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable:not(.disabled)
    .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable:not([disabled]) {
    background-color: transparent !important;
  }
}
@media (hover: none) {
  .uil-side-nav-configurable
    ul.uil-side-nav-configurable-list
    li.uil-side-nav-configurable-content-item
    a.uil-clickable {
    background-color: transparent !important;
  }
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.active
  a {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.active
  a:not(.uil-side-nav-icon) {
  padding-left: calc(14px - 4px);
  border-left: 4px solid #460073 !important;
  border-radius: 0;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.active
  a::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.uil-merged-item {
  border-bottom: solid 1px #e8ecee;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item:hover
  a:not(.uil-side-nav-icon) {
  padding-left: calc(14px - 4px);
  border-left: 4px solid #7500c0 !important;
  border-radius: 0;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item:hover
  a::before {
  color: var(--brand-01);
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.disabled,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item.disabled
  a,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-content-item[disabled]
  a {
  color: #c2c7cb !important;
  cursor: not-allowed;
}
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-merged-item.hasSubmenu
  + ul:last-child,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-merged-item:last-child,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.uil-merged-item
  + ul:last-child,
.uil-side-nav-configurable
  ul.uil-side-nav-configurable-list
  li.uil-side-nav-configurable-menu-item.uil-merged-item.hasSubmenu
  + ul:last-child {
  border-bottom: none;
}
.uil-side-nav {
  min-width: 248px;
  max-width: 248px;
  overflow-y: auto;
  overflow-y: overlay;
  color: #000 !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  line-height: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: #fff !important;
  -webkit-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24),
    0 8px 8px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
}
.uil-side-nav.active {
  min-width: 80px;
  max-width: 80px;
  overflow: initial;
}
.uil-side-nav.active .uil-side-nav-item,
.uil-side-nav.active .uil-side-nav-menu-item {
  height: 44px;
  overflow: hidden;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  white-space: nowrap !important;
}
.uil-side-nav.active .uil-side-nav-item a,
.uil-side-nav.active .uil-side-nav-menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: transparent !important;
  white-space: nowrap !important;
}
.uil-side-nav.active .uil-side-nav-item a[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-item a[aria-expanded="true"]::before,
.uil-side-nav.active .uil-side-nav-menu-item a[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-menu-item a[aria-expanded="true"]::before {
  display: none;
}
.uil-side-nav.active .uil-side-nav-item:focus,
.uil-side-nav.active .uil-side-nav-item:hover,
.uil-side-nav.active .uil-side-nav-menu-item:focus,
.uil-side-nav.active .uil-side-nav-menu-item:hover {
  color: #000 !important;
}
.uil-side-nav.active .uil-side-nav-item[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-item[aria-expanded="true"]::before,
.uil-side-nav.active .uil-side-nav-menu-item[aria-expanded="false"]::before,
.uil-side-nav.active .uil-side-nav-menu-item[aria-expanded="true"]::before {
  display: none;
}
.uil-side-nav.without-footer .uil-collapsed-sidemenu-items,
.uil-side-nav.without-footer .uil-side-nav-list {
  height: auto !important;
}
.uil-nav-icon-large {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  font-size: 32px;
  vertical-align: middle;
}
.uil-nav-icon-small {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
  font-size: 24px;
  vertical-align: middle;
}
.uil-nav-icon-footer {
  width: 13px;
  height: 13px;
  min-width: 13px;
  min-height: 13px;
  margin-right: 8px;
  font-size: 13px;
}
.uil-collapsed-sidemenu-items,
.uil-side-nav-list {
  padding-left: 0;
  list-style: none;
  display: block;
  margin-bottom: 0;
  width: 100%;
  padding-top: 0;
  min-height: calc(100% - 32px);
  overflow: auto;
}
.nonactive.uil-collapsed-sidemenu-items,
.uil-side-nav-list.nonactive {
  display: none;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item,
.uil-side-nav-list > .uil-side-nav-content-item {
  width: 100%;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item.active a,
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item:active a,
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item:focus a,
.uil-side-nav-list > .uil-side-nav-content-item.active a,
.uil-side-nav-list > .uil-side-nav-content-item.active a:focus,
.uil-side-nav-list > .uil-side-nav-content-item:active a,
.uil-side-nav-list > .uil-side-nav-content-item:active a:focus,
.uil-side-nav-list > .uil-side-nav-content-item:focus a,
.uil-side-nav-list > .uil-side-nav-content-item:focus a:focus {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1.25rem;
  margin-left: 16px !important;
  padding-left: calc(14px - 4px) !important;
  border-left: 4px solid #460073 !important;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item:hover a,
.uil-side-nav-list > .uil-side-nav-content-item:hover a {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  margin-left: 16px !important;
  padding-left: calc(14px - 4px) !important;
  border-left: 4px solid #460073 !important;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
}
.uil-collapsed-sidemenu-items > .uil-side-nav-content-item a,
.uil-side-nav-list > .uil-side-nav-content-item a {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  margin-left: 16px !important;
  padding-left: 14px !important;
  width: calc(100% - 16px);
  -webkit-transition: none;
  transition: none;
}
.uil-collapsed-sidemenu-items.collapse {
  display: none;
}
.uil-collapsed-sidemenu-items.collapse.in,
.uil-collapsed-sidemenu-items.collapse.show {
  display: block;
}
nav ul .uil-side-nav-menu-item > a,
nav ul .uil-side-nav-menu-item > a.collapse,
nav ul .uil-side-nav-menu-item > a.collapse.in,
nav ul .uil-side-nav-menu-item > a.collapse.show {
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
nav ul li.uil-side-nav-content-item:hover > a,
nav ul li.uil-side-nav-content-item > a {
  line-height: 20px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
li.uil-side-nav-menu-item {
  min-height: 44px;
  padding-left: 0 !important;
  width: 100%;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  margin-bottom: 0 !important;
  white-space: nowrap !important;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  outline: 0 !important;
  border: none !important;
  padding-left: 0;
  color: #000 !important;
}
li.uil-side-nav-menu-item.with-icon ul {
  margin-left: 6px;
  width: 238px;
}
li.uil-side-nav-menu-item.without-arrow a[aria-expanded="false"]::before,
li.uil-side-nav-menu-item.without-arrow a[aria-expanded="true"]::before,
li.uil-side-nav-menu-item.without-arrow[aria-expanded="false"]::before,
li.uil-side-nav-menu-item.without-arrow[aria-expanded="true"]::before {
  display: none;
}
li.uil-side-nav-menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 10px 10px 12px !important;
  width: calc(100% - 36px);
  min-height: inherit;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-color: transparent !important;
  color: #000 !important;
  -webkit-transition: none;
  transition: none;
  margin-left: 36px;
  margin-right: 0;
  white-space: normal;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-menu-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 12px;
}
li.uil-side-nav-menu-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 12px;
}
li.uil-side-nav-menu-item a:hover::before {
  color: #7500c0;
}
li.uil-side-nav-menu-item a[aria-expanded="true"] {
  border: none !important;
}
li.uil-side-nav-menu-item a[data-toggle="collapse"] {
  position: relative;
}
li.uil-side-nav-menu-item a:hover {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-menu-item a:hover.active {
  padding-left: 12px !important;
}
li.uil-side-nav-menu-item a:hover[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-menu-item a span {
  color: #000;
  vertical-align: middle;
  font-size: 24px;
}
li.uil-side-nav-menu-item[data-toggle="collapse"] {
  position: relative;
}
li.uil-side-nav-menu-item[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-menu-item[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-menu-item[aria-expanded="true"] {
  border: none !important;
}
li.uil-side-nav-menu-item.active,
li.uil-side-nav-menu-item:active,
li.uil-side-nav-menu-item:focus,
li.uil-side-nav-menu-item:hover {
  outline: 0 !important;
}
li.uil-side-nav-menu-item.active > .svg path,
li.uil-side-nav-menu-item:active > .svg path,
li.uil-side-nav-menu-item:focus > .svg path,
li.uil-side-nav-menu-item:hover > .svg path {
  fill: #460073;
}
li.uil-side-nav-menu-item ul {
  width: 100%;
}
li.uil-side-nav-menu-item.disabled,
li.uil-side-nav-menu-item[disabled] {
  color: #c2c7cb;
}
li.uil-side-nav-menu-item.disabled a,
li.uil-side-nav-menu-item[disabled] a {
  color: #c2c7cb !important;
}
li.uil-side-nav-menu-item.active {
  color: #000 !important;
  border: none !important;
  padding-left: 2px;
  background: 0 0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-menu-item.active > a {
  color: #000 !important;
  border-left: 2px solid #460073 !important;
  border-radius: 0;
  padding-left: 12px;
}
li.uil-side-nav-menu-item.active > a:hover {
  padding-left: 12px !important;
}
li.uil-side-nav-menu-item.active > a[aria-expanded="false"],
li.uil-side-nav-menu-item.active > a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px;
}
li.uil-side-nav-menu-item.active > a div span {
  color: #460073;
}
li.uil-side-nav-menu-item.active > a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
li.uil-side-nav-menu-item.active > a > .svg path {
  fill: #460073;
}
.uil-side-nav-item {
  min-height: 44px;
  padding-left: 2px !important;
  width: 100%;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  margin-bottom: 0 !important;
  white-space: nowrap !important;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  outline: 0 !important;
  border: none !important;
  padding-left: 0;
  color: #000 !important;
}
.uil-side-nav-item.with-icon ul {
  margin-left: 6px;
  width: 238px;
}
.uil-side-nav-item.without-arrow a[aria-expanded="false"]::before,
.uil-side-nav-item.without-arrow a[aria-expanded="true"]::before,
.uil-side-nav-item.without-arrow[aria-expanded="false"]::before,
.uil-side-nav-item.without-arrow[aria-expanded="true"]::before {
  display: none;
}
.uil-side-nav-item a {
  padding: 10px 10px 10px 12px !important;
  width: 100%;
  min-height: inherit;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-color: transparent !important;
  color: #000 !important;
  -webkit-transition: none;
  transition: none;
}
.uil-side-nav-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item a[aria-expanded="true"] {
  border: none !important;
}
.uil-side-nav-item a[data-toggle="collapse"] {
  position: relative;
}
.uil-side-nav-item a:hover {
  border-left: 2px solid #7500c0 !important;
  border-radius: 0;
  border-radius: 0;
  padding-left: 10px !important;
}
.uil-side-nav-item a:hover.active {
  padding-left: 12px !important;
}
.uil-side-nav-item a:hover[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
.uil-side-nav-item a span {
  color: #000;
  vertical-align: middle;
  font-size: 24px;
}
.uil-side-nav-item[data-toggle="collapse"] {
  position: relative;
}
.uil-side-nav-item[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  float: right;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transition: 0s ease;
  transition: 0s ease;
  background-size: 0.75rem 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  margin-right: 4px;
  color: #8b9298;
  font-size: 0.75rem;
}
.uil-side-nav-item[aria-expanded="true"] {
  border: none !important;
}
.uil-side-nav-item.active,
.uil-side-nav-item:active,
.uil-side-nav-item:focus,
.uil-side-nav-item:hover {
  outline: 0 !important;
}
.uil-side-nav-item.active > .svg path,
.uil-side-nav-item:active > .svg path,
.uil-side-nav-item:focus > .svg path,
.uil-side-nav-item:hover > .svg path {
  fill: #460073;
}
.uil-side-nav-item ul {
  width: 246px;
  margin-left: -2px;
}
.uil-side-nav-item.disabled,
.uil-side-nav-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-item.disabled a,
.uil-side-nav-item[disabled] a {
  color: #c2c7cb !important;
}
.uil-side-nav-item.active {
  color: #000 !important;
  border: none !important;
  padding-left: 2px !important;
  background: 0 0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-item.active a {
  color: #000 !important;
  border-left: 2px solid #460073 !important;
  border-radius: 0;
  padding-left: 10px !important;
}
.uil-side-nav-item.active a[aria-expanded="false"],
.uil-side-nav-item.active a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
.uil-side-nav-item.active a div span {
  color: #460073;
}
.uil-side-nav-item.active a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-item.active a > .svg path {
  fill: #460073;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item a,
.uil-side-nav-sub-menu-item:not(.active) .uil-side-nav-content-item a {
  margin-left: 64px !important;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:active a,
.uil-side-nav-sub-menu-item:not(.active) .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item:not(.active) .uil-side-nav-content-item:active a {
  padding-left: calc(16px - 2px) !important;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:not(.active) a,
.uil-side-nav-sub-menu-item:not(.active)
  .uil-side-nav-content-item:not(.active)
  a {
  padding-left: 16px !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item.active
  .uil-side-nav-content-item:not(.active):hover
  a,
.uil-side-nav-sub-menu-item:not(.active)
  .uil-side-nav-content-item:not(.active):hover
  a {
  padding-left: calc(16px - 2px) !important;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:hover a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item:hover a {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:active a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item:active a {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item.active:hover a,
.uil-side-nav-sub-menu-item.active .uil-side-nav-content-item:active:hover a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item.active:hover a,
.uil-side-nav-sub-menu-item:active .uil-side-nav-content-item:active:hover a {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item {
  width: calc(100% - 12px) !important;
  margin-left: 12px !important;
  padding-left: 0;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item a {
  width: calc(100% - 68px) !important;
  padding-left: 16px !important;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item:hover a {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
  padding-left: 16px !important;
}
.uil-side-nav-sub-menu-item .uil-side-nav-content-item.active a,
.uil-side-nav-sub-menu-item .uil-side-nav-content-item:active a,
.uil-side-nav-sub-menu-item .uil-side-nav-content-item:focus a {
  border-left: 2px solid #460073 !important;
  border-radius: 0;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.4;
  line-height: 1rem;
  padding-left: 16px !important;
}
li.uil-side-nav-sub-menu-item {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 40px;
  height: auto;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  background: 0 0 !important;
}
li.uil-side-nav-sub-menu-item[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-sub-menu-item[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
li.uil-side-nav-sub-menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% - 68px);
  padding-left: 12px !important;
  margin-left: 68px !important;
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
li.uil-side-nav-sub-menu-item a:hover {
  padding-left: calc(12px - 2px) !important;
}
li.uil-side-nav-sub-menu-item a {
  white-space: normal;
}
li.uil-side-nav-sub-menu-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 8px;
}
li.uil-side-nav-sub-menu-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
  margin-left: calc((12px + 1.5rem) * -1);
  margin-right: 8px;
}
li.uil-side-nav-sub-menu-item a:hover[aria-expanded="false"] {
  border-left: 2px solid #7500c0 !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item a:hover[aria-expanded="true"] {
  border: none !important;
  padding-left: calc(12px - 2px) !important;
}
li.uil-side-nav-sub-menu-item:hover {
  border: none !important;
}
li.uil-side-nav-sub-menu-item:hover a {
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item:hover a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
li.uil-side-nav-sub-menu-item:hover a > .svg path {
  fill: #460073;
}
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="false"] {
  border-left: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="false"]::before,
li.uil-side-nav-sub-menu-item:hover a[aria-expanded="true"]::before {
  color: #7500c0;
}
li.uil-side-nav-sub-menu-item.active,
li.uil-side-nav-sub-menu-item:active,
li.uil-side-nav-sub-menu-item:focus {
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
li.uil-side-nav-sub-menu-item.active a,
li.uil-side-nav-sub-menu-item:active a,
li.uil-side-nav-sub-menu-item:focus a {
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  padding-left: 10px !important;
}
li.uil-side-nav-sub-menu-item.active a:focus,
li.uil-side-nav-sub-menu-item:active a:focus,
li.uil-side-nav-sub-menu-item:focus a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-left: 2px solid #7500c0 !important;
  border-radius: 0;
}
li.uil-side-nav-sub-menu-item.active a > .svg path,
li.uil-side-nav-sub-menu-item:active a > .svg path,
li.uil-side-nav-sub-menu-item:focus a > .svg path {
  fill: #460073;
}
li.uil-side-nav-sub-menu-item.active a[aria-expanded="false"],
li.uil-side-nav-sub-menu-item:active a[aria-expanded="false"],
li.uil-side-nav-sub-menu-item:focus a[aria-expanded="false"] {
  border: none !important;
  padding-left: 12px !important;
}
li.uil-side-nav-sub-menu-item.active a[aria-expanded="true"],
li.uil-side-nav-sub-menu-item:active a[aria-expanded="true"],
li.uil-side-nav-sub-menu-item:focus a[aria-expanded="true"] {
  border: none !important;
  padding-left: 12px !important;
}
.uil-side-nav-menu-item > ul > .uil-side-nav-content-item a {
  width: calc(100% - 48px) !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item,
li.uil-side-nav-content-item {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 40px;
  height: auto;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  background: 0 0 !important;
  white-space: initial;
}
.uil-side-nav-menu-item .uil-side-nav-content-item a,
li.uil-side-nav-content-item a {
  border: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 28px !important;
  margin-left: 48px;
  width: calc(100% - 68px);
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item a:hover,
li.uil-side-nav-content-item a:hover {
  padding-left: 12px !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item
  .uil-side-nav-content-item
  a[aria-expanded="false"]::before,
li.uil-side-nav-content-item a[aria-expanded="false"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea1e";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
.uil-side-nav-menu-item
  .uil-side-nav-content-item
  a[aria-expanded="true"]::before,
li.uil-side-nav-content-item a[aria-expanded="true"]::before {
  font-family: uilicon_dl, Graphik, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e981";
  display: inline-block;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  background-size: 1.5rem 1.5rem;
  height: 1rem;
  width: 1.5rem;
  margin-top: 0;
  margin-right: 12px;
  color: #393a3e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0;
  float: left;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover,
li.uil-side-nav-content-item:hover {
  border: none !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover a,
li.uil-side-nav-content-item:hover a {
  padding-left: calc(28px - 2px) !important;
  border-left: 2px solid #7500c0 !important;
  border-radius: 0;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover a:focus,
li.uil-side-nav-content-item:hover a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-menu-item .uil-side-nav-content-item:hover a > .svg path,
li.uil-side-nav-content-item:hover a > .svg path {
  fill: #460073;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active,
.uil-side-nav-menu-item .uil-side-nav-content-item:active,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus,
li.uil-side-nav-content-item.active,
li.uil-side-nav-content-item:active,
li.uil-side-nav-content-item:focus {
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active:hover a,
.uil-side-nav-menu-item .uil-side-nav-content-item:active:hover a,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus:hover a,
li.uil-side-nav-content-item.active:hover a,
li.uil-side-nav-content-item:active:hover a,
li.uil-side-nav-content-item:focus:hover a {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active a,
.uil-side-nav-menu-item .uil-side-nav-content-item:active a,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus a,
li.uil-side-nav-content-item.active a,
li.uil-side-nav-content-item:active a,
li.uil-side-nav-content-item:focus a {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  padding-left: calc(28px - 2px) !important;
  border-left: 2px solid #460073 !important;
  border-radius: 0;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active a:focus,
.uil-side-nav-menu-item .uil-side-nav-content-item:active a:focus,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus a:focus,
li.uil-side-nav-content-item.active a:focus,
li.uil-side-nav-content-item:active a:focus,
li.uil-side-nav-content-item:focus a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-left: 2px solid #7500c0 !important;
  border-radius: 0;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.active a > .svg path,
.uil-side-nav-menu-item .uil-side-nav-content-item:active a > .svg path,
.uil-side-nav-menu-item .uil-side-nav-content-item:focus a > .svg path,
li.uil-side-nav-content-item.active a > .svg path,
li.uil-side-nav-content-item:active a > .svg path,
li.uil-side-nav-content-item:focus a > .svg path {
  fill: #460073;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.disabled,
.uil-side-nav-menu-item .uil-side-nav-content-item[disabled],
li.uil-side-nav-content-item.disabled,
li.uil-side-nav-content-item[disabled] {
  color: #c2c7cb;
}
.uil-side-nav-menu-item .uil-side-nav-content-item.disabled a,
.uil-side-nav-menu-item .uil-side-nav-content-item[disabled] a,
li.uil-side-nav-content-item.disabled a,
li.uil-side-nav-content-item[disabled] a {
  color: #c2c7cb !important;
}
.uil-side-nav-item .uil-side-nav-subitem,
.uil-side-nav-subitem {
  padding-left: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
  color: #000 !important;
  background: #fff !important;
}
.uil-side-nav-item .uil-side-nav-subitem a,
.uil-side-nav-subitem a {
  margin-left: 12px;
  padding-left: 12px !important;
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-item .uil-side-nav-subitem a:hover,
.uil-side-nav-subitem a:hover {
  padding-left: 12px !important;
}
.uil-side-nav-item .uil-side-nav-subitem:hover,
.uil-side-nav-subitem:hover {
  border: none !important;
}
.uil-side-nav-item .uil-side-nav-subitem:hover a,
.uil-side-nav-subitem:hover a {
  padding-left: 10px !important;
  border-left: 2px solid #7500c0 !important;
  border-radius: 0;
}
.uil-side-nav-item .uil-side-nav-subitem:hover a:focus,
.uil-side-nav-subitem:hover a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
}
.uil-side-nav-item .uil-side-nav-subitem:hover a > .svg path,
.uil-side-nav-subitem:hover a > .svg path {
  fill: #460073;
}
.uil-side-nav-item .uil-side-nav-subitem.active,
.uil-side-nav-item .uil-side-nav-subitem:active,
.uil-side-nav-item .uil-side-nav-subitem:focus,
.uil-side-nav-subitem.active,
.uil-side-nav-subitem:active,
.uil-side-nav-subitem:focus {
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-item .uil-side-nav-subitem.active a,
.uil-side-nav-item .uil-side-nav-subitem:active a,
.uil-side-nav-item .uil-side-nav-subitem:focus a,
.uil-side-nav-subitem.active a,
.uil-side-nav-subitem:active a,
.uil-side-nav-subitem:focus a {
  color: #000 !important;
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  padding-left: 10px !important;
  border-left: 2px solid #460073 !important;
  border-radius: 0;
}
.uil-side-nav-item .uil-side-nav-subitem.active a:focus,
.uil-side-nav-item .uil-side-nav-subitem:active a:focus,
.uil-side-nav-item .uil-side-nav-subitem:focus a:focus,
.uil-side-nav-subitem.active a:focus,
.uil-side-nav-subitem:active a:focus,
.uil-side-nav-subitem:focus a:focus {
  border: none !important;
  background: 0 0 !important;
  outline: 0 !important;
  border-left: 2px solid #7500c0 !important;
  border-radius: 0;
}
.uil-side-nav-item .uil-side-nav-subitem.active a > .svg path,
.uil-side-nav-item .uil-side-nav-subitem:active a > .svg path,
.uil-side-nav-item .uil-side-nav-subitem:focus a > .svg path,
.uil-side-nav-subitem.active a > .svg path,
.uil-side-nav-subitem:active a > .svg path,
.uil-side-nav-subitem:focus a > .svg path {
  fill: #460073;
}
.uil-side-nav .uil-side-nav-footer {
  max-width: 248px;
  height: 32px;
  width: 100%;
  display: block;
  background-color: #460073;
  position: sticky;
  bottom: 0;
  border-radius: 0;
}
.uil-side-nav .uil-side-nav-footer.active {
  max-width: 80px;
}
.uil-side-nav .uil-side-nav-footer.active .sidebar-open {
  display: block;
}
.uil-side-nav .uil-side-nav-footer.active .sidebar-collapse {
  display: none;
}
.uil-side-nav .uil-side-nav-footer .sidebar-open {
  display: none;
}
.sidebarCollapse {
  background: 0 0;
  outline: 0;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  display: block;
  padding-top: 3px;
  padding-bottom: 4px;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
}
.sidebarCollapse:active,
.sidebarCollapse:focus,
.sidebarCollapse:focus:hover,
.sidebarCollapse:hover {
  background: 0 0;
}
.uil-side-nav-menu-item.uil-merged-item a {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-side-nav-menu-item.uil-merged-item a[aria-expanded="true"] {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  line-height: 1.25rem;
}
.uil-footer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--color-bg-light);
  border-top: 1px solid var(--color-border-default);
}
.uil-footer-basic {
  padding: 24px;
  position: absolute;
  height: 77px;
}
.uil-footer-text {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-greyDark);
}
.uil-footer-text-right {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  padding: 6px 8px;
  color: var(--color-font-greyDark);
  float: right;
}
.uil-left {
  padding-left: 16px;
  border-left: 1px solid var(--color-border-default);
}
.uil-right {
  padding-right: 16px;
}
.uil-footer-basic.uil-footer-padding-small,
.uil-footer-detailed.uil-footer-padding-small {
  padding-left: 32px;
  padding-right: 32px;
}
.uil-footer-basic.uil-footer-padding-medium,
.uil-footer-detailed.uil-footer-padding-medium {
  padding-left: 80px;
  padding-right: 80px;
}
.uil-footer-basic.uil-footer-padding-large,
.uil-footer-detailed.uil-footer-padding-large {
  padding-left: 96px;
  padding-right: 96px;
}
.uil-footer-detailed {
  padding: 56px 24px;
  position: absolute;
  height: 261px;
}
.uil-footer-brand-copyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 305px;
  height: 100%;
}
.uil-footer-logos {
  margin-bottom: 21px;
}
.uil-footer-logo-image {
  width: 101px;
  height: 28px;
  border: 0;
  margin-right: 16px;
}
.uil-footer-appcoe {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-title-lg);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  color: var(--color-font-dark);
  padding-left: 16px;
  border-left: 2px solid var(--color-border-default);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
}
.uil-footer-hyperlinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 126px;
  height: 100%;
  list-style-type: none;
}
.uil-footer-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 8px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -ms-flex-positive: 0;
  flex-grow: 0;
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-font-greyDark);
}
.uil-footer-helpfeedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 437px;
  height: 100%;
}
.uil-footer-feedback {
  font-style: normal;
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-md);
  line-height: 120%;
  color: var(--color-font-dark);
}
.uil-footer-button {
  width: 200px;
  padding: 14px 40px;
  margin-bottom: 10px;
}
.uil-footer-small-links {
  list-style-type: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.uil-footer-small-links > li + li:before {
  content: "|";
  color: var(--color-font-disabled);
  font-weight: 400;
  padding: 0 16px;
}
.uil-footer a {
  color: var(--color-font-greyDark);
}
.uil-curtain {
  height: 100%;
  overflow: hidden;
  padding: 0;
  border: 1px solid #f1f2f4;
}
.uil-curtain-wrapper {
  width: 100%;
  height: 100%;
}
.uil-curtain-panel {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  z-index: 2;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.uil-curtain-prize {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.uil-curtain-prize.curtain-prize-hidden:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.uil-curtain-prize:after {
  content: "";
}
.uil-curtain-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uil-curtain-modal-navbar {
  top: 60px;
  bottom: auto;
  z-index: 999;
}
.uil-curtain-open {
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #f1f2f4;
}
.uil-curtain-close {
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #f1f2f4;
}
.uil-curtain-panel-content {
  direction: ltr;
  height: 100%;
}
.uil-curtain-panel-left {
  text-align: left;
}
.uil-curtain-panel-left > .uil-curtain-open {
  display: none;
  direction: rtl;
}
.uil-curtain-panel-left > .uil-curtain-open * {
  direction: ltr;
}
.uil-curtain-panel-left > .uil-curtain-close {
  display: inherit;
  direction: rtl;
  height: 100%;
  float: right;
}
.uil-curtain-panel-left > .uil-curtain-close * {
  direction: ltr;
}
.uil-curtain-panel-left.active > .uil-curtain-open {
  display: inherit;
  height: 100%;
  float: right;
  direction: rtl;
}
.uil-curtain-panel-left.active > .uil-curtain-open * {
  direction: ltr;
}
.uil-curtain-panel-left.active > .uil-curtain-close {
  display: none;
  direction: rtl;
}
.uil-curtain-panel-left.active > .uil-curtain-close * {
  direction: ltr;
}
.uil-curtain-panel-right {
  text-align: left;
}
.uil-curtain-panel-right > .uil-curtain-open {
  display: none;
}
.uil-curtain-panel-right > .uil-curtain-close {
  display: inherit;
  height: 100%;
  float: left;
}
.uil-curtain-panel-right.active > .uil-curtain-open {
  display: inherit;
  height: 100%;
  float: left;
}
.uil-curtain-panel-right.active > .uil-curtain-close,
.uil-curtain-panel-right.active > .uil-curtain-open ~ .uil-curtain-close {
  display: none;
}
.uil-curtain-panel-top {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.uil-curtain-panel-top .uil-curtain-open {
  display: none;
}
.uil-curtain-panel-top .uil-curtain-close {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-top.active .uil-curtain-open {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-top.active .uil-curtain-close {
  display: none;
}
.uil-curtain-panel-bottom {
  text-align: left;
}
.uil-curtain-panel-bottom .uil-curtain-open {
  display: none;
}
.uil-curtain-panel-bottom .uil-curtain-close {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-bottom.active .uil-curtain-open {
  display: inherit;
  width: 100%;
  float: left;
}
.uil-curtain-panel-bottom.active .uil-curtain-close {
  display: none;
}
.uil-curtain-open-0.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-left .uil-curtain-open {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-left .uil-curtain-close {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-right .uil-curtain-open {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-right .uil-curtain-close {
  width: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-top .uil-curtain-open {
  height: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-top .uil-curtain-close {
  height: 0%;
  top: 100%;
}
.uil-curtain-open-0.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.uil-curtain-open-0.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 0%;
}
.uil-curtain-open-0.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 0%;
}
.uil-curtain-open-5.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-95%);
  transform: translateX(-95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-left .uil-curtain-open {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-left .uil-curtain-close {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-right {
  -webkit-transform: translateX(95%);
  transform: translateX(95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-right .uil-curtain-open {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-right .uil-curtain-close {
  width: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-95%);
  transform: translateY(-95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-top .uil-curtain-open {
  height: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-top .uil-curtain-close {
  height: 5%;
  top: 95%;
}
.uil-curtain-open-5.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(95%);
  transform: translateY(95%);
}
.uil-curtain-open-5.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 5%;
}
.uil-curtain-open-5.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 5%;
}
.uil-curtain-open-10.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-90%);
  transform: translateX(-90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-left .uil-curtain-open {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-left .uil-curtain-close {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-right {
  -webkit-transform: translateX(90%);
  transform: translateX(90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-right .uil-curtain-open {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-right .uil-curtain-close {
  width: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-90%);
  transform: translateY(-90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-top .uil-curtain-open {
  height: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-top .uil-curtain-close {
  height: 10%;
  top: 90%;
}
.uil-curtain-open-10.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(90%);
  transform: translateY(90%);
}
.uil-curtain-open-10.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 10%;
}
.uil-curtain-open-10.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 10%;
}
.uil-curtain-open-15.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-85%);
  transform: translateX(-85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-left .uil-curtain-open {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-left .uil-curtain-close {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-right {
  -webkit-transform: translateX(85%);
  transform: translateX(85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-right .uil-curtain-open {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-right .uil-curtain-close {
  width: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-85%);
  transform: translateY(-85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-top .uil-curtain-open {
  height: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-top .uil-curtain-close {
  height: 15%;
  top: 85%;
}
.uil-curtain-open-15.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(85%);
  transform: translateY(85%);
}
.uil-curtain-open-15.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 15%;
}
.uil-curtain-open-15.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 15%;
}
.uil-curtain-open-20.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-80%);
  transform: translateX(-80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-left .uil-curtain-open {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-left .uil-curtain-close {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-right {
  -webkit-transform: translateX(80%);
  transform: translateX(80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-right .uil-curtain-open {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-right .uil-curtain-close {
  width: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-80%);
  transform: translateY(-80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-top .uil-curtain-open {
  height: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-top .uil-curtain-close {
  height: 20%;
  top: 80%;
}
.uil-curtain-open-20.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(80%);
  transform: translateY(80%);
}
.uil-curtain-open-20.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 20%;
}
.uil-curtain-open-20.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 20%;
}
.uil-curtain-open-25.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-75%);
  transform: translateX(-75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-left .uil-curtain-open {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-left .uil-curtain-close {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-right {
  -webkit-transform: translateX(75%);
  transform: translateX(75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-right .uil-curtain-open {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-right .uil-curtain-close {
  width: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-75%);
  transform: translateY(-75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-top .uil-curtain-open {
  height: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-top .uil-curtain-close {
  height: 25%;
  top: 75%;
}
.uil-curtain-open-25.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(75%);
  transform: translateY(75%);
}
.uil-curtain-open-25.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 25%;
}
.uil-curtain-open-25.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 25%;
}
.uil-curtain-open-30.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-left .uil-curtain-open {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-left .uil-curtain-close {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-right {
  -webkit-transform: translateX(70%);
  transform: translateX(70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-right .uil-curtain-open {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-right .uil-curtain-close {
  width: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-top .uil-curtain-open {
  height: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-top .uil-curtain-close {
  height: 30%;
  top: 70%;
}
.uil-curtain-open-30.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(70%);
  transform: translateY(70%);
}
.uil-curtain-open-30.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 30%;
}
.uil-curtain-open-30.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 30%;
}
.uil-curtain-open-35.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-65%);
  transform: translateX(-65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-left .uil-curtain-open {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-left .uil-curtain-close {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-right {
  -webkit-transform: translateX(65%);
  transform: translateX(65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-right .uil-curtain-open {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-right .uil-curtain-close {
  width: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-65%);
  transform: translateY(-65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-top .uil-curtain-open {
  height: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-top .uil-curtain-close {
  height: 35%;
  top: 65%;
}
.uil-curtain-open-35.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(65%);
  transform: translateY(65%);
}
.uil-curtain-open-35.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 35%;
}
.uil-curtain-open-35.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 35%;
}
.uil-curtain-open-40.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-60%);
  transform: translateX(-60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-left .uil-curtain-open {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-left .uil-curtain-close {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-right {
  -webkit-transform: translateX(60%);
  transform: translateX(60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-right .uil-curtain-open {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-right .uil-curtain-close {
  width: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-top .uil-curtain-open {
  height: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-top .uil-curtain-close {
  height: 40%;
  top: 60%;
}
.uil-curtain-open-40.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(60%);
  transform: translateY(60%);
}
.uil-curtain-open-40.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 40%;
}
.uil-curtain-open-40.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 40%;
}
.uil-curtain-open-45.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-55%);
  transform: translateX(-55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-left .uil-curtain-open {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-left .uil-curtain-close {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-right {
  -webkit-transform: translateX(55%);
  transform: translateX(55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-right .uil-curtain-open {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-right .uil-curtain-close {
  width: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-top .uil-curtain-open {
  height: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-top .uil-curtain-close {
  height: 45%;
  top: 55%;
}
.uil-curtain-open-45.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(55%);
  transform: translateY(55%);
}
.uil-curtain-open-45.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 45%;
}
.uil-curtain-open-45.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 45%;
}
.uil-curtain-open-50.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-left .uil-curtain-open {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-left .uil-curtain-close {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-right {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-right .uil-curtain-open {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-right .uil-curtain-close {
  width: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-top .uil-curtain-open {
  height: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-top .uil-curtain-close {
  height: 50%;
  top: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.uil-curtain-open-50.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 50%;
}
.uil-curtain-open-50.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 50%;
}
.uil-curtain-open-55.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-45%);
  transform: translateX(-45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-left .uil-curtain-open {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-left .uil-curtain-close {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-right {
  -webkit-transform: translateX(45%);
  transform: translateX(45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-right .uil-curtain-open {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-right .uil-curtain-close {
  width: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-top .uil-curtain-open {
  height: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-top .uil-curtain-close {
  height: 55%;
  top: 45%;
}
.uil-curtain-open-55.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(45%);
  transform: translateY(45%);
}
.uil-curtain-open-55.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 55%;
}
.uil-curtain-open-55.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 55%;
}
.uil-curtain-open-60.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-40%);
  transform: translateX(-40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-left .uil-curtain-open {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-left .uil-curtain-close {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-right {
  -webkit-transform: translateX(40%);
  transform: translateX(40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-right .uil-curtain-open {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-right .uil-curtain-close {
  width: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-top .uil-curtain-open {
  height: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-top .uil-curtain-close {
  height: 60%;
  top: 40%;
}
.uil-curtain-open-60.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(40%);
  transform: translateY(40%);
}
.uil-curtain-open-60.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 60%;
}
.uil-curtain-open-60.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 60%;
}
.uil-curtain-open-65.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-35%);
  transform: translateX(-35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-left .uil-curtain-open {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-left .uil-curtain-close {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-right {
  -webkit-transform: translateX(35%);
  transform: translateX(35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-right .uil-curtain-open {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-right .uil-curtain-close {
  width: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-35%);
  transform: translateY(-35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-top .uil-curtain-open {
  height: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-top .uil-curtain-close {
  height: 65%;
  top: 35%;
}
.uil-curtain-open-65.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(35%);
  transform: translateY(35%);
}
.uil-curtain-open-65.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 65%;
}
.uil-curtain-open-65.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 65%;
}
.uil-curtain-open-70.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-left .uil-curtain-open {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-left .uil-curtain-close {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-right {
  -webkit-transform: translateX(30%);
  transform: translateX(30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-right .uil-curtain-open {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-right .uil-curtain-close {
  width: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-top .uil-curtain-open {
  height: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-top .uil-curtain-close {
  height: 70%;
  top: 30%;
}
.uil-curtain-open-70.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
}
.uil-curtain-open-70.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 70%;
}
.uil-curtain-open-70.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 70%;
}
.uil-curtain-open-75.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-left .uil-curtain-open {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-left .uil-curtain-close {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-right {
  -webkit-transform: translateX(25%);
  transform: translateX(25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-right .uil-curtain-open {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-right .uil-curtain-close {
  width: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-25%);
  transform: translateY(-25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-top .uil-curtain-open {
  height: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-top .uil-curtain-close {
  height: 75%;
  top: 25%;
}
.uil-curtain-open-75.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(25%);
  transform: translateY(25%);
}
.uil-curtain-open-75.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 75%;
}
.uil-curtain-open-75.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 75%;
}
.uil-curtain-open-80.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-20%);
  transform: translateX(-20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-left .uil-curtain-open {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-left .uil-curtain-close {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-right {
  -webkit-transform: translateX(20%);
  transform: translateX(20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-right .uil-curtain-open {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-right .uil-curtain-close {
  width: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-top .uil-curtain-open {
  height: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-top .uil-curtain-close {
  height: 80%;
  top: 20%;
}
.uil-curtain-open-80.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
}
.uil-curtain-open-80.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 80%;
}
.uil-curtain-open-80.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 80%;
}
.uil-curtain-open-85.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-15%);
  transform: translateX(-15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-left .uil-curtain-open {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-left .uil-curtain-close {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-right {
  -webkit-transform: translateX(15%);
  transform: translateX(15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-right .uil-curtain-open {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-right .uil-curtain-close {
  width: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-15%);
  transform: translateY(-15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-top .uil-curtain-open {
  height: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-top .uil-curtain-close {
  height: 85%;
  top: 15%;
}
.uil-curtain-open-85.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(15%);
  transform: translateY(15%);
}
.uil-curtain-open-85.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 85%;
}
.uil-curtain-open-85.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 85%;
}
.uil-curtain-open-90.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-left .uil-curtain-open {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-left .uil-curtain-close {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-right {
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-right .uil-curtain-open {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-right .uil-curtain-close {
  width: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-top .uil-curtain-open {
  height: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-top .uil-curtain-close {
  height: 90%;
  top: 10%;
}
.uil-curtain-open-90.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
}
.uil-curtain-open-90.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 90%;
}
.uil-curtain-open-90.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 90%;
}
.uil-curtain-open-95.active.uil-curtain-panel-left {
  -webkit-transform: translateX(-5%);
  transform: translateX(-5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-left .uil-curtain-open {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-left .uil-curtain-close {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-right {
  -webkit-transform: translateX(5%);
  transform: translateX(5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-right .uil-curtain-open {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-right .uil-curtain-close {
  width: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-top {
  -webkit-transform: translateY(-5%);
  transform: translateY(-5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-top .uil-curtain-open {
  height: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-top .uil-curtain-close {
  height: 95%;
  top: 5%;
}
.uil-curtain-open-95.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(5%);
  transform: translateY(5%);
}
.uil-curtain-open-95.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 95%;
}
.uil-curtain-open-95.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 95%;
}
.uil-curtain-open-100.active.uil-curtain-panel-left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-left .uil-curtain-open {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-left .uil-curtain-close {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-right .uil-curtain-open {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-right .uil-curtain-close {
  width: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-top {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-top .uil-curtain-open {
  height: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-top .uil-curtain-close {
  height: 100%;
  top: 0;
}
.uil-curtain-open-100.active.uil-curtain-panel-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-open-100.active.uil-curtain-panel-bottom .uil-curtain-open {
  height: 100%;
}
.uil-curtain-open-100.active.uil-curtain-panel-bottom .uil-curtain-close {
  height: 100%;
}
.uil-curtain-close-0.uil-curtain-panel-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.uil-curtain-close-0.uil-curtain-panel-left .uil-curtain-open {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-left .uil-curtain-close {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.uil-curtain-close-0.uil-curtain-panel-right .uil-curtain-open {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-right .uil-curtain-close {
  width: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.uil-curtain-close-0.uil-curtain-panel-top .uil-curtain-open {
  height: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-top .uil-curtain-close {
  height: 0%;
  top: 100%;
}
.uil-curtain-close-0.uil-curtain-panel-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.uil-curtain-close-0.uil-curtain-panel-bottom .uil-curtain-open {
  height: 0%;
}
.uil-curtain-close-0.uil-curtain-panel-bottom .uil-curtain-close {
  height: 0%;
}
.uil-curtain-close-10.uil-curtain-panel-left {
  -webkit-transform: translateX(-90%);
  transform: translateX(-90%);
}
.uil-curtain-close-10.uil-curtain-panel-left .uil-curtain-open {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-left .uil-curtain-close {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-right {
  -webkit-transform: translateX(90%);
  transform: translateX(90%);
}
.uil-curtain-close-10.uil-curtain-panel-right .uil-curtain-open {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-right .uil-curtain-close {
  width: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-top {
  -webkit-transform: translateY(-90%);
  transform: translateY(-90%);
}
.uil-curtain-close-10.uil-curtain-panel-top .uil-curtain-open {
  height: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-top .uil-curtain-close {
  height: 10%;
  top: 90%;
}
.uil-curtain-close-10.uil-curtain-panel-bottom {
  -webkit-transform: translateY(90%);
  transform: translateY(90%);
}
.uil-curtain-close-10.uil-curtain-panel-bottom .uil-curtain-open {
  height: 10%;
}
.uil-curtain-close-10.uil-curtain-panel-bottom .uil-curtain-close {
  height: 10%;
}
.uil-curtain-close-20.uil-curtain-panel-left {
  -webkit-transform: translateX(-80%);
  transform: translateX(-80%);
}
.uil-curtain-close-20.uil-curtain-panel-left .uil-curtain-open {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-left .uil-curtain-close {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-right {
  -webkit-transform: translateX(80%);
  transform: translateX(80%);
}
.uil-curtain-close-20.uil-curtain-panel-right .uil-curtain-open {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-right .uil-curtain-close {
  width: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-top {
  -webkit-transform: translateY(-80%);
  transform: translateY(-80%);
}
.uil-curtain-close-20.uil-curtain-panel-top .uil-curtain-open {
  height: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-top .uil-curtain-close {
  height: 20%;
  top: 80%;
}
.uil-curtain-close-20.uil-curtain-panel-bottom {
  -webkit-transform: translateY(80%);
  transform: translateY(80%);
}
.uil-curtain-close-20.uil-curtain-panel-bottom .uil-curtain-open {
  height: 20%;
}
.uil-curtain-close-20.uil-curtain-panel-bottom .uil-curtain-close {
  height: 20%;
}
.uil-curtain-close-30.uil-curtain-panel-left {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
}
.uil-curtain-close-30.uil-curtain-panel-left .uil-curtain-open {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-left .uil-curtain-close {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-right {
  -webkit-transform: translateX(70%);
  transform: translateX(70%);
}
.uil-curtain-close-30.uil-curtain-panel-right .uil-curtain-open {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-right .uil-curtain-close {
  width: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-top {
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
}
.uil-curtain-close-30.uil-curtain-panel-top .uil-curtain-open {
  height: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-top .uil-curtain-close {
  height: 30%;
  top: 70%;
}
.uil-curtain-close-30.uil-curtain-panel-bottom {
  -webkit-transform: translateY(70%);
  transform: translateY(70%);
}
.uil-curtain-close-30.uil-curtain-panel-bottom .uil-curtain-open {
  height: 30%;
}
.uil-curtain-close-30.uil-curtain-panel-bottom .uil-curtain-close {
  height: 30%;
}
.uil-curtain-close-40.uil-curtain-panel-left {
  -webkit-transform: translateX(-60%);
  transform: translateX(-60%);
}
.uil-curtain-close-40.uil-curtain-panel-left .uil-curtain-open {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-left .uil-curtain-close {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-right {
  -webkit-transform: translateX(60%);
  transform: translateX(60%);
}
.uil-curtain-close-40.uil-curtain-panel-right .uil-curtain-open {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-right .uil-curtain-close {
  width: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-top {
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
}
.uil-curtain-close-40.uil-curtain-panel-top .uil-curtain-open {
  height: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-top .uil-curtain-close {
  height: 40%;
  top: 60%;
}
.uil-curtain-close-40.uil-curtain-panel-bottom {
  -webkit-transform: translateY(60%);
  transform: translateY(60%);
}
.uil-curtain-close-40.uil-curtain-panel-bottom .uil-curtain-open {
  height: 40%;
}
.uil-curtain-close-40.uil-curtain-panel-bottom .uil-curtain-close {
  height: 40%;
}
.uil-curtain-close-50.uil-curtain-panel-left {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.uil-curtain-close-50.uil-curtain-panel-left .uil-curtain-open {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-left .uil-curtain-close {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-right {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}
.uil-curtain-close-50.uil-curtain-panel-right .uil-curtain-open {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-right .uil-curtain-close {
  width: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-top {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.uil-curtain-close-50.uil-curtain-panel-top .uil-curtain-open {
  height: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-top .uil-curtain-close {
  height: 50%;
  top: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-bottom {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.uil-curtain-close-50.uil-curtain-panel-bottom .uil-curtain-open {
  height: 50%;
}
.uil-curtain-close-50.uil-curtain-panel-bottom .uil-curtain-close {
  height: 50%;
}
.uil-curtain-close-60.uil-curtain-panel-left {
  -webkit-transform: translateX(-40%);
  transform: translateX(-40%);
}
.uil-curtain-close-60.uil-curtain-panel-left .uil-curtain-open {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-left .uil-curtain-close {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-right {
  -webkit-transform: translateX(40%);
  transform: translateX(40%);
}
.uil-curtain-close-60.uil-curtain-panel-right .uil-curtain-open {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-right .uil-curtain-close {
  width: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-top {
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%);
}
.uil-curtain-close-60.uil-curtain-panel-top .uil-curtain-open {
  height: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-top .uil-curtain-close {
  height: 60%;
  top: 40%;
}
.uil-curtain-close-60.uil-curtain-panel-bottom {
  -webkit-transform: translateY(40%);
  transform: translateY(40%);
}
.uil-curtain-close-60.uil-curtain-panel-bottom .uil-curtain-open {
  height: 60%;
}
.uil-curtain-close-60.uil-curtain-panel-bottom .uil-curtain-close {
  height: 60%;
}
.uil-curtain-close-70.uil-curtain-panel-left {
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%);
}
.uil-curtain-close-70.uil-curtain-panel-left .uil-curtain-open {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-left .uil-curtain-close {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-right {
  -webkit-transform: translateX(30%);
  transform: translateX(30%);
}
.uil-curtain-close-70.uil-curtain-panel-right .uil-curtain-open {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-right .uil-curtain-close {
  width: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-top {
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
}
.uil-curtain-close-70.uil-curtain-panel-top .uil-curtain-open {
  height: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-top .uil-curtain-close {
  height: 70%;
  top: 30%;
}
.uil-curtain-close-70.uil-curtain-panel-bottom {
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
}
.uil-curtain-close-70.uil-curtain-panel-bottom .uil-curtain-open {
  height: 70%;
}
.uil-curtain-close-70.uil-curtain-panel-bottom .uil-curtain-close {
  height: 70%;
}
.uil-curtain-close-80.uil-curtain-panel-left {
  -webkit-transform: translateX(-20%);
  transform: translateX(-20%);
}
.uil-curtain-close-80.uil-curtain-panel-left .uil-curtain-open {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-left .uil-curtain-close {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-right {
  -webkit-transform: translateX(20%);
  transform: translateX(20%);
}
.uil-curtain-close-80.uil-curtain-panel-right .uil-curtain-open {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-right .uil-curtain-close {
  width: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-top {
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
}
.uil-curtain-close-80.uil-curtain-panel-top .uil-curtain-open {
  height: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-top .uil-curtain-close {
  height: 80%;
  top: 20%;
}
.uil-curtain-close-80.uil-curtain-panel-bottom {
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
}
.uil-curtain-close-80.uil-curtain-panel-bottom .uil-curtain-open {
  height: 80%;
}
.uil-curtain-close-80.uil-curtain-panel-bottom .uil-curtain-close {
  height: 80%;
}
.uil-curtain-close-90.uil-curtain-panel-left {
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
}
.uil-curtain-close-90.uil-curtain-panel-left .uil-curtain-open {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-left .uil-curtain-close {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-right {
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
}
.uil-curtain-close-90.uil-curtain-panel-right .uil-curtain-open {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-right .uil-curtain-close {
  width: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-top {
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}
.uil-curtain-close-90.uil-curtain-panel-top .uil-curtain-open {
  height: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-top .uil-curtain-close {
  height: 90%;
  top: 10%;
}
.uil-curtain-close-90.uil-curtain-panel-bottom {
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
}
.uil-curtain-close-90.uil-curtain-panel-bottom .uil-curtain-open {
  height: 90%;
}
.uil-curtain-close-90.uil-curtain-panel-bottom .uil-curtain-close {
  height: 90%;
}
.uil-curtain-close-100.uil-curtain-panel-left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-close-100.uil-curtain-panel-left .uil-curtain-open {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-left .uil-curtain-close {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.uil-curtain-close-100.uil-curtain-panel-right .uil-curtain-open {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-right .uil-curtain-close {
  width: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-top {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-close-100.uil-curtain-panel-top .uil-curtain-open {
  height: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-top .uil-curtain-close {
  height: 100%;
  top: 0;
}
.uil-curtain-close-100.uil-curtain-panel-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uil-curtain-close-100.uil-curtain-panel-bottom .uil-curtain-open {
  height: 100%;
}
.uil-curtain-close-100.uil-curtain-panel-bottom .uil-curtain-close {
  height: 100%;
}
.uil-curtain-toggle-button-default {
  z-index: 2;
  position: absolute;
  top: 10px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #f1f2f4;
}
.uil-curtain-text-button-default {
  height: 30px;
  width: auto;
}
.uil-curtain-panel-left .uil-curtain-toggle-button-default {
  right: calc(30px * (-1));
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 0 3px 3px 0;
  border-left: none;
}
.uil-curtain-panel-right .uil-curtain-toggle-button-default {
  left: calc(30px * (-1));
  -webkit-box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 3px 0 0 3px;
  border-right: none;
}
.uil-curtain-panel-top .uil-curtain-toggle-button-default {
  left: calc(10px);
  top: auto;
  bottom: calc(30px * (-1));
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 0 0 3px 3px;
  border-top: none;
}
.uil-curtain-panel-bottom .uil-curtain-toggle-button-default {
  left: calc(10px);
  top: calc(30px * (-1));
  -webkit-box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 3px 3px 0 0;
  border-bottom: none;
}
.uil-side-close-toolicon {
  right: 200px !important;
  z-index: 1000;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  width: 36px;
  height: 64px;
  top: 0;
}
.uil-curtain-gradient-toggle-button {
  position: absolute;
  left: -2px;
  top: 0;
  z-index: 100;
  height: 120px;
  width: 120px;
  clip-path: polygon(100% 0, 0 0, 0 100%);
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .uil-curtain-gradient-toggle-button {
    background: -ms-linear-gradient(
      -48deg,
      #460073 0,
      #460073 50%,
      transparent 51%,
      transparent 100%
    );
    background-color: transparent !important;
  }
}
@supports (-ms-ime-align: auto) {
  .uil-curtain-gradient-toggle-button {
    background-image: -webkit-gradient(
      linear,
      left top,
      right bottom,
      from(#460073),
      color-stop(50%, #460073),
      color-stop(51%, transparent),
      to(transparent)
    );
    background-image: linear-gradient(
      to bottom right,
      #460073 0,
      #460073 50%,
      transparent 51%,
      transparent 100%
    );
    background-color: transparent !important;
  }
}
