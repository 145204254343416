.uil-shadow {
  /* width: 350px;
  height: 200px; */
  background: #ffffff;
}

.uil-shadow-dark {
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.uil-shadow-light {
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px 0px #00000040;
}

.uil-shadow-lightg {
  -webkit-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.08);
}

.uil-shadow-spreaded {
  -webkit-box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
}

.uil-shadow-tile {
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.uil-shadow-disabled {
  cursor: not-allowed;
  -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.uil-custom-height {
  max-height: 70px;
}

.uil-custom-width {
  max-height: 80px;
}

.uil-disable {
  max-height: 35px;
}
/*# sourceMappingURL=uil_shadow.module.css.map */
