.uil-sidenavbar {
  z-index: 1000;
  position: absolute;
}
.div-tabbar{
  position: fixed;
  z-index: 100;
  width:100%;
  left:80px;
}

.uil-banner-section{
  position: relative;
  top:50px;

}
.uil-primary-btn-custom {
  background-color: inherit !important;
}
.uil-banner-styles {
  width: 100rem;
  position: relative;
  background-position-y: center;
  left: 80px;
  height: 320px;
}
.body-class {
  padding-top: 400px;
  padding-left: 160px;
  display: flex;
  justify-content: space-around;
  padding-right: 20px;
  gap: 80px;
}
.uil-card-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-title-class {
  font-family: Graphik;
  font-size: var(--font-size-heading-md);
  font-weight: var(--font-weight-sm);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
.content-class {
  font-family: Graphik;
  font-size: 16px;
  font-weight: var(--font-weight-sm);
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 24px;
}

 .uil-tabbar-default-without-border:hover{
    box-shadow: none !important;
    -webkit-box-shadow:none
}

.divTabBarHorizontal{
    left:40px !important;
}