.contentBlock{
    display: block;
  }
  /* html,body { height:100%; } */

.uil-sidenavbar{
  /* //height: 100%; */
}
.uil-primary-btn-custom{
  background-color: inherit !important;
}