.uil-alert-maincontainer {
  width: 560px;
  padding: 0;
  border-radius: 0 4px 4px 0;
}
.uil-toaster-cross-icon {
  margin: auto;
  padding-right: 10px;
  cursor: pointer;
}
.uil-toaster-cross-icon:hover {
  color: var(--color-font-primaryLight);
  font-weight: 900;
}
.uil-alert-body {
  padding: 0;
}
.uil-alert-datasection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
}
.uil-alert-datasection.uil-alert-success {
  border-left: 4px solid var(--green);
}
.uil-alert-datasection.uil-alert-alert {
  border-left: 4px solid var(--orange);
}
.uil-alert-datasection.uil-alert-default {
  border-left: 4px solid var(--grey-medium);
  border-radius: 0 4px 4px 0;
  background: var(--transparent-grey-medium-10, rgba(116, 116, 116, 0.1));
}
.uil-alert-datasection.uil-alert-failure {
  border-left: 4px solid var(--color-border-error);
}
.uil-alert-datasection.uil-alert-generalinfo {
  border-left: 4px solid var(--blue);
}
.uil-alert-notificationIcon {
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-size: 12px;
  margin-top: 9px;
}
.uil-alert-cross-icon {
  margin-top: 11px;
}
.uil-alert-notificationIcon.uil-dl-icon-default:before {
  content: "\ea23";
  color: var(--grey-dark);
}
.uil-alert-notificationIcon.uil-dl-icon-informational:before {
  content: "\e994";
  color: var(--blue);
  position: relative;
}
.uil-alert-notificationIcon.uil-dl-icon-danger:before {
  content: "\e95f";
  color: var(--red);
}
.uil-alert-notificationIcon.uil-dl-icon-warning:before {
  content: "\e994";
  color: #fe6100;
}
.uil-alert-notificationIcon.uil-dl-icon-success:before {
  content: "\e979";
  color: var(--green);
}
.uil-alert-textSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-top: 5px;
}
.uil-alert-textSection .uil-alert-title {
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  line-height: 16.8px;
  letter-spacing: 0;
  margin-top: 4px;
  color: var(--color-font-dark);
}
.uil-alert-textSection .uil-alert-subtitle {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 19.6px;
  letter-spacing: 0;
  color: var(--color-font-dark);
}
.uil-alert-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: start;
  align-self: start;
  font-size: 16px;
  cursor: pointer;
}
.uil-alert-action .uil-toaster-cross-icon {
  cursor: pointer;
}
.uil-alert-action .uil-toaster-cross-icon:hover {
  color: var(--color-font-primaryLight);
  font-weight: 900;
}
.uil-alert-success {
  border-left: 4px solid var(--green);
  background: var(--green, rgba(18, 137, 38, 0.1));
}
.uil-alert-danger {
  border-left: 4px solid var(--red);
  background: var(--red, rgba(230, 25, 45, 0.1));
}
.uil-alert-default {
  border-left: 4px solid var(--color-font-greyDark);
  border-radius: 0 4px 4px 0;
}
.uil-alert-warning {
  border-left: 4px solid #fe6100;
}
.uil-alert-informational {
  border-left: 4px solid var(--blue);
}
.success {
  background: var(--green-10, rgba(18, 137, 38, 0.1));
}
.danger {
  background: var(--red-10, rgba(230, 25, 45, 0.1));
}
.default {
  background: var(--color-font-greyMedium-10, rgba(116, 116, 116, 0.1));
}
.warning {
  background: var(--orange2-10, rgba(254, 97, 0, 0.1));
}
.informational {
  background: var(--blue-10, rgba(0, 65, 240, 0.1));
}
.notification-container {
  font-size: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  background-color: var(--color-bg-light);
  z-index: 999999;
  top: 140px;
  left: 240px;
  border-radius: 0 4px 4px 0;
}
.toast {
  height: 100%;
  width: 560px;
  padding: 0;
  color: var(--color-bg-light);
  padding: 20px 15px 10px 10px;
}
.uil-dl-icon-close-default:before {
  content: "\e98e";
  color: var(--grey-dark);
}
.uil-dl-icon-close-informational:before {
  content: "\e98e";
  color: var(--blue);
}
.uil-dl-icon-close-danger:before {
  content: "\e98e";
  color: var(--red);
}
.uil-dl-icon-close-warning:before {
  content: "\e98e";
  color: #fe6100;
}
.uil-dl-icon-close-success:before {
  content: "\e98e";
  color: var(--green);
}
.uil-alert-button {
  color: var(--color-font-dark);
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.uil-alert-button span {
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  line-height: 140%;
}
