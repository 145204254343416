.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--color-bg-light);
  padding: 10px;
  height: 55px;
  border-bottom: 3px solid var(--color-border-secondaryDark);
}

.header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  resize: none;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.header-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  resize: none;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-right: 48px;
}

.tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tool-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  position: static;
}

.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  list-style: none;
  gap: 24px;
  margin: 0;
  padding: 0;
}
.tabs ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.tabs li:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-border-primaryLight);
  text-decoration-color: var(--color-border-primaryLight);
}
.tabs li a {
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  color: var(--color-font-greyDark);
  text-decoration: none;
  line-height: 24px;
}

.tabs.active {
  color: var(--color-font-dark) !important;
}

.tabs.default {
  height: inherit;
}

.tabs.baseline {
  position: relative;
}

.separator {
  padding-left: 24px;
  padding-right: 24px;
  color: var(--color-font-greyMedium);
  font-size: var(--font-size-title-md);
}

.uil-horizontal-nav-title {
  color: var(--color-font-dark);
  font-family: var(--font-family);
  font-weight: var(--font-weight-lg);
  font-size: var(--font-size-body-md);
  line-height: 19.2px;
}
.uil-horizontal-nav-title:hover {
  color: var(--color-font-dark);
}

.header.baseline.small {
  height: 56px;
  padding: 0px 24px 0px 24px;
  width: 100%;
  z-index: 1000;
  position: relative;
}

.header.baseline {
  height: 56px;
  padding: 0px 24px 0px 24px;
}

.header.default.small {
  height: 72px;
  padding: 0px 24px 0px 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  width: 100%;
  z-index: 1000;
  position: relative;
}

.header.default {
  height: 72px;
  padding: 0px 24px 0px 24px;
}

.header.baseline.medium {
  padding: 0px 36px 0px 36px;
}

.header.default.medium {
  padding: 0px 36px 0px 36px;
}

.header.baseline.large {
  padding: 0px 80px 0px 80px;
}

.header.default.large {
  padding: 0px 80px 0px 80px;
}

.header.baseline.xlarge {
  padding: 0px 96px 0px 96px;
}

.header.default.xlarge {
  padding: 0px 96px 0px 96px;
}

.nav {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex: 1 1;
  flex: 1 1;
}

.nav.default {
  height: 72px;
}

.nav.baseline {
  height: 56px;
}

.uil-horizontal-nav-navigationarea {
  white-space: nowrap;
  height: inherit;
  padding-top: 0;
  padding-right: 0;
  position: relative;
  z-index: 1000;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-right: auto;
}

.uil-horizontal-nav-navbar {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  max-width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.uil-horizontal-nav-navbar.default {
  height: inherit;
}

.uil-horizontal-nav-navbar.baseline {
  height: inherit;
}

.uil-horizontal-nav-mainmenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
}
.uil-horizontal-nav-mainmenu li {
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 12px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  position: relative;
  font-family: var(--font-family);
  bottom: 12px;
}

.uil-navbar-tabs {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  border: none;
}
.uil-navbar-tabs > li > a {
  padding: 18px 24px 18px 24px;
  margin-right: 0;
  background-color: transparent;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 24px;
  height: inherit;
  color: var(--color-font-greyDark);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-bottom: 4px solid transparent;
}
.uil-navbar-tabs > li > a:focus {
  padding: 18px 24px 18px 24px;
  margin-right: 0;
  background-color: transparent;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 24px;
  height: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-bottom: 4px solid transparent;
}
.uil-navbar-tabs > li > a:hover {
  background-color: transparent;
  border-bottom: 4px solid var(--color-border-primaryLight);
  color: var(--color-font-dark);
}

.uil-horizontal-nav-item {
  height: 100%;
  vertical-align: middle;
  text-align: center;
  position: relative;
  margin-right: 20px;
}
.uil-horizontal-nav-item ul {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--color-bg-light);
  padding: 10px;
  list-style: none;
  margin: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.uil-horizontal-nav-item ul li {
  margin-bottom: 5px;
}
.uil-horizontal-nav-item ul li a {
  color: var(--color-font-greyDark);
  text-decoration: none;
  padding: 5px;
  display: block;
}
.uil-horizontal-nav-item ul > li > a:hover {
  color: var(--color-font-greyDark);
  -webkit-box-shadow: inset 0 -4px 0 var(--color-border-primaryLight);
  box-shadow: inset 0 -4px 0 var(--color-border-primaryLight);
}

ul {
  margin-top: 0;
}

.uil-dl-icon-Burger:before {
  content: "\e98d";
}

.uil-borderless {
  border-bottom: unset;
}

@media only screen and (max-width: 680px) {
  .header {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}
/*# sourceMappingURL=uil_headerbar.module.css.map */
