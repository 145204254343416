/* Copyright (C) 2020 Accenture. All rights reserved. */
/*$white: #FFFFFF;
$gray-01: #F8F9FA;
$gray-02: #CCD1D7;
$gray-03: #ABB0B6;
$gray-04: #575961;
$gray-05: #393A3E;
$gray-06: #2F2F2F;
$gray-07: #222222;*/
.divTabBarHorizontal {
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: none;
  padding: 0;
  min-height: 48px;
  width: 100%;
  left: 40px;
  background: #f0f0f0;
  position: relative;
}

.divTabBarVertical {
  border: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  width: 60px;
  min-height: 48px;
  word-break: break-all;
}

.divTabBarVertical > .uil-tabbar-default {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--color-bg-light);
}

.divTabBarHorizontal > .uil-tabbar-mandatory {
  background: var(--color-bg-light);
}

.divTabBarVertical > .uil-tabbar-mandatory {
  background: var(--color-bg-light);
}

.divTabBarVertical > .uil-tabbar-textonly:hover[disabled] {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.divTabBarVertical > .uil-tabbar-textonly {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 5px;
}

.uil-tabbar-mandatory {
  border: none;
  background: none;
  min-width: 122px;
  min-height: 48px;
  font-size: 14px;
}

.uil-tabbar-mandatory:hover {
  min-width: 122px;
  min-height: 48px;
  font-size: 14px;
  background: var(--color-bg-secondaryLight);
}

.uil-tabbar-mandatory.selected,
.uil-tabbar-mandatory.children.selected {
  min-width: 122px;
  min-height: 48px;
  font-size: 14px;
  background: var(--color-bg-secondaryLight);
}

.uil-tabbar-mandatorymark {
  color: var(--color-font-red);
}

.uil-tabbar-mandatorymark > .uil-tabbar-mandatorymark {
  width: 130px;
}

.uil-tabbar-mandatory.disabled,
.uil-tabbar-mandatory:hover[disabled] {
  color: var(--color-font-disabled);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  border-bottom: none;
  border-left: none;
  background: none;
}

.uil-tabbar-textonly.uil-tabbar-mandatorymark.disabled,
.uil-tabbar-mandatorymark[disabled],
.uil-tabbar-mandatorymarkfieldset[disabled] {
  color: var(--color-font-disabled);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  border-bottom: none;
  border-left: none;
}

.uil-tabbar-textonly {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 122px;
  min-height: 48px;
  background: var(--color-bg-light);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.uil-tabbar-textonly.disabled,
.uil-tabbar-textonly[disabled],
.uil-tabbar-textonly.disabled .disabled.uil-tabbar-option,
.uil-tabbar-textonly fieldset[disabled] {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  border-bottom: none;
  border-left: none;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
}

.uil-tabbar-textonly:hover {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  color: var(--color-font-primaryLight);
  line-height: 16px;
  min-width: 122px;
  min-height: 48px;
  -webkit-box-shadow: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-positive: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-boxshadow-primaryLight);
  text-decoration-color: var(--color-boxshadow-primaryLight);
}

.uil-tabbar-textonly.selected > .uil-tabbar-option {
  color: var(--color-font-primaryLight);
}

.uil-tabbar-textonly.selected {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  min-width: 122px;
  min-height: 48px;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-boxshadow-primaryLight);
  text-decoration-color: var(--color-boxshadow-primaryLight);
  color: var(--color-font-primaryLight);
}

.uil-tabbar-default-without-border {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding:0px 24px 0px 24px;
  min-width: 122px;
  min-height: 48px;
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: var(--color-bg-light);
  background: var(--color-bg-disabled);
}

.uil-tabbar-default-without-border:hover {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 16px;
  min-width: 122px;
  min-height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-bottom: 4px solid var(--color-border-primaryLight);
  color: var(--color-font-primaryLight);
  background: var(--color-bg-disabled);
  border-left: none;
  border-right: none;
}

.uil-tabbar-default-without-border.selected {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  min-width: 122px;
  min-height: 48px;
  color: var(--color-font-primaryLight);
  background: var(--color-bg-disabled);
  border-left: none;
  border-right: none;
  border-bottom: 4px solid var(--color-border-primaryLight);
}

.divTabBarVertical > .uil-tabbar-default-without-border {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--color-bg-light);
}

.divTabBarVertical > .uil-tabbar-default-without-border.selected {
  border-left: 2px solid var(--color-border-primaryLight);
  border-bottom: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.divTabBarVertical > .uil-tabbar-default-without-border {
  background: var(--color-bg-disabled);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border: none;
  min-width: 122px;
  border-left: 2px solid var(--color-border-default);
  border-left-color: var(--color-border-default);
}

.divTabBarVertical > .uil-tabbar-default-without-border:hover.disabled {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border: none;
  border-left: 2px solid var(--color-border-default);
  border-left-color: var(--color-border-default);
}

.divTabBarVertical > .uil-tabbar-default-without-border:hover {
  border-left: 2px solid var(--color-border-primaryLight);
  border-bottom: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.uil-tabbar-default {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* padding: 14px 24px; */
  min-width: 122px;
  min-height: 48px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: var(--color-border-default);
  background: var(--color-bg-disabled);
}

.uil-tabbar-default:hover {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 16px;
  min-width: 122px;
  min-height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* color: $branding-color-01; */
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-bottom: 2px solid var(--color-border-primaryLight);
  background: var(--color-bg-light);
  border-left: none;
  border-right: none;
  -webkit-box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
}

.uil-tabbar-default.selected,
.uil-tabbar-default.children.selected {
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  min-width: 122px;
  min-height: 48px;
  background: var(--color-bg-light);
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--color-border-primaryLight);
  -webkit-box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
}

.divTabBarHorizontal.uil-tabbar-textonly:hover[disabled],
.uil-tabbar-textonly[disabled],
.uil-tabbar-textonly fieldset[disabled] {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  border-bottom: none;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
}

.divTabBarVertical.uil-tabbar-textonly:hover[disabled],
.uil-tabbar-textonly[disabled],
.uil-tabbar-textonly fieldset[disabled] .uil-tabbar-option:hover[disabled] {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  border-bottom: none;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
}

.divTabBarVertical > .uil-tabbar-textonly:hover.disabled {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
}

.divTabBarVertical > .uil-tabbar-textonly.disabled {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
}

.divTabBarVertical > .uil-tabbar-default:hover.disabled {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border: none;
  border-left: 2px solid var(--color-border-default);
  border-left-color: var(--color-border-default);
}

.divTabBarVertical > .uil-tabbar-default {
  background: var(--color-bg-disabled);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border: none;
  min-width: 122px;
  border-left: 2px solid var(--color-border-default);
  border-left-color: var(--color-border-default);
  border-bottom: 1px solid var(--color-border-default);
}

.divTabBarVertical > .uil-tabbar-default.disabled {
  color: var(--color-font-disabled);
  background: var(--color-bg-light);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  -webkit-text-decoration-color: none;
  text-decoration-color: none;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border: none;
  border-left: 2px solid var(--color-border-default);
  border-left-color: var(--color-border-default);
}

.uil-tabbar-default.disabled,
.uil-tabbar-default:hover[disabled],
.uil-tabbar-default fieldset[disabled] {
  color: var(--color-font-disabled);
  background: rgba(204, 204, 204, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: var(--color-border-default);
}

.divTabBarVertical > .uil-tabbar-textonly:hover {
  border-bottom: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: underline;
  text-underline-offset: 8px;
  -webkit-text-decoration-color: var(--color-boxshadow-primaryLight);
  text-decoration-color: var(--color-boxshadow-primaryLight);
  padding-left: 5px;
}

.divTabBarVertical > .uil-tabbar-textonly.selected,
.divTabBarVertical > .uil-tabbar-textonly.children.selected {
  border-bottom: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: underline;
  text-underline-offset: 8px;
  -webkit-text-decoration-color: var(--color-boxshadow-primaryLight);
  text-decoration-color: var(--color-boxshadow-primaryLight);
  padding-left: 5px;
  color: var(--color-font-primaryLight);
}

.divTabBarVertical > .uil-tabbar-default:hover {
  border-left: 2px solid var(--color-border-primaryLight);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  line-height: 16px;
  min-width: 122px;
  min-height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -ms-flex-positive: 0;
  flex-grow: 0;
  background: var(--color-bg-light);
  border-right: none;
  -webkit-box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
}

.divTabBarVertical > .uil-tabbar-default.selected {
  border-left: 2px solid var(--color-border-primaryLight);
  font-family: var(--font-family);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-sm);
  -webkit-box-align: center;
  -ms-flex-align: center;
  min-width: 122px;
  min-height: 48px;
  background: var(--color-bg-light);
  border-right: none;
  -webkit-box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.1);
}

.uil-tabbar-withcounter {
  color: var(--color-font-greyMedium2);
}

.uil-tabbar-withcounter[disabled] {
  color: var(--color-font-disabled);
}

.uil-tabbar-mandatory.disabled {
  background: var(--color-bg-light);
}

.uil-tabbar-option.uil-children-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
}

.divTabBarVertical .uil-tabbar-textonly.children:hover,
.divTabBarVertical > .uil-tabbar-textonly.children.selected {
  text-underline-offset: 4px;
}

.divTabBarVertical .uil-tabbar-textonly.children,
.divTabBarVertical .uil-tabbar-default.children,
.divTabBarVertical .uil-tabbar-mandatory.children {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: center;
      align-content: center;
}

.divTabBarVertical > .uil-tabbar-textonly.children.selected {
  text-decoration-thickness: 2px;
}

.divTabBarHorizontal > .uil-tabbar-textonly.children.selected {
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  border-bottom: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-boxshadow-primaryLight);
  text-decoration-color: var(--color-boxshadow-primaryLight);
  padding-left: 5px;
  color: var(--color-font-primaryLight);
}

.divTabBarVertical > .uil-tabbar-default.children.selected {
  border-left: 2px solid var(--color-border-primaryLight);
  border-bottom: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/*# sourceMappingURL=uil_tabbar.module.css.map */