.uil-banner {
  background: var(--color-bg-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  background-size: cover;
  font-family: "Graphik";
  font-style: normal;
}
.uil-banner-large {
  height: 720px;
}
.uil-banner-medium {
  height: 480px;
  width: 100%;
}
.uil-banner-small {
  height: 320px;
}

.uil-banner-title {
  width: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-heading-xl);
  line-height: 72px;
  cursor: text;
}

.uil-banner-content {
  width: 870px;
  font-size: var(--font-size-title-sm);
  font-weight: var(--font-weight-sm);
  line-height: 21.6px;
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: initial;
}

.uil-banner-subheading {
  width: inherit;
  height: inherit;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-md);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  caret-color: transparent;
  margin-bottom: 24px;
}

.uil-banner-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 50px;
  width: max-content;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -ms-flex-item-align: center;
  align-self: center;
  gap: 16px;
  left: 0px;
  position: absolute;
}

.uil-dl-icon-Circle-chevron-right:after {
  content: "\ea45";
  bottom: -2px;
  position: relative;
  cursor: pointer;
}

.uil-banner-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: var(--color-font-dark);
}

.uil-banner-hero {
  color: var(--color-font-light);
  left: 80px;
  position: relative;
  padding-bottom: 50px;
}

.uil-dl-icon-Arrow-left:before {
  content: "\e9ee";
  padding-left: 9px;
  padding-right: 24px;
  position: relative;
  top: 10px;
  cursor: pointer;
  font-size: var(--font-size-heading-md);
}

.uil-banner-btn-border {
  border: 1px solid white;
}

.uil-banner.small {
  padding: 0px 0px 0px 24px;
}

.uil-banner.medium {
  padding: 0px 0px 0px 32px;
}

.uil-banner.large {
  padding: 0px 0px 0px 80px;
}

.uil-banner.xlarge {
  padding: 0px 0px 0px 96px;
}
/*# sourceMappingURL=uil_banner.module.css.map */
