.uil_sidenavbarlight {
  width: 80px;
  height: 1024px;
  background: var(--color-bg-light);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.uil_sidenavbardark {
  width: 80px;
  height: 1024px;
  background: var(--color-bg-dark);
}

.uil_sidenavbar_wrapper {
  padding: 80px 0px 32px;
}
.uil_sidenavbar_wrapper > ul {
  padding-left: 0px;
}

.uil-nav-expanded {
  width: 249px;
}

li::before {
  font-family: var(--font-family);
  font-size: var(--font-size-title-md);
  font-weight: var(--font-weight-sm);
}
li::before.disabled::before {
  color: var(--color-font-disabled);
}

.uil-sidenav-items {
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  overflow: hidden;
  line-height: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding-left:20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--color-font-dark);
  cursor: pointer;
}
.uil-sidenav-items.disabled {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-sidenav-items:not(.disabled):hover {
  color: var(--color-font-primaryLight);
  background: var(--color-bg-disabledLight);
}
.uil-sidenav-items:not(.disabled).active {
  color: var(--color-font-primaryLight);
  border-left: 4px solid var(--color-border-primaryLight);
  padding-left: 20px;
  background: inherit;
  background: var(--color-bg-disabledLight);
}

.uil-sidenav-bottom-items {
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  overflow: hidden;
  line-height: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding-left: 20px;
  list-style-type: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--color-font-dark);
  cursor: pointer;
}
.uil-sidenav-bottom-items.disabled {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-sidenav-bottom-items:not(.disabled):hover {
  color: var(--color-font-primaryLight);
  background: var(--color-bg-disabledLight);
}
.uil-sidenav-bottom-items:not(.disabled).active {
  color: var(--color-font-primaryLight);
  border-left: 4px solid var(--color-border-primaryLight);
  padding-left: 20px;
  background: inherit;
}

.uil-sidenav-items-dark {
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  color: var(--color-font-light);
  overflow: hidden;
  line-height: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding-left: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.uil-sidenav-items-dark.disabled {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-sidenav-items-dark:not(.disabled):hover {
  background: rgba(204, 204, 204, 0.2);
}
.uil-sidenav-items-dark:not(.disabled).active {
  background: rgba(204, 204, 204, 0.2);
  border-left: 4px solid var(--color-border-primaryLight);
  padding-left: 20px;
}

.uil-sidenav-bottom-items-dark {
  font-family: var(--font-family);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-sm);
  font-style: normal;
  color: var(--color-font-light);
  overflow: hidden;
  line-height: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding-left: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.uil-sidenav-bottom-items-dark.disabled {
  cursor: not-allowed;
  color: var(--color-font-disabled);
}
.uil-sidenav-bottom-items-dark:not(.disabled):hover {
  background: rgba(204, 204, 204, 0.2);
}
.uil-sidenav-bottom-items-dark:not(.disabled).active padding-left{
  background: rgba(204, 204, 204, 0.2);
  border-left: 4px solid var(--color-border-primaryLight);
  padding-left: 20px;
}

.uil-sideNav-btn {
  position: relative;
  right: 18px;
}
