.uil-badge-status .uil-badge-primary,
.uil-badge-status .uil-badge-secondary,
.uil-badge-status .uil-badge-blue,
.uil-badge-status .uil-badge-green,
.uil-badge-status .uil-badge-orange,
.uil-badge-status .uil-badge-red,
.uil-badge-status .uil-badge-grey {
  position: absolute;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: var(--font-size-caption-xxs);
  height: var(--font-size-caption-xxs);
  border-radius: 50%;
}

.uil-badge-status .uil-badge-primary {
  background: var(--primary-light);
  border: 1px solid var(--white);
}

.uil-badge-status .uil-badge-secondary {
  border: 1px solid var(--primary-light);
  background: var(--white);
}

.uil-badge-status .uil-badge-blue {
  background: var(--blue);
  border: 1px solid var(--white);
}

.uil-badge-status .uil-badge-green {
  background: var(--green);
  border: 1px solid var(--white);
}

.uil-badge-status .uil-badge-orange {
  background: var(--orange);
  border: 1px solid var(--white);
}

.uil-badge-status .uil-badge-red {
  background: var(--red);
  border: 1px solid var(--white);
}

.uil-badge-status .uil-badge-grey {
  background: var(--grey-medium);
  border: 1px solid var(--white);
}

.uil-badge-singleDigit .uil-badge-primary,
.uil-badge-singleDigit .uil-badge-secondary,
.uil-badge-singleDigit .uil-badge-blue,
.uil-badge-singleDigit .uil-badge-green,
.uil-badge-singleDigit .uil-badge-orange,
.uil-badge-singleDigit .uil-badge-red,
.uil-badge-singleDigit .uil-badge-grey {
  font-size: var(--font-size-caption-xxs);
  color: var(--color-font-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  width: var(--font-size-body-md);
  height: var(--font-size-body-md);
  border-radius: 16px;
  padding: 1px 0px 0px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.uil-badge-singleDigit .uil-badge-primary {
  background: var(--primary-light);
  border: 1px solid var(--color-border-light);
}

.uil-badge-singleDigit .uil-badge-secondary {
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
}

.uil-badge-singleDigit .uil-badge-blue {
  background: var(--blue);
  border: 1px solid var(--color-border-light);
}

.uil-badge-singleDigit .uil-badge-green {
  background: var(--green);
  border: 1px solid var(--color-border-light);
}

.uil-badge-singleDigit .uil-badge-orange {
  background: var(--orange);
  border: 1px solid var(--color-border-light);
}

.uil-badge-singleDigit .uil-badge-red {
  background: var(--red);
  border: 1px solid var(--color-border-light);
}

.uil-badge-singleDigit .uil-badge-grey {
  background: var(--grey-medium);
  border: 1px solid var(--color-border-light);
}

.uil-badge-multipleDigit .uil-badge-primary,
.uil-badge-multipleDigit .uil-badge-secondary,
.uil-badge-multipleDigit .uil-badge-blue,
.uil-badge-multipleDigit .uil-badge-green,
.uil-badge-multipleDigit .uil-badge-orange,
.uil-badge-multipleDigit .uil-badge-red,
.uil-badge-multipleDigit .uil-badge-grey {
  font-family: "Graphik";
  font-size: var(--font-size-caption-xxs);
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 4px;
  position: absolute;
  width: auto;
  height: 16px;
  border-radius: 8px;
}

.uil-badge-multipleDigit .uil-badge-primary {
  background: var(--primary-light);
  color: var(--color-font-light);
}

.uil-badge-multipleDigit .uil-badge-secondary {
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
}

.uil-badge-multipleDigit .uil-badge-blue {
  background: var(--blue);
  color: var(--color-font-light);
}

.uil-badge-multipleDigit .uil-badge-green {
  background: var(--green);
  color: var(--color-font-light);
}

.uil-badge-multipleDigit .uil-badge-orange {
  background: var(--orange);
  color: var(--color-font-light);
}

.uil-badge-multipleDigit .uil-badge-red {
  background: var(--red);
  color: var(--color-font-light);
}

.uil-badge-multipleDigit .uil-badge-grey {
  background: var(--grey-medium);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-primary .uil-badge-smallDark,
.uil-badge-text .uil-badge-secondary .uil-badge-smallDark,
.uil-badge-text .uil-badge-blue .uil-badge-smallDark,
.uil-badge-text .uil-badge-green .uil-badge-smallDark,
.uil-badge-text .uil-badge-orange .uil-badge-smallDark,
.uil-badge-text .uil-badge-red .uil-badge-smallDark,
.uil-badge-text .uil-badge-grey .uil-badge-smallDark,
.uil-badge-text .uil-badge-primary .uil-badge-smallLight,
.uil-badge-text .uil-badge-secondary .uil-badge-smallLight,
.uil-badge-text .uil-badge-blue .uil-badge-smallLight,
.uil-badge-text .uil-badge-green .uil-badge-smallLight,
.uil-badge-text .uil-badge-orange .uil-badge-smallLight,
.uil-badge-text .uil-badge-red .uil-badge-smallLight,
.uil-badge-text .uil-badge-grey .uil-badge-smallLight {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 8px;
  gap: 4px;
  /* position: absolute; */
  width: fit-content;
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
  line-height: 120%;
}

.uil-badge-text .uil-badge-primary .uil-badge-smallDark {
  background: var(--primary-light);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-secondary .uil-badge-smallDark {
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
}

.uil-badge-text .uil-badge-blue .uil-badge-smallDark {
  background: var(--blue);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-green .uil-badge-smallDark {
  background: var(--green);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-orange .uil-badge-smallDark {
  background: var(--orange);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-red .uil-badge-smallDark {
  background: var(--red);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-grey .uil-badge-smallDark {
  background: var(--grey-medium);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-primary .uil-badge-smallLight {
  background: rgba(161, 0, 255, 0.1);
  color: var(--primary-light);
}

.uil-badge-text .uil-badge-secondary .uil-badge-smallLight {
  color: var(--primary-light);
  background: var(--color-font-light);
  border: 1px solid rgba(161, 0, 255, 0.1);
}

.uil-badge-text .uil-badge-blue .uil-badge-smallLight {
  background: rgba(0, 65, 240, 0.1);
  color: var(--blue);
}

.uil-badge-text .uil-badge-green .uil-badge-smallLight {
  background: rgba(18, 137, 38, 0.1);
  color: var(--green);
}

.uil-badge-text .uil-badge-orange .uil-badge-smallLight {
  background: rgba(210, 71, 2, 0.1);
  color: var(--orange);
}

.uil-badge-text .uil-badge-red .uil-badge-smallLight {
  background: rgba(230, 25, 45, 0.1);
  color: var(--red);
}

.uil-badge-text .uil-badge-grey .uil-badge-smallLight {
  background: rgba(116, 116, 116, 0.1);
  color: var(--grey-medium);
}

.uil-badge-text .uil-badge-primary .uil-badge-mediumDark,
.uil-badge-text .uil-badge-secondary .uil-badge-mediumDark,
.uil-badge-text .uil-badge-blue .uil-badge-mediumDark,
.uil-badge-text .uil-badge-green .uil-badge-mediumDark,
.uil-badge-text .uil-badge-orange .uil-badge-mediumDark,
.uil-badge-text .uil-badge-red .uil-badge-mediumDark,
.uil-badge-text .uil-badge-grey .uil-badge-mediumDark,
.uil-badge-text .uil-badge-primary .uil-badge-mediumLight,
.uil-badge-text .uil-badge-secondary .uil-badge-mediumLight,
.uil-badge-text .uil-badge-blue .uil-badge-mediumLight,
.uil-badge-text .uil-badge-green .uil-badge-mediumLight,
.uil-badge-text .uil-badge-orange .uil-badge-mediumLight,
.uil-badge-text .uil-badge-red .uil-badge-mediumLight,
.uil-badge-text .uil-badge-grey .uil-badge-mediumLight {
  cursor: pointer;
  line-height: 140%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 8px;
  gap: 4px;
  position: absolute;
  height: 24px;
  border-radius: 16px;
}

.uil-badge-text .uil-badge-primary .uil-badge-mediumDark {
  background: var(--primary-light);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-secondary .uil-badge-mediumDark {
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
}

.uil-badge-text .uil-badge-blue .uil-badge-mediumDark {
  background: var(--blue);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-green .uil-badge-mediumDark {
  background: var(--green);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-orange .uil-badge-mediumDark {
  background: var(--orange);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-red .uil-badge-mediumDark {
  background: var(--red);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-grey .uil-badge-mediumDark {
  background: var(--grey-medium);
  color: var(--color-font-light);
}

.uil-badge-text .uil-badge-primary .uil-badge-mediumLight {
  background: rgba(161, 0, 255, 0.1);
  color: var(--primary-light);
}

.uil-badge-text .uil-badge-secondary .uil-badge-mediumLight {
  border: 1px solid rgba(161, 0, 255, 0.1);
  color: var(--color-font-primaryLight);
  background: var(--color-border-light);
}

.uil-badge-text .uil-badge-blue .uil-badge-mediumLight {
  background: rgba(0, 65, 240, 0.1);
  color: var(--blue);
}

.uil-badge-text .uil-badge-green .uil-badge-mediumLight {
  background: rgba(18, 137, 38, 0.1);
  color: var(--green);
  width:100px;
}

.uil-badge-text .uil-badge-orange .uil-badge-mediumLight {
  background: rgba(210, 71, 2, 0.1);
  color: var(--orange);
}

.uil-badge-text .uil-badge-red .uil-badge-mediumLight {
  background: rgba(230, 25, 45, 0.1);
  color: var(--red);
  width:110px;
}

.uil-badge-text .uil-badge-grey .uil-badge-mediumLight {
  background: rgba(116, 116, 116, 0.1);
  color: var(--grey-medium);
}
/*# sourceMappingURL=uil_badge.module.css.map */