@import "./uil_dls.css";
@import "./common.css";
:root {
  --primary-01: #004dff;
  --primary-02: #0032a3;
  --primary-03: #83a9ff;
  --secondary-01: #4f6573;
  --secondary-02: #283940;
  --brand-01: #a100ff;
  --brand-02: #7500c0;
  --brand-03: #460073;
  --brand-04: #d48bff;
  --primary-dark: #460073;
  --primary-light: #a100ff;
  --secondary-dark: #7500c0;
  --secondary-light: #f5e5ff;
  --black: #000000;
  --grey-dark: #505050;
  --grey-medium: #747474;
  --grey-medium2: #777777;
  --grey-light1: #cccccc;
  --grey-light2: #efefef;
  --grey-light3: #aeaeae;
  --grey-light4: #f5f5f5;
  --white: #ffffff;
  --white-light: #f1f1f1;
  --red: #e6192d;
  --green: #128926;
  --green-light: #5aa700;
  --blue: #0041f0;
  --orange: #d24702;
  --font-family: uilicon_dl, Graphik;
  --font-size-caption-xxs: 10px;
  --font-size-caption-xs: 12px;
  --font-size-body-sm: 14px;
  --font-size-body-sm2: 15px;
  --font-size-body-md: 16px;
  --font-size-title-sm: 18px;
  --font-size-title-md: 20px;
  --font-size-title-lg: 24px;
  --font-size-heading-sm: 28px;
  --font-size-heading-md: 32px;
  --font-size-heading-lg: 48px;
  --font-size-heading-xl: 60px;
  --font-weight-xs: 300;
  --font-weight-sm: 400;
  --font-weight-md: 500;
  --font-weight-lg: 600;
  --color-bg-dark: var(--black);
  --color-bg-primaryDark: var(--primary-dark);
  --color-bg-primaryLight: var(--primary-light);
  --color-bg-secondaryDark: var(--secondary-dark);
  --color-bg-disabledDark: var(--grey-light1);
  --color-bg-disabled: var(--grey-light2);
  --color-bg-disabledLight: var(--grey-light4);
  --color-bg-light: var(--white);
  --color-bg-whiteLight: var(--white-light);
  --color-bg-green: var(--green);
  --color-bg-red: var(--red);
  --color-bg-secondaryLight: var(--secondary-light);
  --color-border-default: var(--grey-light1);
  --color-border-greyLight3: var(--grey-light3);
  --color-border-dark: var(--black);
  --color-border-primaryDark: var(--primary-dark);
  --color-border-primaryLight: var(--primary-light);
  --color-border-secondaryDark: var(--secondary-dark);
  --color-border-light: var(--white);
  --color-border-light1: var(--grey-light1);
  --color-border-error: var(--red);
  --color-border-light: var(--white);
  --color-border-secondaryLight: var(--secondary-light);
  --color-font-light: var(--white);
  --color-font-dark: var(--black);
  --color-font-primaryDark: var(--primary-dark);
  --color-font-primaryLight: var(--primary-light);
  --color-font-secondaryDark: var(--secondary-dark);
  --color-font-disabled: var(--grey-light1);
  --color-font-red: var(--red);
  --color-font-green: var(--green);
  --color-font-greenLight: var(--green-light);
  --color-font-greyDark: var(--grey-dark);
  --color-font-greyMedium: var(--grey-medium);
  --color-font-greyMedium2: var(--grey-medium2);
  --color-boxshadow-primaryLight: var(--primary-light);
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
