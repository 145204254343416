table > thead:first-child > tr:first-child > th,
table > thead:first-child > tr:first-child > td {
  column-width: 256px;
}
.tablefontstyle > tbody > tr > td:nth-child(2) {
  width: 600px;
  line-height: normal;
  height: 100%;
  display: block;
  white-space: break-spaces;
}
.tablefontstyle {
  overflow-x: hidden;
}
.table-container {
  overflow-x: hidden;
}
.fillRectangle {
  box-sizing: border-box;
  width: 50px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #ccd1d7;
}
.hexCodeContent {
  margin-left: 60px;
  top: 3px;
  position: relative;
}

.div-tabbar {
  background: #f0f0f0;
  /* right: 590px; */
  display: flex;
}

.tab-bar {
  /* right: inherit; */
  position: relative;
}

.typoHeadings {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #000000;
}

.typoContent {
  font-family: Graphik;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 24px;
}
.typoSections {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.fonttoken-caption {
  font-family: "Graphik";
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  min-width: 100px;
  position: relative;
  top: 20px;
}

.fonttoken-heading {
  font-family: "Graphik";
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  min-width: 100px;
  display: flex;
  align-items: center;
}

.fontoken-scale {
  display: flex;
  flex-direction: column;
}
.fontoken-scale > span {
  display: flex;
  height: 48px;
  align-items: center;
}

.fonttokens-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.fonttokens-onlyarrow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 116px;
}

.fonttokens-arrow {
  border: 2px solid #747474;
}

.style-typography-inPageContainer {
  display: flex;
  flex-direction: column;
}

.fonttokens-arrowIcon::before {
  content: "\e92d";
}

.fonttokens-arrowIcon::after {
  content: "\e92c";
  position: relative;
}
.typo-brandspace-card {
  width: 231px;
  height: 148px;
  margin-left: 25px;
}

.uil-basic-card {
  width: 230px;
  padding: 16px;
  height: auto;
}
.uil-dl-icon-Arrow-right-up-card::before {
  content: "\e9b3";
}
.uil-basic-card-captions {
  display: flex;
}
.uil-card .uil-basic-card-title{
  width: fit-content;
}

a{
  color: #000 !important;
}

a:hover{
  color: #000 !important;
}
